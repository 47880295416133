import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { RecurringForm } from "./RecurringForm";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import { DialogSearchOrder } from "./DialogSearchOrder";

import * as woocommerce from "../../../utils/woocommerce";
import * as wcApi from "../../../services/wcApi";

const useStyles = makeStyles(theme => ({
  dialogContent: {},
  field: {
    marginBottom: theme.spacing(2)
  },
  errorMessage: {
    textAlign: "center",
    padding: "0.2rem",
    backgroundColor: theme.palette.secondary.light
  },
  notice: {
    textAlign: "center",
    padding: "1.5rem 1rem",
    backgroundColor: "#57be8c",
    color: "white",
    margin: "0.5rem 0"
  },
  noticeError: {
    backgroundColor: theme.palette.secondary.light
  },
  readOnlyDetails: {
    textAlign: "center",
    padding: "0.5rem 1rem",
    backgroundColor: "#57be8c",
    color: "white",
    margin: "0.5rem 0",
    display: "flex",
    justifyContent: "space-between",
    "& > div:last-child": { fontWeight: "600" }
  }
}));

export function RecurringOrderDialog({ open, initialValues, onClose, onSave }) {
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [originalOrder, setOriginalOrder] = useState(null);
  const [roData, setRoData] = useState(null);
  const [formData, setFormData] = useState(null);

  async function checkIfAllItemsAvailable()
  {
    let hidden = [];
    let outOfStock = [];

    let order = await wcApi.fetchOrder(originalOrder.order_id ? originalOrder.order_id : originalOrder.id);
    let productIds = order.data.line_items.map(item => item.product_id);
    let products = await wcApi.fetchProductDetails(productIds);
    Object.values(products).forEach(product => {
      if (product.catalog_visibility === 'hidden') {
        if (!hidden.includes(product.name)) {
          hidden.push(product.name);
        }
      } else if (product.stock_status === "outofstock") {
        if (!outOfStock.includes(product.name)) {
          outOfStock.push(product.name);
        }
      }
    });

    let message = ``;
    if (hidden.length) {
      message = `Not salable items: ${hidden.join()}`
    }
    if (outOfStock.length) {
      if (message !== "") {
        message = `${message} \n`
      }
      message = `${message}Out of stock items: ${outOfStock.join()}`
    }

    if (message !== "")
    {
      setIsSaving(false);
      setErrorMessage(message);
      return false;
    }
    return true;
  }

  async function saveForm() {
    let allAvailable = await checkIfAllItemsAvailable();
    if (!allAvailable) {
      return;
    }

    const selectedDays = Object.values(formData.selectedDays);
    const payload = {
      expire_date: formData.selectedDate,
      order_id: roData.orderId,
      company: roData.name,
      is_active: formData.isActive,
      timeslot: roData.timeslot,
      days: [...selectedDays.keys()].filter(index => selectedDays[index])
    };

    setIsSaving(true);
    try {
      await onSave(payload);
    } catch (error) {
      setErrorMessage(
        "status" in error.response.data ? error.response.data.status : "Unexpected Error Ocurred"
      );
    }
    setIsSaving(false);
  }

  function resetForm() {
    setErrorMessage(null);
    setOriginalOrder(null);
    setRoData(null);
    setFormData(null);
    setIsSaving(false);
  }

  function handleFoundOrder(order) {
    if (order === null) {
      setOriginalOrder(null);
      setRoData(null);
    } else {
      updateStateOrder(order);
    }
  }

  function updateStateOrder(order) {
    const metadata = woocommerce.getMetadata(order.meta_data);
    setOriginalOrder(order);
    setRoData({
      orderId: order.order_id || order.id,
      name:
        order.company || order.shipping.company || order.shipping.first_name + " " + order.shipping.last_name,
      timeslot: order.timeslot || [metadata["Shookit Timeslot Start"], metadata["Shookit Timeslot End"]]
    });
  }

  // console.log(roData);
  if (!originalOrder && !roData && initialValues) {
    updateStateOrder(initialValues);
  }

  return (
    <Dialog
      open={open}
      onEnter={resetForm}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{ elevation: 24 }}>
      <DialogTitle id="form-dialog-title">Recurring Order</DialogTitle>

      {errorMessage && <Box className={classes.errorMessage}>{errorMessage}</Box>}

      <DialogContent dividers className={classes.dialogContent}>
        <Grid container direction="column" spacing={2}>
          {!initialValues && <DialogSearchOrder onFound={handleFoundOrder} />}

          {roData && (
            <React.Fragment>
              <Box className={`${classes.readOnlyDetails}`}>
                <div>Order ID#</div>
                <div>
                  <Link to={`/order/${roData.orderId}`}>{roData.orderId}</Link>
                </div>
              </Box>
              <Box className={`${classes.readOnlyDetails}`}>
                <div>Customer Name</div>
                <div>{roData.name}</div>
              </Box>
              <Box className={`${classes.readOnlyDetails}`}>
                <div>Timeslot</div>
                <div>{roData.timeslot.join(" - ")}</div>
              </Box>
            </React.Fragment>
          )}

          {/* Days */}
          <RecurringForm onChange={setFormData} initialValues={initialValues} />
        </Grid>
      </DialogContent>

      {isSaving && <LinearProgress />}

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary">
          Cancel
        </Button>
        <Button disabled={!originalOrder || isSaving} onClick={saveForm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
