import React, {useState} from 'react'

import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

import * as wcApi from "../../../../services/wcApi";
import * as giApi from "../../../../services/giApi";
import * as woocommerce from "../../../../utils/woocommerce";

const useStyles = makeStyles(theme => ({
  emptyOrders: {
    textAlign: "center",
    padding: theme.spacing(5)
  },
  paper: {
    padding: theme.spacing(2)
  },
  btnGroup: {
    marginRight: theme.spacing(2)
  },
  danger: {
    backgroundColor: 'rgba(227, 118, 86)',
  }
}));

export default function CreateReceiptDialog ({showModal, onClose, customer, orders, selectedOrders, fetchOrders}) {
  const classes = useStyles();
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isCreatingReceipt, setIsCreatingReceipt] = useState(false);
  const [createReceiptError, setCreateReceiptError] = useState(null);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [invalidDocumentId, setinvalidDocumentId] = useState(false)
  const [alreadyExistingDocumentId, setAlreadyExistingDocumentId] = useState(null);
  (() => {
    if (!showModal) {
      return;
    }
    wcApi.getOrdersTotal(selectedOrders).then(result => {
      setOrdersTotal(result);
      setIsModalLoading(false);
    });
  })()

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  const createReciept = async () => {
    setIsCreatingReceipt(true);
    let existingOrder = woocommerce.flattenOrderMetadata(orders.find(order => order.id === selectedOrders[0]));
    let documentId = existingOrder['_wc_shookit_greeninvoice_document'];
    
    try {
      if (ordersTotal === 0) {
        if (!/^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/.test(alreadyExistingDocumentId)) {
          setinvalidDocumentId(true);
          setIsCreatingReceipt(false);
          return;
        }
        await wcApi.markAsPaidManually(selectedOrders, alreadyExistingDocumentId, selectedDate);
      } else {
        await giApi.payInvoicedOrders(customer, documentId, ordersTotal, selectedDate, selectedOrders);
      }
      onClose()
      fetchOrders();
    }
    catch (err) {
      setCreateReceiptError(err.message || err);
      onClose()
    }
    finally {
      setIsCreatingReceipt(false);
      setSelectedDate(new Date());      
    }
  };

  const resetForm = () => {
    setSelectedDate(new Date());
    onClose();
  }

  
  const handleDocumentIdChange = (e) => {
    setAlreadyExistingDocumentId(e.target.value);
  }

  return (
    <>
    <Dialog fullWidth open={createReceiptError != null} onClose={() => resetForm()}>
    <DialogTitle>Failed to create a Receipt due to an error</DialogTitle>
    <DialogContent>
      <i>{createReceiptError}</i>
    </DialogContent>
    </Dialog>
    <Dialog fullWidth open={showModal} onClose={() => onClose()}>
    <DialogTitle>Create a Receipt for {selectedOrders.length} Orders!</DialogTitle>
    <DialogContent>
      <Grid container direction="column">
        {isModalLoading ? ( <Grid item><CircularProgress className={classes.loadingBar} /></Grid>) : (<>
        <Grid item>
          <Typography><b>Selected Orders total:</b>  {ordersTotal} ₪</Typography>
        </Grid>
        {ordersTotal === 0 && (
          <Grid item>
            <TextField
              required
              error={invalidDocumentId}
              type="text"
              helperText="Document ID from GreenInvocie - NOT THE NUMBER!"
              value={alreadyExistingDocumentId}
              onChange={handleDocumentIdChange}
            />
          </Grid>
        )}
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Payment Date"
            helperText="When did the customer pay?"
            value={selectedDate}
            onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        </>)}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={() => resetForm()}>
        Cancel
      </Button>
      <Button
        disabled={isCreatingReceipt}
        className={classes.danger}
        color="primary"
        type="submit"                
        onClick={createReciept}>
        Create Receipt!
      </Button>
    </DialogActions>
  </Dialog>
  </>
  )
}