import React, { useState, useEffect } from "react";
import { Tabs, Tab, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FloatingEditButtons from "../../../UI/FloatingEditButtons";
import * as wcApi from "../../../../services/wcApi";
import { isEmpty } from "lodash";
import UpdateOrderDialog from "../Dialogs/UpdateAddressDialog";
import AddressTypes from "../../../../enums/AddressTypes"
const useStyles = makeStyles(theme => ({
  addressTab: {
    minWidth: "50%"
  },
  addressPanel: {
    paddingTop: theme.spacing(1)
  },
  notice: {
    textAlign: "center",
    padding: "1.5rem 0"
  }
}));

function getAddress(order, type) {
  if (type === "billing") {
    return {
      address: order._billing_autofill_checkout || "",
      address_street : order.billing_address_street || "",
      address_street_number : order.billing_address_street_number || "",
      type: order._billing_address_type || "",
      entrance: order._billing_entrance || "",
      floor: order._billing_floor_number || "",
      apt: order._billing_apt_number || "",
      notes: order._billing_notes || ""
    };
  } else {
    return {
      address: order._shipping_autofill_checkout || "",
      address_city : order.address_city || "",
      address_street : order.shipping_address_street || "",
      address_street_number : order.shipping_address_street_number || "",
      type: order._shipping_address_type || "",
      entrance: order._shipping_entrance || "",
      floor: order._shipping_floor_number || "",
      apt: order._shipping_apt_number || "",
      notes: order._shipping_notes || "",
      buildingCode: getBuildingCode(order), 
    };
  }
}

function getBuildingCode (order) {
  const buildingCode = order.meta_data.find(meta_data => {
    return meta_data.key === "building_code";
  })
  return buildingCode ? buildingCode.value : ''; 
}

const AddressDetailsPane = ({ order, onOrderUpdated }) => {
  const styles = useStyles();

  const [isUpdating, setIsUpdating] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [buildingCode , setbuildingCode] = useState(getBuildingCode(order));
  const [address, setAddress] = useState(getAddress(order, "shipping"));

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setAddress(getAddress(order, tabValue === 0 ? "shipping" : "billing"));
    return () => {};
  }, [order]);

  const isCompany = (order) => {
    return order.shipping.company && !isEmpty(order.shipping.company);
  };

  const handleTabChange = (e, index) => {
    setTabValue(index);
    switch (index) {
      // Shipping
      case 0:
        setAddress(getAddress(order, "shipping"));
        break;
      // Billing
      case 1:
        setAddress(getAddress(order, "billing"));
        break;
      default:
        throw new Error("Tab index not found");
    }
  };

  async function handleDialogClosed(address) {
    setShowDialog(false);
    const type = tabValue === 0 ? "shipping" : "billing";
    let metadata = {};
    let payload = {};

    // Cancel Clicked
    if (address === null) {
      return;
    }

    payload[type] = {};

    // Address was not changed (but other values might !)
    if ("placeId" in address) {
      metadata.placeId = address.placeId;
      metadata.longlat = `${address.latLng.lng},${address.latLng.lat}`;
      metadata.long = address.latLng.lng;
      metadata.lat = address.latLng.lat;
      metadata.autofill_checkout = address.address;

      // Fill "WC" Address fields
      payload[type].address_1 = address.address1;
      payload[type].city = address.city;
    }

    // Fill Other Fields
    metadata.entrance = address.entrance;
    metadata.apt_number = address.apt;
    metadata.floor_number = address.floor;
    metadata.notes = address.notes;

    // if no city is present, use adress string as city
    if (!payload[type].city) {
      payload[type] = {
        address_1 : ' ',
        city : address.address
      }
    }

    // Rebuild metadata
    let wc_metadata = [];
    Object.keys(metadata).forEach(key => {
      wc_metadata.push({
        key: `_${type}_${key}`,
        value: metadata[key]
      });
    });

    payload["meta_data"] = wc_metadata;
    payload["meta_data"].push({
      key : 'building_code',
      value : address.buildingCode
    })

    payload["meta_data"].push({
      key : 'address_city',
      value : address.address_city
    })

    payload["meta_data"].push({
      key : 'shipping_address_street',
      value : address.address_street
    })

    payload["meta_data"].push({
      key : 'shipping_address_street_number',
      value : address.address_street_number
    })

    payload["meta_data"].push({
      key : 'billing_address_street',
      value : address.address_street
    })

    payload["meta_data"].push({
      key : 'billing_address_street_number',
      value : address.address_street_number
    })


    setbuildingCode(address.buildingCode)

    setIsUpdating(true);
    const newOrderDetails = await wcApi.updateOrder(order.id, payload);
    onOrderUpdated(newOrderDetails.data);
    setIsUpdating(false);
  }

  return (
    <>
      <UpdateOrderDialog
        open={showDialog}
        initialValues={address}
        onClose={handleDialogClosed}
        role={isCompany(order) ? "b2b" : "b2c"}
        deliverableAddress={tabValue === 0 }
      />
      <div className={styles.addressTabs}>
        <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
          <Tab
            className={styles.addressTab}
            label="Shipping Address"
            value={0}
            wrapped
          />
          <Tab
            className={styles.addressTab}
            label="Billing Address"
            value={1}
            wrapped
          />
        </Tabs>
      </div>

      <div className={styles.addressPanel}>
        <FloatingEditButtons
          onEditClicked={() => {
            setShowDialog(true);
          }}
          onDiscardClicked={() => {}}
          onSaveClicked={() => {}}
          editing={false}
        >
          {isEmpty(address.address) ? (
            <div className={styles.notice}>No address filled</div>
          ) : (
            <>
              <div>
                <b>Type:</b> {AddressTypes[address.type] || ""}
              </div>
              <div>
                <b>City:</b> {address.address}
              </div>
              <div>
                <b>Street:</b> {address.address_street}
              </div>
              <div>
                <b>Street Number:</b> {address.address_street_number}
              </div>
              <div>
                <b>Entrance:</b> {address.entrance || "-"} | <b>Floor:</b>{" "}
                {address.floor || "-"} | <b>Apartment:</b> {address.apt || "-"}
              </div>
              <div>
                <b>Building Code:</b> {buildingCode ? buildingCode : 'אין'}
              </div>
              <div>
                {address.notes ? (
                  <>
                    <b>Delivery Notes:</b> {address.notes}
                  </>
                ) : (
                  <>
                    <b>Delivery Notes:</b> -
                  </>
                )}
              </div>
            </>
          )}
        </FloatingEditButtons>
      </div>
      {isUpdating && <LinearProgress />}
    </>
  );
};

export default AddressDetailsPane;
