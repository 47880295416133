import { createMuiTheme } from "@material-ui/core/styles";

import lightGreen from "@material-ui/core/colors/lightGreen";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif"
  },
  palette: {
    primary: {
      main: "#024238"
    },
    secondary: {      
      main: "#2eb49f",
      secondary: "#57be8c"
    }
  }
});
