import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: "bold"
  },
  oldPrice: {
    textDecoration: "line-through"
  },
  redText: {
      color: "red"
  }
}));

const OrderSubtotalPane = ({
  totalTax,
  totalDiscount,
  totalShipping,
  totalPrice,
  totalRefund,
  totalPointsUsed,
  totalMultipassUsed,
  totalPointsRefund,
  totalTaxRefund,
  currencySymbol,
}) => {
  const styles = useStyles();
  const refund = totalPointsRefund + totalRefund;

  return (
    <>
    <div>
        Total Shipping Cost: {totalShipping.toFixed(2)}
        {currencySymbol}
      </div>
      <div>
        Total Discount: {totalDiscount.toFixed(2)}
        {currencySymbol}
      </div>
      <div>
        Total Tax: {" "}
        {totalTaxRefund ? (
          <span>
            <span className={styles.oldPrice}>
              {totalTax.toFixed(2)}
              {currencySymbol}
            </span>{" "}
            {(totalTax + totalTaxRefund).toFixed(2)}
          </span>
        ) : (
          totalTax.toFixed(2)
        )}
        {currencySymbol}
      </div>
      <div>
        Total Points Used: {totalPointsUsed ? `-${totalPointsUsed.toFixed(2)}` : 0 }
      </div>
      <div className={totalMultipassUsed > 0 ? styles.redText : ''}>
        Total Multipass Used: {totalMultipassUsed ? `-${totalMultipassUsed.toFixed(2)}` : 0 }₪
      </div>
      <div className={styles.bold}>
        Order Total With Tax:{" "}
        {refund ? (
          <span>
            <span className={styles.oldPrice}>
              {totalPrice.toFixed(2)}
              {currencySymbol}
            </span>{" "}
            {(totalPrice + refund).toFixed(2)}
          </span>
        ) : (
          totalPrice.toFixed(2)
        )}
        {currencySymbol}
      </div>
      {
        Math.abs(totalRefund) > 0 && !totalPointsRefund && (
          <div>
            Refund(CC): {totalRefund.toFixed(2)}
            {currencySymbol}
          </div>
        )
      }
      {
        Math.abs(totalPointsRefund) > 0 && (
          <div>
            Refund(Wallet): {totalPointsRefund.toFixed(2)}
          </div>

        )
      }
    </>
  );
};

OrderSubtotalPane.propTypes = {
  totalTax: PropTypes.number.isRequired,
  totalDiscount: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  totalPointsUsed: PropTypes.number.isRequired,
  totalMultipassUsed: PropTypes.number.isRequired,
  totalRefund: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired
};

export default OrderSubtotalPane;
