import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { classes } from "istanbul-lib-coverage";

const useStyles = makeStyles(theme => ({
  option: {
    textAlign: "center",
    padding: "0.5rem 1rem",
    backgroundColor: "#57be8c",
    color: "white",
    margin: "0.5rem 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#2e7553"
    }
  },
  input: {
    textAlign: "center",
    padding: "0.5rem 1rem",
    backgroundColor: "#57be8c",
    color: "white",
    margin: "0 0.5rem 0 0",
    cursor: "pointer",
    border: "0",
    width: "100%",
    fontSize: "1rem",
    "&::placeholder": {
      color: "#ffffffb0"
    }
  }
}));

export default function CancelDialog({ isOpen, cancelOrder, onClose, title, message }) {
  const classes = useStyles();
  const [inputField, setInputField] = useState("");

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          <div className={classes.option} onClick={() => cancelOrder("Customer reject")}>
            Customer reject
          </div>
          <div className={classes.option} onClick={() => cancelOrder("Created by mistake")}>
            Created by mistake
          </div>
          <div className={classes.option} onClick={() => cancelOrder("Order could not be fulfilled")}>
            Order could not be fulfilled
          </div>
          <div className={classes.option} onClick={() => cancelOrder("Recurring order canceled")}>
            Recurring order canceled
          </div>
          <form
            style={{ display: "flex" }}
            onSubmit={event => {
              event.preventDefault();
              cancelOrder(inputField);
            }}>
            <input
              className={classes.input}
              type="text"
              placeholder="Different Message"
              value={inputField}
              onChange={event => setInputField(event.target.value)}
            />
            <Button color="primary" size="small" variant="contained" type="submit">
              Send
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Whoops! Didn't meant to do that
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
