import { format } from "date-fns";
import parse from "date-fns/parse";

// Returns two ISO timestamps of today from 00:00 until 23:59
export function Today() {
  const start = new Date();
  const end = new Date();

  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);

  return [start.toISOString(), end.toISOString()];
}

// Format Date() object to URL based date param
export function WCFormatDay(date) {
  return format(date, "d-M-yyyy");
}

// Parses WC Date Format
function WCParseDate(date) {
  // return new Date();
  if (date) {
    return parse(date, "d-M-yyyy", new Date());
  }
  return date;
}

// Resets `time` fields and leaves only `day` fields
export function resetTime(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  // Remove timezoneoffset (if exists)
  return new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
}

export function DayRange(date) {
  const start = new Date(date);
  const end = new Date(date);

  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);

  return [start.toISOString(), end.toISOString()];
}

export function formatISODateString(dateString) {
    if(dateString.includes("Z"))
    {
        return dateString.replace("T", " ");
    }

    let utcDate = new Date(dateString + "Z");
    return utcDate.toLocaleDateString("en-IL") + " " + utcDate.toLocaleTimeString("en-IL")
}

const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export function dayName(index) {
  return DAYS[index];
}

export function dayShortname(index) {
  return DAYS[index].substring(0, 3);
}
