import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    // marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

export default function TopBar({ title, onBackClicked, onMenuClicked, onProfileClicked, onCartClicked }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          {onBackClicked && (
            <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={() => onBackClicked()}>
              <ArrowBackIosIcon />
            </IconButton>
          )}
          {onMenuClicked && (
            <IconButton edge="start" className={classes.menuButton} color="inherit" component={Link} to={onMenuClicked}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {onProfileClicked && (
            <IconButton edge="end" color="inherit" onClick={onProfileClicked}>
              <PersonIcon />
            </IconButton>
          )}
          {onCartClicked && (
            <IconButton edge="end" color="inherit"  onClick={onCartClicked}>
              <ShoppingCartIcon />
            </IconButton>
          )}
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </div>
  );
}
