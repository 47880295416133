import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import { makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import { getAddressDetails } from "../../../services/mapsApi";

const useStyles = makeStyles(theme => ({
  TextField: {
    width: "100%",
    margin: theme.spacing(1)
  },
  autocompleteDropdownContainer: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingLeft: "10px",
    paddingRight: "10px"
  }
}));

const searchOptions = {componentRestrictions: { country: "il" }, types: ['(cities)']}

const PlacesInput = ({ value, onChange, onSelect }) => {
  const classes = useStyles();

  async function handleSelect(address) {
    const addressDetails = await getAddressDetails(address);
    onSelect(addressDetails);
  }


  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            autoComplete="off"
            label="Address"
            className={classes.TextField}
            fullWidth
            {...getInputProps({
              placeholder: "Search Places ...",
              className: classes.TextField
            })}
          />

          <div className={clsx("autocomplete-dropdown-container")}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "#4BBD8E",
                    color: "white",
                    cursor: "pointer"
                  }
                : { backgroundColor: "#DBE6E8", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: clsx(
                      className,
                      classes.autocompleteDropdownContainer
                    ),
                    style
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default PlacesInput;
