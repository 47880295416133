import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  topBar: {
    position: "relative"
  },
  appBar: {
    position: "fixed",
    bottom: 0,
    top: "auto"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {},
  message: {
    height: "100%",
    fontSize: "2em",
    backgroundColor: "#eceff1"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, onCancel, onApprove }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog fullScreen open={open} onClose={onCancel} TransitionComponent={Transition}>
        <AppBar className={classes.topBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Pay Attention
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid className={classes.message} container direction="column" justify="center" alignItems="center" spacing={5}>
          <Grid item>Some items are missing</Grid>
          <Grid item>
            <Button color="primary" variant="contained" className={classes.button} size="large" onClick={onApprove}>
              Continue Anyway
            </Button>
          </Grid>
          <Grid item />
          <Grid item>
            <Button color="secondary" variant="contained" onClick={onCancel} className={classes.button}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
