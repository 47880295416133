import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { isEmpty } from "lodash";

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  // Load localStorage authentication information (if exists)
  const prevAuth = window.localStorage.getItem("authenticated") || false;
  const prevAuthBody = JSON.parse(window.localStorage.getItem("authBody")) || {};

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [authBody, setAuthBody] = useState(prevAuthBody);

  // Update axios headers
  if (authBody && "token" in authBody) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + authBody.token;
  }

  useEffect(() => {
    // If authenticated
    if (authenticated !== "true") {
      window.localStorage.removeItem("authenticated");
      window.localStorage.removeItem("authBody");
    } else {
      window.localStorage.setItem("authenticated", authenticated);
      window.localStorage.setItem("authBody", JSON.stringify(authBody));
    }
  }, [authenticated, authBody]);

  // Check token expiration
  if (!isEmpty(authBody)) {
    const now = Math.round(new Date().getTime() / 1000);
    const expiration = jwt_decode(authBody.token).exp;
    if (now > expiration) {
      console.log(">>>> Expired");
      setAuthenticated(false);
      setAuthBody(null);
      return <Redirect to="/login" />;
    }
  }

  // Save userId
  const userId = !isEmpty(authBody) ? jwt_decode(authBody.token).data.user.id : null;

  const defaultContext = {
    authenticated,
    setAuthenticated,
    authBody,
    setAuthBody,
    userId
  };

  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>;
};
