import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import * as config from "../../../config";

import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../UI/PageHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TimeslotsTableContainer from "./TimeslotsTableContainer";
import SpecialTimeslotsContainer from "./SpecialTimeslotsContainer";
import * as geoApi from "../../../services/geoApi";

const useStyles = makeStyles(theme => ({
  brandSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  brandLabel: {
    fontSize: "0.8rem",
    textTransform: "uppercase"
  }
}));

const ManageTimeslotsPage = ({ match }) => {
  const classes = useStyles();

  const [site, setSite] = useState(null);
  const [currentBrand, setCurrentBrand] = useState(config.ROLE_ALL_SITE);
  const siteId = match.params.siteId;

  useEffect(() => {
    async function fetchData() {
      const result = await geoApi.fetchShookitSiteInformation(siteId);

      setSite(result);
    }

    fetchData();
  }, [siteId]);

  console.log("SS");

  function evaluateCustomBrands() {
    var setBrands = _.uniq(site.timeslots.map(item => item.role));
    // var unsetBrands = config.SUPPORTED_ROLES.filter(item => !setBrands.includes(item));
    var unsetBrands = [config.ROLE_ALL_SITE, ...site.roles].filter(item => !setBrands.includes(item));
    setBrands = setBrands.map(brand => [brand, true]);
    unsetBrands = unsetBrands.map(brand => [brand, false]);

    return [...setBrands, ...unsetBrands];
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <PageHeader>{(site && site.name) || <CircularProgress />}</PageHeader>
        </Grid>

        {/* Brand selector */}
        <Grid item xs={2}>
          <Grid container spacing={2} justify="center" alignItems="center" wrap="nowrap">
            <Grid item>Brand:</Grid>
            <Grid item>
              <FormControl variant="outlined">
                <Select autoWidth value={currentBrand} onChange={event => setCurrentBrand(event.target.value)}>
                  {site &&
                    evaluateCustomBrands().map(brand => (
                      <MenuItem key={brand[0]} value={brand[0]}>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                          <Grid item xs={true}>
                            {brand[0] === config.ROLE_ALL_SITE ? (
                              "Default Windows"
                            ) : brand[1] ? (
                              <b>{brand[0]}</b>
                            ) : (
                              brand[0]
                            )}
                          </Grid>
                          {brand[0] !== config.ROLE_ALL_SITE && brand[1] && (
                            <Grid item align="right" className={classes.brandLabel}>
                              MODIFIED
                            </Grid>
                          )}
                        </Grid>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TimeslotsTableContainer siteId={siteId} currentBrand={currentBrand} site={site} setSite={setSite} />
        </Grid>
        <Grid item xs={12}>
          <SpecialTimeslotsContainer siteId={siteId} currentBrand={currentBrand} site={site} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManageTimeslotsPage;
