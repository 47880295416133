import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const parseGoogleAddressComponent = component => {
  const data = {};
  var houseNumberPresent = true; // set to true as part of changes required by Amir to be able to input addresses which are not recongized by Google

  /* Search address object for address components values */
  for (var i = 0; i < component.length; i++) {
    var addressType = component[i].types[0];
    var address = component;

    // Fill in the [Country]
    if (addressType === "country") {
      data["country"] = address[i]["short_name"];
    }

    // Fill in the [Address] + House Number
    if (addressType === "street_number") {
      houseNumberPresent = true;
      data["address1"] = address[i]["long_name"];
    }

    // Fill in the [Address] + Street Name
    if (addressType === "route") {
      var address1 = data["address1"] || "";
      data["address1"] = address1 + ", " + component[i]["long_name"];
    }

    // Fill in the [City]
    if (addressType === "locality") {
      data["city"] = address[i]["long_name"];
    }

    // Fill in additional data available [Address 2]
    if (addressType === "administrative_area_level_2") {
      data["address2"] = address[i]["long_name"];
    }
  }

  data["houseNumberPresent"] = houseNumberPresent;

  return data;
};

export const getAddressDetails = async address => {
  try {
    const geo = await geocodeByAddress(address);
    const latLng = await getLatLng(geo[0]);
    return {
      address,
      latLng,
      placeId: geo[0].place_id,
      ...parseGoogleAddressComponent(geo[0].address_components)
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};
