import React, { useState, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"

import Grid from "@material-ui/core/Grid"
import { makeStyles, withStyles} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import BackspaceIcon from '@material-ui/icons/Backspace';

import * as wcApi from "../../../services/wcApi";
import * as geoApi from "../../../services/geoApi";
import * as woocommerce from "../../../utils/woocommerce";
import { BILLING_ADMIN_ROLES } from "../../../config";
import { useWooUser } from "../../../utils/authentication/WooUserContext";
import { isEmpty } from "lodash";

import CustomerOrderRow from "./CustomerOrderRow";

import DateRangePicker from "../../UI/DateRangePicker"
import CreateReceiptDialog from "./Dialogs/CreateReceiptDialog"
import MarkAsInvoicedDialog from "./Dialogs/MarkAsInvoicedDialog"

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#024238",
    color: "white"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  emptyOrders: {
    textAlign: "center",
    padding: theme.spacing(5)
  },
  paper: {
    padding: theme.spacing(2)
  },
  btnGroup: {
    marginRight: theme.spacing(2)
  },
  danger: {
    backgroundColor: 'rgba(227, 118, 86)',
  }
}));

const FilterType = {
  None: "Not Set",
  NeedToReceipt: "Need To Receipt",
  PaymentFailed: "Payment Failed",
  NeedToInvoice: "Neeed To Invoice",
};

const CustomerOrders = ({ customer }) => {
  const wooUser = useWooUser();
  const customerId = customer.id;
  const [page, setPage] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const [orders, setOrders] = useState(null);
  const [sites, setSites] = useState(null);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [totalOrders, setTotalOrders] = useState(0);
  const [filterPendingPayment, setFilterPendingPayment] = useState(FilterType.None);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [showMarkAsInvoiced, setShowMarkAsInvoiced] = useState(false);
  const [dateSelcetion, setDateSelcetion] = useState([]);
  const handleChangePage = (e, newPage) => {
    setIsLoading(true);
    setPage(newPage+1);
    wcApi
          .fetchCustomerOrders(customerId, 50, newPage+1)
          .then(result => {
            if (result) {
              setOrders(result.data);
              setTotalOrders(sortOrdersByShookitDate(result.totalItems));
            } else {
              setNotFound(true);
            }
            setIsLoading(false);
          })
          .catch(err => {
            setNotFound(true);
            setIsLoading(false);
          });
  }

  const sortOrdersByShookitDate = (orders) => {
    let flattenedOrders = woocommerce.flattenOrdersMetadata(orders);
    return flattenedOrders.sort((a, b) => {
      let aDelivery = new Date(a['Shookit Timeslot Date']);
      let bDelivery = new Date(b['Shookit Timeslot Date']);
      
      if (aDelivery > bDelivery) {
        return -1;
      }

      if (bDelivery > aDelivery) {
        return 1;
      }

      return 0;
    })
  }

  const fetchOrders = (newPage = null) => {
    if (!newPage) {
      newPage = page;
    }
    setSelectedOrders([]);
    if (filterPendingPayment === FilterType.NeedToReceipt) {
      wcApi.fetchNeedToPayOrders(customerId).then(result => {
        if (result) {
          setOrders(sortOrdersByShookitDate(result));
          setTotalOrders(result.length)
        } else {
          setNotFound(true);
        }
        setIsLoading(false);
      }).catch(err => {
        setNotFound(true);
        setIsLoading(false);
      });
    } else if (filterPendingPayment === FilterType.PaymentFailed) {
      wcApi.fetchPaymentFailedOrders(customerId).then(result => {
        if (result) {
          setOrders(sortOrdersByShookitDate(result));
          setTotalOrders(result.length)
        } else {
          setNotFound(true);
        }
        setIsLoading(false);
      }).catch(err => {
        setNotFound(true);
        setIsLoading(false);
      });    
    } else if (filterPendingPayment === FilterType.NeedToInvoice) {
      wcApi.fetchNeedToInvoice(customerId).then(result => {
        if (result) {
          setOrders(sortOrdersByShookitDate(result));
          setTotalOrders(result.length)
        } else {
          setNotFound(true);
        }
        setIsLoading(false);
      }).catch(err => {
        setNotFound(true);
        setIsLoading(false);
      });
    } else {
      wcApi
        .fetchCustomerOrders(customerId, 50, newPage)
        .then(result => {
          if (result) {
            setOrders(sortOrdersByShookitDate(result.data));
            setTotalOrders(result.totalItems);
          } else {
            setNotFound(true);
          }
          setIsLoading(false);
        })
        .catch(err => {
          setNotFound(true);
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    geoApi
      .fetchAllSites()
      .then(result => {
        setSites(result);
        wcApi
        .fetchCustomerOrders(customerId, 50, page)
        .then(result => {
          if (result) {
            setOrders(sortOrdersByShookitDate(result.data));
            setTotalOrders(result.totalItems);
          } else {
            setNotFound(true);
          }
          setIsLoading(false);
        })
        .catch(err => {
          setNotFound(true);
          setIsLoading(false);
        });
      })
      .catch(err => {
        setSites([]);
        wcApi
        .fetchCustomerOrders(customerId, 50, page)
        .then(result => {
          if (result) {
            setOrders(sortOrdersByShookitDate(result.data));
            setTotalOrders(result.totalItems);
          } else {
            setNotFound(true);
          }
          setIsLoading(false);
        })
        .catch(err => {
          setNotFound(true);
          setIsLoading(false);
        });
      });

    return () => {};
  }, [customerId]);

  useEffect(() => {
    clearDateSelection();
    fetchOrders();
    return () => {};
  }, [filterPendingPayment])

  const clearFilter = () => {
    setIsLoading(true);
    setFilterPendingPayment(FilterType.None);    
  };

  const filterPending = () => {
    setIsLoading(true);
    setFilterPendingPayment(FilterType.NeedToReceipt);
  }

  const filterPaymentFailed = () => {
    setIsLoading(true);
    setFilterPendingPayment(FilterType.PaymentFailed);
  }

  const filterNeedToInvoice = () => {
    setIsLoading(true);
    setFilterPendingPayment(FilterType.NeedToInvoice);
  }

  const onDateSelectionChange = (selection) => {

    if (selection.begin == null && selection.end == null) return;
    setDateSelcetion(selection);
    const sStart = selection.begin.getTime();
    const sEnd = selection.end.getTime();
    let start = Math.min(sStart, sEnd);
    let end = Math.max(sStart, sEnd);
    if (end === start) {  
      selection.end.setDate(selection.end.getDate() + 1);
      end = selection.end.getTime()
    }
    let filteredOrders = [];
    for(let order of woocommerce.flattenOrdersMetadata(orders)) {
      let orderTime = new Date(order["Shookit Timeslot Date"]).getTime();
      if (orderTime >= start && orderTime <= end) {
        filteredOrders.push(order);
      }
    }

    setOrders(filteredOrders);
    setTotalOrders(filteredOrders.totalItems);
  }

  const onSelectOrder = (order_id, selected) => {
    if (selected) {
      if (selectedOrders.length) {
        let flatOrder = woocommerce.flattenOrderMetadata(orders.find(order => order.id === order_id));
        let existingOrder = woocommerce.flattenOrderMetadata(orders.find(order => order.id === selectedOrders[0]));
        if (flatOrder['_wc_shookit_greeninvoice_document'] !== existingOrder['_wc_shookit_greeninvoice_document']) {
          return;
        }
      }
      
      setSelectedOrders([...selectedOrders, order_id]);
    } else {
      setSelectedOrders([...selectedOrders.filter(oid => oid !== order_id)])
    }
  };

  const handleAllChecked = (e) => {
    if(e.target.checked) {
      let tempSelectedOrders = [];
      orders.map(order => {
        tempSelectedOrders.push(order.id);
      });
      setSelectedOrders(tempSelectedOrders);
    } else {
      setSelectedOrders([]);
    }
  };

  const clearDateSelection = () => {
    setDateSelcetion([]);
    setIsLoading(true);
    fetchOrders();
  }


  return (
    <>
      {isLoading ? (
        <Grid  container direction="row"
        justify="center"
        alignItems="center">
          <Grid item>
            <CircularProgress className={classes.loadingBar} />
          </Grid>
        </Grid>
        
      ) : (
        <>
          {BILLING_ADMIN_ROLES.includes(wooUser.role) && (<>
          <CreateReceiptDialog customer={customer} showModal={showReceiptModal} onClose={() => setShowReceiptModal(false)} orders={orders} fetchOrders={fetchOrders} selectedOrders={selectedOrders} />
          <MarkAsInvoicedDialog showModal={showMarkAsInvoiced} onClose={() => setShowMarkAsInvoiced(false)} selectedOrders={selectedOrders} fetchOrders={fetchOrders} />
          <Grid container direction="row" justify="space-between" alignItems="center" className={classes.paper}>
            <Grid item>
              <ButtonGroup className={classes.btnGroup} size="small" color="primary" aria-label="large outlined primary button group">
                <Button variant={filterPendingPayment === FilterType.None ? "contained" : undefined} onClick={clearFilter}>Show All</Button>
                <Button variant={filterPendingPayment === FilterType.NeedToReceipt ? "contained" : undefined} onClick={filterPending}>Need to be Paid</Button>
                <Button variant={filterPendingPayment === FilterType.PaymentFailed ? "contained" : undefined} onClick={filterPaymentFailed}>Payment Faild</Button>
                <Button variant={filterPendingPayment === FilterType.NeedToInvoice ? "contained" : undefined} onClick={filterNeedToInvoice}>Need to Invoice</Button>
              </ButtonGroup>
              {filterPendingPayment !== FilterType.None && (
                <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateRangePicker
                      autoOk
                      value={dateSelcetion}
                      placeholder="Select a date range"
                      onChange={values => {
                        onDateSelectionChange(values);
                      }}
                      disabled={filterPendingPayment === false}
                    />
                </MuiPickersUtilsProvider>
                {dateSelcetion && dateSelcetion.begin != null && dateSelcetion.end != null && (
                  <BackspaceIcon htmlColor="rgba(227, 118, 86)" onClick={() => clearDateSelection()} />
                )}
                </>
              )}
              <input type="checkbox" onClick={handleAllChecked} value="checkedall" />{" "} Select All
            </Grid>
            {selectedOrders.length > 0 && (
              <Grid item>
                <ButtonGroup className={classes.btnGroup} size="small" color="primary" aria-label="large outlined primary button group">
                  <Button disabled={filterPendingPayment !== FilterType.NeedToReceipt} onClick={() => setShowReceiptModal(true)}><ReceiptIcon />Create Receipt</Button>
                  <Button disabled={filterPendingPayment !== FilterType.NeedToInvoice} onClick={() => setShowMarkAsInvoiced(true)}><DescriptionIcon />Mark as Invoiced</Button>
                </ButtonGroup>
              </Grid>
            )}
          </Grid>
          </>)}
          <Table width={1}>
            <TableHead>
              <TableRow>
                { filterPendingPayment !== FilterType.None && (<StyledTableCell>Select</StyledTableCell>) }                
                <StyledTableCell>Order #</StyledTableCell>
                <StyledTableCell>Create Date</StyledTableCell>
                <StyledTableCell>Delivery date</StyledTableCell>
                <StyledTableCell>Window Start & End</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>Site</StyledTableCell>
                <StyledTableCell>Est./Final Price</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(orders) && (
                <TableRow>
                  <TableCell colSpan="8" className={classes.emptyOrders}>
                    No orders found for this user
                  </TableCell>
                </TableRow>
              )}
              {orders.map(order => (
                <CustomerOrderRow isSelectMode={filterPendingPayment !== FilterType.None} onSelectOrder={onSelectOrder} selectedOrders={selectedOrders} key={order.id} sites={sites} order={order} />
              ))}
            </TableBody>
          </Table>
          <TablePagination      
            rowsPerPageOptions={[]}
            component="div"
            count={parseInt(totalOrders) || 0}
            rowsPerPage={50}
            page={page-1}
            onChangePage={handleChangePage}
          />
        </>
      )}
    </>
  );
};

export default CustomerOrders;
