import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  makeStyles
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import * as wcApi from "../../../../services/wcApi";
import * as calcApi from "../../../../services/priceCalculatorApi";
import * as geoApi from "../../../../services/geoApi";
import { formatAsDay } from "../../../../utils/shookit"
import { flattenMetadata } from "../../../../utils/woocommerce";
import { useWooUser } from "../../../../utils/authentication/WooUserContext";
import {AVAILABLE_UNITS, FIXED_PRICING_METHOD, VAT, WC_ORDER_SITE, WEIGHT_PRICING_METHOD} from "../../../../config";

const useStyles = makeStyles(theme => ({
  buttonPadding: {
    padding: "5px 16px",
    "&:hover": { padding: "5px 16px" }
  },
  loadingBar: {
    position: "relative",
    left: "40%"
  }
}));

const DuplicateOrderDialog = ({ originalOrder, onClose, open }) => {

  const wooUser = useWooUser();
  const classes = useStyles();

  const history = useHistory();
  const maxDatesToShow = 7;
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedWindow, setSelectedWindow] = useState(null);
  const [selectedTs, setSelectedTs] = useState([null, null]);
  const [isLoading, setIsLoading] = useState(true);
  const [geoError, setGeoError] = useState(null);

  const getSiteTimeSlots = async () => {
    try {
      let role = originalOrder.billing.company ? "b2b" : "b2c";
      let timeSlots = await geoApi.fetchShookitUpcomingTimeslots(originalOrder[WC_ORDER_SITE], role);
      setAvailableTimeSlots(timeSlots.data.windows);
    } catch (err) {
      console.error(err);
      setGeoError({ message: "Failed to load site time slots" });
    }
  };
  const onCancelClicked = e => {
    if (onClose) {
      onClose(e);
    }
    setSelectedWindow(null);
    setSelectedTs([null, null]);
  };

  const selectWindowSlot = timeSlotId => {
    setSelectedWindow(timeSlotId);
  };

  const getSiteTimeSlotDate = () => {
    let ts = availableTimeSlots.find(ts => ts.id === selectedWindow);
    let date = new Date(ts.date.year, ts.date.month - 1, ts.date.day);
    date.setHours(0, 0, 0, 0);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
    return date.toISOString();
  };

  const deleteMetaKey = (obj, key_name) => {
    let metadata = obj.meta_data;
    for(let i = 0; i < metadata.length; i++) {
      if(metadata[i].key === key_name) {
        metadata.splice(i, 1);
        break;
      }
    }
  };

  const setMetaKey = (order, key_name, value) => {

    let key_exists = false;
    order.meta_data.forEach(item => {
      if (item.key === key_name) {
        item.value = value;
        key_exists = true;
      }
    });
    if (!key_exists) {
      order.meta_data.push({key: key_name, value: value});
    }
  };

  const initializeOrderFields = (order) => {
    let flatOrder = flattenMetadata(order);
    delete order.id;
    delete order.number;
    delete order.order_key;
    delete order["created-via"];

    order.status = "cancelled";
    order.date_paid = null;
    order.date_paid_gmt = null;
    order.date_completed = null;
    order.date_completed_gmt = null;
    order.date_modified = null;
    order.date_modified_gmt = null;
    order.cart_hash = "";
    order.transaction_id = "";
    order.coupons_lines = [];
    order.coupon_lines = [];
    order.fee_lines = [];
    order.shipping_lines = order.shipping_lines.map(line => ({ ...line, id: undefined }));

    let leaveAtDoor = (selectedTs.length > 2 && selectedTs[3].isNightWindow) ? 1 : 0 ;
    setMetaKey(order, "onfleet_task_id", "");
    setMetaKey(order, "boxes_quantity", "");
    setMetaKey(order, "Shookit Site", originalOrder["Shookit Site"]);
    setMetaKey(order, "Shookit Timeslot Start", selectedTs[0]);
    setMetaKey(order, "Shookit Timeslot End", selectedTs[1]);
    setMetaKey(order, "leave_at_door", leaveAtDoor);
    setMetaKey(order, "Shookit Timeslot Date", getSiteTimeSlotDate());
    setMetaKey(order, "feedback_score", "pending");
    setMetaKey(order, "shipment_status", "pending");
    setMetaKey(order, "_draft_order", "1");
    setMetaKey(order, "_created_by_admin", wooUser.id);
    setMetaKey(order, 'missing_items_sms_sent', null)
    setMetaKey(order, "force_free_shipping", "1"); // for duplicated orders free shipping is forced

    
    // CreditGuard
    if (flatOrder._creditguard_authorization) {
      // _ccnumber 
      // _payments
      // _payment_gateway
      // _first_payment
      // _creditguard_token
      // _creditguard_expiration
      // _creditguard_authorization
      // _periodical_payment
      setMetaKey (order, "_ccnumber", flatOrder._ccnumber);
      setMetaKey (order, "_payments", flatOrder._payments);
      setMetaKey (order, "_periodical_payment", flatOrder._periodical_payment);
      setMetaKey (order, "_first_payment", flatOrder._first_payment);
      setMetaKey (order, "_creditguard_token", flatOrder._creditguard_token);
      setMetaKey (order, "_creditguard_expiration", flatOrder._creditguard_expiration);
      setMetaKey (order, "_creditguard_authorization", flatOrder._creditguard_authorization);
      setMetaKey (order, "_payment_gateway", 'CreditGuard' );
      order.payment_method = "creditguard";
      order.payment_method_title = "תשלום באמצעות קרדיטגארד";
    }

    deleteMetaKey(order, "SHOOKIT_SEEN");
    deleteMetaKey(order, "SHOOKIT_STATUS");
    deleteMetaKey(order, "SHOOKIT_STATUS_FULFILLING_datetime");
    deleteMetaKey(order, "SHOOKIT_STATUS_FULFILLING_user_id");
    deleteMetaKey(order, "SHOOKIT_STATUS_PENDING_datetime");
    deleteMetaKey(order, "SHOOKIT_STATUS_PENDING_user_id");
    deleteMetaKey(order, "SHOOKIT_STATUS_READY_datetime");
    deleteMetaKey(order, "SHOOKIT_STATUS_READY_user_id");
    deleteMetaKey(order, "SHOOKIT_STATUS_ON_ITS_WAY_datetime");
    deleteMetaKey(order, "SHOOKIT_STATUS_ON_ITS_WAY_user_id");
    deleteMetaKey(order, "SHOOKIT_STATUS_ARRIVED_datetime");
    deleteMetaKey(order, "SHOOKIT_STATUS_ARRIVED_user_id");
    deleteMetaKey(order, "_cancel_reason");
    deleteMetaKey(order, "hidden_items");
    deleteMetaKey(order, "onfleet_task_id");
    deleteMetaKey(order, "completed_by_onfleet");
    deleteMetaKey(order, "outofstock_items");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_created");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_document");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_send_time");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_due_time");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_failed_to_invoice");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_receipt_document");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_receipt_send_time");
    deleteMetaKey(order, "_wc_shookit_greeninvoice_receipt_charge_date");
    deleteMetaKey(order, "creditguard_cardId");
    deleteMetaKey(order, "creditguard_tranId");
    deleteMetaKey(order, "zoho_salesorder_id");
    deleteMetaKey(order, "zoho_package_id");
    deleteMetaKey(order, "is_recurring");
    deleteMetaKey(order, "sent_shookbook");

  };

  const getItemTotalPrice = async (flatItem, basePrice, newMetadata ) => {
    let total_price = 0;
    const isItemCalculated = await calcApi.isProductCalculated(flatItem.product_id);
    if(isItemCalculated) {
      if(!flatItem.Type) {
        throw new Error("Product calculation changed. Product ID:" + flatItem.product_id);
      }
      newMetadata.push(
        {key: "Type", value: flatItem.Type},
        {key: "Quantity", value: flatItem.shookit_requested_quantity}
      );
      total_price = await calcApi.calculatePrice(
        flatItem.product_id,
        flatItem.shookit_requested_quantity,
        flatItem.Type === AVAILABLE_UNITS.KG ? FIXED_PRICING_METHOD : WEIGHT_PRICING_METHOD,
        basePrice);
    }
    else {
      total_price = basePrice * flatItem.shookit_requested_quantity;
    }
    return total_price;
  };

  const checkIfItemsAvailable = async (order, products, hidden, outOfStock) => {
    Object.values(products).forEach(product => {
      if (product.catalog_visibility === 'hidden') {
        if (!hidden.includes(product.name)) {
          hidden.push(product.name);
        }
      } else if (product.stock_status === "outofstock") {
        if (!outOfStock.includes(product.name)) {
          outOfStock.push(product.name);
        }
      }
    });

    if (hidden.length) {
      order.meta_data.push({key: 'hidden_items', value: hidden.join()})
    }

    if (outOfStock.length) {
      order.meta_data.push({key: 'outofstock_items', value: outOfStock.join()})
    }
  };

  const getItems = async (order) => {
    let items = [];
    let hidden = [];
    let outOfStock = [];

    let productIds = order.line_items.map(item => item.product_id);
    if (productIds.length === 0) {
      return [];
    }
    let products = await wcApi.fetchProductDetails(productIds);

    await checkIfItemsAvailable(order, products, hidden, outOfStock);

    for(let i = 0 ; i < order.line_items.length; i ++) {
      const item = order.line_items[i];
      const flatItem = flattenMetadata(item);

      let product = await wcApi.getProduct(item.product_id, order.customer_id);

      let basePriceOverride = item.meta_data.find(metaDataLine => {
        return  metaDataLine.key == 'shookit_base_price_override' ? metaDataLine.value : false   
      })

      basePriceOverride = basePriceOverride ? basePriceOverride.display_value : product.price;

      let newMetadata = [
        {key: 'shookit_requested_quantity', value: flatItem.shookit_requested_quantity},
        {key: 'shookit_requested_unit', value: flatItem.shookit_requested_unit},
        {key: 'shookit_pricing_method', value: flatItem.shookit_pricing_method},
        {key: 'shookit_base_price_override', value: basePriceOverride}
      ];

      if (!hidden.includes(item.name) && !outOfStock.includes(item.name)) {
        let total_price = await getItemTotalPrice(flatItem, parseFloat(product.price), newMetadata);
        if(product.tax_status == 'taxable') {
          total_price = total_price / VAT
        }
        items.push({
          name: item.name,
          product_id : item.product_id,
          variation_id: item.variation_id,
          quantity: flatItem.shookit_requested_quantity,
          tax_class: item.tax_class,
          subtotal: total_price.toString(),
          total: total_price.toString(),
          meta_data: newMetadata
        });
      }
    }
    return items;
  };

  const duplicateOrder = async () => {
    try {
      setIsLoading(true);
      let order = await wcApi.fetchOrder(originalOrder.id);
      initializeOrderFields(order.data);
      order.data.line_items = await getItems(order.data);

      let result = await wcApi.duplicateOrder(order.data);
      setIsLoading(false);

      history.push(`/order/${result.data.id}`);
      onCancelClicked(result.id);

    } catch (err) {
      console.log(err);
    }
  };

  const selectTs = ts => {
    setSelectedTs(ts);
  };

  useEffect(() => {
    getSiteTimeSlots()
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  }, originalOrder[WC_ORDER_SITE]);


  if (isLoading) {
    return (
      <Dialog open={open} onClose={onCancelClicked}>
        <DialogTitle>Duplicating Order</DialogTitle>
        <DialogContent>
          <CircularProgress className={classes.loadingBar}/>
        </DialogContent>
      </Dialog>
    );
  }

  if (geoError) {
    return (
      <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
        <DialogTitle>Can not duplicate order</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Due to an error: <i>{geoError.message}</i>
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
      <DialogTitle>Duplicate Order</DialogTitle>
      <DialogContent dividers>
        <Typography varian="h4">Select Day</Typography>
        <Grid container direction="row">
          {availableTimeSlots.slice(0, maxDatesToShow).map(timeslotWindow => (
            <Grid item key={timeslotWindow.id}>
              <Button
                variant={selectedWindow === timeslotWindow.id ? "outlined" : "text"}
                className={classes.buttonPadding}
                color={selectedWindow === timeslotWindow.id ? "primary" : "default"}
                onClick={() => selectWindowSlot(timeslotWindow.id)}>
                {formatAsDay(new Date(
                  timeslotWindow.date.year,
                  timeslotWindow.date.month - 1,
                  timeslotWindow.date.day))
                }
              </Button>
            </Grid>
          ))}
        </Grid>
        <br/>
        <Typography varian="h4">Select Time slot</Typography>
        <Grid container direction="column">
          {selectedWindow != null ? (
            availableTimeSlots
              .find(ts => ts.id === selectedWindow)
              .timeslots.map((ts, index) => (
              <Grid item key={index}>
                <Button
                  className={classes.buttonPadding}
                  variant={selectedTs[0] === ts[0] && selectedTs[1] === ts[1] ? "outlined" : "text"}
                  color={selectedTs[0] === ts[0] && selectedTs[1] === ts[1] ? "primary" : "default"}
                  onClick={() => selectTs(ts)}>
                  {ts[0]} - {ts[1]}
                </Button>
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={selectedTs[0] == null || selectedTs[1] == null}
          onClick={duplicateOrder}>
          Duplicate Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateOrderDialog;
