import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { zipObject } from "lodash";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(theme => ({}));

export function RecurringForm({ initialValues, onChange }) {
  const classes = useStyles();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const initialMapping = days.map((day, index) =>
    initialValues ? initialValues.days.includes(index) : false
  );

  const [isActive, setIsActive] = useState(initialValues ? initialValues.is_active : true);
  const [selectedDate, setSelectedDate] = useState(
    initialValues ? new Date(initialValues.expire_date) : new Date(2025, 2, 20)
  );
  const [selectedDays, setSelectedDays] = useState(zipObject(days, initialMapping));

  useEffect(() => {
    onChange({ isActive, selectedDate, selectedDays });
    return () => {};
  }, [isActive, selectedDate, selectedDays]);

  const handleDayChange = day => event => {
    setSelectedDays({ ...selectedDays, [day]: event.target.checked });
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  return (
    <React.Fragment>
      <Grid item>
        <FormLabel style={{ paddingTop: "0.7rem", paddingBottom: "0.7rem" }} component="legend">
          Day
        </FormLabel>

        <Grid container alignItems="center" justify="center">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(day => (
            <Grid
              key={day}
              container
              style={{ width: "auto" }}
              direction="column"
              alignItems="center"
              justify="center">
              <Grid item>{day}</Grid>
              <Grid item>
                <Checkbox color="primary" checked={selectedDays[day]} onChange={handleDayChange(day)} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Expiration Date */}
      <Grid
        item
        style={{
          width: "100%"
        }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            fullWidth
            format="dd/MM/yyyy"
            margin="normal"
            id="expire_date"
            label="Expiration Date"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      {/* Activation Status */}
      <Grid
        item
        style={{
          width: "100%",
          textAlign: "center"
        }}>
        <FormControlLabel
          control={
            <Switch
              checked={isActive}
              onChange={event => {
                setIsActive(event.target.checked);
              }}
              value="active"
              color="primary"
            />
          }
          label="Active"
        />
      </Grid>
    </React.Fragment>
  );
}
