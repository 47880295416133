import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import * as wcApi from "../../../services/wcApi";

import PaymentTypes from "../../../enums/PaymentTypes";
import FormSelect from "../../UI/FormSelect";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  }
}));

export default function OrderPaymentMethodField({ order, onOrderUpdate }) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentOption, setCurrentOption] = useState(order.payment_method);

  const optionKeys = Object.keys(PaymentTypes);
  const optionValues = optionKeys.map(key => PaymentTypes[key]);

  async function handleChange(selectedValue) {
    const index = optionValues.findIndex(value => value === selectedValue);
    if (index < 0) return;

    const newPaymentMethod = optionKeys[index];

    setIsSaving(true);
    const payload = {
      payment_method: newPaymentMethod
    };
    
    const result = await wcApi.updateOrder(order.id, payload);
    onOrderUpdate(result.data);
    setCurrentOption(newPaymentMethod);
    setEditMode(false);
    setIsSaving(false);
  }

  function paymentMethod() {
    if (order.payment_method && order.payment_method in PaymentTypes) {
      return PaymentTypes[order.payment_method];
    }

    return order.payment_method;
  }

  // Find Default
  let selectedValue = null;
  if (currentOption) {
    const index = optionKeys.findIndex(option => option === order.payment_method);
    if (index >= 0) {
      selectedValue = optionValues[index];
    }
  }

  if (editMode) {
    return (
      <div>
        <b>Payment Method:</b>{" "}
        <FormSelect
          small
          values={optionValues}
          initialValue={selectedValue}
          onChange={handleChange}
          fullWidth={false}
        />
      </div>
    );
  } else {
    return (
      <div onClick={() => setEditMode(true)} className={classes.clickable}>
        <div>
          <strong>Payment Method:</strong> {paymentMethod()}
        </div>
        {isSaving && <LinearProgress />}
      </div>
    );
  }
}
