import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import * as wcApi from "../../../services/wcApi";

import { TextField, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  }
}));

export default function BillingEmailField({ customer, onCustomerUpdated }) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [billingEmail, setBillingEmail] = useState(customer.billing.email);


  const saveChanges = async () => {

    // Update site in database
    setIsSaving(true);
    const payload = {
      billing: {
        email: billingEmail
      }
    };
    const result = await wcApi.updateCustomer(customer.id, payload);
    onCustomerUpdated(result.data);
    setEditMode(false);
    setIsSaving(false);
  }

  function handleSiteChanged(e) {
    setBillingEmail(e.target.value);
  }


  if (editMode) {
    return (
      <div>
        <b>Mailing address:</b>{" "}
          <TextField
            small
            type="email"
            value={billingEmail}
            onChange={handleSiteChanged}
            fullWidth={false}
            onBlur={() => saveChanges()}
          />        
      </div>
    );
  } else {
    return (
      <div onClick={() => setEditMode(true)} className={classes.clickable}>
        <b>Mailing address :</b> {billingEmail || "[not set]"}
        {isSaving && <LinearProgress />}
      </div>
    );
  }
}
