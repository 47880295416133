import React, { useState } from "react";
import LongPress from "react-long";
import clsx from "clsx";
import { getHours, isToday, parse, differenceInMinutes } from "date-fns";
import { groupBy } from "lodash";

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Toolbar,
  Typography,
  Box,
  Grid,
  Button,
  AppBar
} from "@material-ui/core"
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from "@material-ui/core/styles";

import * as conf from "../../../config";
import * as shookit from "../../../utils/shookit";
import { getMetadata } from "../../../utils/woocommerce";

const useStyles = makeStyles(theme => ({
  list: {
    marginBottom: theme.spacing(8),
    minHeight: "400px",
    userSelect: "none"
  },
  listItem: {
    marginBottom: theme.spacing(1),
    backgroundColor: "white",
    userSelect: "none"
  },
  highlight: {
    backgroundColor: "#bbdefb"
  },
  firstItem: {
    flex: 2
  },
  secondItem: {
    flex: "inherit",
    minWidth: "100px",
    textTransform: "capitalize"
  },
  selectionBar: {
    bottom: "55px",
    top: "auto",
    position: "fixed",
    background: "#57be8c"
  },
  checkbox: {
    // color: "#2196f3"
  },
  highlightUpcoming: {
    background: "#ffe0b2 !important"
  },
  highlightLate: {
    background: "#f75f5f !important"
  },
  cancelButton: {
    color: "white",
    border: "1px solid rgb(0, 66, 56)"
  },
  submitButton: {
    color: "white",
    background: "#004238"
  },
  startTime: {
    margin: "1px 10px",
    fontWeight: "bold"
  },
  secondayTypographyWarningLate: {
    color: "white",
    fontWeight: "bold",
    fontSize: "1rem"
  },
  secondayTypographyWarningNormat: {
    color: "#f75f5f",
    fontWeight: "bold",
    fontSize: "1rem"
  }
}));

export default function OrderList({
  orders,
  onPress,
  enableSelection,
  limitToSingleSelection,
  onSelected,
  buttonTitle
}) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);

  function toggleOrder(orderId) {
    if (limitToSingleSelection) {
      setSelectedOrders([orderId]);
    } else {
      if (selectedOrders.includes(orderId)) {
        const index = selectedOrders.indexOf(orderId);

        let newSelection = Array.from(selectedOrders);
        newSelection.splice(index, 1);
        setSelectedOrders(newSelection);
      } else {
        let newSelection = Array.from(selectedOrders);
        setSelectedOrders([...selectedOrders, orderId]);
      }
    }
  }

  function handlePress(orderId) {
    if (!editMode) {
      onPress(orderId);
    } else {
      toggleOrder(orderId);
    }
  }

  function handleLongPress(orderId) {
    if (enableSelection) {
      toggleOrder(orderId);
      setEditMode(true);
    }
  }

  function handleSelected() {
    onSelected(selectedOrders);
    setSelectedOrders([]);
    setEditMode(false);
  }

  function handleCancel() {
    setSelectedOrders([]);
    setEditMode(false);
  }

  function getLateClass(order) {
    const deliveryDay = new Date(order["Shookit Timeslot Date"]);
    const startHour = parse(order["Shookit Timeslot Start"], "HH:mm", new Date());
    const endHour = parse(order["Shookit Timeslot End"], "HH:mm", new Date());
    const currentHour = new Date();
    const currentStatus = shookit.getShookiterStatus(order);

    if (!isToday(deliveryDay)) {
      return undefined;
    }

    // Every order that not arrived after the window is red
    if (shookit.statusValues.ARRIVED !== currentStatus && differenceInMinutes(endHour, currentHour) < 0) {
      return classes.highlightLate;
    }

    // Policies
    switch (currentStatus) {
      case shookit.statusValues.PENDING:
      case shookit.statusValues.FULFILLING:
        if (differenceInMinutes(startHour, currentHour) < 0) return classes.highlightLate;
        if (differenceInMinutes(startHour, currentHour) < 30) return classes.highlightUpcoming;
        break;
      case shookit.statusValues.READY:
      case shookit.statusValues.ON_ITS_WAY:
        if (differenceInMinutes(endHour, currentHour) < 30) return classes.highlightUpcoming;
      case shookit.statusValues.ARRIVED:
        return undefined;
    }

    return undefined;
  }

  //function checkItems(order) {
  //  for (let i = 0; i < order.line_items.length; i++) {
  //    let item = { ...order.line_items[i], ...getMetadata(order.line_items[i].meta) };
  //    if (item.shookit_pricing_method !== conf.FIXED_PRICING_METHOD) {
  //      if (parseFloat(item.shookit_shipped_weight) !== 0 &&
  //        parseFloat(item.shookit_shipped_weight) > parseFloat(item.shookit_estimated_weight) * shookit.MAX_ITEM_OVER_PACKING) {
  //        return false;
  //      }
  //    }
  //
  //    if (parseFloat(item.shookit_shipped_quantity) !== 0 &&
  //      parseFloat(item.shookit_shipped_quantity) > parseFloat(item.shookit_requested_quantity) * shookit.MAX_ITEM_OVER_PACKING) {
  //      return false;
  //    }
  //  }
  //  return true;
  //}
  const getSecondayColor = (order) => {
    if (order.shipping.company) {
      return undefined;
    } else {
      if (order.total < 1000) {
        return undefined;
      }
      if (getLateClass(order) !== undefined) {
        return classes.secondayTypographyWarningLate;
      }
      return classes.secondayTypographyWarningNormat;
    }
  }

  // Renders a single order item
  function renderOrder(order) {
    return (
      <LongPress
        time={800}
        onLongPress={() => handleLongPress(order.id)}
        onPress={() => handlePress(order.id)}
        key={order.id}>
        <ListItem
          button
          className={clsx(classes.listItem, getLateClass(order), {
            [classes.highlight]: !shookit.isSeen(order)
          })}
          // onClick={() => handlePress(order.id)}
        >
          {editMode && (
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                edge="start"
                checked={selectedOrders.includes(order.id)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
          )}
          <ListItemText
            className={classes.firstItem}
            primary={order.shipping.company ? order.shipping.company : order["_shipping_autofill_checkout"]}
            secondary={`#${order.id} | ${order.total} ₪`}
            secondaryTypographyProps={{
              className: getSecondayColor(order)
            }}
          />
          {/*
          { !checkItems(order) && (
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
          )}
          */ }
          <ListItemText
            className={classes.secondItem}
            primary={shookit.formatShookitStatus(shookit.getShookiterStatus(order))}
            secondary={`${order["Shookit Timeslot Start"]} - ${order["Shookit Timeslot End"]}`}
          />
        </ListItem>
      </LongPress>
    );
  }

  // Renders a list of orders of the same start time
  function renderOrdersList(ordersList) {
    let startTime = ordersList[0][conf.WC_ORDER_TS_START];
    return (
      <Box key={startTime}>
        <Typography className={classes.startTime}>{startTime}</Typography>
        {<List>{ordersList.map(order => renderOrder(order))}</List>}
      </Box>
    )
  }


  if (orders && orders.length > 0) {
    let ordersByStart = groupBy(orders, order => {
      return order[conf.WC_ORDER_TS_START].split(":")[0]
    });

    let sorterOrdersByStart = {};
    Object.keys(ordersByStart)
      .sort()
      .forEach(function(v, i) {
        sorterOrdersByStart[parseInt(v)] = ordersByStart[v];
      });
    return (
      <React.Fragment>
        {editMode && (
          <SelectionBar
            orders={orders}
            selectedOrders={selectedOrders}
            limitToSingleSelection={limitToSingleSelection}
            onClick={handleSelected}
            onCancel={handleCancel}
            buttonTitle={buttonTitle}
          />
        )}
        <List className={classes.list}>{Object.values(sorterOrdersByStart).map(orderList => renderOrdersList(orderList))}</List>
      </React.Fragment>
    );
  } else {
    return (
      <Box className={classes.list} fontSize="1.2rem" pt={3} textAlign="center">
        No orders found
      </Box>
    );
  }
}

function SelectionBar({ selectedOrders, orders, onCancel, onClick, buttonTitle, limitToSingleSelection }) {
  const classes = useStyles();

  let isArrived = false;
  if (buttonTitle === "Arrived" && limitToSingleSelection) {
    let [selectedOrder] = orders.filter(order => order.id === selectedOrders[0]);
    if (selectedOrder.SHOOKIT_STATUS === shookit.statusValues.ARRIVED) {
      isArrived = true;
    }
  }

  return (
    <AppBar className={classes.selectionBar} position="fixed">
      <Toolbar>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Button variant="outlined" onClick={onCancel} className={classes.cancelButton}>
              Cancel
            </Button>
          </Grid>
          {isArrived ? (
            <Grid item>
              <Typography>Already Arrived!</Typography>
            </Grid>
            ) : (
            <>
              <Grid item>
                <Typography>{selectedOrders.length} Selected</Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onClick} className={classes.submitButton}>
                  {buttonTitle}
                </Button>
              </Grid>
            </>
            )
            }
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
