import React from "react";
import AsyncSelect from "react-select/async";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { makeStyles, useTheme } from "@material-ui/core";

import { debounce } from "lodash";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 12
  },
  placeholder: {
    position: "absolute",
    // padding: "6px 0px 7px",    
    color: "rgba(0, 0, 0, 0.87);",
    opacity: "0.42"
    // left: 2
  },
  paper: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(5)
  },
  dropdownIndicator: {}
});
const useStyles = (customStyles = a => ({})) =>
  makeStyles(theme => ({ ...styles(theme), ...customStyles(theme) }));

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function OptionDefaultComponent(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}></MenuItem>
  );
}

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}
function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.data.name}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} square {...props.innerProps}>
      <div
        style={{
          position: "fixed",
          zIndex: "1000",
          background: "white",
          borderRadius: "4px",
          boxShadow:
            "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
        }}>
        {props.children}
      </div>
    </Paper>
  );
}

function DropdownIndicator(props) {
  return <div></div>;
}
function LoadingIndicator(props) {
  return <></>;
}

const Autocomplete = props => {
  const Option = props.optionComponent || OptionDefaultComponent;
  const loadOptions = props.loadOptions || (() => {});
  const placeHolder = props.placeholder || "";
  const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    LoadingIndicator
  };

  const classes = useStyles(props.customStyles)();

  const defaultOnChange = () => {};
  const theme = useTheme();
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };

  // It look's like a hack - but AsyncSelect doesn't handle properly 'async' functions
  const debounceLoadOptions = debounce((inputValue, callback) => {
    loadOptions(inputValue).then(results => callback(results));
    // Explicitly not returning a Promise.
    return;
  }, 500);

  return (
    <Container className={classes.container}>
      <AsyncSelect
        styles={selectStyles}
        inputId="react-select-single"
        TextFieldProps={{
          label: "Search",
          InputLabelProps: {
            htmlFor: "react-select-single",
            shrink: true
          }
        }}
        classes={classes}
        cacheOptions
        loadOptions={debounceLoadOptions}
        defaultOptions
        components={components}
        onChange={props.onChange || defaultOnChange}
        placeholder={placeHolder}
      />
    </Container>
  );
};

export default Autocomplete;
