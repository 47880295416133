import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Button, Grid } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import DirectionsIcon from "@material-ui/icons/Directions";
import CallIcon from "@material-ui/icons/Call";

const useStyles = makeStyles(theme => ({
  bottomBar: {
    top: "auto",
    bottom: "1em",
    right: 0,
    left: 0,
    textAlign: "center"
  },
  text: {
    padding: theme.spacing(1),
    fontSize: "0.9em"
  },
  button: {
    width: "95%",
    background: "#2196f3",
    marginBottom: theme.spacing(1)
  },
  arrivedButton: {
    background: "#57be8c",
    color: "white",
    border: "none",
    marginTop: theme.spacing(3),
    width: "90%"
  }
}));

export default function BottomBar({
  lat,
  long,
  phone,
  orderStatus,
  sendArrived,
  disabled = false
}) {
  const classes = useStyles();

  return (
    <Box className={classes.bottomBar} position="fixed">
      <Grid container justify="space-around">
        <Fab
          variant="extended"
          color="primary"
          aria-label="delete"
          className={classes.fab}
          href={`tel:${phone}`}
        >
          <CallIcon className={classes.extendedIcon} />
          Call
        </Fab>
        <Fab
          variant="extended"
          color="primary"
          aria-label="delete"
          className={classes.fab}
          href={`https://www.waze.com/ul?ll=${lat}%2C${long}&navigate=yes`}
        >
          <NavigationIcon className={classes.extendedIcon} />
          Waze
        </Fab>
        <Fab
          variant="extended"
          color="primary"
          aria-label="delete"
          className={classes.fab}
          href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`}
        >
          <NavigationIcon className={classes.extendedIcon} />
          Google
        </Fab>
      </Grid>
      {orderStatus === "ON_ITS_WAY" && (
        <Grid item>
          <Button
            className={classes.arrivedButton}
            onTouchEnd={sendArrived}
            disabled={disabled}
          >
            Arrived
          </Button>
        </Grid>
      )}
    </Box>
  );
}
