const PaymentTypes = {
  "": "Not Set",
  preorder: "EFT - Pre Order",
  monthly: "EFT - Monthly - Automate",
  monthlyApproval: "EFT - Monthly - Needs approval",
  wire0: "Bank Transfer + 0",
  wire30: "Bank Transfer + 30",
  wire45: "Bank Transfer + 45",
  wire60: "Bank Transfer + 60",
  check: "Check"
};
export default PaymentTypes;
