import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import keyBy from "lodash/keyBy";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import * as geoApi from "../../../services/geoApi";

import Dropzone from "../../UI/Dropzone/Dropzone";
import FormSelect from "../../UI/FormSelect";
import {ROLE_ALL_SITE, SUPPORTED_ROLES} from "../../../config";
import TripOriginIcon from "@material-ui/icons/TripOrigin";

const useStyles = makeStyles(theme => ({
    dialogContent: {
        width: "600px",
        minHeight: "300px"
    },
    field: {
        marginBottom: theme.spacing(2)
    },
    errorMessage: {
        textAlign: "center",
        padding: "0.2rem",
        backgroundColor: theme.palette.secondary.light
    },
    row: {
        display: "flex"
    },
    button:{
      marginTop:"10px",
marginBottom: '16px',
marginLeft: '16px',

fontSize: '0.6725rem'
    }
}));

export default function LoadMapsDialog({open, onClose, sites}) {
    const classes = useStyles();
    const defaultAllBrands = 'All Brands';

    const [files, setFiles] = useState([]);
    const [role, setRole] = useState(ROLE_ALL_SITE);
    const [modifiedRoles, setModifiedRoles] = useState({});
    const [successStatus, setSuccessStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const sitesById = keyBy(sites, "site-id");

    async function uploadFile() {
        console.log(">>> Uploading file");
        resetForm();

        setIsSaving(true);
        try {
            const response = await geoApi.uploadMapsFile(files[0], role);
            setSuccessStatus(response.data);
        } catch (error) {
            setErrorMessage(
                (error.response && error.response.data && error.response.data.status) ||
                "Unexpected server error ocurred"
            );
        }
        setIsSaving(false);
    }


    function resetForm() {
        setRole(ROLE_ALL_SITE);
        setErrorMessage(null);
        setSuccessStatus(null);
    }

    function handleFilesAdded(newFiles) {
        console.log("Chosed files: ", newFiles);
        setFiles(newFiles);
    }

    function getSiteName(siteId) {
        return siteId in sitesById
            ? `${siteId} (${sitesById[siteId].name})`
            : siteId;
    }

    const updatedSiteIds = successStatus
        ? successStatus.updatedSites.map(site => site.name)
        : [];

    async function deleteBrandMaps() {
        try {
            const response = await geoApi.deleteRoleMaps(role);
            await checkIsRoleModified(role)
        } catch (error) {
            setErrorMessage(
                (error.response && error.response.data && error.response.data.status) ||
                "Unexpected server error ocurred"
            );
        }
    }

    async function checkIsRoleModified(role) {
        const isModified = await geoApi.isRoleModified(role);
        let newModifiedRoles = {...modifiedRoles};
        newModifiedRoles[role] = isModified;
        setModifiedRoles(newModifiedRoles);
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                resetForm();
                onClose();
            }}
            aria-labelledby="form-dialog-title"
            PaperProps={{elevation: 24}}
        >
            <DialogTitle id="form-dialog-title">Load Maps</DialogTitle>

            {errorMessage && (
                <Box className={classes.errorMessage}>{errorMessage}</Box>
            )}

            <DialogContent className={classes.dialogContent}>
                {/* Form */}
                {!successStatus && (
                    <>
                        <div className={classes.row} >
                            <FormSelect
                                label="Brand"
                                values={[defaultAllBrands, ...SUPPORTED_ROLES.slice(1)]}
                                multiple={false}
                                initialValue={defaultAllBrands}
                                onChange={value => {let role = value === defaultAllBrands ? ROLE_ALL_SITE : value;
                                    setRole(role);
                                 checkIsRoleModified(role)}}
                            />
                            {role !== ROLE_ALL_SITE && !!modifiedRoles[role] && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    onClick={deleteBrandMaps}
                                >
                                    <TripOriginIcon className={classes.extendedIcon}/>
                                    Use Default
                                </Button>
                            )}
                        </div>
                        <Dropzone onFilesAdded={handleFilesAdded}/>
                        {files.length > 0 && (
                            <Message>File chosen: {files[0].name}</Message>
                        )}


                        <ButtonContainer>
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                component="span"
                                disabled={files.length === 0}
                                onClick={uploadFile}
                            >
                                Upload
                            </Button>
                        </ButtonContainer>
                    </>
                )}

                {/* Success status report */}
                {successStatus && (
                    <Summary.Container>
                        <Summary.Title>Update Status</Summary.Title>
                        {successStatus.sitesToUpdate &&
                        successStatus.sitesToUpdate.map(site => (
                            <Summary.Item key={site.name}>
                                {getSiteName(site.name)} -{" "}
                                {updatedSiteIds.includes(site.name) ? (
                                    <Summary.Success>Successfully updated</Summary.Success>
                                ) : (
                                    <Summary.Failed>Could not find site</Summary.Failed>
                                )}
                            </Summary.Item>
                        ))}
                        {/* <Summary.Title>
              Pay Attention: You have tried to update sites that were not found
              in DB
            </Summary.Title>
            <Summary.Item>Site name (2344fdf7gdf6dsf78ds)</Summary.Item>
            <Summary.Item>Site name (2344fdf7gdf6dsf78ds)</Summary.Item> */}
                        <hr/>

                        {successStatus.missingPolygons &&
                        successStatus.missingPolygons.length > 0 && (
                            <>
                                <Summary.SmallTitle>
                                    <Summary.Failed>Invalid site data:</Summary.Failed> The
                                    following sites has a polygon - but missing a location
                                </Summary.SmallTitle>
                                {successStatus.missingPolygons.map(site => (
                                    <Summary.Item key={site.name}>{site.name}</Summary.Item>
                                ))}
                            </>
                        )}

                        {successStatus.missingLocations &&
                        successStatus.missingLocations.length > 0 && (
                            <>
                                <Summary.SmallTitle>
                                    <Summary.Failed>Invalid site data:</Summary.Failed> The
                                    following sites has a location set - but missing a polygon
                                </Summary.SmallTitle>
                                {successStatus.missingLocations.map(site => (
                                    <Summary.Item key={site.name}>{site.name}</Summary.Item>
                                ))}
                            </>
                        )}
                    </Summary.Container>
                )}
            </DialogContent>

            {isSaving && <LinearProgress/>}

            <DialogActions>
                <Button
                    onClick={() => {
                        resetForm();
                        onClose();
                    }}
                    color="primary"
                >
                    {successStatus ? "Close" : "Cancel"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const Message = styled.div`
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const Summary = {
    Container: styled.div``,
    Title: styled.div`
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
  `,
    SmallTitle: styled.div`
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: bold;
  `,
    Item: styled.div`
    padding-left: 1rem;
  `,

    Failed: styled.span`
    color: red;
    font-weight: bold;
  `,
    Success: styled.span`
    color: green;
    font-weight: bold;
  `
};
