import React from "react";
import { Link, useParams } from "react-router-dom";
import { useWooUser } from "../../../utils/authentication/WooUserContext";
import { makeStyles } from "@material-ui/core/styles";

import * as woocommerce from "../../../utils/woocommerce";
import { PICK_PACK_ADMIN_ROLES } from "../../../config";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";

export const useStyles = makeStyles(theme => ({
  highlight: { color: "#2196f3", "& span": { fontWeight: 600 } }
}));

//
export function SiteList({ siteMapping }) {
  const classes = useStyles();
  const params = useParams();
  const wooUser = useWooUser();

  const currentSiteId = params.siteId;

  const metadata = woocommerce.flattenMetadata(wooUser);
  const managed_sites = metadata.managed_sites  && metadata.managed_sites !== "Array" ? metadata.managed_sites.split(",") : [];
  const sites = PICK_PACK_ADMIN_ROLES.includes(wooUser.role) ? Object.keys(siteMapping) : managed_sites;

  return (
    <List>
      <ListSubheader>Site</ListSubheader>
      {sites.map(siteId => (
        <ListItem key={siteId} button component={Link} to={`/pickandpack/${siteId}`}>
          {currentSiteId === siteId ? (
            <ListItemText
              className={classes.highlight}
              primary={siteId in siteMapping ? siteMapping[siteId].name : siteId}
            />
          ) : (
            <ListItemText primary={siteId in siteMapping ? siteMapping[siteId].name : siteId} />
          )}
        </ListItem>
      ))}
    </List>
  );
}
