import React, { useEffect, useState } from "react";
import { isEmpty, groupBy, sum } from "lodash";

import * as config from "../../../config";
import * as wcApi from "../../../services/wcApi";
import * as woocommerce from "../../../utils/woocommerce";
import * as shookit from "../../../utils/shookit";
import * as timestamps from "../../../utils/timestamps";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: { paddingTop: 70, paddingBottom: 70, textAlign: "center" }
}));

export default function ProductSummaryReport({ siteId, date }) {
  const classes = useStyles();

  const [groupedItems, setGroupedItems] = useState({});
  const [products, setProducts] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  async function loadOrders() {
    // Get all order for today (for all sites, we will filter sites manually)
    wcApi
      .fetchAllOrders(date.toISOString(), setLoadingProgress)
      .then(result => {
        // Flat meta_data for easy search
        result = woocommerce.flattenOrdersMetadata(result);

        // Filter by site_id
        result = filterSite(result, siteId);

        if (isEmpty(result)) {
          setGroupedItems({});
        }

        // Extract all line_items
        const lineItems = result
          .map(order => order.line_items)
          .reduce((acc, arr) => acc.concat(arr))
          .map(lineItem => woocommerce.flattenMetadata(lineItem));

        const groupedItems = groupBy(lineItems, "product_id");

        setGroupedItems(groupedItems);
      })
      .catch(err => {
        // setGroupedItems({});
      });
  }

  useEffect(() => {
    loadOrders();
  }, [date]);

  // Get all products details related to the order
  useEffect(() => {
    setIsLoading(true);

    if (!groupedItems || isEmpty(groupedItems)) {
      setIsLoading(false);
      return;
    }
    const productIds = Object.keys(groupedItems);

    wcApi
      .fetchProductDetails(productIds)
      .then(result => {
        setProducts(result);
      })
      .catch(err => {});
    setIsLoading(false);
  }, [groupedItems]);

  // If Loading
  if (isLoading || loadingProgress !== 1) {
    return (
      <div className={classes.root}>
        <CircularProgress size="5rem" />
      </div>
    );
  }

  // If No Data
  if (isEmpty(groupedItems)) {
    return <div className={classes.root}>No orders this day</div>;
  }

  const data = productsCount(groupedItems, products);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(data => (
          <TableRow key={data.productId}>
            <TableCell>{data.name}</TableCell>
            <TableCell>{data.quantity}</TableCell>
            <TableCell>{data.type}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function filterSite(orders, siteId) {
  if (siteId) {
    return orders.filter(order => order[config.WC_ORDER_SITE] === siteId);
  }
  return orders;
}

// For each product, estimated the required quantity in KG/Units.
function productsCount(groupedItems, products) {
  return Object.keys(products).map(productId => {
    const product = products[productId];
    const productItems = groupedItems[productId];

    const [type, quantity] = countLineitems(productItems, product);

    return { productId, name: product.name, type, quantity };
  });
}

// Counts all line items of a product
// Returns a tuple of [Units, 1234] or [KG, 1234]
// (weight might be estimated)
function countLineitems(lineItems, product) {
  if (isEmpty(lineItems) || lineItems.length === 0) {
    return ["-", 0];
  }

  // If PriceCalculator is used
  if ("Type" in lineItems[0]) {
    return ["KG", sum(lineItems.map(item => evalWeight(item, product)))];
  } else {
    return ["Units", sum(lineItems.map(item => item.quantity))];
  }
}

// Returns either the exact weight or the estimated weights (according to the lineItem)
function evalWeight(item, product) {
  if (item.Type === config.AVAILABLE_UNITS.KG) {
    return parseFloat(item.Quantity);
  } else {
    return parseFloat(shookit.estimatedWeight(item, product));
  }
}
