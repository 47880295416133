import styled from "styled-components";

export const Container = styled.div`
  font-size: 24px;
  display: flex;
  background-color: #57be8c;
  color: white;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.8rem 1.5rem;
`;

export const Header = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;

export const Content = styled.div`
  & > a {
    color: white;
    text-decoration: none;
  }

  & > a:hover {
    text-decoration: underline;
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;
