import axios from "axios";
import * as config from "../config";

/**
 *
 * @param {*} productId
 * @param {number} quantity
 * @param {string} unit: One of "Unit" or "Weight"
 */
export async function calculatePrice(productId, quantity, pricingMethod, override_price = null) {
  if (![config.FIXED_PRICING_METHOD, config.WEIGHT_PRICING_METHOD].includes(pricingMethod)) {
    throw "Invalid parameters";
  }

  const baseUrl = `${config.BASE_URL}/wp-admin/admin-ajax.php?task=price_callback&action=awspricecalculator_ajax_callback&id=${productId}&simulatorid=${config.WC_PRICE_CALC_ID}`;

  const calcType = parseInt(pricingMethod === config.WEIGHT_PRICING_METHOD ? config.WC_PRICE_CALC_TYPE_UNIT : config.WC_PRICE_CALC_TYPE_WEIGHT);

  const formData = new FormData();
  formData.append(config.WC_PRICE_CALC_QUANTITY, quantity);
  formData.append(config.WC_PRICE_CALC_TYPE, calcType);
  if (override_price != null && !isNaN(override_price)) {
    formData.append('override_price', override_price)
  }

  var response = await axios.post(baseUrl, formData);

  return response.data.priceRaw;
}

export async function isProductCalculated(productId) {
  const baseUrl = `${config.BASE_URL}/wp-admin/admin-ajax.php?action=awspricecalculator_ajax_callback&task=is_product_calculated&id=${productId}&simulatorid=${config.WC_PRICE_CALC_ID}`;

  const res = await axios.post(baseUrl);
  if (res.data && res.data.is_calculated) {
    return res.data.is_calculated === "true";
  }
 
  return false;
}
