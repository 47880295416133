import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SpecialTimeslotsEntry from "./SpecialTimeslotsEntry";

const useStyles = makeStyles(theme => ({
  row: {
    "& > th": {
      textAlign: "center",
      padding: theme.spacing(1)
    }
  },
  smallWidth: {
    width: "100px"
  }
}));

export default function SpecialTimeslotsTable({ data, onCellChanged, currentBrand, brands }) {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell>Date</TableCell>
          <TableCell className={classes.smallWidth}>Start</TableCell>
          <TableCell className={classes.smallWidth}>End</TableCell>
          <TableCell>Site/Brand</TableCell>
          <TableCell>Open/Close</TableCell>
          <TableCell>Reason</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <SpecialTimeslotsEntry
            key={index}
            entry={row}
            onCellChanged={(entryId, fieldName, newValue) => onCellChanged(index, entryId, fieldName, newValue)}
            currentBrand={currentBrand}
            brands={brands}
          />
        ))}
      </TableBody>
    </Table>
  );
}
