import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {ROLE_ALL_SITE} from "../../../config";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AddIcon from "@material-ui/icons/Add";

import HourCell from "./HourCell";

const NEW_CELL = ["", "", "active"];

const useStyles = makeStyles(theme => ({
  addButton: {
    backgroundColor: "#ebf5e0"
  }
}));

export default function DailyTimeslots({ day, onChange, onNightWindowToggle, editMode = false }) {
  const classes = useStyles();

  function toggleNightWindow(windowStart, changeToNightWindow) {
    console.log(changeToNightWindow ? 'Changing To Night!' : 'Changing to Day');

    let index;
    if (changeToNightWindow) {
      index = day.timeslots.findIndex(timeslot => {
        return (timeslot[0] === windowStart);
      });
    }  else {
      index = day.nightTimeslots.findIndex(nightTimeslot => {
        return (nightTimeslot[0] === windowStart);
      });
    } 

    let CellToChange = changeToNightWindow ?  day.timeslots[index] : day.nightTimeslots[index];

    if (changeToNightWindow) {
      day.timeslots.splice(index, 1 );
      CellToChange.push({'isNightWindow' : changeToNightWindow})
      day.nightTimeslots.push(CellToChange);
    } else {
      day.nightTimeslots.splice(index, 1 );
      CellToChange.splice(CellToChange.findIndex(value => {
        return (value.isNightWindow)
      }), 1)
      day.timeslots.push(CellToChange);
    }
    
    onNightWindowToggle(day)
  }

  function onCellChanged(index, text) {
    onChange(index, text);
  }
  if (!day.nightTimeslots) {
    day.nightTimeslots = [];
  }
  return (
    <div className={classes.root}>
      <List dense>
      { day.nightTimeslots.map((row, index) => (
            <HourCell
            onChange={newValue => {
              onCellChanged(index, newValue);
            }}
            onNightWindowChange={ data => {
              toggleNightWindow(data.windowStart, data.changeToNightWindow);
            }}
            allowMaxOrders={day.role===ROLE_ALL_SITE}
            key={index}
            editMode={editMode}
            cell={row}
          />
        ))}
        {    day.timeslots.map((row, index) => (
            <HourCell
            onChange={newValue => {
              onCellChanged(index, newValue);
            }}
            onNightWindowChange={data => {
              toggleNightWindow(data.windowStart, data.changeToNightWindow);
            }}
            allowMaxOrders={day.role===ROLE_ALL_SITE}
            key={index}
            editMode={editMode}
            cell={row}
          />
        ))}
        {editMode && editMode === "TIMESLOTS" && (
          <ListItem
            button
            onClick={() => {
              onCellChanged(day.timeslots.length, NEW_CELL);
            }}
            className={classes.addButton}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText>Add</ListItemText>
          </ListItem>
        )}
      </List>
    </div>
  );
}
