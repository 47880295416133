import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createClient } from "@google/maps";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core";

import * as wcApi from "../../../services/wcApi";
import * as geoApi from "../../../services/geoApi";
import * as config from "../../../config";
import * as woocommerce from "../../../utils/woocommerce";
import { CircularProgress } from "@material-ui/core";
import { formatAsDay } from "../../../utils/shookit"
import { useWooUser } from "../../../utils/authentication/WooUserContext";

const useStyles = makeStyles(theme => ({
  buttonPadding: {
    padding: "5px 16px",
    "&:hover": { padding: "5px 16px" }
  }
}));

const NewOrderDialog = ({ customer, onClose, open, addToast, role }) => {
  const wooUser = useWooUser();
  const customerMeta = woocommerce.flattenMetadata(customer)
  const classes = useStyles();
  const googleMapsClient = createClient({ key: config.GOOGLE_MAP_API_KEY });
  const history = useHistory();

  const maxDatesToShow = 7;
  const [availableTimeslots, setavailableTimeslots] = useState([]);
  const [selectedWindow, setSelectedWindow] = useState(null);
  const [selectedTs, setSelectedTs] = useState([null, null]);
  const [isLoading, setIsLoading] = useState(true);
  const [geoLocation, setGeoLocation] = useState(null);
  const [shookitSiteId, setShookitSiteId] = useState(null);
  const [geoError, setGeoError] = useState(null);  
  const [shippingAutoFill, setShippingAutoFill] = useState(null);
  const [billingAutofill, setBillingAutofill] = useState(null);

  const getGeoLocation = async (addressObj, name) => {
    let address = `${addressObj.address_1}, ${addressObj.city}, ישראל`;

    return new Promise(function(resolve, reject) {
      googleMapsClient.geocode({ address: address }, function(err, response) {
        if (!err) {
          let geo = response.json.results[0].geometry;
          if (name === 'shipping') {
            setShippingAutoFill(response.json.results[0].formatted_address);
          } else {
            setBillingAutofill(response.json.results[0].formatted_address);
          }
          resolve({ lat: geo.location.lat, long: geo.location.lng });
        } else {
          reject(new Error("Couldnt't find the location " + address));
        }
      });
    });
  };

  const getShookitSite = async () => {
    try {
      let geo = await getGeoLocation(customer.shipping, 'shipping');
      await getGeoLocation(customer.billing, 'billing');
      var selectedSite = "";

      console.log(shippingAutoFill);
      console.log(billingAutofill);

      // Check if site override enabled
      if (customer.site_of_service) {
        selectedSite = customer.site_of_service;
      } else {
        let shookitSiteQuery = await geoApi.fetchShookitSiteForAddress({
          role: role,
          longitude: geo.long,
          latitude: geo.lat
        });
        if (shookitSiteQuery.data.status !== "ADDRESS_IS_SUPPORTED") {
          setGeoError({ message: "Address is not supported" });
          return;
        }

        selectedSite = shookitSiteQuery.data.site;
      }

      setShookitSiteId(selectedSite);
      let timeSlots = await geoApi.fetchShookitUpcomingTimeslots(selectedSite, role);
      setGeoLocation({ longitude: geo.long, latitude: geo.lat });
      setavailableTimeslots(timeSlots.data.windows);
    } catch (err) {
      console.error(err);
      setGeoError({ message: "Failed to load site information" });
    }
  };

  const onCancelClicked = e => {
    if (onClose) {
      onClose(e);
    }
  };

  const selectWindowSlot = timeslotId => {
    setSelectedWindow(timeslotId);
  };

  const getShookitTimeslotDate = () => {
    let ts = availableTimeslots.find(ts => ts.id === selectedWindow);
    let date = new Date(ts.date.year, ts.date.month - 1, ts.date.day);
    date.setHours(0, 0, 0, 0);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
    return date.toISOString();
  };

  const createNewOrder = async () => {
    let leaveAtDoor = (selectedTs.length > 2 && selectedTs[3].isNightWindow) ? 1 : 0 ;

    let additional_data = {};
    try {
      let meta_data = [
        {
          key: "_shipping_lat",
          value: geoLocation.latitude
        },
        {
          key: "_billing_autofill_checkout",
          value: billingAutofill
        },
        {
          key: "_shipping_autofill_checkout",
          value: shippingAutoFill
        },
        {
          key: "_shipping_long",
          value: geoLocation.longitude
        },
        {
          key: "Shookit Site",
          value: shookitSiteId
        },
        {
          key: "Shookit Timeslot Start",
          value: selectedTs[0]
        },
        {
          key: "Shookit Timeslot End",
          value: selectedTs[1]
        },
        {
          key: "Shookit Timeslot Date",
          value: getShookitTimeslotDate()
        },
        {
          key: "_draft_order",
          value: "1"
        },
        {
          key: "_created_by_admin",
          value: wooUser.id
        },
        {
          key: "force_free_shipping", // for manually created orders free shipping is forced
          value: "1"

        }, 
        {
          key: "leave_at_door", 
          value: leaveAtDoor
        },  
        {
          key: "building_code",
          value: customerMeta.building_code ? customerMeta.building_code : ''
        },  
        {
          key: "shipping_address_street",
          value: customerMeta.shipping_address_street ? customerMeta.shipping_address_street : ''
        },  
        {
          key: "shipping_address_street_number",
          value: customerMeta.shipping_address_street_number ? customerMeta.shipping_address_street_number : ''
        },  
       
      ];

    // CreditGuard
    if (customer['creditguard-authorization']) {
      setMetaKey (meta_data, "_creditguard_token", customer['creditguard-token']);
      setMetaKey (meta_data, "_creditguard_expiration", customer['creditguard-expiration']);
      setMetaKey (meta_data, "_ccnumber", customer['creditguard-number']);
      setMetaKey (meta_data, "_payments", 1);
      setMetaKey (meta_data, "_first_payment", "0");
      setMetaKey (meta_data, "_periodical_payment", "");
      setMetaKey (meta_data, "creditguard_authorization", customer['creditguard-authorization'] );
      setMetaKey (meta_data, "_payment_gateway", 'CreditGuard' );
      setMetaKey (meta_data, "creditguard_token", customer['creditguard-token']);
      setMetaKey (meta_data, "creditguard_expiration", customer['creditguard-expiration']);
      setMetaKey (meta_data, "creditguard_payments", 1);
      setMetaKey (meta_data, "order_first_payment", "0");
      setMetaKey (meta_data, "order_periodical_payment", "");
      setMetaKey (meta_data, "creditguard_authorization", customer['creditguard-authorization'] );
      setMetaKey (meta_data, "payment_gateway", 'CreditGuard' );
      additional_data = {
        payment_method: "creditguard",
        payment_method_title: "תשלום באמצעות קרדיטגארד",
      }
    }

      let result = await wcApi.createNewOrder(customer, meta_data, additional_data);
      history.push(`/order/${result.data.id}`);
    } catch (err) {
      console.log(err);
      // addToast({variant: 'error', message: err.response.message})
    }
  };

  const setMetaKey = (meta_data, key, value) => {
    meta_data.push({
      key: key,
      value: value
    });
  }

  const selectTs = ts => {
    setSelectedTs(ts);
  };

  useEffect(() => {
    getShookitSite()
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
        <DialogTitle>Create new Order For User</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (geoError) {
    return (
      <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
        <DialogTitle>Can not create new</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Due to an errror: <i>{geoError.message}</i>
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
      <DialogTitle>Create new order</DialogTitle>
      <DialogContent dividers>
        <Typography varian="h4">Select Day</Typography>
        <Grid container direction="row">
          {availableTimeslots.slice(0, maxDatesToShow).map(timeslotWindow => (
            <Grid item key={timeslotWindow.id}>
              <Button
                variant={selectedWindow === timeslotWindow.id ? "outlined" : "text"}
                className={classes.buttonPadding}
                color={selectedWindow === timeslotWindow.id ? "primary" : "default"}
                onClick={() => selectWindowSlot(timeslotWindow.id)}>
                {formatAsDay(new Date(timeslotWindow.date.year, timeslotWindow.date.month - 1, timeslotWindow.date.day))}
              </Button>
            </Grid>
          ))}
        </Grid>
        <br/>
        <Typography varian="h4">Select Time slot</Typography>
        <Grid container direction="column">
          {selectedWindow != null ? (
            availableTimeslots
              .find(ts => ts.id === selectedWindow)
              .timeslots.map((ts, index) => (
                <Grid item key={index}>
                  <Button
                    className={classes.buttonPadding}
                    variant={selectedTs[0] === ts[0] && selectedTs[1] === ts[1] ? "outlined" : "text"}
                    color={selectedTs[0] === ts[0] && selectedTs[1] === ts[1] ? "primary" : "default"}
                    onClick={() => selectTs(ts)}>
                    {ts[0]} - {ts[1]}
                  </Button>
                </Grid>
              ))
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={selectedTs[0] == null || selectedTs[1] == null}
          onClick={createNewOrder}>
          Create Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewOrderDialog;
