import React from "react";
import * as Stepper from "../../UI/Stepper";

export function StatusStepper({ steps, index }) {
  console.log(index);
  return (
    <Stepper.Stepper>
      {steps.map((step, i) => (
        <Stepper.Item key={step} checked={i < index} current={i === index}>
          <Stepper.Header>{step}</Stepper.Header>
        </Stepper.Item>
      ))}
    </Stepper.Stepper>
  );
}
