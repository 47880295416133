import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as config from "../../../config";

import InputBase from "@material-ui/core/InputBase";
import Switch from "@material-ui/core/Switch";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  cell: {
    padding: 0,
    // verticalAlign: "top",
    textAlign: "center"
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.background.default
    // }
  },
  row: {
    "& > th": {
      textAlign: "center"
    }
  },
  rowChanged: {
    "& > td": {
      //   backgroundColor: "#ffeb3b !important",
      fontWeight: "600"
    }
  },
  rowDeleted: {
    "& > td": {
      textDecoration: "line-through"
    }
  },
  narrowButton: {
    padding: theme.spacing(0)
  },
  input: {
    fontSize: "0.875rem",
    padding: 0,
    "& > input": {
      textAlign: "center",
      backgroundColor: "white"
      //   padding: 0
    }
  },
  smallInput: {
    width: "2.5rem"
  }
}));

export default function SpecialTimeslotsEntry({ entry, onCellChanged, currentBrand, brands }) {
  const classes = useStyles();

  function onChange(fieldName, newValue) {
    onCellChanged(entry.id, fieldName, newValue);
  }

  return (
    <TableRow className={clsx(classes.row, entry.changed && classes.rowChanged, entry.deleted && classes.rowDeleted)}>
      <TableCell className={classes.cell}>
        <InputBase
          className={clsx(classes.input, classes.smallInput)}
          value={entry.date.day}
          onChange={event => {
            onChange("date", { ...entry.date, day: event.target.value });
          }}
        />
        /
        <InputBase
          className={clsx(classes.input, classes.smallInput)}
          value={entry.date.month}
          onChange={event => {
            onChange("date", { ...entry.date, month: event.target.value });
          }}
        />
        /
        <InputBase
          className={clsx(classes.input, classes.smallInput)}
          value={entry.date.year}
          onChange={event => {
            onChange("date", { ...entry.date, year: event.target.value });
          }}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <InputBase
          className={classes.input}
          value={entry.timeslot[0]}
          onChange={event => {
            onChange("timeslot", [event.target.value, entry.timeslot[1]]);
          }}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <InputBase
          className={classes.input}
          value={entry.timeslot[1]}
          onChange={event => {
            onChange("timeslot", [entry.timeslot[0], event.target.value]);
          }}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <BrandSelector
          brands={brands}
          currentValue={entry.role}
          onChange={event => {
            onChange("role", event.target.value);
          }}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <Switch checked={entry.opened ? true : false} onChange={event => onChange("opened", !entry.opened)} color="primary" />
        {entry.opened ? "Opened" : "Closed"}
      </TableCell>
      <TableCell className={classes.cell}>
        <InputBase
          fullWidth={true}
          className={classes.input}
          value={entry.reason}
          onChange={event => {
            onChange("reason", event.target.value);
          }}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <IconButton
          color="secondary"
          className={classes.narrowButton}
          onClick={event => {
            onChange("deleted", !entry.deleted);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export function BrandSelector({ currentValue, onChange, brands }) {
  return (
    <FormControl variant="outlined">
      <Select autoWidth value={currentValue} onChange={onChange}>
        <MenuItem key={config.ROLE_ALL_REGION} value={config.ROLE_ALL_REGION}>
          All Sites (in region)
        </MenuItem>
        <MenuItem key={config.ROLE_ALL_SITE} value={config.ROLE_ALL_SITE}>
          All Brands (in site)
        </MenuItem>
        {brands.map(
          brand => (
            // brand !== config.ROLE_ALL_SITE && (
            <MenuItem key={brand} value={brand}>
              {brand}
            </MenuItem>
          )
          // )
        )}
      </Select>
    </FormControl>
  );
}
