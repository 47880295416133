import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";

import DailyTimeslots from "./DailyTimeslots";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles(theme => ({
    cell: {
        padding: 0,
        verticalAlign: "top",
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    },
    row: {
        "& > th": {
            textAlign: "center"
        }
    }
}));

export default function TimeslotsTable({data, editMode = false, onCellChanged, nightWindowChanged}) {
    const classes = useStyles();
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.row}>
                    <TableCell>Sunday</TableCell>
                    <TableCell>Monday</TableCell>
                    <TableCell>Tuesday</TableCell>
                    <TableCell>Wednesday</TableCell>
                    <TableCell>Thursday</TableCell>
                    <TableCell>Friday</TableCell>
                    <TableCell>Saturday</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {_.sortBy(data, "day").map((row, index) => (
                        <TableCell key={index} className={classes.cell}>
                            <DailyTimeslots
                                editMode={editMode}
                                day={row}
                                onChange={(timeslotIndex, newValue) => {
                                    onCellChanged(index, timeslotIndex, newValue);
                                }}
                                onNightWindowToggle={(day) => {nightWindowChanged(day)}}
                            />
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    );
}
