import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import * as config from "../../../config";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import PaperTitle from "../../UI/PaperTitle";
import SitesTables from "./SitesTable";
import LoadMapsDialog from "./LoadMapsDialog";
import SiteFormDialog from "./SiteFormDialog";
import ConfirmationDialog from "../../UI/ConfirmationDialog";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import * as geoApi from "../../../services/geoApi";
import * as wcApi from "../../../services/wcApi";
  
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  title: {
    flex: "1"
  },
  titleBar: {
    display: "flex",
    alignItems: "center"
  }
}));

const Sites = () => {
  const classes = useStyles();
  const [sites, setSites] = useState([]);
  const [showDialog, setDialog] = useState(false);
  const [showEditDialog, setEditDialog] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [initialDialogValues, setInitialDialogValues] = useState(null);
  const [showMapsDialog, setShowMapsDialog] = useState(false);
  const [filteredSites, setfilteredSites] = useState(sites);
  const [filterActive, setFilterActive] = useState(true);

  async function fetchData() {
    let result = await wcApi.fetchAllSites();
    setSites(result);
    if (filterActive !== null) {
      result = result.filter(site => site.isactive === filterActive);
    }

    setfilteredSites(result);
  }

  // Load Sites List
  useEffect(() => {
    fetchData();
  }, [showDialog, showEditDialog]);

  useEffect(() => {
    let result = sites;
    if (filterActive !== null) {
      result = result.filter(site => site.isactive === filterActive);
    }
    setfilteredSites(result)
  }, [filterActive]);


  async function addNewSite(site) {
    if (site) {
      return geoApi.addNewSite(site);
    }
  }

  async function updateSite(site) {
    if (site) {
      const siteId = site["site-id"];
      const result = await wcApi.updateSite(siteId, site);
      setInitialDialogValues(null);
      return result;
    }
  }

  async function showDeleteSiteDIalog(siteId) {
    setSiteToDelete(siteId);
  }

  async function deleteSite(status) {
    if (status === true) {
      await geoApi.deleteSite(siteToDelete);
      fetchData();
    }
    setSiteToDelete(null);
  }

  function displayEditDialog(site) {
    console.log(">> Showing edit dialog ");
    console.log(site);
    setInitialDialogValues(site);
    setEditDialog(true);
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* <PageHeader>Sites Management</PageHeader> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {/* Load Maps Dialog */}
            <LoadMapsDialog
              open={showMapsDialog}
              onClose={() => setShowMapsDialog(false)}
              sites={sites}
            />

            {/* Add Dialog */}
            <SiteFormDialog
              open={showDialog}
              onSave={addNewSite}
              onClose={() => setDialog(false)}
            />

            {/* Edit Dialog */}
            {showEditDialog && (
              <SiteFormDialog
                open={showEditDialog}
                onSave={updateSite}
                onClose={() => setEditDialog(false)}
                initialValues={initialDialogValues}
              />
            )}

            {/* Delete Confirmation Dialog */}
            <ConfirmationDialog
              title={`Confirm Deletion`}
              message={
                <div>
                  Are you sure you want to delete <b>{siteToDelete}</b> site?
                </div>
              }
              isOpen={siteToDelete != null}
              onClose={deleteSite}
            />

            {/* Title Toolbar */}
            <div className={classes.titleBar}>
              <div className={classes.title}>
                <PaperTitle>Sites</PaperTitle>
              </div>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <ButtonGroup color="primary" size="small">
                <Button
                  variant={filterActive === null ? "contained" : undefined}
                  onClick={() => setFilterActive(null)}>
                  All
                </Button>
                <Button
                  variant={filterActive === true ? "contained" : undefined}
                  onClick={() => setFilterActive(true)}>
                  Active
                </Button>
                <Button
                  variant={filterActive === false ? "contained" : undefined}
                  onClick={() => setFilterActive(false)}>
                  InActive
                </Button>
              </ButtonGroup>
          </Grid>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setDialog(true);
                }}
                className={classes.button}
              >
                <AddIcon className={classes.extendedIcon} />
                New Site
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                  setShowMapsDialog(true);
                }}
              >
                <CloudUploadIcon className={classes.extendedIcon} />
                Load Maps
              </Button>
            </div>

            {/* Sites Tables */}
            <SitesTables
              sites={filteredSites}
              onEdit={displayEditDialog}
              onDelete={showDeleteSiteDIalog}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Sites;
