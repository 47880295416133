import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

const useStyles = makeStyles(theme => ({
  bottomNavigation: {
    top: "auto",
    bottom: 0,
    position: "fixed",
    right: 0,
    left: 0,
    borderTop: "1px #8bc349 solid",
    height: "4rem"
  },
  actionItem: {
    "&$selected": {
      backgroundColor: "#024238",
      color: "white"
    }
  },
  selected: {}
}));

export default function StatusNavigation({ current, onChange }) {
  const classes = useStyles();

  return (
    <BottomNavigation
      value={current}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      showLabels
      className={classes.bottomNavigation}
    >
      <BottomNavigationAction label="To Do" classes={{
        root: classes.actionItem,
        selected: classes.selected
      }}/>
      <BottomNavigationAction label="Ready" classes={{
        root: classes.actionItem,
        selected: classes.selected
      }}/>
      <BottomNavigationAction label="On Its Way" classes={{
        root: classes.actionItem,
        selected: classes.selected
      }}/>
      <BottomNavigationAction label="Done" classes={{
        root: classes.actionItem,
        selected: classes.selected
      }}/>
    </BottomNavigation>
  );
}
