import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness3OutlinedIcon from '@material-ui/icons/Brightness3Outlined';
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SpeedOutlinedIcon from "@material-ui/icons/SpeedOutlined";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    input: {
        fontSize: "0.875rem",
        padding: 0,
        "& > input": {
            // padding: 0
        }
    },
    smallInput:{
        maxWidth: '3.2em',
    },
    changed: {
        fontWeight: 500
    },
    notActive: {
        textDecoration: "line-through",
        opacity: 0.6
    },
    smallIcon: {
        fontSize: "1.2rem"
    },
    centered: {
        textAlign: "center"
    },
    smallText:{
        '& *': {
            fontSize: "0.8em"
        }
    },
    marginRight:{
        marginRight:'8px',
    },
    maxOrdersInput:{
        direction:'rtl',
        color:'steelblue'
    },
    toggleMaxOrders:{

    },
    times:{
        maxWidth: '8.8em'
    },
    timesContainer:{
        paddingRight: '65px',
        paddingLeft: '8px'
}
}));

const toggleActive = value => (value === "active" ? "not-active" : "active");

export default function HourCell({cell, editMode, allowMaxOrders, onChange, onNightWindowChange}) {
    const classes = useStyles();
    const [maxOrdersMode, setMaxOrdersMode] = useState(false);

    useEffect(() => {
        if (!editMode) {
            setMaxOrdersMode(false);
        }
    }, [editMode]);

    function toggleMaxOrdersLimitMode() {
        setMaxOrdersMode(!maxOrdersMode)
    }

    function _isMaxOrderExists() {
        return cell[3] != undefined && cell[3] !== '';
    }

    function initNightWindow() {
        
        for (let i = 0; i < cell.length; i++) {
            if (typeof cell[i].isNightWindow === typeof undefined) {
                continue;
            } else {
                return cell[i].isNightWindow ? true : false;
            } 
        }
        return false
    }
    const [isNightWindow, setisNightWindow] = useState(initNightWindow());
    
    if (editMode) {
        return (
            <ListItem className={editMode === "BLOCKING" && cell[2] !== "active" ? classes.notActive : classes.timesContainer}>
                <InputBase
                    autoFocus='true'
                    size="small"
                    className={classes.input}
                    placeholder='00:00'
                    value={cell[0]}
                    inputProps={{ maxLength: 5 }}
                    onChange={event => {
                        onChange([event.target.value, cell[1], cell[2], {isNightWindow}]);
                    }}
                />
                <InputBase
                    size="small"
                    placeholder='00:00'
                    inputProps={{ maxLength: 5 }}
                    className={classes.input}
                    value={cell[1]}
                    onChange={event => {
                        onChange([cell[0], event.target.value, cell[2], {isNightWindow}]);
                    }}
                />
                {editMode === "TIMESLOTS" ? (

                    <ListItemSecondaryAction >
                    <IconButton
                            edge="end"
                            size="small"
                        >
                        {initNightWindow() ? <Brightness3Icon  className={classes.smallIcon} onClick={() => {setisNightWindow(false); onNightWindowChange({windowStart : cell[0], changeToNightWindow : false})}} /> : <Brightness3OutlinedIcon  className={classes.smallIcon} onClick={() => {setisNightWindow(true); onNightWindowChange({windowStart : cell[0], changeToNightWindow : true})}} />}
                     </IconButton>
                        <IconButton
                            edge="end"
                            size="small"
                            onClick={() => {
                                onChange([null, {isNightWindow}] );
                            }}
                        >
                            <DeleteOutlineOutlinedIcon className={classes.smallIcon}/>
                        </IconButton>

                            {/* { */}
                                {/* allowMaxOrders && (maxOrdersMode || _isMaxOrderExists() ? */}
                                {/* <InputBase */}
                                    {/* autoFocus={cell[3]==undefined} */}
                                    {/* color="primary" */}
                                    {/* size="small" */}
                                    {/* type="number" */}
                                    {/* inputProps={{ min: "0", step: "1" }} */}
                                    {/* className={`${classes.input} ${classes.smallInput} ${classes.maxOrdersInput}`} */}
                                    {/* value={cell[3]} */}
                                    {/* onChange={event => { */}
                                        {/* onChange([cell[0], cell[1], cell[2],event.target.value]); */}
                                    {/* }} */}
                                {/* /> : */}
                                 <IconButton
                                    className={classes.toggleMaxOrders}
                                    edge="end"
                                    size="small"
                                    onClick={() => {
                                        toggleMaxOrdersLimitMode()
                                    }}
                                >
                                    <SpeedOutlinedIcon className={classes.smallIcon}/>
                                </IconButton>
                                {/* )} */}

                    </ListItemSecondaryAction>
                ) : (
                    <ListItemSecondaryAction
                        onClick={() => {
                            onChange([cell[0], cell[1], toggleActive(cell[2]), {isNightWindow}]);
                        }}
                    >
                        <IconButton edge="end" size="small">
                            <BlockIcon className={classes.smallIcon}/>
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        );
    } else {
        return (
            <ListItem className={clsx(classes.centered, cell[2] !== "active" ? classes.notActive : "")}>
                <ListItemText className={classes.times}>
                    {cell[0]} - {cell[1]}
                </ListItemText>
                <ListItemText >
                {initNightWindow() ? <Brightness3Icon className={classes.extendedIcon}/> : ''}
                </ListItemText>
            </ListItem>
        );
    }
}
