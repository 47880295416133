import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";

import Autocomplete from "../../UI/Autocomplete";
import * as wcApi from "../../../services/wcApi";
import * as calcApi from "../../../services/priceCalculatorApi";
import { flattenMetadata } from "../../../utils/woocommerce";
import { AVAILABLE_UNITS } from "../../../config";
import IconButton from "@material-ui/core/IconButton";
import HighlightOff from '@material-ui/icons/HighlightOff';
import { shookitRound } from "../../../utils/shookit";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    height: "20vh",
    width: "50vw"
  },
  menuOption: {
    fontSize: 12
  },
  selectField: {
    width: "100%"
  },
  button: {
    padding: theme.spacing(1)
  }
}));

const autoCompleteCustomStyles = theme => ({
  container: {
    padding: "0"
  },
  valueContainer: {
    width: "100%"
  },
  paper: {
    position: "fixed",
    zIndex: 1,
    marginTop: theme.spacing(1),
    width: "50vw"
  }
});

function OptionDefaultComponent(props) {
  const styles = useStyles();
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      <div className={styles.menuOption}>
        {props.data.name} ({props.data.sku})
      </div>
    </MenuItem>
  );
}

const AddItemForm = ({ itemData, customerID, updateItem, deleteItem }) => {
  const styles = useStyles();

  const [formData, setFormData] = useState(itemData);
  const [hideKg, setHideKg] = useState(false);

  const productsLoadOptions = async inputValue => {
    const result = await wcApi.searchProducts(inputValue);
    return result;
  };

  async function onChangeProduct(item) {
    let product = await wcApi.getProduct(item.id, customerID);
    let taxStatus = product.tax_status;

    const flattenItem = flattenMetadata(product);    
    if (flattenItem['hide_kg']) {
      setHideKg(true);
    }
    let units;

    // Check the API if this product can be calculated as units.
    const isPriceCalculator = await calcApi.isProductCalculated(product.id);
    let typeEnabled = false;
    if (isPriceCalculator) {
      typeEnabled = true;
      units = AVAILABLE_UNITS.UNIT;
    } else {
      units = flattenItem.override_kg || AVAILABLE_UNITS.KG;
    }

    let newData = { ...formData,
      product: product,
      units: units,
      quantity: formData.quantity !== "" ? formData.quantity : 1,
      basePrice: product.price,
      isTypeFieldEnabled: typeEnabled,
      taxStatus
    };
    updateItem(newData);
    setFormData(newData);
  }

  const onDeleteItem = () => {
    deleteItem(formData.id);
  };

  const onChangeUnit = e => {
    const value = e.target.value;
    let quantity = 0;
    if (Object.values(AVAILABLE_UNITS).includes(value)) {
      if (value === AVAILABLE_UNITS.KG) {
        quantity = parseFloat(formData.quantity);
      } else {
        quantity = parseInt(formData.quantity);
      }
      setFormData({ ...formData, units: value, quantity: quantity });
      updateItem({ ...formData, units: value,  quantity: quantity });
    }
  };

  const onChangeQuantity = e => {
    let value = 0;
    if (formData.units !== AVAILABLE_UNITS.KG) {
      value = parseInt(e.target.value);
    } else {
      value = shookitRound(parseFloat(e.target.value));
    }

    let newData;
    if (isNaN(value) || value < 0) {
      newData ={ ...formData, quantity: "" };
    } else {
      newData ={ ...formData, quantity: value };
    }
    setFormData(newData);
    updateItem(newData);
  };

  const onChangeBasePrice = e => {
    const value = parseFloat(e.target.value);

    let newData;
    if (isNaN(value) || value < 0) {
      newData = { ...formData, basePrice: "" };
    } else {
      newData = { ...formData, basePrice: value };
    }
    setFormData(newData);
    updateItem(newData);
  };

  return (
    <Grid container item spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          placeholder="Search for a product..."
          loadOptions={productsLoadOptions}
          optionComponent={OptionDefaultComponent}
          onChange={onChangeProduct}
          customStyles={autoCompleteCustomStyles}
        />
      </Grid>
      <Grid item xs={1}>
        {Object.values(AVAILABLE_UNITS).includes(formData.units) && (
          <Select
            onChange={onChangeUnit}
            disabled={!formData.isTypeFieldEnabled}
            className={styles.selectField}
            value={formData.units}
          >
            {Object.values(AVAILABLE_UNITS).map((u, i) => 
            !(u === AVAILABLE_UNITS.KG && hideKg) && (              
              <MenuItem key={i} value={u}>
                {u}
              </MenuItem>
            ))}
          </Select>
        )}
        {!Object.values(AVAILABLE_UNITS).includes(formData.units) && (
          <TextField type="text" value={formData.units} disabled />
        )}
      </Grid>
      <Grid item xs={2}>
        <TextField
          type="number"
          onChange={onChangeQuantity}
          value={formData.quantity}
          placeholder={"0"}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          type="number"
          onChange={onChangeBasePrice}
          value={formData.basePrice}
          placeholder={"0"}
          inputProps={{min: 0}}
        />
      </Grid>
      {formData.product !== null && (
        <Grid item xs={1}>
          <IconButton style={{ color: "red" }} onClick={onDeleteItem}>
            <HighlightOff />
          </IconButton>
        </Grid>
      )}
    </Grid>
);
};

AddItemForm.propTypes = {
  customerID: PropTypes.number,
  addItem: PropTypes.func
};

export default AddItemForm;
