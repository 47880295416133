import React, { useState } from "react";
import { useGetResult } from "../../../utils/useGetResult";
import _ from "lodash";

import { Container, Grid, Button, Paper, TextField, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import * as recurringApi from "../../../services/recurringApi";
import PageHeader from "../../UI/PageHeader";
import { RecurringTable } from "./RecurringTable";
import { RecurringOrderDialog } from "./RecurringOrderDialog";
import ConfirmationDialog from "../../UI/ConfirmationDialog";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: "white"
  }
}));

export default function RecurringOrdersPage() {
  const classes = useStyles();

  const [uglyCounter, setUglyCounter] = useState(0);
  const [search, setSearch] = useState("");
  const [filterActive, setFilterActive] = useState(true);
  const [recurring, isLoading] = useGetResult(recurringApi.listAll(), [uglyCounter]);
  const [showDialog, setShowDialog] = useState(false);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [currentEditing, setCurrentEditing] = useState(null);

  const [recurringToDelete, setRecurringToDelete] = useState(null);

  function getOrders(recurring) {
    if (recurring === null) {
      return [];
    }

    var orders = recurring.orders;

    // Filter active or not active
    if (filterActive !== null) {
      orders = orders.filter(order => order.is_active === filterActive);
    }

    if (search.length > 0) {
      return orders.filter(
        order => order.company.toLowerCase().includes(search.toLowerCase()) || "" + order.order_id === search
      );
    }

    return orders;
  }

  async function handleSave(recurring) {
    await recurringApi.addRecurring(recurring);
    setShowDialog(false);
    setUglyCounter(uglyCounter + 1);
  }

  async function handleUpdate(recurring) {
    await recurringApi.updateRecurring(currentEditing.id, recurring);
    setCurrentEditing(null);
    setShowEditDialog(false);
    setUglyCounter(uglyCounter + 1);
  }

  function handleEdit(order) {
    setCurrentEditing(order);
    setShowEditDialog(true);
  }

  function showDeleteDialog(order) {
    setRecurringToDelete(order);
  }

  async function handleDelete(isApproved) {
    if (isApproved) {
      try {
        await recurringApi.deleteRecurring(recurringToDelete.id);
      } catch (error) {}
    }
    setRecurringToDelete(null);
    setUglyCounter(uglyCounter + 1);
  }

  return (
    <Container maxWidth="lg">
      {/* Add Dialog */}
      <RecurringOrderDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        onSave={handleSave}
      />

      {/* Edit Dialog */}
      <RecurringOrderDialog
        key={currentEditing ? currentEditing.id : null}
        open={showEditDialog}
        onClose={() => {
          setShowEditDialog(false);
        }}
        initialValues={currentEditing}
        onSave={handleUpdate}
      />

      {/* Delete Confirmation Dialog */}
      <ConfirmationDialog
        title={`Confirm Deletion`}
        message={
          <>
            Are you sure you want to delete recurring for order{" "}
            <b>{recurringToDelete && recurringToDelete.order_id}</b>?
          </>
        }
        isOpen={recurringToDelete !== null}
        onClose={handleDelete}
      />
      {/* Page */}
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={7}>
          <PageHeader>Recurring Orders</PageHeader>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          <ButtonGroup color="primary" size="small">
            <Button
              variant={filterActive === null ? "contained" : undefined}
              onClick={() => setFilterActive(null)}>
              All
            </Button>
            <Button
              variant={filterActive === true ? "contained" : undefined}
              onClick={() => setFilterActive(true)}>
              Active
            </Button>
            <Button
              variant={filterActive === false ? "contained" : undefined}
              onClick={() => setFilterActive(false)}>
              InActive
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            className={classes.textField}
            margin="dense"
            variant="outlined"
            value={search}
            onChange={event => {
              setSearch(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setShowDialog(true);
            }}
            className={classes.button}>
            <AddIcon className={classes.extendedIcon} />
            New
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <RecurringTable orders={getOrders(recurring)} onEdit={handleEdit} onDelete={showDeleteDialog} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
