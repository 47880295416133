import React from "react";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: baseline; */
`;
export const Name = styled.div`
  padding-left: 4px;
  font-size: 0.7em;
  color: rgb(128, 128, 128);
  /* font-weight: 600; */
  text-transform: uppercase;
`;
export const Value = styled.div``;

export default function Label({ name, value }) {
  return (
    <Container>
      <Value>{value}</Value>
      <Name>{name}</Name>
    </Container>
  );
}

export const LabelGroup = styled.div`
  display: flex;
  & > div:not(:last-child) {
    border-right: 1px #9e9e9e solid;
    margin-right: 0.8rem;
    padding-right: 0.8rem;
  }
`;
