import React, {useState} from "react";
import { withRouter, Link } from "react-router-dom";

import * as wcApi from "../../../services/wcApi";

import PageHeader from "../../UI/PageHeader";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "../../UI/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Typography, TextField, IconButton, Dialog, DialogTitle, DialogContent } from "@material-ui/core";

const CreateCustomerButton = withRouter(({ location }) => {
  if (location.pathname.match(/\/customers\/create/)) {
    return null;
  }
  return (
    <Grid container direction="row" justify="flex-end">
      <Grid item>
        <Button variant="contained" color="primary" size="small" component={Link} to={"/customers/create"}>
          <AddIcon />
          Create
        </Button>
      </Grid>
    </Grid>
  );
});

function OptionDefaultComponent(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          {props.data.first_name} {props.data.last_name}{" "}
          {props.data.shipping.company ? `(${props.data.shipping.company})` : ""}
        </Grid>
        <Grid item xs={3}>
          {props.data.shipping.company ? "Business" : "Private"}
        </Grid>
        <Grid item xs={3}>
          {props.data.billing.phone}
        </Grid>
        <Grid item xs={3}>
          {props.data.email}
        </Grid>
      </Grid>
    </MenuItem>
  );
}

const SearchCustomer = withRouter(({ history }) => {
  const [orderNumber, setOrderNumber] = useState(null);
  const [failedError, setFailedError] = useState(false)
  const onChange = customer => {
    history.push(`/customers/view/${customer.id}`);
  };

  const loadOptions = async inputValue => {
    if (inputValue.length && inputValue.length < 3) return;
    return wcApi.searchCustomers(inputValue);
  };

  const onOrderNumberChange = (e) => {  
    setOrderNumber(e.target.value);
  };

  const searchByOrder = async e => {
    try {
      var result = await wcApi.fetchOrder(orderNumber);
      if (result.data) {
        history.push(`/order/${orderNumber}`);
      }
    } catch (err) {
      setFailedError(true)
    }
  }

  const onKeyPress = (e) => {
    if(e.keyCode === 13){
      searchByOrder();
    }
  };

  const onCancelClicked = () => {
    setFailedError(null);
  };

  return (
    <React.Fragment>
      <Dialog open={failedError} onClose={onCancelClicked} maxWidth="lg">
        <DialogTitle>No order found</DialogTitle>
        <DialogContent dividers>
          <Typography>
            The specified Order Number resulted in 0 results
          </Typography>
        </DialogContent>
      </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={9}>
            <PageHeader>Customers & Orders</PageHeader>
          </Grid>
          <Grid item xs={3}>
            <CreateCustomerButton />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Paper style={{ marginTop: "2rem" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={11}>
              <Autocomplete
                placeholder="Please enter a part of name, email or phone number"
                loadOptions={loadOptions}
                optionComponent={OptionDefaultComponent}
                onChange={onChange}
              />
            </Grid>
            <Grid item>
              <SearchIcon style={{'color': 'rgba(0, 0, 0, 0.54)'}} />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={11}>
                <TextField
                  fullWidth             
                  style={{marginLeft: '6%', width: '91%'}}
                  placeholder="Order Number"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  onChange={onOrderNumberChange}
                  onKeyDown={onKeyPress}
                />
              </Grid>
              <Grid item>
                <IconButton onClick={() => searchByOrder()} type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
});

export default SearchCustomer;
