import React, { useState } from "react";

import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import AddressTabInput from "../../../common/inputs/AddressTabInput";

export default function UpdateAddressDialog({
  open,
  onClose,
  role,
  initialValues = {},
  deliverableAddress = true
}) {
  const [address, setAddress] = useState(initialValues);

  return (
    <Dialog open={open} onClose={() => onClose(null)} maxWidth="md" fullWidth>
      <DialogTitle>Edit Address</DialogTitle>
      <DialogContent>
        {"address" in initialValues && (
          <AddressTabInput
            onChange={setAddress}
            autosave
            initialValues={initialValues}
            role={role}
            deliverableAddress={deliverableAddress}
            strictValidation
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose(null)}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          onClick={() => onClose(address)}
          disabled={_.isEmpty(address)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
