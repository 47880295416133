import React,{useState} from 'react'

import {Dialog, DialogTitle, Paper, DialogContent, Typography, Container, Grid, Button, DialogActions} from '@material-ui/core'
import PageHeader from "../../UI/PageHeader";
import * as giApi from "../../../services/giApi";
import { BILLING_ADMIN_ROLES } from "../../../config";
import { useWooUser } from "../../../utils/authentication/WooUserContext";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({  
  btn: {
    margin: theme.spacing(1),
  },
  takeOrderLiveButton: {
    marginRight: theme.spacing(1),
    backgroundColor: 'rgba(227, 118, 86)',
    fontSize: '14px',
    height: '75px',
    width: '150px'
  }
}));

const Billing = (props) => {
  const styles = useStyles();
  const wooUser = useWooUser();
  const [showDialog, setShowDialog] = useState(false);
  const [isInvocing, setIsInvocing] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [showSucessDialog, setShowSucessDialog] = useState(false);

  const onDialogClose = () => {
    setShowDialog(false);
  }

  const invoiceOrders = async () => {
    setShowDialog(false);
    setIsInvocing(true);

    try {
      await giApi.invoicePendingOrders();
      setShowSucessDialog(true);
    }
    catch (err) {
      setSendError(err.message || JSON.stringify(err));
      setShowErrorDialog(true);
    }

    setIsInvocing(false);
  }

  if (!BILLING_ADMIN_ROLES.includes(wooUser.role)) {
    return (
      <Container maxWidth="lg">
        YOU DONT HAVE PERMISSIONS!
      </Container>
    )
  } else {

  return (
    <>
      <Dialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)}>
        <DialogTitle>Failed to send invoices due to an error</DialogTitle>
        <DialogContent>
          {sendError}
        </DialogContent>
      </Dialog>
      <Dialog open={showSucessDialog} onClose={() => setShowSucessDialog(false)}>
        <DialogTitle>Sent invoices succesfully</DialogTitle>
        <DialogContent>
          All invoices were created, please see Google report for more information
        </DialogContent>
      </Dialog>
      <Dialog open={showDialog} onClose={onDialogClose} maxWidth="lg">
        <DialogTitle>Fetching </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Are you sure you wish to send all customers their monthly invoices?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onDialogClose} color="danger">
            Cancel
          </Button>
          <Button onClick={invoiceOrders} color="primary" variant="contained" className={styles.takeOrderLiveButton}>
            Invoice Orders!
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={9}>
            <PageHeader>Billing</PageHeader>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Paper style={{ marginTop: "2rem" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Button
              disabled={isInvocing}
              onClick={() => setShowDialog(true)}
              variant="contained"
              size="large"
              color="primary"
              className={styles.btn}
            >
              יאבלולולו!
            </Button>
          </Grid>
        </Paper>
      </Container>
    </>
  );
  }
};

export default Billing;