import React, { useState } from "react";
import { toNumber, toInteger } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    margin: "1rem 10px"
    // width: 400
  },
  input: {
    flex: 1,
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  iconButton: {
    padding: 10
  }
}));

export default function CustomizedInputBase({ onSearch, onClear }) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  let searchByNumber = false;
  let buttonTitle = "Search Today";
  if (!value.includes(" ") && toInteger(value) !== 0) {
    buttonTitle = "Search Order#";
    searchByNumber = true;
  }

  function handleClick() {
    onSearch(searchByNumber, value);
  }

  function handleClear() {
    setValue("");
    onClear();
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search Order"
        value={value}
        onChange={event => {
          setValue(event.target.value);
        }}
      />
      {value === "" ? (
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      ) : (
        <>
          <IconButton className={classes.iconButton} aria-label="search" onClick={handleClear}>
            <ClearIcon />
          </IconButton>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleClick}>
            {buttonTitle}
          </Button>
        </>
      )}
    </Paper>
  );
}
