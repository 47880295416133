import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SearchBar from "./SearchBar";
import OrderList from "./OrderList";
import { toInteger } from "lodash";

import * as wcApi from "../../../services/wcApi";
import * as woocommerce from "../../../utils/woocommerce";
import * as config from "../../../config";

const FILTER_TODO = 0;

export function TodoPage({ siteId, orders }) {
  const history = useHistory();
  if (!history.location.state || history.location.state.filter !== FILTER_TODO) {
    history.push({pathname: history.location.pathname, state: {filter: FILTER_TODO}});
  }

  const [localFilter, setLocalFilter] = useState("");
  const [localSearch, setLocalSearch] = useState(null);


  // function handleSearch(searchByNumber, searchInput) {
  //   if (searchByNumber) {
  //     setLocalFilter("");

  //     wcApi
  //       .fetchOrderDetails(searchInput)
  //       .then(result => {
  //         let order = woocommerce.flattenOrderMetadata(result);
  //         if (order[config.WC_ORDER_SITE] === siteId) {
  //           setLocalSearch([order]);
  //         } else {
  //           setLocalSearch([]);
  //         }
  //       })
  //       .catch(err => {
  //         // TODO
  //         setLocalSearch([]);
  //       });
  //   } else {
  //     setLocalFilter(searchInput);
  //     setLocalSearch(null);
  //   }
  // }

  // function handleClear() {
  //   setLocalFilter("");
  //   setLocalSearch(null);
  // }

  // // If local filter is set
  // if (localFilter !== "") {
  //   filteredOrders = orders.filter(order => {
  //     // Built keywords string
  //     const searchValues = `${order.id} ${order.shipping.first_name} ${order.shipping.last_name} ${order._billing_autofill_checkout}`.toLowerCase();

  //     // Split Keywords
  //     const keywords = localFilter.toLowerCase().split(" ");

  //     // Return true only if all keywords found
  //     return keywords.map(keyword => searchValues.includes(keyword)).every(res => res === true);
  //   });
  // }

  // // If local search was enabled
  // if (localSearch !== null) {
  //   filteredOrders = localSearch;
  // }

  // Todo:
  //  1. Order by timestamp
  //  2. Filter by PENDING only
  //  3. Put "seen" before "not seen"

  return (
    <React.Fragment>
      {/* <SearchBar onSearch={handleSearch} onClear={handleClear} /> */}
      <OrderList
        orders={orders}
        editMode={false}
        onPress={orderId => {
          history.push(`/pickandpack/${siteId}/orders/${orderId}`);
        }} // onLongPress={orderId => setEditMode(true)}
      />
    </React.Fragment>
  );
}
