import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  makeStyles,
  Divider,
  Container,
  Grid,
  Paper,
  TextField,
  Checkbox,
  MenuItem,
  Tabs,
  Tab,
  AppBar,
  FormHelperText,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { isEmpty } from "lodash";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

import Autocomplete from "../../UI/Autocomplete";
import AddressTabInput from "../../common/inputs/AddressTabInput";
import PhoneInput from "../../common/inputs/PhoneInput";
import BusinessTypes from "../../../enums/BusinessTypes"

import * as geoApi from "../../../services/geoApi";
import * as wcApi from "../../../services/wcApi";

const useStyles = makeStyles(theme => ({
  overrideBillingName: {
    marginTop: theme.spacing(2)
  },
  billingCompany: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  dupBilling: {
    margin: theme.spacing(1)
  },
  TextField: {
    width: "100%",
    margin: theme.spacing(1)
  },
  AddressContainer: {
    marginLeft: theme.spacing(3)
  },
  AddressField: {
    marginRight: theme.spacing(1)
  },
  SecuirtyCode: {
    "-webkit-outer-spin-button": {
      margin: 0,
      " -webkit-appearance": "none"
    },
    "-webkit-inner-spin-button": {
      margin: 0,
      " -webkit-appearance": "none"
    }
  },
  notificationPreferences: {
    marginLeft: "1vw",
    padding: "10px 10px",
    boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.5), 0px 1px 1px 0px rgba(0,0,0,0.5), 0px 2px 1px -1px rgba(0,0,0,0.5)"
  },
  paper: {
    boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.5), 0px 1px 1px 0px rgba(0,0,0,0.5), 0px 2px 1px -1px rgba(0,0,0,0.5)"
  }
}));

const AutocompleteResult = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.data.name}
    </MenuItem>
  );
};

const errorMapping = {
  "registration-error-username-exists":
    "An account is already registered with that username. Please choose another.",
  "registration-error-email-exists":
    "An account is already registered with your email address.",
  "no-force-site": "You must select a site of service for B2B customer",
  "registration-error-invalid-username":
    "Email address contains an unallowed special character, such as '+' sign",
  "invalid-shipping-address": "Invalid shipping address",
  "invalid-billing-address": "Invalid billing address",
  "invalid-billing-contact": "Invalid billing contact",
  "invalid-tax-id": "Invalid Company ID!" 
};

const CreateCustomer = ({ history }) => {
  const classes = useStyles();

  const [canCreateUser, setCanCreateUser] = useState(true);
  const [addressTab, setAddressTab] = useState(0);
  const [createError, setCreateError] = useState(null);
  const [dupBilling, setDupBilling] = useState(true);
  const [overrideBillingContact, setOVerrideBillingContact] = useState(false);

  const [customerMetadata, setCustomerMetadata] = useState({
    paymentAgreement: "preorder",
    forceSite: "",
    creditCardNumber: "",
    idNumber: "",
    expiryMonth: "",
    expiryYear: "",
    ccv: "",
    companyId: "",
    subSegment: BusinessTypes.business,
    opt_in: true,
    opt_in_sms : true,
    blacklist_invocing: false,
    subscribe_on_the_way_sms: true,
    subscribe_arrived_sms: true,
    subscribe_missing_sms: true,
    subscribe_on_hold_email: true,
    subscribe_certificate_email: true
  });

  const [customer, setCustomer] = useState({
    type: "b2b",
    username: "",
    firstName: "",
    email: "",
    billingEmail: "",
    surname: "",
    phone: "",
    opt_in: true,
    opt_in_sms : true,
    companyName: ""
  });

  const [billingAddress, setBillingAddress] = useState({});
  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingCompany, setBillingCompany] = useState("");
  const [shippingAddress, setShippingAddress] = useState({});

  const handleCustomerChange = name => event => {
    setCustomer({ ...customer, [name]: event.target.value });
  };

  const handleSetDupBilling = event => {
    setDupBilling(event.target.checked);
    if (event.target.checked) setAddressTab(0);
  };

  const handleBillingChange = address => {
    setBillingAddress(address);
  };

  const handleShippingChange = address => {
    setShippingAddress(address);
  };

  const handleCustomerMetadataChange = name => event => {
    setCustomerMetadata({ ...customerMetadata, [name]: event.target.value });
  };

  const handleCustomerMetadataCheckbox = name => event => {
    console.log(event.target.checked); 
    console.log({ ...customerMetadata, [name]: event.target.checked });
    setCustomerMetadata({ ...customerMetadata, [name]: event.target.checked });
  };

  const onTabSelected = (event, newValue) => {
    setAddressTab(newValue);
  };

  const loadOptions = async inputValue => {
    const result = await geoApi.fetchAllSites();
    return result.filter(site => site.isactive);
  };

  const onForceSiteSelected = newVal => {
    setCustomerMetadata({ ...customerMetadata, forceSite: newVal });
  };

  const generateMetadatas = () => {
    let metadatas = [];

    if (customer.type === "b2b") {
      if (customerMetadata.forceSite) {
        metadatas.push({
          key: "site_of_service",
          value: customerMetadata.forceSite["site-id"]
        });
      }

      if (customerMetadata.subSegment) {
        metadatas.push({
          key: "b2b_sub_segment",
          value: customerMetadata.subSegment
        });
      }

      if (customerMetadata.companyId) {
        metadatas.push({
          key: "company_id",
          value: customerMetadata.companyId
        });
      }

      if (customerMetadata.paymentAgreement) {
        metadatas.push({
          key: "payment_method",
          value: customerMetadata.paymentAgreement
        });
      }
    }

    metadatas.push({
      key: "opt_in",
      value: customerMetadata.opt_in ? "1" : "0"
    });

    metadatas.push({
      key: "opt_in_sms",
      value: customerMetadata.opt_in_sms ? "1" : "0"
    });

    metadatas.push({
      key: "blacklist_invocing",
      value: customerMetadata.blacklist_invocing ? "1" : "0"
    });

    metadatas.push({
      key: "subscribe_on_the_way_sms",
      value: customerMetadata.subscribe_on_the_way_sms ? "1" : "0"
    });

    metadatas.push({
      key: "subscribe_arrived_sms",
      value: customerMetadata.subscribe_arrived_sms ? "1" : "0"
    });

    metadatas.push({
      key: "subscribe_missing_sms",
      value: customerMetadata.subscribe_missing_sms ? "1" : "0"
    });

    metadatas.push({
      key: "subscribe_on_hold_email",
      value: customerMetadata.subscribe_on_hold_email ? "1" : "0"
    });

    metadatas.push({
      key: "subscribe_certificate_email",
      value: customerMetadata.subscribe_certificate_email ? "1" : "0"
    });

    metadatas.push({
      key: "address_city",
      value: shippingAddress.address_city ? shippingAddress.addresscity : ""
    });

    metadatas.push({
      key: "shipping_address_street",
      value: shippingAddress.address_street ? shippingAddress.address_street : ""
    });

    metadatas.push({
      key: "shipping_address_street_number",
      value: shippingAddress.address_street_number ? shippingAddress.address_street_number : ""
    });

    metadatas.push({
      key: "billing_address_street",
      value: billingAddress.address_street ? billingAddress.address_street : ""
    });

    metadatas.push({
      key: "billing_address_street_number",
      value: billingAddress.address_street_number ? billingAddress.address_street_number : ""
    });



    // Push Billing and Shipping Metadata
    const fields = {
      shipping_address_type: shippingAddress.addressType,
      shipping_autofill_checkout: shippingAddress.address,
      shipping_placeid: shippingAddress.placeId,
      shipping_country: 'IL',
      shipping_longlat:
        shippingAddress.latLng &&
        `${shippingAddress.latLng.lng},${shippingAddress.latLng.lat}`,
      shipping_long: shippingAddress.latLng && shippingAddress.latLng.lng,
      shipping_lat: shippingAddress.latLng && shippingAddress.latLng.lat,
      shipping_entrance: shippingAddress.entrance,
      shipping_apt_number: shippingAddress.apt,
      shipping_floor_number: shippingAddress.floor,
      shipping_notes: shippingAddress.notes,
      billing_address_type: dupBilling
        ? shippingAddress.addressType
        : billingAddress.addressType,
      billing_autofill_checkout: dupBilling
        ? shippingAddress.address
        : billingAddress.address,
      billing_placeid: dupBilling
        ? shippingAddress.placeId
        : billingAddress.placeId,
      billing_longlat: dupBilling
        ? shippingAddress.latLng &&
          `${shippingAddress.latLng.lng},${shippingAddress.latLng.lat}`
        : billingAddress.latLng &&
          `${billingAddress.latLng.lng},${billingAddress.latLng.lat}`,
      billing_long: dupBilling
        ? shippingAddress.latLng && shippingAddress.latLng.lng
        : billingAddress.latLng && billingAddress.latLng.lng,
      billing_lat: dupBilling
        ? shippingAddress.latLng && shippingAddress.latLng.lat
        : billingAddress.latLng && billingAddress.latLng.lat,
      billing_entrance: dupBilling
        ? shippingAddress.entrance
        : billingAddress.entrance,
      billing_apt_number: dupBilling ? shippingAddress.apt : billingAddress.apt,
      billing_floor_number: dupBilling
        ? shippingAddress.floor
        : billingAddress.floor,
      billing_notes: dupBilling ? shippingAddress.notes : billingAddress.notes,
      billing_country: 'IL'
    };

    Object.keys(fields).forEach(key => {
      metadatas.push({
        key: key,
        value: fields[key]
      });
    });

    console.log(metadatas);

    return metadatas;
  };

  const validateTaxId = (idNumber) => {
    const id = idNumber.toString();
    if (id.length !== 9 || isNaN(idNumber)) {
      // Make sure ID is formatted properly
      return false;
    }
    let sum = 0;
    let incNum;
    for (let i = 0; i < id.length; i++) {
      incNum = Number(id[i]) * ((i % 2) + 1); // Multiply number by 1 or 2
      sum += incNum > 9 ? incNum - 9 : incNum; // Sum the digits up and add to total
    }
    return sum % 10 === 0;
  };

  const createCustomer = async event => {
    event.preventDefault();
    setCanCreateUser(false);

    let customer_dto = {
      email: customer.email,
      username: customer.email,
      first_name: customer.firstName,
      last_name: customer.surname,

      billing: {
        first_name:
          !dupBilling && overrideBillingContact
            ? billingFirstName
            : customer.firstName,
        last_name:
          !dupBilling && overrideBillingContact
            ? billingLastName
            : customer.surname,
        company:
          !dupBilling && overrideBillingContact
            ? billingCompany
            : customer.companyName,
        address_1: dupBilling
          ? shippingAddress.address1
          : billingAddress.address1,
        address_2: "",
        city: dupBilling ? shippingAddress.city : billingAddress.city,
        email: customer.billingEmail || customer.email,
        phone: customer.phone.replace(/-/g, "")
      },

      shipping: {
        first_name: customer.firstName,
        last_name: customer.surname,
        company: customer.companyName,
        address_1: shippingAddress.address1,
        address_2: "",
        city: shippingAddress.city
      },

      meta_data: generateMetadatas()
    };

    if (!(customer_dto.shipping.address_1 || customer_dto.shipping.city)) {
      setCreateError({ data: { code: "invalid-shipping-address" } });
      setCanCreateUser(true);
      return;
    }

    if (
      !dupBilling &&
      !(customer_dto.billing.address_1 || customer_dto.billing.city)
    ) {
      setCreateError({ data: { code: "invalid-billing-address" } });
      setCanCreateUser(true);
      return;
    }

    if (
      !dupBilling &&
      overrideBillingContact &&
      (!billingFirstName ||
        !billingLastName ||
        (customer.type === "b2b" && !billingCompany))
    ) {
      setCreateError({ data: { code: "invalid-billing-contact" } });
      setCanCreateUser(true);
      return;
    }

    if (customer.type === "b2b") {
      if (!validateTaxId(customerMetadata.companyId.toString())) {
        setCreateError({ data: { code: "invalid-tax-id" } });
        setCanCreateUser(true);
        return;
      }
    }

    if (customer.type === "b2b") {
      if (!customerMetadata.forceSite) {
        setCreateError({ data: { code: "no-force-site" } });
        setCanCreateUser(true);
        return;
      }
    }

    try {
      let result = await wcApi.createCustoemr(customer_dto);
      if (customer.type === "b2b") {
        let result2 = await wcApi.changeUserRole(result.data.id, "b2b");
      }
      wcApi.updateSubscriptionStatus('email', customerMetadata.opt_in == 1 ? "1" : "0", result.data.id)
      wcApi.updateSubscriptionStatus('sms', customerMetadata.opt_in_sms == 1 ? "1" : "0", result.data.id)
      history.push(`/customers/view/${result.data.id}`);
    } catch (err) {
      setCreateError(err.response);
    }
    setCanCreateUser(true);
  };

  const handleModalClose = () => {
    setCreateError(null);
  };

  const resolveError = error_code => {
    return errorMapping[error_code]
      ? errorMapping[error_code]
      : "Unknown Error!";
  };

  return (
    <form onSubmit={event => createCustomer(event)} autoComplete="off">
      <Dialog open={createError != null} onClose={handleModalClose}>
        <DialogTitle>Failed to Create User</DialogTitle>
        <DialogContent>
          Failed to create user:{" "}
          <i>
            {createError != null ? resolveError(createError.data.code) : ""}
          </i>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleModalClose}>
            OK <SentimentVeryDissatisfiedIcon />
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="xl">
        <Paper className={classes.paper}>
          <Grid container wrap="nowrap">
            <Grid item xs={3}>
              <Grid container direction="column">
                <Grid item>
                  <TextField
                    select
                    required
                    label="User type"
                    className={classes.TextField}
                    value={customer.type}
                    onChange={handleCustomerChange("type")}
                    fullWidth
                  >
                    <MenuItem key="b2b" value="b2b">
                      B2B Customer
                    </MenuItem>
                    <MenuItem key="b2c" value="b2c">
                      B2C Customer
                    </MenuItem>
                  </TextField>
                </Grid>
                {customer.type === "b2c" ? (
                  <></>
                ) : (
                  <>
                    <Grid>
                      <TextField
                        select
                        required
                        label="B2B Type"
                        className={classes.TextField}
                        value={customerMetadata.subSegment}
                        onChange={handleCustomerMetadataChange("subSegment")}
                        fullWidth
                      >
                        {Object.keys(BusinessTypes).map(type =>
                          <MenuItem key={type} value={BusinessTypes[type]}>
                            {BusinessTypes[type]}
                          </MenuItem>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        label="Company Name"
                        className={classes.TextField}
                        value={customer.companyName}
                        onChange={handleCustomerChange("companyName")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        label="Company ID (ח.פ)"
                        className={classes.TextField}
                        value={customerMetadata.companyId}
                        onChange={handleCustomerMetadataChange("companyId")}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                <Grid item>
                  <TextField
                    inputProps={{ autoComplete: "off" }}
                    required
                    label={
                      customer.type !== "b2c"
                        ? "Contact First Name"
                        : "First Name"
                    }
                    className={classes.TextField}
                    value={customer.firstName}
                    onChange={handleCustomerChange("firstName")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    label={
                      customer.type !== "b2c" ? "Contact Surname" : "Surname"
                    }
                    className={classes.TextField}
                    value={customer.surname}
                    onChange={handleCustomerChange("surname")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    label="Phone"
                    className={classes.TextField}
                    value={customer.phone}
                    onChange={handleCustomerChange("phone")}
                    fullWidth
                    InputProps={{
                      inputComponent: PhoneInput
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    label="Email for Login"
                    type="email"
                    className={classes.TextField}
                    value={customer.email}
                    onChange={handleCustomerChange("email")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Mailing Address"
                    type="email"
                    placeholder="Leave blank to use Email for login"
                    className={classes.TextField}
                    value={customer.billingEmail}
                    onChange={handleCustomerChange("billingEmail")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    checked={customerMetadata.opt_in}
                    onChange={handleCustomerMetadataCheckbox("opt_in")}
                  />{" "}
                  Want to get marketing emails?
                </Grid>
                <Grid item>
                  <Checkbox
                    checked={customerMetadata.opt_in_sms}
                    onChange={handleCustomerMetadataCheckbox("opt_in_sms")}
                  />{" "}
                  Want to get marketing sms?
                </Grid>
                <Grid item>
                  <Checkbox
                    checked={customerMetadata.blacklist_invocing}
                    onChange={handleCustomerMetadataCheckbox("blacklist_invocing")}
                  />{" "}
                  Blacklsit from Auto-Invocing?
                </Grid>
                <Grid item>
                  <Paper variant="outlined" className={classes.notificationPreferences}>
                    <Typography variant="h6">Notification Preferences</Typography>
                    <Grid container direction="column">
                    <Grid item>
                      <Checkbox
                        checked={customerMetadata.subscribe_on_the_way_sms}
                        onChange={handleCustomerMetadataCheckbox("subscribe_on_the_way_sms")}
                      />{" "}
                      On The Way SMS?
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={customerMetadata.subscribe_arrived_sms}
                        onChange={handleCustomerMetadataCheckbox("subscribe_arrived_sms")}
                      />{" "}
                      Feedback (On Arrive) SMS?
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={customerMetadata.subscribe_missing_sms}
                        onChange={handleCustomerMetadataCheckbox("subscribe_missing_sms")}
                      />{" "}
                      Missing Items SMS?
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={customerMetadata.subscribe_on_hold_email}
                        onChange={handleCustomerMetadataCheckbox("subscribe_on_hold_email")}
                      />{" "}
                      Order Summary email?
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={customerMetadata.subscribe_certificate_email }
                        onChange={handleCustomerMetadataCheckbox("subscribe_certificate_email")}
                      />{" "}
                      Order Certificate email?
                    </Grid>
                    </Grid>
                  </Paper>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.AddressContainer}>
              <FormControlLabel
                className={classes.dupBilling}
                control={
                  <Switch checked={dupBilling} onChange={handleSetDupBilling} />
                }
                label="Billing and shipping are the same"
              />
              <Card>
                <CardContent>
                  <Grid container direction="column">
                    <Grid item>
                      {/* Tab Bar (Shipping/Billing) */}
                      <AppBar position="static">
                        <Tabs value={addressTab} onChange={onTabSelected}>
                          <Tab label="Shipping Address (default)" />
                          {!dupBilling && (
                            <Tab
                              disabled={dupBilling}
                              label="Billing Address"
                            />
                          )}
                        </Tabs>
                      </AppBar>

                      {/* Shipping Address */}
                      <Typography
                        component="div"
                        role="tabpanel"
                        hidden={addressTab !== 0}
                      >
                        <AddressTabInput
                          initialValues={{ addressType: "building" }}
                          onChange={handleShippingChange}
                          role={customer.type}
                          autosave
                        />
                      </Typography>

                      {/* Billing Address */}
                      {!dupBilling && (
                        <Typography
                          component="div"
                          role="tabpanel"
                          hidden={addressTab !== 1}
                        >
                          <Grid container>
                            <Grid item md={2} xs={12}>
                              <FormControlLabel
                                className={classes.overrideBillingName}
                                control={
                                  <Switch
                                    checked={overrideBillingContact}
                                    onChange={e =>
                                      setOVerrideBillingContact(
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label="Override Contact"
                              />
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <TextField
                                inputProps={{ autoComplete: "off" }}
                                disabled={!overrideBillingContact}
                                label="First Name"
                                className={classes.TextField}
                                value={billingFirstName}
                                onChange={e =>
                                  setBillingFirstName(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <TextField
                                inputProps={{ autoComplete: "off" }}
                                disabled={!overrideBillingContact}
                                label="Last Name"
                                className={classes.TextField}
                                value={billingLastName}
                                onChange={e =>
                                  setBillingLastName(e.target.value)
                                }
                              />
                            </Grid>
                            {customer.type === "b2b" && (
                              <Grid item md={3} xs={12}>
                                <TextField
                                  inputProps={{ autoComplete: "off" }}
                                  disabled={!overrideBillingContact}
                                  label="Company"
                                  className={classes.billingCompany}
                                  value={billingCompany}
                                  onChange={e =>
                                    setBillingCompany(e.target.value)
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>

                          <AddressTabInput
                            initialValues={{ addressType: "building" }}
                            onChange={handleBillingChange}
                            role={customer.type}
                            autosave
                            deliverableAddress={false}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignContent="flex-end"
                  >
                    <Grid item hidden={customer.type === "b2c"} xs={3}>
                      <FormControl>
                        <InputLabel htmlFor="force-site">Force Site</InputLabel>
                        <Autocomplete
                          id="force-site"
                          required
                          aria-describedby="force-site-helper-text"
                          loadOptions={loadOptions}
                          onChange={onForceSiteSelected}
                          optionComponent={AutocompleteResult}
                        />
                        <FormHelperText id="force-site-helper-text">
                          Select a site to enforce all customer orders upon
                        </FormHelperText>
                      </FormControl>
                      <TextField
                        select
                        required
                        label="Payment Agreement"
                        className={classes.TextField}
                        value={customerMetadata.paymentAgreement}
                        onChange={handleCustomerMetadataChange(
                          "paymentAgreement"
                        )}
                        fullWidth
                      >
                        <MenuItem key="preorder" value="preorder">
                          EFT - Pre Order
                        </MenuItem>
                        <MenuItem key="monthly" value="monthly">
                          EFT - Monthly - Automate
                        </MenuItem>
                        <MenuItem key="monthlyApproval" value="monthlyApproval">
                          EFT - Monthly - Needs approval
                        </MenuItem>
                        <MenuItem key="check" value="check">
                          Check
                        </MenuItem>
                        <MenuItem key="wire0" value="wire0">
                          Bank Transfer + 0
                        </MenuItem>
                        <MenuItem key="wire30" value="wire30">
                          Bank Transfer + 30
                        </MenuItem>
                        <MenuItem key="wire45" value="wire45">
                          Bank Transfer + 45
                        </MenuItem>
                        <MenuItem key="wire60" value="wire60">
                          Bank Transfer + 60
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={8}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" alignItems="flex-end">
            <Grid item>
              <Button
                type="submit"
                color="primary"
                disabled={!canCreateUser}
                size="large"
              >
                Create!
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </form>
  );
};

export default withRouter(CreateCustomer);
