import * as config from "../config";
import axios from "axios";

const twilioAxios = axios.create({
  headers: {
      common: {
          "Authorization": `Bearer ${config.BOND_API_KEY}`
      }
  }
});

/*
    `items` of type: [
        {name: "Banana", count: 5, comment: "KG"},
    ]
*/
export async function sendMissingItems(orderId, phone, countryCode, orderCreationDate, items) {
  const payload = {
    orderId,
    countryCode,
    date: orderCreationDate,
    phone: stripPhoneNumber(phone),
    items
  };

  await twilioAxios.post(`${config.BASE_TWILIO_URL}/onMissingOrderItems`, payload);
}

export async function sendOrderOnItsWay(orderId, phone, countryCode, zendeskId) {
  const payload = {
    orderId,
    countryCode,
    phone: stripPhoneNumber(phone),
    zendeskId
  };
  //TODO: remove this permanently when twilio is out
  //await twilioAxios.post(`${config.BASE_TWILIO_URL}/onOrderOnItsWay`, payload);
}

export async function sendOrderArrived(orderId, phone, countryCode, zendeskId) {
  const payload = {
    orderId,
    countryCode,
    phone: stripPhoneNumber(phone),
    zendeskId
  };

  //TODO: remove this permanently when twilio is out
  //await twilioAxios.post(`${config.BASE_TWILIO_URL}/onOrderArrived`, payload);
}

// Strips all symbols and letters from `phone`
function stripPhoneNumber(phone) {
  return phone.replace(/\D/g, "");
}
