import React from "react";
import { withRouter } from "react-router-dom";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles, withStyles, Checkbox, Typography } from "@material-ui/core";
import { formatOrderStatus } from "../../../utils/woocommerce";
import { WC_ORDER_TS_DATE } from "../../../config";

import * as timestamp from "../../../utils/timestamps";
import * as shookit from "../../../utils/shookit";
import * as woocommerce from "../../../utils/woocommerce";

const StyledTableRow = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),

    "&:hover": {
      backgroundColor: "#FAFAFA",
      cursor: "pointer"
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  draft: {
    opacity: "0.5",
    "&:hover": {
      opacity: "1"
    }
  },
  addressCell: {      
    maxWidth: "300px",
    direction: "rtl"
  }
}));

const CustomerOrderRow = ({ order, sites, history, isSelectMode, onSelectOrder, selectedOrders }) => {
  const classes = useStyles();
  const flatOrder = woocommerce.flattenMetadata(order);

  const onOrderSelect = (event, orderId) => {
    event.preventDefault();
    console.log(history);
    history.push(`/order/${orderId}`);
  };

  const onCheckboxChange = (e) => {
    onSelectOrder(order.id, !!e.target.checked)
  }

  const getOrderCreateDate = () => {
    if (order.date_created) {
      return timestamp.formatISODateString(order.date_created);
    }
    const date = new Date(order.created_at);
    return date.toLocaleDateString("en-IL") + " " + date.toLocaleTimeString("en-IL")
  }

  const orderAddress = () => {
    let floor = "";
    let apt = "";
    let ent = "";
    if (flatOrder._shipping_floor_number) {
      floor = `, קומה ${flatOrder._shipping_floor_number}`;
    }
    if (flatOrder._shipping_apt_number) {
      apt = `, דירה ${flatOrder._shipping_apt_number}`;
    }
    if (flatOrder._shipping_entrance) {
      ent = `, כניסה ${flatOrder._shipping_entrance}`;
    }

    return  flatOrder.shipping_address_street ? `${flatOrder.shipping_address_street} ${flatOrder.shipping_address_street_number}, ${flatOrder._shipping_autofill_checkout}${floor}${apt}${ent}` :
     `${flatOrder.shipping.address_1} ${flatOrder.shipping.city}${floor}${apt}${ent}`;
  };

  const site = sites ? sites.filter(site => site["site-id"] === flatOrder["Shookit Site"]) : [];
  const siteName = site.length ? site[0].name : site.name || "N\\A";
  return (
    <StyledTableRow
      key={order.id}
      onClick={event => {
        if (event.target.type === "checkbox") return;
        onOrderSelect(event, order.id);
      }}
      className={shookit.getOrderStatus(flatOrder) === "draft" ? classes.draft : undefined}>
      {isSelectMode && (<TableCell><Checkbox checked={selectedOrders.indexOf(order.id) > -1 ? true : false} onChange={onCheckboxChange} /></TableCell>)}
      <TableCell>{order.id}</TableCell>
      <TableCell>{ getOrderCreateDate() }</TableCell>
      <TableCell>{shookit.formatAsShortDay(flatOrder[WC_ORDER_TS_DATE])}</TableCell>
      <TableCell>{flatOrder["Shookit Timeslot Start"]} - {flatOrder["Shookit Timeslot End"]}</TableCell>
      <TableCell>{shookit.formatShookitStatus(shookit.getOrderStatus(flatOrder))}</TableCell>
      <TableCell><Typography className={classes.addressCell} noWrap={true} title={orderAddress()}>{orderAddress()}</Typography></TableCell>
      <TableCell>{siteName}</TableCell>
      <TableCell>
        {order.total} {order.final_total ? ` / ${order.final_total}` : ""}
      </TableCell>
    </StyledTableRow>
  );
};

export default withRouter(CustomerOrderRow);
