import React, { useState, useEffect } from "react";

export function useGetResult(getRequest, dependencies = []) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getRequest
      .then(result => {
        setResult(result.data);
        setIsLoading(false);
      })

      .catch(err => {
        setError(err);
        setIsLoading(false);
      });

    return () => {};
  }, dependencies);

  return [result, isLoading, error];
}
