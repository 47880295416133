import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";


import * as wcApi from "../../../services/wcApi";

import {TextField, Grid, CircularProgress} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  loadingBar: {
    width: "50%"
  }
}));

export default function CustomerDetailsField({ customer, onCustomerUpdated, type = "shipping", mandatory = false, label, field, 
  labelClassName= "", valueClassName="", useSaveButton=false, validationFunc=null, defaultValue=0 }) {
  let extractedValue;
  if (type === "meta_data") {
    const metaObj = customer.meta_data.find(m => m.key === field);
    if (metaObj) {
      extractedValue = metaObj.value;
    } else {
      extractedValue = defaultValue;
    }
  } else {
    extractedValue = customer[type][field];
  }
  
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(extractedValue)
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sameValueMsg, setSameValueMsg] = useState(false)
  async function handleChange(e) {
    const targetVal = e.target.value;
    if ((mandatory && targetVal.length === 0) || (validationFunc && !validationFunc(targetVal))) {
      setHasError(true);
    } else if (hasError) {
      setHasError(false);
    }
    setValue(targetVal);
    targetVal === extractedValue ? setSameValueMsg(true) : setSameValueMsg(false);

  }

  async function saveChanges () {
    if (hasError) {
      return;
    }

    if (value === extractedValue) {
      setEditMode(false);
      setSameValueMsg(false);
      return;
    }

    setIsLoading(true);
    let payload= {};
    if (type === "meta_data") {
      payload = {
        meta_data: [
          {
            key: field,
            value
          }
        ]
      }
    } else {
      payload = {
        [type]: {
          [field]: value
        }
      };
    }

    const result = await wcApi.updateCustomer(customer.id, payload);
    setSameValueMsg(false);
    onCustomerUpdated(result.data);
    setEditMode(false);
    setIsLoading(false);
  }

  if (editMode) {
    return (
      <Grid container alignItems="center">
        <Grid item md={5} s={6}>
          <b className={labelClassName}>{label}:</b>{" "}
        </Grid>
        <Grid item md={5} s={6}>
          <TextField
            value={value}
            onChange={handleChange}
            fullWidth={false}
            error={hasError}
            onBlur={!useSaveButton ? () => saveChanges() : undefined}
          />
        </Grid>
        {(useSaveButton && !isLoading && !hasError) && 
        <Grid item md={2} s={12}>
          <IconButton onClick={() => saveChanges()}>
            <SaveIcon />
          </IconButton>
        </Grid>
        }
        <span className="same-value-span" style={{position : "relative", color: "#f44336"}}>{sameValueMsg ? 'Same value' : ''}</span>
        {isLoading &&
        <Grid item md={2} s={12}>
          <CircularProgress style={{ width:"20px", height: "20px" }} />
        </Grid>
        }
      </Grid>
    );
  } else {
    return (
      <div onClick={() => setEditMode(true) } className={classes.clickable}>
        <div>
          <strong className={labelClassName}>{label}: </strong> 
          <span className={valueClassName}>{value}</span>
        </div>
      </div>
    );
  }
}
