import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Input,
  Button,
  Typography, 
  Select,
  MenuItem,
} from "@material-ui/core";

import cloneDeep from "lodash/cloneDeep";

import * as wcApi from "../../../services/wcApi";
import { flattenMetadata } from "../../../utils/woocommerce";

const useStyles = makeStyles(theme => ({
  oldPrice: {
    textDecoration: "line-through"
  },
  ccRefund: {
    color: "#ee1c23"
  },
  walletRefund: {
    color: "#1fb24c"
  },
  refundContainer: {
    justifyContent: "center"
  },
  refundReasonContainer : {
    marginLeft : '25px',
    marginBlockEnd : '50px', 
    rowGap : '15px'
  },
  emptyOtherReasonGap : {
    paddingBlockEnd : '32px',
  }

}));

const RefundFormDialog = ({ open, onClose, order, refundPoints, refundCC }) => {
  const classes = useStyles();

  const lineItems = cloneDeep(order.line_items);
  const [totalRefund, setTotalRefund] = useState(0);
  const [totalRefundWithVAT, setTotalRefundWithVAT] = useState(0);
  const [refundReason, setRefundReason] = useState("");
  const [refundError, setRefundError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [refundAmounts, setRefundAmounts] = useState({});
  const [canRefund, setCanRefund] = useState(false);
  const [isReasonOther, setisReasonOther] = useState(false)
  const [otherReason, setOtherReason] = useState('')

  const currencySymbol = order.currency_symbol || "₪";

  const clear = () => {
    setTotalRefund(0);
    setTotalRefundWithVAT(0);
    setRefundReason("");
    setRefundError(null);
    setRefundAmounts({});
    setSubmitted(false);
  };

  const cancelClicked = e => {
    clear();
    if (onClose) {
      onClose(e);
    }
  };

  const onRefundReasonChange = event => {
    setRefundReason(event.target.value);
    setisReasonOther (event.target.value === 'Other' );   
  };

  const onOtherReasonChange = event => {
    setOtherReason(event.target.value);
  };

  const calculateTotalRefund = () => {
    let totalRefundNoVAT = 0;
    let totalRefundWithVat = 0;
    lineItems.forEach(li => {
      if (li.id in refundAmounts) {
        let amount =
          refundAmounts[li.id].refund_amount !== ""
            ? refundAmounts[li.id].refund_amount
            : "0";
        let tax =
          refundAmounts[li.id].refund_tax
          ? refundAmounts[li.id].refund_tax
          : "0";

        totalRefundNoVAT += parseFloat(amount);
        totalRefundWithVat += parseFloat(tax);
      }
    });

    setTotalRefund(totalRefundNoVAT.toFixed(2));
    totalRefundWithVat += totalRefundNoVAT;
    setTotalRefundWithVAT(totalRefundWithVat.toFixed(2));
    if (totalRefundNoVAT !== 0) {
      setCanRefund(true);
    } else {
      setCanRefund(false);
    }
  };

  const getRefundLineItems = () => {
    let retLineItems = {};
    lineItems.forEach(lineItem => {
      if (lineItem.id in refundAmounts) {
        let refund_amount = refundAmounts[lineItem.id].refund_amount;
        let refund_quantity = refundAmounts[lineItem.id].refund_quantity !== "" ? refundAmounts[lineItem.id].refund_quantity : "0";
        let refund_tax = refundAmounts[lineItem.id].refund_tax;

        if (refund_amount !== "" && refund_amount !== "0") {
          retLineItems[lineItem.id] = {
            refund_total: refund_amount,
            refund_tax: {
              "1": refund_tax
            },
            qty: refund_quantity
          };
        }
      }
    });
    return retLineItems;
  };

  const refundOrder = async (wallet) => {
    setSubmitted(true);

    let refundDto = {
      amount: `${parseFloat(totalRefundWithVAT).toFixed(2)}`,
      reason: isReasonOther ? otherReason : refundReason,
      line_items: getRefundLineItems(),
      api_refund: false,
      wallet
    };
    
    try {
      let result = await wcApi.addRefund(order.id, refundDto);
      onClose(result.data);
      clear();
    } catch (err) {
      setRefundError(err.response);
      setSubmitted(false);
    }
  };

  const handleModalClose = () => {
    setRefundError(null);
    setSubmitted(false);
  };

  const getRefundByType = (itemId, refundType) => {
    if (!(itemId in refundAmounts)) {
      return "";
    }
    return refundAmounts[itemId][refundType];
  };

  const setRefundByType = (itemId, refundField) => e => {
    let clonedRefundAmounts = Object.assign({}, refundAmounts);

    let lineItemIdx = lineItems.findIndex(li => li.id === itemId);
    let lineRefunds = parseFloat(
      lineItems[lineItemIdx].refunds.reduce(
        (totalRefund, refundLine) => parseFloat(refundLine[refundField]) + totalRefund, 0)
    );

    let refundValue = "";
    let refund = e.target.value !== "" ? e.target.value : "0";
    let maxRefund = (parseFloat(lineItems[lineItemIdx][refundField]) + lineRefunds).toFixed(2);
    if (parseFloat(refund) > maxRefund) {
      refundValue = maxRefund;
    } else if (parseFloat(refund) < 0) {
      refundValue = "";
    } else {
      refundValue = e.target.value;
    }

    if (!(itemId in refundAmounts)) {
      clonedRefundAmounts[itemId] = {};
    }

    if (refundField === 'total') {
      clonedRefundAmounts[itemId].refund_amount = refundValue;
    } else {
      if (parseInt(refundValue)) {
        clonedRefundAmounts[itemId].refund_quantity = parseInt(refundValue);
        let totalRefund = parseFloat(
          lineItems[lineItemIdx].refunds.reduce(
            (totalRefund, refundLine) => parseFloat(refundLine.total) + totalRefund, 0)
        );
        let maxTotal = parseFloat(lineItems[lineItemIdx].total) + totalRefund;
        let amount = (refundValue * parseFloat(lineItems[lineItemIdx].price)).toFixed(2);
        clonedRefundAmounts[itemId].refund_amount = amount > maxTotal ? maxTotal.toFixed(2) : amount;
      } else {
        clonedRefundAmounts[itemId].refund_quantity = "";
        clonedRefundAmounts[itemId].refund_amount = "";
      }
    }

    if (parseFloat(lineItems[lineItemIdx].total_tax) > 0) {
      let vat = (lineItems[lineItemIdx].taxes[0].total / lineItems[lineItemIdx].total);
      clonedRefundAmounts[itemId].refund_tax = parseFloat(clonedRefundAmounts[itemId].refund_amount * vat).toFixed(3);
    } else {
      clonedRefundAmounts[itemId].refund_tax = "0";
    }

    setRefundAmounts(clonedRefundAmounts);
    calculateTotalRefund();
  };

  return (
    <Dialog open={open} onClose={cancelClicked} maxWidth="md">
      <Dialog open={refundError != null} onClose={handleModalClose}>
        <DialogTitle>Failed to Refund Order #{order.id}</DialogTitle>
        <DialogContent>
          Failed to refund order to an error:{" "}
          <i>{refundError != null ? refundError.data.message : ""}</i>
        </DialogContent>
      </Dialog>
      <DialogTitle>Refund Order #{order.id}</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Total Price Before VAT</TableCell>
                  <TableCell>Refund Quantity</TableCell>
                  <TableCell>Refund Amount Before VAT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map(lineItem => {
                  const flatItem = flattenMetadata(lineItem);
                  const refund = parseFloat(
                    flatItem.refunds.reduce(
                      (totalRefund, refundLine) =>
                        parseFloat(refundLine.total) + totalRefund,
                      0
                    )
                  );
                  const refundQuantity = parseInt(
                    flatItem.refunds.reduce(
                      (totalRefundQuantity, refundLine) =>
                        parseInt(refundLine.quantity) + totalRefundQuantity,
                      0
                    )
                  );
                  let quantity, unit;
                  if (flatItem.shookit_shipped_quantity) {
                    quantity = flatItem.shookit_shipped_quantity;
                    unit = flatItem.shookit_requested_unit;
                  } else if (flatItem.shookit_shipped_weight) {
                    quantity = flatItem.shookit_shipped_weight;
                    unit = "KG";
                  } else {
                    quantity = 0;
                    unit = "-";
                  }
                  return (
                    <TableRow key={lineItem.id}>
                      <TableCell>{lineItem.sku}</TableCell>
                      <TableCell>{lineItem.name}</TableCell>
                      <TableCell>
                        {refundQuantity ? (
                          <>
                            <Box className={classes.oldPrice}>
                              {quantity}
                            </Box>
                            <Box>
                              {parseInt(quantity) + refundQuantity}
                            </Box>
                          </>
                        ) : (
                          <Box>
                            {quantity}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>{unit}</TableCell>
                      <TableCell>
                        {refund ? (
                          <>
                            <Box className={classes.oldPrice}>
                              {parseFloat(lineItem.total).toFixed(2)}
                              {currencySymbol}
                            </Box>
                            <Box>
                              {(parseFloat(lineItem.total) + refund).toFixed(2)}
                              {currencySymbol}
                            </Box>
                          </>
                        ) : (
                          `${parseFloat(lineItem.total).toFixed(2)}${currencySymbol}`
                        )}
                      </TableCell>
                      <TableCell>
                        <Input
                          type="number"
                          placeholder='0'
                          value={getRefundByType(lineItem.id, 'refund_quantity')}
                          min="0"
                          max={lineItem.quantity}
                          onChange={setRefundByType(lineItem.id, 'quantity')}
                          onKeyUp={setRefundByType(lineItem.id, 'quantity')}
                        />
                      </TableCell>
                      <TableCell>
                        <Input
                          type="number"
                          placeholder='0'
                          value={getRefundByType(lineItem.id, 'refund_amount')}
                          min="0"
                          max={lineItem.total}
                          onChange={setRefundByType(lineItem.id, 'total')}
                          onKeyUp={setRefundByType(lineItem.id, 'total')}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Grid container className alignItems="flex-end" rowSpacing={5} ml={30}>
                  <TableCell>Refund Reason</TableCell>
                  <Select
                    labelId="refund-reason-select-label"
                    id="refund-reason-simple-select"
                    value={refundReason}
                    label="Refund Reason"
                    onChange={onRefundReasonChange}
                    placeholder="Refund Reason...">
                    <MenuItem value={'Quality'}>Quality</MenuItem>
                    <MenuItem value={'Wrong product'}>Wrong product</MenuItem>
                    <MenuItem value={'Missing product'}>Missing product</MenuItem>
                    <MenuItem value={'Boxes'}>Boxes</MenuItem>
                    <MenuItem value={'Wrong quantity'}>Wrong quantity</MenuItem>
                    <MenuItem value={'Delivery Fee'}>Delivery Fee</MenuItem>
                    <MenuItem value={'Delivery didn\'t arrive'}>Delivery didn't arrive</MenuItem>
                    <MenuItem value={'Coupon'}>Coupon</MenuItem>
                    <MenuItem value={'Overcharge'}>Overcharge</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </Select>

                  {isReasonOther && <TextField 
                    required
                    value={otherReason}
                    onChange={onOtherReasonChange}
                    placeholder="Refund Reason..."
                  />}
                  {!isReasonOther && <Grid className={classes.emptyOtherReasonGap}></Grid>}
              </Grid>
              </Grid>
              <Grid item>
                <Typography>Total Refund Before VAT: {totalRefund}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography>Total Refund with VAT: {totalRefundWithVAT}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.refundContainer}>
        <Button
          className={classes.ccRefund}
          color="danger"
          type="submit"
          disabled={Math.abs(refundPoints) > 0 || !canRefund || refundReason.length === 0 || (isReasonOther && otherReason.length < 1) }
          onClick={() => refundOrder(false)}
        >
          cc refund
        </Button>
        <Button color="primary" onClick={cancelClicked}>
          Cancel
        </Button>
        <Button
          className={classes.walletRefund}
          color="secondary"
          type="submit"
          disabled={(Math.abs(refundCC) >  0 && parseFloat(Math.abs(refundCC)).toFixed(2) !== parseFloat(Math.abs(refundPoints)).toFixed(2) ) || !canRefund || refundReason.length === 0 || 
          ( isReasonOther && otherReason.length >= 1) }
          onClick={() => refundOrder(true)}
        >
          wallet refund
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RefundFormDialog.propTypes = {
  order: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancelClicked: PropTypes.func
};

export default RefundFormDialog;
