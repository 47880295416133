import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormSelect from "../../UI/FormSelect";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import { SUPPORTED_ROLES } from "../../../config";

const useStyles = makeStyles(theme => ({
  dialogContent: {},
  field: {
    marginBottom: theme.spacing(2)
  },
  errorMessage: {
    textAlign: "center",
    padding: "0.2rem",
    backgroundColor: theme.palette.secondary.light
  }
}));

export default function SiteFormDialog({ open, onClose, onSave, initialValues }) {
  const classes = useStyles();
  const [name, setName] = useState((initialValues && initialValues.name) || "");
  const [address, setAddress] = useState((initialValues && initialValues.address) || "");
  const [wazeurl, setWazeurl] = useState((initialValues && initialValues.wazeurl) || "");
  const [region, setRegion] = useState((initialValues && initialValues.region) || null);
  const [roles, setRoles] = useState((initialValues && initialValues.roles) || []);
  const [isActive, setIsActive] = useState((initialValues && initialValues.isactive) || false);
  const [isPickup, setIsPickup] = useState((initialValues && initialValues.is_pickup) || false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  async function save() {
    let result = {
      name,
      "is-active": isActive,
      region,
      roles,
      address,
      wazeurl,
      "is-pickup": isPickup
    };

    // Verify Form
    if (name === "" || region === null || roles.length === 0) {
      console.log(result);
      setErrorMessage("Please fill all fields");
      return;
    }

    // Add siteId if it's an update mode
    if (initialValues && "site-id" in initialValues) {
      result["site-id"] = initialValues["site-id"];
    }

    setIsSaving(true);
    await onSave(result);
    setIsSaving(false);
    onClose();

    // Clear form data
    resetForm();
  }

  function resetForm() {
    setName("");
    setRegion(null);
    setRoles([]);
    setIsActive(false);
    setErrorMessage(null);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm();
        onClose();
      }}
      aria-labelledby="form-dialog-title"
      PaperProps={{ elevation: 24 }}
    >
      <DialogTitle id="form-dialog-title">Add Site</DialogTitle>

      {errorMessage && <Box className={classes.errorMessage}>{errorMessage}</Box>}

      <DialogContent className={classes.dialogContent}>
        {initialValues && (
          <TextField
            className={classes.field}
            disabled
            id="siteId"
            label="Site Id"
            type="text"
            fullWidth
            value={initialValues["site-id"]}
          />
        )}

        <TextField
          label="Name"
          className={classes.field}
          autoFocus
          id="name"
          type="text"
          value={name}
          required
          onChange={event => {
            setName(event.target.value);
          }}
          fullWidth
        />

        <FormSelect
          label="Region"
          required
          values={["Israel", "New York"]}
          initialValue={region}
          onChange={value => setRegion(value)}
        />

        <FormSelect
          label="Customer Type"
          values={SUPPORTED_ROLES.slice(1)}
          multiple={true}
          initialValue={roles}
          onChange={value => setRoles(value)}
        />

        <FormControlLabel
          control={
            <Switch
              checked={isPickup}
              onChange={event => {
                setIsPickup(event.target.checked);
              }}
              value="pickup"
              color="primary"
            />
          }
          label="Pickup"
        />

        <FormControlLabel
          control={
            <Switch
              checked={isActive}
              onChange={event => {
                setIsActive(event.target.checked);
              }}
              value="active"
              color="primary"
            />
          }
          label="Active"
        />

        <TextField
          label="Address"
          className={classes.field}
          autoFocus
          id="address"
          type="text"
          value={address}
          onChange={event => {
            setAddress(event.target.value);
          }}
          fullWidth
        />

        <TextField
          label="Waze Link"
          className={classes.field}
          autoFocus
          id="wazeurl"
          type="text"
          value={wazeurl}
          onChange={event => {
            setWazeurl(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>

      {isSaving && <LinearProgress />}

      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={() => save()} color="primary" disabled={isSaving}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
