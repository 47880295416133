import React, { useState } from "react";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative"
  },
  wrapperEdit: {
    position: "relative",
    paddingRight: "50px"
  },
  editButtons: {
    position: "absolute",
    right: 0,
    top: 0,
    opacity: 1,
    width: "50px"
  },
  hideButtons: {
    display: "none"
  }
}));

const FloatingEditButtons = props => {
  const styles = useStyles();
  const [buttonsShown, setButtonsShown] = useState(false);

  const hideButtons = e => {
    if (buttonsShown) {
      setButtonsShown(false);
    }
  };

  const showButtons = e => {
    if (!buttonsShown) {
      setButtonsShown(true);
    }
  };

  let buttonStyles = styles.hideButtons;

  if (buttonsShown) {
    buttonStyles = styles.editButtons;
  }

  return (
    <div
      className={props.editing ? styles.wrapperEdit : styles.wrapper}
      onMouseEnter={showButtons}
      onMouseLeave={hideButtons}>
      <div className={buttonStyles}>
        {!props.editing ? (
          <IconButton onClick={props.onEditClicked}>
            <EditIcon />
          </IconButton>
        ) : (
          <div>
            <IconButton className={styles.button} onClick={props.onSaveClicked}>
              <SaveIcon />
            </IconButton>
            <IconButton className={styles.button} onClick={props.onDiscardClicked}>
              <ReplayIcon />
            </IconButton>
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};

FloatingEditButtons.propTypes = {
  editing: PropTypes.bool,
  onEditClicked: PropTypes.func,
  onDiscardClicked: PropTypes.func,
  onSaveClicked: PropTypes.func
};

export default FloatingEditButtons;
