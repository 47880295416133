import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  actionColumn: {
    maxWidth: "60px"
  },
  actions: {
    visibility: "hidden",
    // backgroundColor: "blue",
    "tr:hover &": {
      visibility: "visible"
      // backgroundColor: "green"
    }
  },
  tableButton: {
    padding: theme.spacing(1)
  },
  truncate: {
    width: "350px",
    maxWidth: "350px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));
export default ({ users, editUser, sites }) => {
  const classes = useStyles();

  const idSite = sites.reduce((obj, site) => {
    obj[site["site-id"]] = site;
    return obj;
  }, {});

  const siteName = (user) => {
    let siteNames = [];
    user.sites.map(site => {
      if (idSite[site]) {
        siteNames.push(idSite[site].name);
      }
    })

    return siteNames.join(", ");
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Username</TableCell>
          <TableCell>Region</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Sites</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(users).map(user => (
          <TableRow
            hover
            key={user.username}
            onClick={() => editUser(user.username)}
          >
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>{user.region}</TableCell>
            <TableCell>{user.role}</TableCell>
            <TableCell className={classes.truncate}>
              {
                siteName(user)
              }
            </TableCell>
            <TableCell
              align="right"
              className={(classes.noPadding, classes.actionColumn)}
            >
              <span className={classes.actions}>
                <Button href="#" aria-label="edit">
                  <EditIcon />
                </Button>
              </span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
