import React from "react";
import Typography from "@material-ui/core/Typography";

export default function PageHeader(props) {
  return (
    <Typography component="h1" variant="h4" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}
