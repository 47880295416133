import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import FloatingEditButtons from "../../../UI/FloatingEditButtons";
import PhoneInput from "../../../common/inputs/PhoneInput";
import * as wcApi from "../../../../services/wcApi";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import * as woocommerce from "../../../../utils/woocommerce";

import * as PaneForm from "./PaneForm";

const useStyles = makeStyles(theme => ({
  addressTab: {
    minWidth: "50%"
  }
}));

export default function CustomerInfoPane({ customerEmail, order, onOrderUpdated }) {
  const styles = useStyles();

  const initialData = {
    first_name: order.shipping.first_name,
    last_name: order.shipping.last_name,
    shipping_company: order.shipping.company || "",
    billing_company: order.billing.company || "",
    email: order.billing.email,
    phone: order.billing.phone,
  };


  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const handleChange = name => event => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  async function handleSave() {

    if (!formData.shipping_company.length && order.billing.company) {
      return;
    }

    if (!formData.billing_company.length && order.billing.company) {
      return;
    }

    let payload = {};
    payload["shipping"] = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      company: formData.shipping_company,      
    };

    payload["billing"] = {
      email: formData.email,
      phone: formData.phone,
      company: formData.billing_company
    };
    
    payload["meta_data"] = [{
      key: '_shipping_email',
      value: formData.shipping_email
    }]

    const orderDetails = await wcApi.updateOrder(order.id, payload);
    setEditMode(false);
    onOrderUpdated(orderDetails.data);
  }

  const isCompany = "company" in order.shipping && !isEmpty(order.shipping.company);

  return (
    <>
      <div>
        <FloatingEditButtons
          onEditClicked={() => setEditMode(true)}
          onDiscardClicked={() => {
            setFormData(initialData);
            setEditMode(false);
          }}
          onSaveClicked={handleSave}
          editing={editMode}>
          <Typography color="primary">Order Contact Information</Typography>

          {(isCompany || (editMode && isCompany)) && (
            <>
            <PaneForm.TextField
              label="Company Name"
              value={formData.shipping_company}
              onChange={handleChange("shipping_company")}
              editMode={editMode}
              required={true}
            />
            <PaneForm.TextField
              label="Billing Company Name"
              value={formData.billing_company}
              onChange={handleChange("billing_company")}
              editMode={editMode}
            />
            </>
          )}

          <PaneForm.TextField
            label="First Name"
            value={formData.first_name}
            onChange={handleChange("first_name")}
            editMode={editMode}
          />
          <PaneForm.TextField
            label="Last Name"
            value={formData.last_name}
            onChange={handleChange("last_name")}
            editMode={editMode}
          />
          <PaneForm.TextField label="Type" value={isCompany ? "Business" : "Private"} onChange={() => {}} />

          <PaneForm.TextField
            label="Mailing Address"
            value={formData.email}
            onChange={handleChange("email")}
            editMode={editMode}
          />

          <PaneForm.TextField
            label="Email for Login"
            value={customerEmail}
            onChange={() => {}}
            editMode={false}
          />

          <PaneForm.TextField
            label="Phone"
            value={formData.phone}
            onChange={handleChange("phone")}
            editMode={editMode}
          />
        </FloatingEditButtons>
      </div>
    </>
  );
}
