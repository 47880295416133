import React from "react";

import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import { useStyles } from "./index";

export function DatePicker({ selectedDate, onDateChanged }) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleDateChange = date => {
    onDateChanged(date);
  };

  function setDay(delta) {
    onDateChanged(new Date(selectedDate.setDate(selectedDate.getDate() + delta)));
  }

  function TextFieldComponent(props) {
    let [month, day, dayName] = props.value.split(' ');
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let dayString = `${dayName}`;
    if (selectedDate.getTime() === today.getTime()) {
      dayString = `${dayString} (Today)`;
    } else if (selectedDate.getTime() === tomorrow.getTime()) {
      dayString = `${dayString} (Tomorrow)`;
    }

    return (
      <Box>
        <Box fontWeight="fontWeightMedium" fontSize="h6.fontSize" onClick={() => setIsModalOpen(true)}>
          {`${month} ${day}`}
        </Box>
        <Box fontSize="h8.fontSize" display='flex' justifyContent='center'>
          {dayString}
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" pt="1rem" px="10px">
      <Button variant="outlined" color="primary" className={classes.button} onClick={() => setDay(-1)}>
        <DoubleArrowIcon className={classes.flipIcon} />
      </Button>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          value={selectedDate}
          onChange={handleDateChange}
          TextFieldComponent={TextFieldComponent}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          format={"MMMM do eeee"}
        />
      </MuiPickersUtilsProvider>

      <Button variant="outlined" color="primary" className={classes.button} onClick={() => setDay(+1)}>
        <DoubleArrowIcon />
      </Button>
    </Box>
  );
}
