import React, { useState, useEffect } from "react";

import { isEmpty, debounce } from "lodash";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

import { WEIGHT_PRICING_METHOD, FIXED_PRICING_METHOD } from "../../../config";
import useDebouncedCallback from "../../../utils/useDebouncedCallback";
import * as calcApi from "../../../services/priceCalculatorApi";
import { flattenMetadata } from "../../../utils/woocommerce";
import { shookitRound } from "../../../utils/shookit";

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: "0.5rem",
    "& input": { textAlign: "center" }
  },
  buttonSelected: {
    background: "#58be8c"
  },
  highlight: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  },
  priceInput: {
    textAlign: "center"
  }
}));

function EditLineItemForm({
  product,
  item,
  initialQuantity,
  initialUnit,
  initialPrice,
  currencySymbol,
  initialPricingMethod,
  onCancel,
  onSave,
  canEditQuantity
}) {
  const classes = useStyles();

  const [quantity, setQuantity] = useState(initialQuantity);
  const [unit, setUnit] = useState(initialUnit);
  const [price, setPrice] = useState(initialPrice);

const getInitalProductPrice = () => {
  if (initialPricingMethod === FIXED_PRICING_METHOD) {
    return item.subtotal/item.quantity;
  } else {
    if (item.shookit_base_price_override) {
      return item.shookit_base_price_override;
    }
  }
  return product.price;
}

  const [productPrice, setProductPrice] = useState(getInitalProductPrice())
  const [pricingMethod, setPricingMethod] = useState(initialPricingMethod);
  const [isLoading, setIsLoading] = useState(false);

  const flattenProduct = flattenMetadata(product);
  
  const kgUnit = flattenProduct.override_kg || "KG";

  async function _calculateNewPrice(overridePrice = null) {
    let newPrice = 0;
    let overrideQuantity = -1;
    const pricePerUnit = overridePrice ? parseFloat(overridePrice) : parseFloat(productPrice);

    if (pricingMethod === FIXED_PRICING_METHOD) {
      newPrice = parseFloat(quantity) * pricePerUnit;
      setPrice(newPrice);
      setIsLoading(false);
      return newPrice;
    }

    if (!quantity) {
      newPrice = "";
    } else {
      newPrice = await calcApi.calculatePrice(
        flattenProduct.id,
        quantity,
        WEIGHT_PRICING_METHOD,
        pricePerUnit
      );
    }

    setPrice(newPrice);
    setIsLoading(false);
    return newPrice;
  }

  const handlePriceChange = async event => {
    let value = parseFloat(event.target.value);
    if (isNaN(value) || value < 0) return;
    setIsLoading(true);
    setProductPrice(event.target.value);
    setIsLoading(false);    
  };


  const onChangeQuantity = e => {
    let value = 0;
    console.log(kgUnit);
    console.log(pricingMethod);
    if (kgUnit != "KG" || (kgUnit === "KG" && pricingMethod != "FIXED")) {
      value = parseInt(e.target.value);
    } else if (e.target.value.slice(-1) != '.'){
      value = shookitRound(parseFloat(e.target.value)); 
    } else {
      value = e.target.value
    }
    if (e.target.value.length < 1 || value < 0) {
      value = 0;
    }

    setQuantity(value)
  }
  const saveChanges = async () => {
    setIsLoading(true);
    let newPrice = await _calculateNewPrice();
    onSave({ unit, quantity, pricingMethod, price: newPrice, productPrice });
  }

  // useEffect(() => {
  //   // setIsLoading(true);
  //   _calculateNewPrice();
  //   return () => {};
  // }, [quantity, unit, productPrice]);

  return (
    <TableRow className={classes.highlight}>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <TextField
          disabled={!canEditQuantity}
          className={classes.textField}
          onChange={onChangeQuantity}
          // onKeyPress={handleTextFieldKeyPress}
          value={quantity}
        />{" "}
        <ButtonGroup variant="contained" color="primary" size="small" disabled={!canEditQuantity}>
          {!flattenProduct.hide_kg && (
          <Button
            className={unit === kgUnit ? classes.buttonSelected : undefined}
            onClick={() => {              
              setUnit(kgUnit);
              setPricingMethod(FIXED_PRICING_METHOD);
            }}
          >
            {flattenProduct.override_kg || "KG"}
          </Button>
          )}
          {flattenProduct.is_calculated && (
            <Button
              className={unit === "Unit" ? classes.buttonSelected : undefined}
              onClick={() => {
                setQuantity(parseInt(quantity));
                setUnit("Unit");
                setPricingMethod(WEIGHT_PRICING_METHOD);
              }}
            >
              Units
            </Button>
          )}
        </ButtonGroup>
      </TableCell>
      <TableCell></TableCell>      
      <TableCell>
        {!isLoading ? (
        <FormControl className={classes.priceInput} variant="filled">
          <InputLabel htmlFor="base-price">Updated Price</InputLabel>
          <Input
            type="number"
            id="base-price"
            value={productPrice}
            autoComplete="off"
            onChange={handlePriceChange}
            startAdornment={<InputAdornment position="end">{currencySymbol}</InputAdornment>}
          />
        </FormControl>     
        ) : "?"}
        {pricingMethod !== "FIXED" && (
          <>
            <br />
            <small>ESTIMATED</small>
          </>
        )}
      </TableCell>
      <TableCell></TableCell>
      <TableCell colSpan="4">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>{" "}
        <Button
          variant="contained"
          onClick={() => saveChanges()}
          color="primary"
          disabled={isLoading}
        >
          Save
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default EditLineItemForm;
