import _ from "lodash";

const DAYS = _.range(1, 8);

// Receives a list of timeslots and add missing days to the list
export function fillMissingDays(timeslots, role) {
  const presentDays = timeslots.map(day => day.day);
  const nonPresentDays = DAYS.filter(day => presentDays.indexOf(day) === -1);

  const missingData = nonPresentDays.map(day => ({
    day,
    role,
    timeslots: []
  }));

  return _.sortBy([...missingData, ...timeslots], ["day"]);
}

export function splitTimeslotsToRoles(requiredRoles, data) {
  const foundRoles = _.uniq(data.timeslots.map(day => day.role));
  const roles = _.uniq([...foundRoles, ...requiredRoles]);
  console.log(`[] Supported roles: ${roles}`);

  const timeslotsPerRole = _.groupBy(data.timeslots, "role");

  var result = {};
  roles.forEach(role => {
    if (role in timeslotsPerRole) {
      result[role] = fillMissingDays(timeslotsPerRole[role], role);
    } else {
      result[role] = fillMissingDays([], role);
    }
  });

  return result;
}
