import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { isEmpty } from "lodash";
import * as geoApi from "../../../../services/geoApi";
import * as wcApi from "../../../../services/wcApi";
import * as shookitUtils from "../../../../utils/shookit";
import { WC_ORDER_SITE, WC_ORDER_TS_START, WC_ORDER_TS_END, WC_ORDER_TS_DATE, WC_ORDER_LEAVE_AT_DOOR } from "../../../../config";

import { Typography, CircularProgress } from "@material-ui/core";
import FloatingEditButtons from "../../../UI/FloatingEditButtons";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditDeliveryDialog from "../Dialogs/EditDeliveryDialog";

const useStyles = makeStyles(theme => ({
  addressTab: {
    minWidth: "50%"
  }
}));

export default function ShippingInfo({ order, onOrderUpdated }) {
  const styles = useStyles();

  const [siteInfo, setSiteInfo] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const isCompany = order.shipping.company && !isEmpty(order.shipping.company);
  const allowEditing = ["draft", "PENDING", "FULFILLING", "READY"].includes(
    shookitUtils.getOrderStatus(order)
  );

  // Load Sites List
  useEffect(() => {
    geoApi.fetchShookitSiteInformation(order[WC_ORDER_SITE]).then(res => {
      if (res) {
        setSiteInfo(res);
      } else {
      }
    });
  }, [order]);

  async function updateDelivery(result) {
    if (result === null) {
      setShowModal(false);
      setIsSaving(false);
      return;
    }

    // const { site, day, start, end } = result;
    setIsSaving(true);
    setShowModal(false);
    let payload = {};
    payload["meta_data"] = [
      { key: WC_ORDER_SITE, value: result.site },
      { key: WC_ORDER_TS_START, value: result.start },
      { key: WC_ORDER_TS_END, value: result.end },
      { key: WC_ORDER_TS_DATE, value: result.day}, 
      { key: WC_ORDER_LEAVE_AT_DOOR, value: result.leaveAtDoor }
    ];

    const orderDetails = await wcApi.updateOrder(order.id, payload);
    onOrderUpdated(orderDetails.data);

    setIsSaving(false);
  }

  const pageContent = (
    <React.Fragment>
      <Typography color="primary">Delivery Details</Typography>
      <div>
        <b>Responsible Site:</b> {siteInfo ? siteInfo.name : <CircularProgress color="secondary" />}
      </div>
      <div>
        <b>Delivery Date:</b> {shookitUtils.formatAsDay(order[WC_ORDER_TS_DATE])}
      </div>
      <div>
        <b>Delivery Window:</b> {`${order[WC_ORDER_TS_START]} - ${order[WC_ORDER_TS_END]}`}
      </div>
    </React.Fragment>
  );

  return (
    <>
      <EditDeliveryDialog
        currentSite={order[WC_ORDER_SITE]}
        open={showModal}
        role={isCompany ? "b2b" : "b2c"}
        currentDay={order[WC_ORDER_TS_DATE]}
        currentStart={order[WC_ORDER_TS_START]}
        currentEnd={order[WC_ORDER_TS_END]}
        onClose={updateDelivery}
      />
      <div>
        {allowEditing ? (
          <FloatingEditButtons
            onEditClicked={() => {
              setShowModal(true);
            }}
            onDiscardClicked={() => {}}
            onSaveClicked={() => {}}
            editing={false}>
            {pageContent}
          </FloatingEditButtons>
        ) : (
          pageContent
        )}
        {isSaving && <LinearProgress />}
      </div>
    </>
  );
}
