import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { uniq } from "lodash";

import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  TextField
} from "@material-ui/core";

import FormSelect from "../../UI/FormSelect";

const useStyles = makeStyles(theme => ({
  dialogContent: {},
  dialogActions: {
    padding: "8px 20px"
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  errorMessage: {
    textAlign: "center",
    padding: "0.2rem",
    backgroundColor: theme.palette.secondary.light
  },
  cancelButton: {
    minWidth: "200px"
  },
  saveButton: {
    minWidth: "200px",
    width: "100%"
  }
}));

export default ({ user, open, closeDialog, sites, onSave }) => {
  const [chosenRegion, setChosenRegion] = useState(undefined);
  const [chosenSites, setChosenSites] = useState(undefined);
  const [editedUser, setEditedUser] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const regions = uniq(sites.map(site => site.region));
  const sitesByName = sites.reduce((obj, site) => {
    if (site.region === (chosenRegion || user.region)) {
      obj[site.name] = site;
    }
    return obj;
  }, {});
  const sitesById = sites.reduce((obj, site) => {
    obj[site["site-id"]] = site;
    return obj;
  }, {});

  const getInitialSiteNames = (u) => {
    let names = [];
    if (u.sites) {
      u.sites.forEach(site => {
        if (sitesById[site]) {
          names.push(sitesById[site].name);
        }
      });
    }
  };

  const initialRegion = user.region;
  const initialSiteNames = getInitialSiteNames(user);

  useEffect(() => {
    if (user && editedUser !== user) {
      setEditedUser(user);
      setChosenSites(getInitialSiteNames(user));
      setChosenRegion(user.region);
    }
  });

  useEffect(() => {
    setIsSaving(false);
  }, [editedUser]);

  const classes = useStyles();

  const reset = () => {
    setChosenRegion(undefined);
    setChosenSites(undefined);
  };

  const changeRegion = region => {
    if (
      region !== (chosenRegion !== undefined ? chosenRegion : initialRegion)
    ) {
      setChosenSites([]);
    }
    setChosenRegion(region);
  };

  const changeSites = sites => {
    setChosenSites(sites);
  };

  return (
    <Dialog onClose={closeDialog} open={open}>
      <DialogContent className={classes.dialogContent}>
        <TextField
          label="User Email"
          className={classes.field}
          type="email"
          value={user.email || ""}
          disabled
          fullWidth
        />
        <TextField
          label="Name"
          className={classes.field}
          type="text"
          value={user.username || ""}
          disabled
          fullWidth
        />
        <TextField
          label="Role"
          className={classes.field}
          type="text"
          value={user.role || ""}
          disabled
          fullWidth
        />
        <FormSelect
          label="Region"
          values={regions}
          initialValue={initialRegion}
          onChange={changeRegion}
        />
        <FormSelect
          label="Sites"
          values={Object.keys(sitesByName)}
          multiple
          selectAll
          initialValue={initialSiteNames}
          onChange={changeSites}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={isSaving}
          variant="contained"
          onClick={() => {
            closeDialog();
            reset();
          }}
          color="secondary"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.saveButton}
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            const result =
              chosenSites === undefined
                ? user.sites
                : chosenSites.map(siteName => sitesByName[siteName]["site-id"]);
            onSave(result);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
