import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import * as wcApi from "../../../services/wcApi";

import { TextField, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  }
}));

export default function CustomerIdField({ customer, onCustomerUpdated }) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [customerId, setCustomerId] = useState(customer['company_id']);
  const [isValid, setIsValid] = useState(true);

  const validateTaxId = (idNumber) => {
    const id = idNumber.toString();
    if (id.length !== 9 || isNaN(idNumber)) {
      // Make sure ID is formatted properly
      return false;
    }
    let sum = 0;
    let incNum;
    for (let i = 0; i < id.length; i++) {
      incNum = Number(id[i]) * ((i % 2) + 1); // Multiply number by 1 or 2
      sum += incNum > 9 ? incNum - 9 : incNum; // Sum the digits up and add to total
    }
    return sum % 10 === 0;
  };


  const saveChanges = async () => {
    if (!validateTaxId(customerId)) {
      setIsValid(false);
      return;
    }
    setIsValid(true);

    // Update site in database
    setIsSaving(true);
    const payload = {
      meta_data: [
        {
          key: "company_id",
          value: customerId
        }
      ]
    };
    const result = await wcApi.updateCustomer(customer.id, payload);
    onCustomerUpdated(result.data);
    setEditMode(false);
    setIsSaving(false);
  }

  function handleSiteChanged(e) {
    setCustomerId(e.target.value.toString());
  }


  if (editMode) {
    return (
      <div>
        <b>Company ID:</b>{" "}
        
          <TextField
            small
            value={customerId}
            onChange={handleSiteChanged}
            fullWidth={false}
            error={!isValid}
            onBlur={() => saveChanges()}
          />        
      </div>
    );
  } else {
    return (
      <div onClick={() => setEditMode(true)} className={classes.clickable}>
        <b>Company Id:</b> {customerId || "[not set]"}
        {isSaving && <LinearProgress />}
      </div>
    );
  }
}
