import axios from "axios";
import querystring from "querystring";

import _ from "lodash";
import * as config from "../config";

import PaymentTypes from "../enums/PaymentTypes";
import { flattenMetadata } from "../utils/woocommerce";
import { log } from "logrocket";

export async function fetchAllSites() {
    const result = await axios.get(`${config.BASE_URL}/wp-json/shookit-rest/v1/geo-bond-sites`);

    return result.data.sites;
}

export async function updateSite(siteId, site) {
    const result = await axios.put(
        `${config.BASE_URL}/wp-json/shookit-rest/v1/geo-bond-sites/${siteId}`,
        site
    );

    return result.data;
}

export async function sendForgetPassword(customerId) {
  return axios.get(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/resetpw/${customerId}`
  );
}

export async function processPayment(orderId) {
  return axios.post(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/invoke_payment/${orderId}`
  );
}

export async function sendGreenInvoice(orderId) {
  return axios.post(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/send_green_invoice/${orderId}`
  );
}


export async function openPaymentFailedTicket(orderId) {
  return axios.post(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/open_payment_failed_ticket/${orderId}`
  );
}

export async function sendInvoice(orderId) {
  return axios.post(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/send_invoice/${orderId}`
  );
}

export async function completeOrder(orderId) {
  return axios.post(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/complete_order/${orderId}`
  );
}

export async function markAsPaid (orderId) {
  let payload = {
    status: "processing"
  };
  await updateOrder(orderId, payload);
}

export async function createCustoemr(customer) {
  return axios.post(`${config.BASE_WC_URL}/customers`, customer);
}

export async function changeUserRole(userId, role) {
  return axios.post(
    `${config.BASE_URL}/wp-json/shookit-rest/v1/promote_to_b2b/${userId}`
  );
}

export async function updateCustomer(customerId, payload) {
  return axios.put(`${config.BASE_WC_URL}/customers/${customerId}`, payload);
}

export async function fetchOrder(orderId) {
  return await axios.get(`${config.BASE_WC_URL}/orders/${orderId}`);
}

export async function fetchOrderRefunds(orderId) {
  const data = (
    await axios.get(`${config.BASE_WC_URL}/orders/${orderId}/refunds`)
  ).data;
  return data.map(refund => flattenMetadata(refund));
}

export async function fetchOrderDetails(orderId, includeRefunds = false) {
  const data = (await axios.get(`${config.BASE_WC_URL}/orders/${orderId}`))
    .data;
  if (data && includeRefunds) {
    const refunds = _.flatten(
      (await fetchOrderRefunds(orderId)).map(refund =>
        refund.line_items.map(line => flattenMetadata(line))
      )
    );
    for (const item of data.line_items) {
      item.refunds = refunds.filter(
        refund => parseInt(refund._refunded_item_id) === item.id
      );
    }
  }
  return data;
}

export async function fetchCustomerDetail(customerId) {
  const result = await axios.get(
    `${config.BASE_WC_URL}/customers/${customerId}`
  );
  return result.data;
}

export async function fetchCustomerOrders(customerId, limit = 20, page = 1) {
  const result = await axios.get(
    `${config.BASE_WC_URL}/orders/?customer=${customerId}&per_page=${limit}&page=${page}`
  );
  const totalPages = result.headers["x-wp-totalpages"];
  const totalItems = result.headers["x-wp-total"];
  return { data: result.data, totalPages: totalPages, totalItems: totalItems };
}

export async function searchCustomers(searchValue) {
  let parameters = {};
  if (searchValue.length) {
    parameters.search = searchValue;
  }
  const result = await axios.get(
    `${
      config.BASE_URL
    }/wp-json/shookit-rest/v1/search_customer?${querystring.stringify(
      parameters
    )}`
  );
  return result.data;
}

export async function searchShookiters({
  searchValue = "",
  sites = [],
  page = 1
}) {
  let parameters = { page };
  if (searchValue.length) {
    parameters.search = searchValue;
  }
  if (sites.length) {
    parameters.sites = sites.join();
  }
  const result = await axios.get(
    `${
      config.BASE_URL
    }/wp-json/shookit-rest/v1/search_shookiter?${querystring.stringify(
      parameters
    )}`
  );
  return result.data;
}

export async function createNewOrder(customer, metadata, additional_data = {}) {
  // Copy Customer metadata to Order metadata with _ at the beginning
  customer.meta_data.forEach(metaItem => {
    metadata.push({ key: `_${metaItem["key"]}`, value: metaItem["value"] });
  });

  console.log(">>>Creating order for", customer);

  let orderDto = {
    status: "cancelled",
    currency: "ILS",
    customer_id: customer.id,
    billing: customer.billing,
    shipping: customer.shipping,
    meta_data: metadata,
    ...additional_data
  };

  if (customer.payment_method && customer.payment_method !== "") {
    orderDto.payment_method = customer.payment_method;
    orderDto.payment_method_title =
      PaymentTypes[customer.payment_method] || "Could not Detect";
  }

  return axios.post(`${config.BASE_WC_URL}/orders/`, orderDto);
}

export async function duplicateOrder(orderData) {
  return axios.post(`${config.BASE_WC_URL}/orders/`, orderData);
}

export async function fetchPickingOrders (site, date, page, onProgress) {
  onProgress(0);

  const baseUrl = `${config.BASE_URL}/?shookit-ajax=get_pickandpack_order&site=${site}&date=${date}&page=${page}`;

  const result = await axios.get(baseUrl);

  onProgress(1);
  return result.data;
}

export function openOrderCertificatePrint (orderId) {
  const baseUrl = `${config.BASE_ADMIN_URL}/wp-admin/admin-ajax.php?action=print_certificate&order_id=${orderId}`;
  window.open(baseUrl, "_blank");
}

export function openOrderSymmaryPrint (orderId) {
  const baseUrl = `${config.BASE_ADMIN_URL}/wp-admin/admin-ajax.php?action=print_summary&order_id=${orderId}`;
  window.open(baseUrl, "_blank");
}

export async function fetchNeedToPayOrders (customerId) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/get_need_to_pay_orders/${customerId}`;
  const result = await axios.get(baseUrl);

  return result.data;
}

export async function fetchPaymentFailedOrders (customerId) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/get_payment_failed_orders/${customerId}`;
  const result = await axios.get(baseUrl);

  return result.data;
}

export async function fetchNeedToInvoice (customerId) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/get_need_to_invoice_customer/${customerId}`;
  const result = await axios.get(baseUrl);

  return result.data;
}

export async function markAsInvoicedManually(orders, documentId, dueDateTimestamp) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/mark_as_invoiced_manually`;
  await axios.post(baseUrl, {
    orders: orders,
    document_id: documentId,
    due_date: dueDateTimestamp
  });
}

export async function markAsPaidManually(orders, documentId, chargeDate) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/mark_as_paid`;
  await axios.post(baseUrl, {
    orders: orders,
    document_id: documentId,
    charge_date: chargeDate
  });
}

export async function getOrdersTotal (orders) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/get_orders_total`;
  const result = await axios.post(baseUrl, {orders});

  return result.data;
}

/**
 * Search: Any criteria, single one, either site id or, date like: "d-m-yyyy", no `0` padding is allowed, e.g.: "7-10-2019"
 * onProgress: callback(progress) where progress value from 0 .. 1
 */
export async function fetchAllOrders(search, onProgress, status = "any") {
  onProgress(0);

  // const baseUrl = `${config.BASE_WC_URL}/orders?after=${start}&before=${end}`;
  const baseUrl = `${config.BASE_WC_URL}/orders?search=${search}&status=${status}`;

  const result = await axios(`${baseUrl}&per_page=50`); //_fields=id,meta_data
  const totalPages = result.headers["x-wp-totalpages"];
  var data = result.data;

  // Retrieve rest of the pages
  for (var i = 2; i <= totalPages; i++) {
    const res = await axios(`${baseUrl}&per_page=50&page=${i}`);
    data = data.concat(res.data);
    onProgress(i / totalPages);
  }

  onProgress(1);
  return data;
}

export async function searchProducts(keyword, impersonateAs = null) {
  if(/\d/.test(keyword)) {
    const skuUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/filtered_products_by_sku?search=${keyword}${impersonateAs ? `?&impersonate=${impersonateAs}` : ''}`;
    const sku_items = await axios.get(skuUrl);

    return sku_items.data;
  } else {
    const result = await axios.get(
      `${config.BASE_WC_URL}/products?search=${keyword}${impersonateAs ? `&impersonate=${impersonateAs}` : ''}&timestamp=${new Date().getTime()}&per_page=100`
    );
    
    return result.data;
  }
}

export async function getProduct(productID, impersonateAs = null) {
  const result = await axios.get(`${config.BASE_WC_URL}/products/${productID}${impersonateAs ? `?&impersonate=${impersonateAs}` : ''}`);

  return result.data;
}

export async function searchCoupons(keyword) {
  const result = await axios.get(
    `${config.BASE_WC_URL}/coupons?search=${keyword}`
  );

  return result.data;
}

export async function fetchProductDetails(specificItems) {
  // TODO: Pagination
  var requestUrl = `${config.BASE_WC_URL}/products?_fields=id,name,images,price,sku,catalog_visibility,stock_status,meta_data,tax_status&per_page=50`;
  if (specificItems) {
    requestUrl += "&include=" + specificItems.join();
  }

  const result = await axios.get(requestUrl);

  return _.keyBy(result.data, "id");
}

export async function addRefund(orderId, refund) {
  var requestUrl = `${config.BASE_WC_URL}/orders/${orderId}/refunds`;
  return axios.post(requestUrl, refund);
}

export const OrderStatus = {
  FAILED: "failed",
  PENDING: "pending",
  PROCCESSING: "processing",
  ON_HOLD: "on-hold",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  REFUNDED: "refunded"
};

export async function updateOrder(orderId, payload) {
  return await axios.put(`${config.BASE_WC_URL}/orders/${orderId}`, payload);
}

export async function updateOrderMeta(orderId, metaKey, metaValue) {
  var requestUrl = `${config.BASE_WC_URL}/orders/${orderId}`;

  const payload = { meta_data: [{ key: metaKey, value: metaValue }] };

  console.log(`>> Updating order meta: ${metaKey} ${metaValue}`);
  return await axios.put(requestUrl, payload);
}

/****************************************
  Line-Item Update API
 ****************************************/

export async function updateItemMeta(
  orderId,
  itemId,
  product_id,
  metaKey,
  metaValue
) {
  var requestUrl = `${config.BASE_WC_URL}/orders/${orderId}`;

  const payload = {
    line_items: [
      {
        id: itemId,
        product_id,
        meta_data: [{ key: metaKey, value: metaValue }]
      }
    ]
  };

  return await axios.put(requestUrl, payload);
}

export async function updateItem(
  orderId,
  itemId,
  product_id,
  newFields,
  newStatus
) {
  var requestUrl = `${config.BASE_WC_URL}/orders/${orderId}`;

  let payload = { line_items: [{ id: itemId, product_id, ...newFields }] };
  if (newStatus !== null && newStatus !== undefined) {
    payload["meta_data"] = [{ key: "SHOOKIT_STATUS", value: "" + newStatus }];
  }

  return await axios.put(requestUrl, payload);
}

// export async function reapplyCoupons({ orderId }) {
//   await axios.post(
//     `${config.BASE_URL}/wp-json/shookit-rest/v1/reapply_coupons/${orderId}`
//   );
// }

export async function sendPickupSMS({ orderId }) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/send_pickup_sms/${orderId}`;
  await axios.post(baseUrl);
}

export async function sendMissingItemsSms({ orderId }) {
  const baseUrl = `${config.BASE_URL}/wp-json/shookit-rest/v1/send_missing_items_sms/${orderId}`;
  await axios.post(baseUrl);
}

export async function reapplyCoupons({ orderId }) {
  const baseUrl = `${config.BASE_URL}/?shookit-ajax=reapply_coupons&id=${orderId}`;
  await axios.get(baseUrl);
}

export async function addCouponToOrder (orderId, couponCode) {
  const baseUrl = `${config.BASE_URL}/?shookit-ajax=add_coupon_to_order&id=${orderId}&coupon=${couponCode}`;
  await axios.get(baseUrl);
}

export async function updateSubscriptionStatus(listType, subscribeValue, userId) {
  const baseUrl = `${config.BASE_URL}/?shookit-ajax=klaviyo_set_subscription`
  let bodyFormData = new FormData();
  console.log(FormData);
  bodyFormData.append("is_subscribe", subscribeValue);
  bodyFormData.append("list_type", listType);
  bodyFormData.append("user_id",  userId);

  await axios.post(baseUrl, bodyFormData);
}


export async function removeCoupon(orderId, coupons) {
  const payload = { coupon_line: coupons };

  return await axios.put(`${config.BASE_WC_URL}/orders/${orderId}`, payload);
}

export async function removeItem(orderId, itemId, newStatus = null) {
  const requestUrl = `${config.BASE_WC_URL}/orders/${orderId}`;

  const payload = { line_items: [{ id: itemId, product_id: null }] };

  // Update status if requested
  if (newStatus !== null && newStatus !== undefined) {
    payload["meta_data"] = [{ key: "SHOOKIT_STATUS", value: "" + newStatus }];
  }

  return await axios.put(requestUrl, payload);
}

export async function addItemToOrder(orderId, itemId, quantity, units) {
  const requestUrl = `${config.BASE_WC_URL}/orders/${orderId}`;

  let payload = {};
  if (quantity && !units) {
    payload = {
      line_items: [{ id: itemId, quantity: quantity }]
    };
  } else if (quantity && units) {
    payload = {
      line_items: [
        {
          id: itemId,
          quantity: quantity,
          meta_data: [
            {
              key: "Quantity",
              value: quantity
            },
            {
              key: "Type",
              value: units
            }
          ]
        }
      ]
    };
  } else {
    return false;
  }

  return await axios.put(requestUrl, payload);
}
