import { StatusStepper } from "./StatusStepper";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import * as wcApi from "../../../services/wcApi";
import * as geoApi from "../../../services/geoApi";
import * as shookitUtils from "../../../utils/shookit";

import { makeStyles } from "@material-ui/styles";
import { getMetadata, flattenMetadata } from "../../../utils/woocommerce";
import {
  WC_ORDER_TS_START,
  WC_ORDER_TS_END,
  WC_ORDER_TS_DATE,
  VAT,
  SHOOKBOOK_URL,
  SHOOKBOOK_KEY
} from "../../../config";
import { indexOf } from "lodash";
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  LinearProgress,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CancelIcon from "@material-ui/icons/Cancel";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import PaymentIcon from "@material-ui/icons/Payment";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import PrintIcon from '@material-ui/icons/Print';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FastForwardIcon from '@material-ui/icons/FastForward';

import OrderSubtotalPane from "./OrderSubtotalPane";
import OrderProductsTable from "./OrderProductsTable";
import OrderDetailsPane from "./OrderDetailsPane";
import AddItemFormDialog from "./Dialogs/AddItemFormDialog";
import RefundFormDialog from "./RefundFormDialog";
import AddCouponDialog from "./AddCouponDialog";
import * as OrderHeader from "./OrderHeader";
import CancelDialog from "./Dialogs/CancelDialog";
import DuplicateOrderDialog from "./Dialogs/DuplicateOrderDialog";
import Axios from "axios";

const useStyles = makeStyles(theme => ({
  tablePaper: {
    width: "100%",
    overflowX: "auto",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  rightbarTitle: {
    fontWeight: "bold"
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  afterTable: {
    justifyContent: "space-between"
  },
  actionButton: {
    marginRight: theme.spacing(1)
  },
  takeOrderLiveButton: {
    marginRight: theme.spacing(1),
    backgroundColor: 'rgba(227, 118, 86)'
  },
  loadingBar: {
    position: "relative",
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
    textAlign: "center",
    left: "50%",
    top: "50%"
  },
  paymentButton: {
    marginBottom: theme.spacing(1)
  },
  noItem: {
    color: "red"
  }
}));

const CustomerOrderPage = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const orderId = match.params.orderId;

  const [notFound, setNotFound] = useState(false);
  const [orderDetails, _setOrderDetails] = useState(null);

  const [products, setProducts] = useState(null);
  const [shookitSiteInfo, setShookitSiteInfo] = useState(null);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
  const [isRefundDialogOpen, setisRefundDialogOpen] = useState(false);
  const [isCouponDialogOpen, setIsCouponDialogOpen] = useState(false);
  const [canTakeLive, setcanTakeLive] = useState(true);
  const [isCancellingOrder, setIsCancellingOrder] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isResendingCertificate, setIsResendingCertificate] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [isDuplicateOrderDialogOpen, setIsDuplicateOrderDialogOpen] = useState(
    false
  );
  const [hiddenItems, setHiddenItems] = useState([]);
  const [outOfStockItems, setOutOfStockItems] = useState([]);

  function setOrderDetails(order) {
    _setOrderDetails(flattenMetadata(order));
    console.log('flattening meta data');
    flattenMetadata(order)
  }

  const deleteMetaKey = (data, key_name) => {
    let metadata = data.meta_data;
    for (let i = 0; i < metadata.length; i++) {
      if (metadata[i].key === key_name) {
        metadata.splice(i, 1);
        break;
      }
    }
  };

  const updateOrderDetails = async () => {
    try {
      const data = await wcApi.fetchOrderDetails(orderId, true);
      if (data) {
        let orderMetaData = getMetadata(data.meta_data);

        if (orderMetaData.hidden_items) {
          setHiddenItems(orderMetaData.hidden_items.split(","));
        }
        if (orderMetaData.outofstock_items) {
          setOutOfStockItems(orderMetaData.outofstock_items.split(","));
        }
        deleteMetaKey(data, "hidden_items");
        deleteMetaKey(data, "outofstock_items");

        setOrderDetails(data);
      } else {
        setNotFound(true);
      }
    } catch {
      setNotFound(true);
    }
  };

  // Fetch order details
  useEffect(() => {
    if (isRefundDialogOpen) {
      return;
    }
    (async () => {
      await updateOrderDetails(orderId);
    })();
  }, [orderId, isRefundDialogOpen]);

  const orderMetadata = orderDetails
    ? getMetadata(orderDetails.meta_data)
    : null;
  const isLoading =
    orderDetails === null || shookitSiteInfo === null || products === null;

  // Decide is edit is allowed
  let isEditAllowed = false;
  let isPriceEditAllowed = false;
  if (orderDetails) {
    const status = shookitUtils.getOrderStatus(orderDetails);
    isEditAllowed = !(
      status === "ON_ITS_WAY" ||
      status === "ARRIVED" ||
      status === "cancelled" ||
      status === "PAID"
    );
    isPriceEditAllowed = !(status === "cancelled" || status === "PAID");
  }

  // Fetch products details
  useEffect(() => {
    if (!orderDetails) {
      return;
    }
    const productIds = orderDetails["line_items"].map(item => item.product_id);

    wcApi
      .fetchProductDetails(productIds)
      .then(result => {
        setProducts(result);
      })
      .catch(err => {
        console.log(err);
        setNotFound(true);
      });
  }, [orderDetails]);

  useEffect(() => {}, [orderDetails]);

  // Fetch Shookit site info
  useEffect(() => {
    if (orderMetadata && isLoading) {
      const shookitSiteId = orderMetadata["Shookit Site"];
      geoApi
        .fetchShookitSiteInformation(shookitSiteId)
        .then(res => {
          if (res) {
            setShookitSiteInfo(res);
          } else {
            setNotFound(true);
          }
        })
        .catch(err => {
          console.log(err);
          setShookitSiteInfo({});
        });
    }
  }, [orderDetails]);

  const onItemEdited = async (lineItem, newValues) => {
    const product = products[lineItem.product_id];
    let newStatus = null;
    const previousStatus = shookitUtils.getOrderStatus(orderDetails);
    if (product.tax_status == 'taxable') {
      newValues.price = newValues.price / VAT;
      newValues.productPrice = (newValues.price / VAT).toString();
    }
    if (["ON_ITS_WAY", "ARRIVED"].includes(previousStatus)) {
      try {
        const result = await shookitUtils.updateLineItemAfterPacking({
          orderId,
          item: lineItem,
          product,
          newPrice: newValues.price,
          newProductPrice: newValues.productPrice
        });
        if (result.status === 200) {
          await updateOrderDetails();
          await wcApi.reapplyCoupons({orderId});
        }
      } catch (err) {
        console.log(err);
      } finally {
        return setUpdateInProgress(false);
      }
    } else if (previousStatus !== "PENDING") {
      newStatus = "FULFILLING";
    }
    setUpdateInProgress(true);
    try {
      const result = await shookitUtils.updateLineItemBeforePacking(
        orderId,
        lineItem,
        product,
        parseFloat(newValues.quantity),
        newValues.unit,
        newValues.price,
        newValues.pricingMethod,
        newStatus,
        newValues.productPrice
      );
      if (result.status === 200) {
        await updateOrderDetails();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUpdateInProgress(false);
    }
  };

  const onOpenAddItemFormClicked = e => {
    if (!isAddItemDialogOpen) {
      setIsAddItemDialogOpen(true);
    }
  };

  async function onCloseAddItemFormClicked(order) {
    if (isAddItemDialogOpen) {
      setIsAddItemDialogOpen(false);
    }

    if (order) {
      setUpdateInProgress(true);
      await updateOrderDetails();
      setUpdateInProgress(false);
    }
  }

  const onCloseRefundDialogClicked = e => {
    if (e.order) {
      updateOrderDetails();
    }
    if (isRefundDialogOpen) {
      setisRefundDialogOpen(false);
    }
  };

  const onRefundClicked = e => {
    setisRefundDialogOpen(true);
  };

  const onCoupunAddClicked = e => {
    setIsCouponDialogOpen(true);
  };

  const onCloseCopunDialogClicked = e => {
    if (isCouponDialogOpen) {
      setIsCouponDialogOpen(false);
    }
  };

  const onDuplicateOrderClicked = e => {
    setIsDuplicateOrderDialogOpen(true);
  };

  const onCloseDuplicateOrderClicked = e => {
    if (isDuplicateOrderDialogOpen) {
      setIsDuplicateOrderDialogOpen(false);
    }
  };

  const onCloseCancelClicked = e => {
    setShowCancelDialog(false);
  };

  const onCancelMissingItemsClicked = async e => {
    setHiddenItems([]);
    setOutOfStockItems([]);
    await wcApi.updateOrderMeta(orderDetails.id, "hidden_items", "");
    await wcApi.updateOrderMeta(orderDetails.id, "outofstock_items", "");
  };

  const onItemDeleted = async rowKey => {
    const lineItem = orderDetails.line_items[rowKey];

    if (!lineItem) {
      throw new Error("Delete was called on item which doesn't exist");
    }

    // Check status
    let newStatus = null;
    if (shookitUtils.getOrderStatus(orderDetails) !== "PENDING") {
      newStatus = "FULFILLING";
    }
    setUpdateInProgress(true);
    try {
      const res = await wcApi.removeItem(orderId, lineItem.id, newStatus);
      if (res.status === 200) {
        await wcApi.reapplyCoupons({orderId});
        await updateOrderDetails();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUpdateInProgress(false);
    }
  };

  async function processPayment() {
    setIsProcessingPayment(true);
    let payload = {
      status: "processing"
    };
    await wcApi.updateOrder(orderId, payload);
    await updateOrderDetails();
    setIsProcessingPayment(false);
  }

  async function resendOrderCertificate() {
    setIsResendingCertificate(true);
    await wcApi.sendInvoice(orderId);
    setIsResendingCertificate(false);
  }

  const removeCoupon = async coupon_line => {
    const payload = {
      coupon_lines: orderDetails.coupon_lines
        .filter(cl => cl.id !== coupon_line.id)
        .map(line => ({ code: line.code }))
    };

    setUpdateInProgress(true);
    await wcApi.updateOrder(orderDetails.id, payload);
    await updateOrderDetails();
    setUpdateInProgress(false);
  };

  const cancelOrder = async event => {
    let reason = "";
    if (typeof event === "string") {
      reason = event;
    } else {
      if (event && event.target && event.target.value) {
        reason = event.target.value;
      }
    }

    if (!reason.length) return;

    if (!reason) {
      setShowCancelDialog(false);
      return;
    }

    setShowCancelDialog(false);
    setIsCancellingOrder(true);
    let payload = {
      status: "cancelled",
      meta_data: [
        { key: "_cancel_reason", value: reason },
        { key: "SHOOKIT_STATUS", value: null },
        {
          key: "_draft_order",
          value: null
        }
      ]
    };

    await wcApi.updateOrder(orderId, payload);
    await updateOrderDetails();
    setIsCancellingOrder(false);
  };

  const takeOrderLive = async () => {
    setcanTakeLive(false);

    let timeslotStart = orderDetails["Shookit Timeslot Start"];
    let shookbookTimeslot;
  
    if (timeslotStart == '01:00') {
      shookbookTimeslot = 2;
    } else if (timeslotStart == '07:00' || timeslotStart == '08:00') {
      shookbookTimeslot = 0;
    } else {
      shookbookTimeslot = 1; 
    }

    let lineItems = []
    orderDetails.line_items.forEach(lineItem => {
      lineItems.push({
        "Name": lineItem.name,
        "Price": Number(lineItem.total) + Number(lineItem.total_tax) ,
        "Qty": lineItem.quantity,
        "SKU": lineItem.id
      })
    }); 
  
    let payload = {
      status: "on-hold",
      meta_data: [
        { key: "SHOOKIT_STATUS", value: shookitUtils.statusValues.PENDING },
        { key: "_cancel_reason", value: null }
      ]
    };

    await wcApi.updateOrder(orderId, payload);
    await updateOrderDetails();
    setcanTakeLive(true);
  };

  const printCertificate = () => {
    wcApi.openOrderCertificatePrint(orderId);
  }

  const printSummary = () => {
    wcApi.openOrderSymmaryPrint(orderId);
  }

  if (notFound) {
    return (
      <Paper
        className={classes.paper}
      >{`Order ID: ${orderId} was not found.`}</Paper>
    );
  }

  const allowCancelOrder =
    !isLoading &&
    ["PENDING", "FULFILLING", "READY", "ON_ITS_WAY"].includes(
      shookitUtils.getOrderStatus(orderDetails)
    );

  const allowRefundButton =
    !isLoading &&
    ["COMPLETED", "ARRIVED", "PAID"].includes(
      shookitUtils.getOrderStatus(orderDetails)
    );

  const allowCouponEditing =
    !isLoading &&
    orderDetails.status === "on-hold" &&
    orderDetails.refunds.length === 0;

  return (
    <Grid container spacing={3} className={classes.grid}>
      {isLoading ? (
        <CircularProgress className={classes.loadingBar} />
      ) : (
        <>
          <AddItemFormDialog
            open={isAddItemDialogOpen}
            onClose={onCloseAddItemFormClicked}
            orderId={orderDetails.id}
            orderDetails={orderDetails}
          />
          <RefundFormDialog
            open={isRefundDialogOpen}
            onClose={onCloseRefundDialogClicked}
            order={orderDetails}
            refundPoints={parseFloat(orderDetails.points_refund) ? parseFloat(orderDetails.points_refund) : 0}
            refundCC={orderDetails.refunds.reduce(
              (total, refundLine) =>
                parseFloat(refundLine.total) + total,
              0
            )}
          />
          <AddCouponDialog
            open={isCouponDialogOpen}
            onClose={onCloseCopunDialogClicked}
            order={orderDetails}
            onOrderUpdated={updateOrderDetails}
          />
          <DuplicateOrderDialog
            originalOrder={orderDetails}
            open={isDuplicateOrderDialogOpen}
            onClose={onCloseDuplicateOrderClicked}
          />
          <Dialog
            open={hiddenItems.length || outOfStockItems.length}
            onClose={onCancelMissingItemsClicked}
            maxWidth="lg"
          >
            <DialogTitle>
              Order Duplicated! <br /> But there are some missing items:
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    Item
                  </Grid>
                  <Grid item xs={20}>
                    Reason
                  </Grid>
                </Grid>
                {hiddenItems.map(itemName => (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {itemName}
                    </Grid>
                    <Grid item xs={20} className={classes.noItem}>
                      Not salable
                    </Grid>
                  </Grid>
                ))}
                {outOfStockItems.map(itemName => (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {itemName}
                    </Grid>
                    <Grid item xs={20} className={classes.noItem}>
                      Out of Stock
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          </Dialog>
          <Grid item xs={12}>
            <Header orderDetails={orderDetails} orderId={orderId} />
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12}>
              <CancelDialog
                title="Cancel Order"
                message="Are you sure you want to cancel this order?"
                isOpen={showCancelDialog}
                cancelOrder={cancelOrder}
                onClose={onCloseCancelClicked}
              />
              <div>
                <Paper className={classes.tablePaper}>
                  <OrderProductsTable
                    totalPointsRefund={orderDetails.points_refund ? -parseFloat(orderDetails.points_refund) : 0}
                    order={orderDetails}
                    products={products}
                    onItemEdited={onItemEdited}
                    onItemDeleted={onItemDeleted}
                    updateInProgress={updateInProgress}
                    isEditAllowed={isEditAllowed}
                    isPriceEditAllowed={isPriceEditAllowed}
                    total={orderDetails.total}
                  />
                </Paper>
              </div>
              {orderDetails.coupon_lines &&
                orderDetails.coupon_lines.length > 0 && (
                  <div>
                    <Paper className={classes.tablePaper}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography color="primary">Coupons: </Typography>
                        {orderDetails.coupon_lines.map(cline => {
                          return (
                            <Chip
                              key={cline.id}
                              label={cline.code.replace(
                                "wccs_cart_discount_",
                                ""
                              )}
                              onDelete={
                                allowCouponEditing
                                  ? () => removeCoupon(cline)
                                  : undefined
                              }
                              className={classes.chip}
                            />
                          );
                        })}
                      </div>
                    </Paper>
                  </div>
                )}
            </Grid>
            <Grid container className={classes.afterTable}>
              <Grid item xs={8}>
                <Button
                  className={classes.actionButton}
                  variant="contained"
                  color="primary"
                  disabled={!isEditAllowed}
                  onClick={onOpenAddItemFormClicked}
                >
                  <AddIcon />
                  Add Item
                </Button>
                <Button
                  className={classes.actionButton}
                  disabled={!allowCouponEditing}
                  variant="contained"
                  color="default"
                  onClick={onCoupunAddClicked}
                >
                  <AttachMoneyIcon />
                  Add Coupon
                </Button>
                <Button
                  className={classes.actionButton}
                  disabled={!allowRefundButton}
                  variant="contained"
                  color="default"
                  onClick={onRefundClicked}
                >
                  <IndeterminateCheckBoxIcon />
                  Refund
                </Button>
                {orderDetails.status.toLowerCase() !== "cancelled" ? (
                  <></>
                ) : (
                  <Button
                    disabled={!canTakeLive}
                    className={classes.takeOrderLiveButton}
                    variant="contained"
                    color="secondary"
                    onClick={takeOrderLive}
                  >
                    <FlightTakeoffIcon />
                    Take Order Live
                  </Button>
                )}

                <Button
                  disabled={isCancellingOrder || !allowCancelOrder}
                  className={classes.actionButton}
                  variant="contained"
                  onClick={() => {
                    setShowCancelDialog(true);
                  }}
                >
                  <CancelIcon />
                  Cancel Order
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <OrderSubtotalPane
                    totalShipping={parseFloat(orderDetails.shipping_total)}
                    totalTax={parseFloat(orderDetails.total_tax)}
                    totalDiscount={parseFloat(orderDetails.discount_total)}
                    totalPrice={parseFloat(orderDetails.total)}
                    totalPointsUsed={parseFloat(orderDetails.points_paid)}
                    totalMultipassUsed={parseFloat(orderDetails.multipass_points_paid)}
                    totalPointsRefund={orderDetails.points_refund ? -parseFloat(orderDetails.points_refund) : 0}
                    totalRefund={orderDetails.refunds.reduce(
                      (total, refundLine) =>
                        parseFloat(refundLine.total) + total,
                      0
                    )}
                    totalTaxRefund={orderDetails.line_items
                      .map(item =>
                        item.refunds.reduce(
                          (lineTotalRefund, refundLine) =>
                            parseFloat(refundLine.total_tax) + lineTotalRefund,
                          0
                        )
                      )
                      .reduce(
                        (totalTaxRefund, lineTotalTax) =>
                          totalTaxRefund + lineTotalTax,
                        0
                      )}
                    currencySymbol={orderDetails.currency_symbol}
                    isForceFreeShipping={orderDetails.force_free_shipping === "1"}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Button
              className={classes.paymentButton}
              variant="contained"
              // color="primary"
              onClick={printSummary}
              fullWidth
            >
              <DoneAllIcon />
              Print Order Summary
            </Button>

            <Button
              className={classes.paymentButton}
              variant="contained"
              // color="primary"
              disabled={
                shookitUtils.getOrderStatus(orderDetails) !== "REFUNDED" && 
                shookitUtils.getOrderStatus(orderDetails) !== "ARRIVED" && 
                shookitUtils.getOrderStatus(orderDetails) !== "ON_ITS_WAY" && 
                shookitUtils.getOrderStatus(orderDetails) !== "PAID" && 
                shookitUtils.getOrderStatus(orderDetails) !== "READY"
              }
              onClick={printCertificate}
              fullWidth
            >
              <PrintIcon />
              Print Order Certificate
            </Button>
            {isProcessingPayment && <LinearProgress />}
            <Button
              className={classes.paymentButton}
              variant="contained"
              disabled={
                (shookitUtils.getOrderStatus(orderDetails) !== "ARRIVED" &&
                  shookitUtils.getOrderStatus(orderDetails) !== "PAID") ||
                isResendingCertificate
              }
              onClick={resendOrderCertificate}
              fullWidth
            >
              <FastForwardIcon />
              Re-Send order certificate
            </Button>
            <Button
              className={classes.paymentButton}
              variant="contained"
              // color="primary"
              disabled={
                shookitUtils.getOrderStatus(orderDetails) !== "ARRIVED" ||
                isProcessingPayment
              }
              onClick={processPayment}
              fullWidth
            >
              <PaymentIcon />
              Mark as Paid
            </Button>
            {isResendingCertificate && <LinearProgress />}
            <OrderDetailsPane
              order={orderDetails}
              onOrderUpdated={updateOrderDetails}
            />
            <Button
              className={classes.paymentButton}
              variant="contained"
              color="primary"
              onClick={onDuplicateOrderClicked}
              fullWidth
            >
              <AddCircleOutline />
              Duplicate order
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

// Returns Company for company or Customer Name for b2c
export function getCustomerName(order) {
  return order.shipping.company
    ? order.shipping.company
    : `${order.shipping.first_name} ${order.shipping.last_name}`;
}

export default CustomerOrderPage;

function Header({ orderDetails, orderId }) {
  return (
    <OrderHeader.Container>
      <OrderHeader.Item>
        <OrderHeader.Header>Customer</OrderHeader.Header>
        <OrderHeader.Content>
          <Link to={`/customers/view/${orderDetails.customer_id}`}>
            {getCustomerName(orderDetails)}
          </Link>
        </OrderHeader.Content>
      </OrderHeader.Item>
      <OrderHeader.Item>
        <OrderHeader.Header>Order ID</OrderHeader.Header>
        <OrderHeader.Content>{orderId}</OrderHeader.Content>
      </OrderHeader.Item>
      <OrderHeader.Item>
        <OrderHeader.Header>Status</OrderHeader.Header>
        <OrderHeader.Content>
          {shookitUtils.formatShookitStatus(
            shookitUtils.getOrderStatus(orderDetails)
          )}
        </OrderHeader.Content>
      </OrderHeader.Item>
      <OrderHeader.Spacer />
      <OrderHeader.Item style={{ margin: "0" }}>
        <StatusStepper
          index={indexOf(
            Object.values(shookitUtils.statusValues),
            shookitUtils.getOrderStatus(orderDetails)
          )}
          steps={Object.values(shookitUtils.statusValues).map(status =>
            shookitUtils.formatShookitStatus(status)
          )}
        />
      </OrderHeader.Item>
      {/* <OrderHeader.Spacer /> */}
      {/* 
      <OrderHeader.Item>
        <OrderHeader.Header>SHOOKITER</OrderHeader.Header>
        <OrderHeader.Content>{shookitUtils.isSeen(orderDetails) ? "Seen" : "Not Seen"}</OrderHeader.Content>
      </OrderHeader.Item> */}

      <OrderHeader.Item>
        <OrderHeader.Header>
          {shookitUtils.formatAsDay(orderDetails[WC_ORDER_TS_DATE])}
        </OrderHeader.Header>
        <OrderHeader.Content>
          {`${orderDetails[WC_ORDER_TS_START]} - ${orderDetails[WC_ORDER_TS_END]}`}
        </OrderHeader.Content>
      </OrderHeader.Item>
    </OrderHeader.Container>
  );
}
