import React from "react";
import { useHistory } from "react-router-dom";
import OrderList from "./OrderList";

export function SearchResultPage({ siteId, orders }) {
  const history = useHistory();

  return (
    <React.Fragment>
      <OrderList
        orders={orders}
        editMode={false}
        onPress={orderId => {
          history.push(`/pickandpack/${siteId}/orders/${orderId}`);
        }}
      />
    </React.Fragment>
  );
}
