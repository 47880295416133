import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import LongPress from "react-long";
import { InputBase, Grid } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles(theme => ({
  box: {
    border: "1px black solid",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    maxWidth: "180px"
  },
  input: {
    "& input": {
      textAlign: "center",
      padding: 2
    }
  },
  unitDescription: {
    textTransform: "uppercase",
    fontSize: "0.8em"
  }
}));

export default function NumberInput({
  description,
  value,
  onChange,
  isDecimal,
  disabled,
  onLongPress
}) {
  const classes = useStyles();

  const reduceNumber = () => {
    // Set initial value
    let newValue = value ? value : "0";

    // Parse According to type
    newValue = isDecimal ? parseFloat(newValue) : parseInt(newValue);

    if (newValue > 0) {
      newValue -= isDecimal ? 0.5 : 1;
    }

    if (isDecimal) {
      onChange(newValue.toFixed(2));
    } else {
      onChange(newValue);
    }
  };

  const addNumber = () => {
    // Set initial value
    let newValue = value ? value : "0";

    // Parse According to type
    newValue = isDecimal ? parseFloat(newValue) : parseInt(newValue);
    newValue += isDecimal ? 0.5 : 1;

    if (isDecimal) {
      onChange(newValue.toFixed(2));
    } else {
      onChange(newValue);
    }
  };

  const onChangeInput = event => {
    let newValue = event.target.value;

    const re = /^(\d*\.?\d*)$/;

    if (newValue !== "") {
      if (!re.test(newValue)) {
        return;
      }
    }

    if (description === "KG") {
      if (value === "0." && newValue === "0") {
        newValue = "";
      } else if (newValue === "0" || newValue === ".") {
        newValue = "0.";
      }
    }
    onChange(newValue);
  };

  return (
    <Grid container className={classes.box}>
      {description !== "KG" &&  <Grid item>
        <IconButton onClick={reduceNumber}>
          <RemoveIcon fontSize="small" />
        </IconButton>
      </Grid>}
      <LongPress time={800}
        onLongPress={onLongPress}
      >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <InputBase
                className={classes.input}
                autoFocus={!value}
                value={value}
                onChange={onChangeInput}
                disabled={disabled}
                position="initial"
                placeholder={description === "KG" ? "0.000": value}
                inputProps={
                  !isDecimal
                    ? {
                        pattern: "[0-9]*",
                        inputMode: "numeric"
                      }
                    :
                    {
                      inputMode: "decimal"
                    }
                }
              />
            </Grid>
          <Grid item className={classes.unitDescription}>
            {description}
          </Grid>
        </Grid>
      </LongPress>
      {description !== "KG" && <IconButton onClick={addNumber}>
        <AddIcon fontSize="small" />
      </IconButton>}
    </Grid>
  );
}
