import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import * as wcApi from "../../../services/wcApi";

import BusinessTypes from "../../../enums/BusinessTypes";
import FormSelect from "../../UI/FormSelect";
import { LinearProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  }
}));

export default function BusinessSubSegmentField({ customer, onCustomerUpdated }) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);

  async function handleChange(selectedValue) {

    const payload = {
      meta_data: [
        {
          key: "b2b_sub_segment",
          value: selectedValue
        }
      ]
    };
    const result = await wcApi.updateCustomer(customer.id, payload);
    onCustomerUpdated(result.data);
    setEditMode(false);
  }

  if (editMode) {
    return (
      <div>
        <b>Sub Segment:</b>{" "}
        <FormSelect
          small
          values={Object.values(BusinessTypes)}
          initialValue={customer.b2b_sub_segment}
          onChange={handleChange}
          fullWidth={false}
        />
      </div>
    );
  } else {
    return (
      <div onClick={() => setEditMode(true) } className={classes.clickable}>
        <div>
          <strong>Sub Segment:</strong> {customer.b2b_sub_segment}
        </div>
      </div>
    );
  }
}
