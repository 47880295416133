import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {
  Typography,
  CardContent,
  Divider,
  CardActions,
  CardHeader,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  LinearProgress
} from "@material-ui/core";

import UpdateOrderDialog from "../CustomerOrder/Dialogs/UpdateAddressDialog";
import NewOrderDialog from "./NewOrderDialog";
import FloatingEditButtons from "../../UI/FloatingEditButtons";
import * as wcApi from "../../../services/wcApi";
import AddressTypes from "../../../enums/AddressTypes";
import PaymentTypes from "../../../enums/PaymentTypes";
import TempForceSiteEdit from "./TempForceSiteEdit";
import TempPaymentMethodEdit from "./TempPaymentMethodEdit";
import BusinessSubSegmentField from "./TempBusinessSegmentEdit";
import { type } from "os";
import CustomerIdField from "./CompanyIdEdit";
import BillingEmailField from "./BillingEmailField";
import CustomerDetailsField from "./CustomerDetailsField";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  bold: {
    fontWeight: "bold"
  },
  addressTab: {
    minWidth: "50%"
  },
  addressPanel: {
    paddingTop: theme.spacing(1)
  },
  typography: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  pointsField: {
    padding: theme.spacing(1.5)
  },
  pointsLabel: {
    color: theme.palette.primary.main,
    fontSize: "16px"
  }
}));

const SHIPPING_ADDRESS_TAB_INDEX = 0;
const BILLING_ADDRESS_TAB_INDEX = 1;

const getMeta = (customer, key) => {
  const meta = customer.meta_data.find(md => md.key === key);
  if (meta) return meta.value;
};

const getAddress = (customer, addressType) => {
  // debugger;
  return {
    ...customer[addressType],
    type: getMeta(customer, `${addressType}_address_type`),
    entrance: getMeta(customer, `${addressType}_entrance`),
    floor_number: getMeta(customer, `${addressType}_floor_number`),
    apt_number: getMeta(customer, `${addressType}_apt_number`),
    notes: getMeta(customer, `${addressType}_notes`),
    address: getMeta(customer, `${addressType}_autofill_checkout`)
  };
};

function getBuildingCode (customer) {
  return getMeta(customer, `building_code`);
}

function getAddressStreet (customer, type) {
  if (type == 'shipping') {
    return {address_street :  getMeta(customer, `shipping_address_street`),
      address_street_number :  getMeta(customer, `shipping_address_street_number`)
    };
  } else {
    return {address_street :  getMeta(customer, `billing-street-input`),
      address_street_number :  getMeta(customer, `billing-street-number-input`)
    };
  }
  
}

const CustomerDetailsPane = props => {
  const styles = useStyles();
  const customer = props.customer;
  const customerPhone = customer.billing.phone || "N\\A";
  const addToast = props.addToast || (() => {});

  const [addressRef, setAddressRef] = useState(
    getAddress(customer, "shipping")
  );

  const [addressStreetRef, setaddressStreetRef] = useState(
    getAddressStreet(customer, "shipping")
  )
  const [addressNotes, setAddressNotes] = useState(customer.shipping_notes);
  const [addressIndex, setAddressIndex] = useState(0);

  const [latestOrder, setLatestOrder] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [buildingCode , setbuildingCode] = useState(getBuildingCode(customer));
  const [isLoading, setisLoading] = useState(true);
  const [isAddressUpdating, setIsAddressUpdating] = useState(false);
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const [isNewOrderDialogOpen, setIsNewOrderDialogOpen] = useState(false);

  const isCompany = customer.shipping.company ? true : false;

  useEffect(() => {
    if (!isLoading) return;
    wcApi
      .fetchCustomerOrders(customer.id, 1)
      .then(result => {
        if (result) {
          setLatestOrder(result.data[0]);
          setOrderCount(result.totalItems);
        }
        setisLoading(false);
      })
      .catch(err => {
        console.error(err);
        setisLoading(false);
      });
  }, [customer.id, isLoading]);

  const [tabValue, setTabValue] = useState(SHIPPING_ADDRESS_TAB_INDEX);

  const handleAddressTabChange = (e, index) => {
    setTabValue(index);
    switch (index) {
      case SHIPPING_ADDRESS_TAB_INDEX:
        setAddressRef(getAddress(customer, "shipping"));
        setaddressStreetRef(getAddressStreet(customer, "shipping"));
        setAddressNotes(customer.shipping_notes);
        break;
      case BILLING_ADDRESS_TAB_INDEX:
        setAddressRef(getAddress(customer, "billing"));
        setaddressStreetRef(getAddressStreet(customer, "billing"));
        setAddressNotes(customer.billing_notes);
        break;
      default:
        throw new Error("Tab index not found");
    }
  };

  const paymentMethod = () => {
    let payment_method = customer.meta_data.find(
      md => md.key === "payment_method"
    );
    if (payment_method && payment_method.value) {
      return PaymentTypes[payment_method.value]
        ? PaymentTypes[payment_method.value]
        : "N/A";
    }
    return latestOrder && latestOrder.payment_method_title
      ? latestOrder.payment_method_title
      : "N\\A";
  };

  const sendResetPassword = async () => {
    try {
      await wcApi.sendForgetPassword(customer.id);
      addToast({
        variant: "success",
        message: "Password Reset was sent succesfuly"
      });
    } catch (err) {
      addToast({
        variant: "error",
        message: `Failed to reset user password due to an error: ${err.response.data.code}`
      });
    }
  };

  const onNewOrderDialogClose = () => {
    setIsNewOrderDialogOpen(false);
  };

  const openNewOrderDialog = () => {
    setIsNewOrderDialogOpen(true);
  };

  const role = customer.shipping.company ? "b2b" : "b2c";
  const [optinUpdating, setOptinUpdating] = useState(false);
  const [blacklsitUpdateing, setBlacklsitUpdateing] = useState(false);
  const [updateingPreferences, setUpdateingPreferences] = useState(false)

  async function handleOptInChange(e) {
    const checked = !!e.target.checked;
    setOptinUpdating(true);
    const result = await wcApi.updateCustomer(customer.id, {
      meta_data: [
        {
          key: "opt_in",
          value: checked ? "1" : "0"
        }
      ]
    });
    wcApi.updateSubscriptionStatus('email', checked ? "1" : "0", customer.id);
    props.onCustomerUpdated(result.data);
    setOptinUpdating(false);
  }

  async function handleOptInSmsChange(e) {
    const checked = !!e.target.checked;
    const result = await wcApi.updateCustomer(customer.id, {
      meta_data: [
        {
          key: "opt_in_sms",
          value: checked ? "1" : "0"
        }
      ]
    });
    wcApi.updateSubscriptionStatus('sms', checked ? "1" : "0", customer.id);
    props.onCustomerUpdated(result.data);
  }


  async function handleBlacklistInvoicingChange(e) {
    const checked = !!e.target.checked;
    setBlacklsitUpdateing(true);
    const result = await wcApi.updateCustomer(customer.id, {
      meta_data: [
        {
          key: "blacklist_invocing",
          value: checked ? "1" : "0"
        }
      ]
    });
    props.onCustomerUpdated(result.data);
    setBlacklsitUpdateing(false);
  };

  const handleUpdatePreferencesChange = name => event => {
    const checked = !!event.target.checked;
    setUpdateingPreferences(true);
    wcApi.updateCustomer(customer.id, {
      meta_data: [
        {
          key: name,
          value: checked ? "1" : "0"
        }
      ]
    }).then(result => {
      props.onCustomerUpdated(result.data);
      setUpdateingPreferences(false)
    });    
  };

  async function handleAdressDialogClosed(address) {
    setShowAddressDialog(false);
    const type = tabValue === 0 ? "shipping" : "billing";
    let metadata = {};
    let payload = {};
    console.log(address);

    // Cancel Clicked
    if (address === null) {
      return;
    }

    payload[type] = {};

    // Address was not changed (but other values might !)
    if ("placeId" in address) {
      metadata.placeId = address.placeId;
      metadata.longlat = `${address.latLng.lng},${address.latLng.lat}`;
      metadata.long = address.latLng.lng;
      metadata.lat = address.latLng.lat;

      // Fill "WC" Address fields
      payload[type].address_1 = address.address1;
      payload[type].city = address.city;
    }

    // Fill Other Fields
    metadata.autofill_checkout = address.address;
    metadata.address_type = address.addressType
    metadata.entrance = address.entrance;
    metadata.apt_number = address.apt;
    metadata.floor_number = address.floor;
    metadata.notes = address.notes;
    metadata.country = address.country;

    // Rebuild metadata
    let wc_metadata = [{
      key: 'different_billing',
      value: 'true'
    }];
    Object.keys(metadata).forEach(key => {
      wc_metadata.push({
        key: `${type}_${key}`,
        value: metadata[key]
      });
    });

    wc_metadata.push({
      key : 'building_code',
      value : address.buildingCode
    })
  if (type == 'shipping') {
    wc_metadata.push({
      key : 'shipping_address_street',
      value : address.address_street
    })

    wc_metadata.push({
      key : 'shipping_address_street_number',
      value : address.address_street_number
    })
  } else {
    wc_metadata.push({
      key : 'billing-street-input',
      value : address.address_street
    })

    wc_metadata.push({
      key : 'billing-street-number-input',
      value : address.address_street_number
    })
  }


    payload["meta_data"] = wc_metadata;
    setAddressRef({});
    setIsAddressUpdating(true);
    const newCustomerDetails = await wcApi.updateCustomer(customer.id, payload);
    props.onCustomerUpdated(newCustomerDetails.data);
    setIsAddressUpdating(false);
    handleAddressTabChange(null, tabValue);
    window.location.reload()
  }

  return (
    <>
      <UpdateOrderDialog
        open={showAddressDialog}
        initialValues={{
          address: addressRef.address,
          address_street_number: addressStreetRef.address_street_number,
          address_street: addressStreetRef.address_street,
          floor: addressRef.floor_number,
          notes: addressRef.notes,
          apt: addressRef.apt_number,
          entrance: addressRef.entrance,
          type: addressRef.type,
          country: addressRef.country,
          buildingCode: buildingCode,
          // selectedAddress: addressRef.autofill_checkout,
          // isSu
        }}
        onClose={handleAdressDialogClosed}
        role={role}
        deliverableAddress={tabValue === 0}
      />

      <NewOrderDialog
        open={isNewOrderDialogOpen}
        onClose={onNewOrderDialogClose}
        customer={customer}
        addToast={addToast}
        role={role}
      />
      <Card className={styles.card}>
        <CardHeader title="Customer Details" />
        <CardContent>
          <Typography
            variant="body2"
            component="div"
            className={styles.typography}
          >
            <div>
              <strong>Name:</strong> {customer.first_name} {customer.last_name}{" "}
              {customer.shipping.company ? (
                  <CustomerDetailsField customer={customer} field="company" label="Shipping Company" onCustomerUpdated={props.onCustomerUpdated} type="shipping" mandatory={true} />
              ) : (
                <></>
              )}
            </div>            
              <CustomerDetailsField customer={customer} field="company" label="Billing Company" onCustomerUpdated={props.onCustomerUpdated} type="billing" mandatory={true} />
            <div>
              <strong>User type:</strong>{" "}
              {customer.shipping.company ? "Business" : "Private"}
            </div>
            {isCompany && (
              <BusinessSubSegmentField
                customer={customer}
                onCustomerUpdated={props.onCustomerUpdated}
              />
            )}
            <div>
              <CustomerDetailsField customer={customer} field="phone" label="Phone" onCustomerUpdated={props.onCustomerUpdated} type="billing" mandatory={true} validationFunc={val=> !isNaN(val) && val.toString().length === 10} />
            </div>
            <div>
              <strong>Email for Login:</strong> {customer.email}
            </div>
            <div>
              <strong>Total Orders:</strong> {orderCount}
            </div>
            {isCompany && (
              <>
              <TempForceSiteEdit
                customer={customer}
                onCustomerUpdated={props.onCustomerUpdated}
              />
              <CustomerIdField
              customer={customer}
              onCustomerUpdated={props.onCustomerUpdated} />
              <BillingEmailField
              customer={customer}
              onCustomerUpdated={props.onCustomerUpdated} />
              </>
            )}
          </Typography>
          <Divider variant="middle" />
          <div className={styles.pointsField}>
            <CustomerDetailsField customer={customer} field="SHOOKIT_POINTS" label="Points Value" 
              onCustomerUpdated={props.onCustomerUpdated} type="meta_data" mandatory={true} labelClassName={styles.pointsLabel} 
              useSaveButton={true} validationFunc={val=> !isNaN(val) && val >= 0}
              />
          </div>
          <Divider variant="middle" />
          <CardActions>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => sendResetPassword()}
                >
                  Send Reset Password
                </Button>
              </Grid>
              <Grid>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => openNewOrderDialog()}
                >
                  New Order
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          <Divider variant="middle" />
          <Tabs
            value={tabValue}
            onChange={handleAddressTabChange}
            variant="fullWidth"
          >
            <Tab
              className={styles.addressTab}
              label="Shipping Address"
              value={SHIPPING_ADDRESS_TAB_INDEX}
              wrapped
            />
            <Tab
              className={styles.addressTab}
              label="Billing Address"
              value={BILLING_ADDRESS_TAB_INDEX}
              wrapped
            />
          </Tabs>
          <Typography
            variant="body2"
            component="div"
            className={styles.typography}
          >
            <div className={styles.addressPanel}>
              {isAddressUpdating && <LinearProgress />}
              {!isAddressUpdating && (
                <FloatingEditButtons
                  onEditClicked={() => {
                    setShowAddressDialog(true);
                  }}
                  onDiscardClicked={() => {}}
                  onSaveClicked={() => {}}
                  editing={false}
                >
                  <div>
                    <strong>Name:</strong> {addressRef.first_name}{" "}
                    {addressRef.last_name}
                  </div>
                  {addressRef.company &&
                    addressRef.company !== customer.shipping.company && (
                      <div>
                        <strong>Company:</strong> {addressRef.company}
                      </div>
                    )}
                  {addressRef.type && (
                    <div>
                      <strong>Address Type:</strong>{" "}
                      {AddressTypes[addressRef.type]}
                    </div>
                  )}
                  <div>
                    <strong>City:</strong> {addressRef.address}
                  </div>
                  <div>
                    <strong>Street:</strong> {addressStreetRef.address_street}
                  </div>
                  <div>
                    <strong>Street Number:</strong> {addressStreetRef.address_street_number}
                  </div>
                  {(addressRef.entrance ||
                    addressRef.floor_number ||
                    addressRef.apt_number) && (
                    <div>
                      <strong>Entrance:</strong> {addressRef.entrance || " "} |{" "}
                      <strong>Floor:</strong> {addressRef.floor_number || " "} |{" "}
                      <strong>Apartment:</strong> {addressRef.apt_number || " "}
                    </div>
                  )}
                  <div>
                  </div>
                  <div>
                      <strong>Building Code:</strong> {buildingCode ? buildingCode : 'אין'} 
                  </div>
                  <div>
                    <strong>Notes:</strong> {addressNotes || "N/A"}
                  </div>
                </FloatingEditButtons>
              )}
            </div>
          </Typography>

          <Divider variant="middle" />
          {isCompany && (
            <div>
              {isLoading ? (
                <CircularProgress className={styles.loadingBar} />
              ) : (
                <Typography
                  variant="body2"
                  component="div"
                  className={styles.typography}
                >
                  <Typography variant="h6" component="div">
                    Payment
                  </Typography>
                  <TempPaymentMethodEdit
                    customer={customer}
                    onCustomerUpdated={props.onCustomerUpdated}
                  />
                </Typography>
              )}
            </div>
          )}
          <Divider variant="middle" />
        </CardContent>

        
      </Card>
      <Card>
        <Typography component="div" variant="h6" className={styles.typography}>Notification Preferences</Typography>
        <Grid container direction="column">
          <Grid item>
            <Checkbox
              disabled={updateingPreferences}
              checked={customer['subscribe_on_the_way_sms'] !== "0" ? true : false}
              onChange={handleUpdatePreferencesChange('subscribe_on_the_way_sms')}
            />{" "}
            On The Way SMS?
          </Grid>
          <Grid item>
            <Checkbox
              disabled={updateingPreferences}
              checked={customer['subscribe_arrived_sms']!== "0" ? true : false}
              onChange={handleUpdatePreferencesChange('subscribe_arrived_sms')}
            />{" "}
            Feedback (On Arrive) SMS?
          </Grid>
          <Grid item>
            <Checkbox
              disabled={updateingPreferences}
              checked={customer['subscribe_missing_sms'] !== "0" ? true : false}
              onChange={handleUpdatePreferencesChange('subscribe_missing_sms')}
            />{" "}
            Missing Items SMS?
          </Grid>
          <Grid item>
            <Checkbox
              disabled={updateingPreferences}
              checked={customer['subscribe_on_hold_email'] !== "0" ? true : false}
              onChange={handleUpdatePreferencesChange('subscribe_on_hold_email')}
            />{" "}
            Order Summary email?
          </Grid>
          <Grid item>
            <Checkbox
              disabled={updateingPreferences}
              checked={customer['subscribe_certificate_email'] !== "0" ? true : false}
              onChange={handleUpdatePreferencesChange('subscribe_certificate_email')}
            />{" "}
            Order Certificate email?
          </Grid>
          <Grid item>
            <Checkbox
              disabled={updateingPreferences}
              checked={customer['multiple_sms'] && customer['multiple_sms'] !== "0" ? true : false}
              onChange={handleUpdatePreferencesChange('multiple_sms')}
            />{" "}
            Multiple SMS per day?
          </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container direction="row">
          <Grid item>
            <Checkbox
              disabled={optinUpdating}
              checked={customer.opt_in === "1" ? true : false}
              onChange={handleOptInChange}
            />{" "}
            Want to get marketing emails?
          </Grid>
          <Grid item>
            <Checkbox
              checked={customer.opt_in_sms && customer.opt_in_sms  === "1" ? true : false}
              onChange={handleOptInSmsChange}
            />{" "}
            Want to get marketing sms?
          </Grid>
          <Grid item>
            <Checkbox
              disabled={blacklsitUpdateing}
              checked={customer.blacklist_invocing === "1" ? true : false}
              onChange={handleBlacklistInvoicingChange}
            />{" "}
            Blacklsit from Auto-Invocing?
          </Grid>
          </Grid>
      </Card>
    </>
  );
};

export default CustomerDetailsPane;
