// Wordpresss Base URL
export const BASE_URL = process.env.REACT_APP_WC_URL;
export const BASE_ADMIN_URL = process.env.REACT_APP_WC_ADMIN_URL;
// WooCommerce API Path
export const BASE_WC_URL = `${BASE_URL}/wp-json/wc/v3`;

// Geo-Sites Management Service API
export const BASE_GEO_API_URL = process.env.REACT_APP_GEO_URL;
export const BOND_API_KEY = process.env.REACT_APP_BOND_API_KEY;

// Twilio Service API
export const BASE_TWILIO_URL = process.env.REACT_APP_TWILIO_URL;

// Recurring Service API
export const BASE_RECURRING_URL = process.env.REACT_APP_RECURRING_URL;

// GI Service API
export const BASE_GI_URL = process.env.REACT_APP_GI_URL;

// Auto Scale API
export const BASE_AUTO_SCALE_URL = process.env.REACT_APP_AUTO_SCALE_URL;
export const AUTO_SCALE_SUPPORTED_CUSTOMERS = process.env.REACT_APP_AUTO_SCALE_SUPPORTED_CUSTOMERS;

// Shookbook API
export const SHOOKBOOK_URL = process.env.SHOOKBOOK_URL;
export const SHOOKBOOK_KEY = process.env.SHOOKBOOK_KEY;

// WooCommerce Price Calculator
export const WC_PRICE_CALC_ID = process.env.REACT_APP_WC_PRICE_CALC_ID;
export const WC_PRICE_CALC_QUANTITY = process.env.REACT_APP_WC_PRICE_CALC_QUANTITY;
export const WC_PRICE_CALC_TYPE = process.env.REACT_APP_WC_PRICE_CALC_TYPE;
export const WC_PRICE_CALC_TYPE_UNIT = process.env.REACT_APP_WC_PRICE_CALC_TYPE_UNIT;
export const WC_PRICE_CALC_TYPE_WEIGHT = process.env.REACT_APP_WC_PRICE_CALC_TYPE_WEIGHT;

// Roles
export const ROLE_ALL_REGION = "global-region";
export const ROLE_ALL_SITE = "global-site";
export const SUPPORTED_ROLES = [
  ROLE_ALL_SITE, 
  "b2b", 
  "b2c", 
  "rustys", 
  "flying cargo", 
  "dhl", 
  "nyc", 
  "nutrifres",
  "Yumble",
  "Rose Box",
  "Maivino",
  "Lumen",
  "Salmon club",
  "Sweet Nothings",
  "SlowUp",
  "Masami",
  "RCVR",
  "Milk Bar",
  "Hungryroot",
  "Onia"
];
export const PICK_PACK_ADMIN_ROLES = ["administrator", "author", "operations", "ops"]
export const BILLING_ADMIN_ROLES = ["administrator", "operations", "ops"]

// Special WooCommerce Field names
export const WC_ORDER_SITE = "Shookit Site";
export const WC_ORDER_TS_DATE = "Shookit Timeslot Date";
export const WC_ORDER_TS_START = "Shookit Timeslot Start";
export const WC_ORDER_TS_END = "Shookit Timeslot End";
export const WC_ORDER_LEAVE_AT_DOOR = "leave_at_door";

export const FIXED_PRICING_METHOD = "FIXED";
export const WEIGHT_PRICING_METHOD = "WEIGHT";

export const AVAILABLE_UNITS = { KG: "KG", UNIT: "Unit" };

// Google Maps API
export const GOOGLE_MAP_API_KEY = "AIzaSyBm6d4eZoj-2K4_cPb5vADyJDdQ2_DP3fE";

// LOG ROCKET API KEY
export const LOGROCKET_API_KEY = process.env.REACT_APP_LOGROCKET_API_KEY;

// VAT Ratio
export const VAT = 1.17;