import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

import * as wcApi from "../../../services/wcApi";

const useStyles = makeStyles(theme => ({
  dialogContent: {},
  field: {
    marginBottom: theme.spacing(2)
  },
  notice: {
    textAlign: "center",
    padding: "1.5rem 1rem",
    backgroundColor: "#57be8c",
    color: "white",
    margin: "0.5rem 0"
  },
  noticeError: {
    backgroundColor: theme.palette.secondary.light
  }
}));

export function DialogSearchOrder({ onFound }) {
  const classes = useStyles();

  const [originalOrderId, setOriginalOrderId] = useState("");
  const [originalOrderError, setOriginalOrderError] = useState(null);
  const [originalOrder, setOriginalOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSearchOrder(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const order = await wcApi.fetchOrderDetails(originalOrderId);
      setOriginalOrder(order);
      setOriginalOrderError(null);
      onFound(order);
    } catch (error) {
      setOriginalOrder(null);
      setOriginalOrderError("Order not found. Try again");
      onFound(null);
    }
    setIsLoading(false);
  }

  return (
    <React.Fragment>
      <Grid item style={{ width: "100%" }}>
        <form onSubmit={handleSearchOrder}>
          {/* Original Order ID */}
          <Grid container justify="center" alignItems="center">
            <Grid item style={{ flex: 1, paddingRight: "1rem" }}>
              <TextField
                label="Original Order ID"
                id="order_id"
                className={classes.field}
                type="text"
                value={originalOrderId}
                onChange={event => {
                  setOriginalOrderId(event.target.value);
                }}
                // variant="outlined"
                required
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Search
              </Button>
            </Grid>
          </Grid>
          {isLoading && <LinearProgress />}
        </form>
      </Grid>

      {originalOrder === null && (
        <Box className={clsx(classes.notice, { [classes.noticeError]: originalOrderError })}>
          {originalOrderError || "Search for an Order to Duplicate Recurringly"}
        </Box>
      )}
    </React.Fragment>
  );
}
