import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import Autocomplete from "../../UI/Autocomplete";

import * as wcApi from "../../../services/wcApi";

function couponType(coupon) {
  if (coupon.discount_type === "percent") {
    return "%";
  } else if (coupon.discount_type === "fixed_product") {
    return " of some products";
  } else {
    return " of total sum";
  }
}

const useStyles = makeStyles(theme => ({
  button: {
    textAlign: "center",
    padding: "0.3rem 1rem",
    backgroundColor: "#57be8c",
    color: "white",
    margin: "0.2rem 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#03da72"
    }
  },
  coupons: {
    marginTop: "0.5rem"
  },
  warning: {
    textAlign: "center",
    padding: "0.2rem",
    backgroundColor: theme.palette.secondary.light
  }
}));

const AddCouponDialog = ({ order, onClose, open, onOrderUpdated }) => {
  const classes = useStyles();
  const [field, setField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [couponError, setCouponError] = useState(null);
  const [coupons, setCoupons] = useState([]);

  async function searchCoupons(event) {
    event.preventDefault();
    setIsLoading(true);
    const result = await wcApi.searchCoupons(field);
    setCoupons(result);
    setIsLoading(false);
  }

  // Show all by default
  // useEffect(() => {
  //   if (open === true) {
  //     console.log(">>> COUPONS");
  //     setIsLoading(true);
  //     const result = wcApi.searchCoupons("").then(result => {
  //       setCoupons(result);
  //     });
  //     setIsLoading(false);
  //   }
  //   return () => {};
  // }, [open]);

  const cancelClicked = e => {
    if (onClose) {
      onClose(null);
    }
  };

  // async function selectCoupon(coupon) {
  //   let payload = {
  //     coupon_lines: [
  //       ...order.coupon_lines.map(line => ({ code: line.code })),
  //       {
  //         code: coupon.code
  //       }
  //     ]
  //   };

  //   try {
  //     const result = await wcApi.updateOrder(order.id, payload);
  //     onOrderUpdated(result.data);
  //     onClose(null);
  //   } catch (err) {
  //     setCouponError(err.response);
  //   }
  // }

  async function selectCoupon(coupon) {

    try {
      await wcApi.addCouponToOrder(order.id, coupon.code);
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
      onClose(null);
    } catch (err) {
      setCouponError(err.response);
    }
  }


  return (
    <Dialog open={open} onClose={cancelClicked} maxWidth="md">
      <DialogTitle>Add Coupon For Order #{order.id}</DialogTitle>
      <DialogContent>
        {couponError != null && <div className={classes.warning}>{couponError.data.message}</div>}
        <Grid item style={{ width: "100%" }}>
          <form onSubmit={searchCoupons}>
            {/* Search Bar */}
            <Grid container justify="center" alignItems="center">
              <Grid item style={{ flex: 1, paddingRight: "1rem" }}>
                <TextField
                  label="Search Coupon"
                  id="coupon_id"
                  className={classes.field}
                  type="text"
                  value={field}
                  onChange={event => {
                    setField(event.target.value);
                  }}
                  // variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <div className={classes.coupons}>
          {coupons.map(coupon => (
            <div key={coupon.id} onClick={() => selectCoupon(coupon)} className={classes.button}>{`${
              coupon.code
            } | ${coupon.amount}${couponType(coupon)}`}</div>
          ))}
        </div>

        {isLoading && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={cancelClicked}>
          Cancel
        </Button>
        {/* <Button color="primary" type="submit" disabled={coupon == null} onClick={() => addCoupon()}>
          Add Coupon
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default AddCouponDialog;
