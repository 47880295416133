import React from "react";
import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import RoomIcon from "@material-ui/icons/Room";
import RepeatIcon from "@material-ui/icons/Repeat";
import PeopleIcon from "@material-ui/icons/People";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalShipping from '@material-ui/icons/LocalShipping';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { Divider } from "@material-ui/core";
import { useAuth } from "../../utils/authentication";

export const Sidebar = () => {
  const auth = useAuth();

  function logout() {
    auth.setAuthenticated(false);
    auth.setAuthBody(null);
  }

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={auth.authBody.user_display_name} />
      </ListItem>

      <Divider />

      <ListItem button component={Link} to="/customers">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItem>

      <ListItem button component={Link} to="/sites">
        <ListItemIcon>
          <RoomIcon />
        </ListItemIcon>
        <ListItemText primary="Site Management" />
      </ListItem>

      <ListItem button component={Link} to="/recurring">
        <ListItemIcon>
          <RepeatIcon />
        </ListItemIcon>
        <ListItemText primary="Recurring Orders" />
      </ListItem>

      <ListItem button component={Link} to="/assignment">
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Site Assignment" />
      </ListItem>

      <ListItem button component={Link} to="/pickandpack">
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText primary="Pick And Pack" />
      </ListItem>


      <Divider />

      <ListItem button component={Link} to="/billing">
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItem>

      <Divider />

      <ListItem button onClick={logout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );
};
