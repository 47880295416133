import React, { useState, useContext, useEffect } from "react";

import { isEmpty } from "lodash";
import { useAuth } from ".";
import * as wcApi from "../../services/wcApi";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";

export const WooUserContext = React.createContext();

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute"
  }
}));

export default function WooUserContextProvider({ children }) {
  const auth = useAuth();
  const classes = useStyles();

  const [notFound, setNotFound] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isEmpty(auth.userId)) {
      setIsLoading(false);
      return;
    }

    wcApi
      .fetchCustomerDetail(auth.userId)
      .then(result => {
        if (result) {
          if (result.length) result = result[0];
          setCustomer(result);
        } else {
          setNotFound(true);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setNotFound(true);
        setIsLoading(false);
      });

    return () => {
      setCustomer(null);
    };
  }, [auth.userId]);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <CssBaseline />

        <CircularProgress size="5rem" />
        <br />
        <Typography>Loading...</Typography>
      </div>
    );
  }

  if (notFound) {
    // Logout
    auth.setAuthenticated(false);
    auth.setAuthBody(null);
  }

  const defaultContext = isEmpty(customer) ? null : customer;

  return <WooUserContext.Provider value={defaultContext}>{children}</WooUserContext.Provider>;
}

export const useWooUser = () => {
  return useContext(WooUserContext);
};
