import React from "react";
import { useMobileSidebar } from "../SideBar/SidebarContext";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AdjustIcon from '@material-ui/icons/Adjust';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  backToTodayButton: {
    marginLeft: theme.spacing(1)
  }
}));

export default function TopBar({ title, siteId, selectedFilter, date }) {
  const history = useHistory();
  const classes = useStyles();
  const mobileSidebar = useMobileSidebar();

  function checkDate() {
    if (date == null) return false;
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    return date.getTime() !== today.getTime();
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={mobileSidebar.open}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>

          {checkDate() && <IconButton
            edge="start"
            className={classes.backToTodayButton}
            color="inherit"
            aria-label="backToToday"
            onClick={() => history.push({ pathname: `/pickandpack/${siteId}`, state: {filter: selectedFilter}})}
          >
            <AdjustIcon />
          </IconButton>}

        </Toolbar>
      </AppBar>
    </div>
  );
}
