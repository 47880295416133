/*
    WC API Data processing functions used to mediate between the WC rest API to a better, easier to use API for the frontend.
*/
import _ from "lodash";
import * as conf from "../config";
import { __, getCurrentLanguage } from "./locale";

const PRODUCT_COUPON_CODE = "copoun-product";

export function getMetadata(metadata) {
  const asHashmap = _.keyBy(metadata, "key");
  var x = _.mapValues(asHashmap, value => value.value);
  return x;
}

export function flattenMetadata(obj = {}) {
  return {
    ...obj,
    ...getMetadata(obj ? obj.meta_data : [])
  };
}

export function flattenOrdersMetadata(orders) {
  return orders.map(order => ({ ...order, ...getMetadata(order.meta_data) }));
}

export function flattenOrderMetadata(order) {
  return { ...order, ...getMetadata(order.meta_data) };
}

/*
 * Parse order and extract the pricing policy.
 */
export function extractQuantityForOrderItem(wooOrderItem) {
  const md = getMetadata(wooOrderItem.meta_data);

  return {
    pricingMethod: md.shookit_pricing_method,
    requestedQuantity: md.shookit_requested_quantity,
    requestedUnit: md.shookit_requested_unit,
    shippedQuantity: md.shookit_shipped_quantity,
    shippedWeight: md.shookit_shipped_weight
  };
}

// Returns `1 Unit` or `5KG`
export function formatQuantityForItem(item) {
  const { requestedQuantity } = extractQuantityForOrderItem(item);
  return `${requestedQuantity} ${formatUnitForItem(item)}`;
}

export function formatUnitForItem(item) {
  const { requestedUnit } = extractQuantityForOrderItem(item);
  return __(requestedUnit === "Unit" ? "Units" : requestedUnit || "KG");
}

export function formatPackedQuantity(item) {
  const { pricingMethod } = extractQuantityForOrderItem(item);

  if (
    item.shookit_shipped_quantity === undefined &&
    item.shookit_shipped_weight === undefined
  ) {
    return "----";
  } else if (parseFloat(item.quantity) === 0) {
    return "Missing";
  } else if (pricingMethod === conf.FIXED_PRICING_METHOD) {
    return `${item.quantity} ${formatUnitForItem(item)}`;
  } else if (item.shookit_requested_unit !== "KG") {
    return `${item.quantity} ${formatUnitForItem(item)} (${
      item.shookit_shipped_weight
    } KG)`;
  } else {
    return `${item.quantity} KG`;
  }
}

export function extractDiscountForProduct(wooProductId, couponsItems) {
  if (!couponsItems) {
    return 0.0;
  } else {
    const coupon = couponsItems
      .filter(c => c.code === PRODUCT_COUPON_CODE)
      .find(ic => {
        const md = getMetadata(ic.meta_data);

        if (md.coupon_data) {
          return md.coupon_data.product_ids.contains(wooProductId);
        }
        return false;
      });

    return coupon.amount;
  }
}

export function formatOrderStatus(orderStatus) {
  // "on-hold" -> "On hold"
  return orderStatus.replace("-", " ").replace(/^\w/, c => c.toUpperCase());
}
