import React, { useState } from "react";
import { useParams } from "react-router-dom";

import * as timestamps from "../../../utils/timestamps";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import TopBar from "../PickAndPack/TopBar";

import { DatePicker } from "../PickAndPack/DatePicker";
import ProductSummaryReport from "./ProductSummaryReport";

export const useStyles = makeStyles(theme => ({
  paper: { paddingTop: 65 }
}));

export default function ProductSummaryReportPage() {
  const classes = useStyles();
  const params = useParams();
  const siteId = params.siteId;

  const [date, setDate] = useState(timestamps.resetTime(new Date()));

  function setSelectedDate(newDate) {
    setDate(timestamps.resetTime(newDate));
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <TopBar title="Daily products Summary" />

      <Paper square className={classes.paper}>
        <DatePicker selectedDate={date} onDateChanged={setSelectedDate} />
        {/* {loadingProgress == 1 ? page : <LoadingProgress percentages={loadingProgress} />} */}
        <ProductSummaryReport siteId={siteId} date={date} />
      </Paper>
    </React.Fragment>
  );
}
