import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FastForwardIcon from "@material-ui/icons/FastForward";
import AppBar from "@material-ui/core/AppBar";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Box, Button, ButtonGroup } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  bottomBar: {
    top: "auto",
    bottom: 0,
    right: 0,
    left: 0,
    background: "#e3f2fd",
    textAlign: "center"
  },
  text: {
    padding: theme.spacing(1),
    fontSize: "0.9em"
  },
  button: {
    background: "#2196f3",
    color: "white",
    border: "none",
    marginBottom: theme.spacing(1)
  },
  button2: {
    background: "#57be8c",
    color: "white",
    border: "none",
    marginBottom: theme.spacing(1),
    width: "95%"
  },
  fullWidth: {
    paddingRight: "1rem",
    paddingLeft: "1rem;"
  },
  scrollBox: {
    background: "white",
    paddingBottom: theme.spacing(1)
  },
  scrollButton: {
    border: "black 1px solid",
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    backgroundColor: "white",
  },
  scrollButtonActive: {
    backgroundColor: "#57be8c!important",
    color: "white",
    border: "none",
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  scrollArrow: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    textWeight: "bold"
  }
}));

export default function BottomBar({
  totalItems,
  packedItems,
  onSave,
  saveTitle = "Save",
  onDone,
  doneTitle = "Done",
  disabled = false,
  boxesQuantity = 0,
  onChangeBoxesQty,
}) {
  const [selectedBoxQty, setSelectedBoxQty] = useState(boxesQuantity);
  const classes = useStyles();
  
  const MAX_BOXES = 16;

  const isBigScreen = useMediaQuery("(min-height:450px)");

  function onClickBoxQty(qty) {
    setSelectedBoxQty(qty);
    if (onChangeBoxesQty) {
      onChangeBoxesQty(qty);
    }
  }

  return (
    <Box className={classes.bottomBar} position={isBigScreen ? "fixed" : null}>
      <Box className={classes.scrollBox}>
        <Box className={classes.text}>
          How many boxes?
        </Box>
        <ScrollMenu 
          data={[...Array(MAX_BOXES).keys()].slice(1).map(i=>(
          <div key={`scroll_item#${i}`}>
            <Button onClick={()=> onClickBoxQty(i)} className={selectedBoxQty === i ? classes.scrollButtonActive : classes.scrollButton}>{i}</Button>
          </div>
          ))}
          scrollToSelected={true}
          selected={`scroll_item#${selectedBoxQty}`}
          alignCenter={false}
        />
      </Box>
      <Box className={classes.text}>
        {packedItems} out of {totalItems} items completed
      </Box>
      {/* <ButtonGroup className={classes.fullWidth} fullWidth> */}
      <Button
        className={classes.button2}
        disabled={disabled || packedItems !== totalItems || boxesQuantity === 0}
        onTouchEnd={onDone}
      >
        &nbsp; {doneTitle}
      </Button>
      {/* {onSave && (
          <Button className={classes.button} onClick={onSave}>
            {saveTitle}
          </Button>
        )}
      </ButtonGroup> */}
    </Box>
  );
}
