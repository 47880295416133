import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextareaAutosize,
  Grid,
  TextField,
  InputLabel,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import AddressTypes from "../../../enums/AddressTypes";
import {
  fetchShookitSiteForPlaceId,
  fetchShookitSiteForAddress,
  fetchShookitSiteInformation
} from "../../../services/geoApi";

import { getAddressDetails } from "../../../services/mapsApi";

import useDebouncedCallback from "../../../utils/useDebouncedCallback";
import PlacesInput from "./PlacesInput";

const useStyles = makeStyles(theme => ({
  TextField: {
    width: "100%",
    margin: theme.spacing(1)
  },
  TextArea: {
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.42)",
    marginTop: theme.spacing(1),
    fontSize: "1rem"
  },
  textAreaItem: {
    margin: theme.spacing(1)
  },
  radioControl: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  shippingArea: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: "#57be8c",
    color: "white"
  },
  shippingAreaInvalid: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: "#c54848",
    color: "white"
  }
}));

const AddressTabInput = ({
  initialValues = {},
  role,
  onChange,
  autosave = false,
  deliverableAddress = true,
  strictValidation = false
}) => {
  const classes = useStyles();

  // Places API State
  const [selectedAddress, setSelectedAddress] = useState(null);

  // Form States
  const [addressType, setAddressType] = useState(
    initialValues.type || "building"
  );

  const [address, setAddress] = useState(initialValues.address || "");
  const [street, setStreet] = useState(initialValues.address_street || "");
  const [streetNumber, setStreetNumber] = useState(initialValues.address_street_number || "");
  const [entrance, setEntrance] = useState(initialValues.entrance || "");
  const [apt, setApt] = useState(initialValues.apt || "");
  const [floor, setFloor] = useState(initialValues.floor || "");
  const [notes, setNotes] = useState(initialValues.notes || "");
  const [buildingCode, setBuildingCode] = useState(initialValues.buildingCode || "");
  // Places Messages
  const [isSupported, setIsSupported] = useState(null);
  const [message, setMessage] = useState(null);
  const [siteName, setSiteName] = useState(null);

  const isValidAddress = () => {
    if (deliverableAddress && !isSupported) return false;
    if (addressType === "building" && (!floor || !apt)) return false;
    return true;
  };

  // Debounce Save function
  const saveChanges = useDebouncedCallback(() => {
    onChange(
      isValidAddress()
        ? 
        { ...selectedAddress, address_street : street,  address_street_number : streetNumber, addressType, apt, floor, entrance, notes, buildingCode}
        : {}
    );
  }, 500);

  useEffect(() => {
    (async () => {
      if (address && addressType) {
        const addressDetails = await getAddressDetails(address);
        handleSelect(addressDetails, addressType);
      }
    })();
  }, [floor, apt]);

  useEffect(() => {
    if (autosave) {
      saveChanges();
    }
    return () => {};
  }, [addressType, floor, notes, apt, entrance, selectedAddress, isSupported, buildingCode, street, streetNumber]);

  function resetState() {
    setMessage(null);
    setSiteName(null);
    setIsSupported(false);
    setSelectedAddress(null);
  }
  async function handleSelect(selected, selectedType) {
    if (!deliverableAddress) {
      setSiteName(null);
      setAddress(selected.address);
      setSelectedAddress(selected);
      return;
    }
    setSiteName(null);
    setAddress(selected.address);
    setMessage("Checking address...");
    if ((selectedType || addressType) === "building" && (!apt || !floor)) {
      setIsSupported(false);
      setMessage("Missing apartment \\ floor");
      return setSelectedAddress(selected);
    }

    // Show message to user
    try {
      if ((selectedType || addressType) === "other") {
        const placeIdQuery = await fetchShookitSiteForPlaceId({
          role,
          placeId: selected.placeId
        });
        if (placeIdQuery.data.status !== "PLACEDID_IS_SUPPORTED") {
          setIsSupported(false);
          setMessage("Address is not supported");
          return setSelectedAddress(selected);
        }
      }
      let shookitSiteQuery = await fetchShookitSiteForAddress({
        role: role,
        longitude: selected.latLng.lng,
        latitude: selected.latLng.lat
      });

      if (shookitSiteQuery.data.status === "ADDRESS_IS_SUPPORTED") {
        if (
          (selectedType || addressType) !== "other" &&
          !selected.houseNumberPresent
        ) {
          setIsSupported(false);
          setMessage("Missing house number");
          return setSelectedAddress(selected);
        }
        setIsSupported(true);
        setMessage("Address is in shipping range");

        let site = await fetchShookitSiteInformation(
          shookitSiteQuery.data.site
        );
        setSiteName(site.name);
      } else {
        setIsSupported(false);
        setMessage("Address is not supported");
      }
    } catch (error) {
      console.log(error);
      setIsSupported(false);
      setMessage(
        "Whoops! It looks like an error occurred while validating address"
      );
    }

    setSelectedAddress(selected);
  }

  return (
    <Grid container direction="column">
      <Grid container direction="row">
        <Grid item xs={12} className={classes.AddressField}>
          <FormControl className={classes.radioControl} required={true}>
            <RadioGroup
              row
              value={addressType}
              onChange={event => {
                setAddressType(event.target.value);
                selectedAddress &&
                  handleSelect(selectedAddress, event.target.value);
              }}
            >
              {Object.entries(AddressTypes).map(([value, label]) => (
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  key={value}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={4} className={classes.AddressField}>
          <PlacesInput
            value={address}
            onChange={value => {
              resetState();
              setAddress(value);
            }}
            onSelect={handleSelect}
          />
        </Grid>
        <Grid item xs={4} className={classes.AddressField}>
          <TextField
            autoComplete="off"
            label= "Street"
            type="text"
            className={classes.TextField}
            fullWidth 
            value={street}
            onChange={event => {
              console.log(event.target.value)
              setStreet(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4} className={classes.AddressField}>
          <TextField
            autoComplete="off"
            label= "Street-Number"
            type="text"
            className={classes.TextField}
            fullWidth 
            value={streetNumber}
            onChange={event => {
              setStreetNumber(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} className={classes.AddressField}>
          <TextField
            autoComplete="off"
            label="Ent."
            className={classes.TextField}
            value={entrance}
            onChange={event => {
              setEntrance(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1} className={classes.AddressField}>
          <TextField
            autoComplete="off"
            label="Floor"
            type="number"
            className={classes.TextField}
            value={floor}
            onChange={event => {
              setFloor(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1} className={classes.AddressField}>
          <TextField
            autoComplete="off"
            label="Apt"
            type="text"
            className={classes.TextField}
            value={apt}
            onChange={event => {
              setApt(event.target.value);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      {message && (
        <Grid
          item
          className={
            isSupported ? classes.shippingArea : classes.shippingAreaInvalid
          }
        >
          {message}{" "}
          {siteName && <small>{`[current delivery site: ${siteName}]`}</small>}
        </Grid>
      )}
      <Grid item xs={5} className={classes.AddressField}>
          <TextField
            autoComplete="off"
            label= "Building Code"
            type="text"
            className={classes.TextField}
            fullWidth 
            value={buildingCode}
            onChange={event => {
              setBuildingCode(event.target.value);
            }}
          />
        </Grid>
      <Grid item className={classes.textAreaItem}>
        <InputLabel>Address Notes</InputLabel>
        <TextareaAutosize
          rows={3}
          className={classes.TextArea}
          value={notes}
          onChange={event => {
            setNotes(event.target.value);
          }}
        />
      </Grid>
      {!autosave && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2}>
            <Button
              onClick={saveChanges}
              variant="contained"
              color="primary"
              fullWidth
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={9} className={classes.AddressField}></Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AddressTabInput;
