import React, { useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import FloatingEditButtons from "../../../UI/FloatingEditButtons";
import PhoneInput from "../../../common/inputs/PhoneInput";
import * as wcApi from "../../../../services/wcApi";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";

import * as timestamp from "../../../../utils/timestamps";
import * as PaneForm from "./PaneForm";

const useStyles = makeStyles(theme => ({
  addressTab: {
    minWidth: "50%"
  }
}));

export default function OrderInfoPane({ order, onOrderUpdated }) {
  const styles = useStyles();
  const initialData = {
    customer_note: order.customer_note,
    _shipping_usebags: order._shipping_usebags == 1 ? true : false,
    is_pickup: order.is_pickup == 1 ? true: false,
    leave_at_door: order.leave_at_door == 1 ? true: false
  };
  const [formData, setFormData] = useState(initialData);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = name => event => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  useEffect(() => {
    setFormData(initialData)
  }, [order]);

  const handleChangeChecked = name => event => {
    setFormData({ ...formData, [name]: event.target.checked });
  };

  async function handleSave() {
    let payload = {};
    payload["customer_note"] = formData.customer_note;
    payload["meta_data"] = [
      {
        key: "is_pickup", value: formData.is_pickup ? "1" : "0",
      },
      {
        key: "_shipping_usebags", value: formData._shipping_usebags ? "1" : "" ,
      },
      {
       key: "leave_at_door", value: formData.leave_at_door ? "1" : "0",
      }
  ];
  console.log(payload);
    setIsSaving(true);
    const orderDetails = await wcApi.updateOrder(order.id, payload);
    setEditMode(false);
    setIsSaving(false);
    onOrderUpdated(orderDetails.data);
  }

  return (
    <>
      <div>
        <FloatingEditButtons
          onEditClicked={() => setEditMode(true)}
          onDiscardClicked={() => {
            setFormData(initialData);
            setEditMode(false);
          }}
          onSaveClicked={handleSave}
          editing={editMode}>
          {/* <Typography color="primary">Order Contact Information</Typography> */}

          {/* <PaneForm.TextField label="Order ID" value={`#${order.id}`} onChange={() => {}} /> */}
          {order._cancel_reason && (
            <PaneForm.TextField label="Cancel Reason" value={(typeof order._cancel_reason === 'string' ? order._cancel_reason : 'N\\A')} onChange={() => {}} />
          )}

          <PaneForm.TextField
            label="Date Created"
            value={timestamp.formatISODateString(order.date_created)}
            onChange={() => {}}
          />

          <PaneForm.TextField label="Feedback Score" value={order.feedback_score} onChange={() => {}} />

          <PaneForm.TextArea
            label="Order Notes"
            value={formData.customer_note}
            onChange={handleChange("customer_note")}
            editMode={editMode}
            rows={3}
            style={{ width: "100%" }}
          />

          <PaneForm.CheckboxField
            label="Minimum bags"
            value={formData._shipping_usebags}
            onChange={handleChangeChecked("_shipping_usebags")}
            editMode={editMode}
          />

          <PaneForm.CheckboxField
            label="Pickup"
            value={formData.is_pickup}
            onChange={handleChangeChecked("is_pickup")}
            editMode={editMode}
          />
            <PaneForm.CheckboxField
            label="Leave at door"
            value={formData.leave_at_door}
            onChange={handleChangeChecked("leave_at_door")}
            editMode={editMode}
          />
        </FloatingEditButtons>
        {isSaving && <LinearProgress />}
      </div>
    </>
  );
}
