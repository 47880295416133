import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Snackbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

import * as woocommerce from "../../../utils/woocommerce";
import * as wcApi from "../../../services/wcApi";

import CustomerDetailsPane from "./CustomerDetailsPane";
import CustomerOrders from "./CustomerOrders";

const useStyles = makeStyles(theme => ({
  tablePaper: {
    width: "100%",
    overflowX: "auto",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  rightbarTitle: {
    fontWeight: "bold"
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableGrid: {
    justifyContent: "flex-end"
  },
  loadingBar: {
    position: "relative",
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
    textAlign: "center",
    left: "50%",
    top: "50%"
  }
}));

const CustomerSnackbar = ({ toastKey, anchorOrigin, autoHideDuation, message }) => {
  const [isOpened, setIsOpened] = useState(true);

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <Snackbar
      key={toastKey}
      anchorOrigin={anchorOrigin}
      open={isOpened}
      autoHideDuration={autoHideDuation}
      message={<span>{message}</span>}
      onClose={handleClose}
    />
  );
};

const ViewCustomer = ({ match }) => {
  const customerId = match.params.customerId;

  const classes = useStyles();

  const [notFound, setNotFound] = useState(false);
  const [customer, setCustomer] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [toastKey, setToastKey] = useState(0);

  useEffect(() => {
    wcApi
      .fetchCustomerDetail(customerId)
      .then(result => {
        if (result) {
          if (result.length) result = result[0];
          setCustomer(woocommerce.flattenMetadata(result));
        } else {
          setNotFound(true);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setNotFound(true);
        setIsLoading(false);
      });

    return () => {
      setCustomer(null);
    };
  }, [customerId]);

  function handleCustomerUpdated(customer) {
    console.log("Customer updated");
    setCustomer(woocommerce.flattenMetadata(customer));
  }

  const addToast = ({
    variant,
    message,
    anchorOrigin = { vertical: "bottom", horizontal: "left" },
    autoHideDuation = 3000
  }) => {
    let key = toastKey + 1;
    setToasts([...toasts, { variant, message, anchorOrigin, autoHideDuation, key }]);
    setToastKey(key);
  };

  if (notFound) {
    return <Paper className={classes.paper}>{`Customer ID: ${customerId} was not found.`}</Paper>;
  }

  return (
    <>
      <Grid container spacing={3} className={classes.grid}>
        {isLoading ? (
          <CircularProgress className={classes.loadingBar} />
        ) : (
          <>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="h5">
                  <IconButton component={Link} to={"/customers/"}>
                    <ChevronLeft />
                  </IconButton>
                  Customer: {customer.first_name} {customer.last_name}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <Grid container className={classes.tableGrid}>
                <Grid item xs={12}>
                  <Paper className={classes.tablePaper}>
                    <CustomerOrders customer={customer} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <CustomerDetailsPane
                addToast={addToast}
                customer={customer}
                onCustomerUpdated={handleCustomerUpdated}
              />
            </Grid>
          </>
        )}
      </Grid>
      {toasts.map(toast => (
        <CustomerSnackbar
          toastKey={toast.key}
          anchorOrigin={toast.anchorOrigin}
          autoHideDuation={toast.autoHideDuation}
          message={toast.message}
        />
      ))}
    </>
  );
};

export default ViewCustomer;
