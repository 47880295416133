import React from "react";

import Cookies from "js-cookie";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FaceIcon from "@material-ui/icons/Face";
import { Typography } from "@material-ui/core";

import BottomBar from "./BottomBar";
import CustomerOrdersCount from "./CustomerOrdersCount";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 70,
    paddingBottom: 140,
    paddingRight: "1em",
    paddingLeft: "1em"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  center: {
    textAlign: "center"
  },
  icon: {
    textAlign: "center",
    fontSize: "4rem"
  }
}));

export default function Customer({
  order,
  sendArrived,
  disabledArrived = false
}) {
  const classes = useStyles();

  const companyName = order.shipping.company;
  const cusotmerName = `${order.shipping.first_name} ${order.shipping.last_name}`;

  return (
    <React.Fragment>
      <BottomBar
        phone={order.billing.phone}
        lat={order._shipping_lat}
        long={order._shipping_long}
        orderStatus={order.SHOOKIT_STATUS}
        sendArrived={sendArrived}
        disabled={disabledArrived}
      />
      <div className={classes.root}>
        <Grid container alignContent="center" direction="column">
          <Grid item className={classes.center}>
            <FaceIcon className={classes.icon} />
          </Grid>
          <Grid item className={classes.center}>
            <Typography variant="h5">
              {companyName ? companyName : cusotmerName}
            </Typography>
            {companyName && (
              <Typography variant="h6">{cusotmerName}</Typography>
            )}
          </Grid>
          <Grid item>
            <Typography align="center" variant="subtitle1">
              <CustomerOrdersCount customerId={order.customer_id} /> orders
            </Typography>
          </Grid>
          <Grid item>
            <br />

            <Typography variant="h6">Address</Typography>
            <Paper className={classes.paper}>
              {order._shipping_autofill_checkout}
              <br />
              Phone: {order.billing.phone}
              <br />
              Entrance: {order._shipping_entrance || "-"}
              <br />
              Floor: {order._shipping_floor_number || "-"}
              <br />
              Apartment: {order._shipping_apt_number || "-"}
            </Paper>
            <br />
          </Grid>
          <Grid item>
            <Typography variant="h6">Address Notes</Typography>
            <Paper className={classes.paper}>{order._shipping_notes}</Paper>
          </Grid>
          <Grid item>
            <br />
            <Typography variant="h6">Order Details</Typography>
            <Paper className={classes.paper}>
              <b>Delivery Date</b>:{" "}
              {new Date(order["Shookit Timeslot Date"]).toLocaleDateString(
                "en-GB"
              )}
              <br />
              <b>Timeslot</b>: {order["Shookit Timeslot Start"]} -{" "}
              {order["Shookit Timeslot End"]}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
