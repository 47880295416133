import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import _ from "lodash";
import * as config from "../../../config";

import PaperTitle from "../../UI/PaperTitle";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import * as geoApi from "../../../services/geoApi";

import SpecialTimeslotsTable from "./SpecialTimeslotsTable";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing(2)
  },
  buttonGroup: {
    textAlign: "center"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  title: {
    flex: "1"
  },

  titleBar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  titleBarHighlighted: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    // color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonProgress: {
    color: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },

  error: {
    textAlign: "center",
    padding: "0.2rem",
    backgroundColor: theme.palette.secondary.light
  }
}));

const TimeslotsTableContainer = ({ siteId, site, currentBrand }) => {
  const classes = useStyles();

  const [editMode, setEditMode] = useState(false);
  const [specialDatesBackup, setSpecialDatesBackup] = useState(null);
  const [specialDates, setSpecialDates] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Load Data once
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await geoApi.getSpecialDates(siteId);

    setSpecialDates(result);
    setSpecialDatesBackup(_.cloneDeep(result));
  }

  function dismissChanges() {
    setEditMode(false);
    setSpecialDates(_.cloneDeep(specialDatesBackup));
  }

  async function saveChanges() {
    setIsSaving(true);

    console.log(specialDates.windows.filter(entry => entry.changed));

    // Save Added entries
    var addTransactions = specialDates.windows
      .filter(entry => entry.added && entry.changed && !entry.deleted)
      .map(entry => geoApi.createSpecialDates(siteId, entry));

    // Save Deleted entries
    var deletedTransactions = specialDates.windows
      .filter(entry => entry.deleted && entry.changed && entry.id)
      .map(entry => geoApi.deleteSpecialDates(entry.id));

    // Save Changed entries
    var editTransactions = specialDates.windows
      .filter(entry => entry.changed && !entry.added && !entry.deleted)
      .map(entry => geoApi.updateSpecialDates(siteId, entry.id, entry));

    // Wait for transactions to end
    try {
      await axios.all([...addTransactions, ...editTransactions, ...deletedTransactions]);
      await fetchData();
      setEditMode(false);
      setErrorMessage(null);
    } catch (e) {
      setErrorMessage(`Error: ${e.response.data.status}`);
    }
    setIsSaving(false);
  }

  function onCellChanged(index, entryId, fieldName, newValue) {
    console.log(`onCellChanged(${index}, ${entryId}, ${fieldName}, ${newValue})`);

    const newEntry = {
      ...specialDates.windows[index],
      [fieldName]: newValue,
      changed: true
    };

    var newSpecialDates = _.cloneDeep(specialDates);
    newSpecialDates.windows[index] = newEntry;
    setSpecialDates(newSpecialDates);
    setEditMode(true);
  }

  function addNewEntry() {
    var newSpecialDates = _.cloneDeep(specialDates);
    newSpecialDates.windows.push({
      date: { day: "DD", month: "MM", year: "YYYY" },
      reason: "",
      role: config.ROLE_ALL_SITE,
      opened: true,
      timeslot: ["00:00", "00:00"],
      added: true
    });
    setSpecialDates(newSpecialDates);
    setEditMode(true);
  }

  return (
    <Paper className={classes.paper}>
      {/* Title Toolbar */}
      {editMode ? (
        <EditHeader currentBrand={currentBrand} onCancel={dismissChanges} onSave={saveChanges} isSaving={isSaving} />
      ) : (
        <NormalHeader currentBrand={currentBrand} />
      )}

      {/* Error Message */}
      {errorMessage && <div className={classes.error}>{errorMessage}</div>}

      {/* Timeslots Table */}
      {site && specialDates ? (
        <SpecialTimeslotsTable
          data={specialDates.windows}
          onCellChanged={onCellChanged}
          currentBrand={currentBrand}
          brands={site.roles}
        />
      ) : (
        <LinearProgress />
      )}
      <div className={classes.buttonGroup}>
        <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={addNewEntry}>
          <AddIcon className={classes.extendedIcon} />
          Add
        </Button>
      </div>
    </Paper>
  );
};

function NormalHeader({ currentBrand }) {
  const classes = useStyles();

  return (
    <div className={classes.titleBar}>
      <div className={classes.title}>
        {/* <PaperTitle>Special Days for {currentBrand}</PaperTitle> */}
        <PaperTitle>Special Days</PaperTitle>
      </div>
    </div>
  );
}

function EditHeader({ onSave, isSaving, onCancel, currentBrand }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleBarHighlighted}>
        <div className={classes.title}>
          <Typography component="h2" variant="h6" color="textPrimary" gutterBottom>
            {/* Special Days for {currentBrand} */}
            Special Days
          </Typography>
        </div>

        <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onCancel}>
          <ClearIcon className={classes.extendedIcon} />
          Dismiss Changes
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={onSave}
          disabled={isSaving}
        >
          <CheckIcon className={classes.extendedIcon} />
          Save Changes
        </Button>
      </div>
      {isSaving && <LinearProgress />}
    </>
  );
}

export default TimeslotsTableContainer;
