/* Shookit Order Status
        For every ON_HOLD order, we maintain our own internal status:
            Pending (Unseen)
            Pending (Seen)
            Fulfilling 
            Ready
            On its way
            Arrived
 */

import * as wcApi from "../services/wcApi";
import * as conf from "../config";

import * as priceCalculatorApi from "../services/priceCalculatorApi";
import {
  getMetadata,
  extractQuantityForOrderItem,
  flattenMetadata
} from "./woocommerce";
import { upperFirst } from "lodash";

export const statusKey = "SHOOKIT_STATUS";
export const statusValues = {
  PENDING: "PENDING",
  FULFILLING: "FULFILLING",
  READY: "READY",
  ON_ITS_WAY: "ON_ITS_WAY",
  ARRIVED: "ARRIVED",
  PAID: "PAID"
};

export const MAX_WEIGHT_DIFFERENCE = 0.5;

export const MAX_ITEM_OVER_PACKING = 2;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const shortDays = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat"
];

// Shookiter Status (for P&P)
export function getShookiterStatus(order) {
  if (!(statusKey in order)) {
    return statusValues.PENDING;
  }

  return order[statusKey];
}

// Get the global order status (incl. P&P)
export function getOrderStatus(order) {
  switch (order.status) {
    case "on-hold":
      return getShookiterStatus(order);

    case "failed":
    case "pending":
      return "draft";
    case "processing":
      return "PAID";
    case "completed":
    case "refunded":
      return order.status.toUpperCase();
    case "cancelled":
      return order._draft_order ? "draft" : order.status;
    default:
      return "[Invalid Status]";
  }
}

export async function updateShookiterStatus(orderId, newStatus) {
  return wcApi.updateOrderMeta(orderId, statusKey, newStatus);
}

export function formatShookitStatus(orderStatus) {
  // "on-hold" -> "On hold"
  return upperFirst(orderStatus.replace(/_/g, " ").toLowerCase());
}

// Receives an ISOString timestamp
export function formatAsDay(date) {
  let currentDate = new Date(date);
  return `${days[currentDate.getDay()]} ${currentDate.toLocaleDateString(
    "en-IL"
  )}`;
}

export function formatAsShortDay(date) {
  let currentDate = new Date(date);
  return `${shortDays[currentDate.getDay()]} ${currentDate.toLocaleDateString(
    "en-IL"
  )}`;
}

export const seenKey = "SHOOKIT_SEEN";

// Order/Item Seen/Not Seen
export function isSeen(order) {
  return seenKey in order;
}

// Order: Update Seen Status
export async function updateOrderSeenStatus(orderId, newValue) {
  return wcApi.updateOrderMeta(orderId, seenKey, newValue);
}

export function isItemSeen(item) {
  let found = false;
  item.meta_data.forEach(meta => {
    if (meta.key === seenKey) {
      found = true;
    }
  });

  return found;
}

// Returns an estimated weight, only for product with `WEIGHT` pricing and requested as `Unit`
export function estimatedWeight(item, product) {
  return parseFloat(item.subtotal) / parseFloat(item.shookit_base_price_override);
}

// Update the packing amount
export async function updateLineItemForPacking(
  orderId,
  item,
  product,
  selectedQuantity,
  selectedWeight,
  selectedPricingMethod
) {
  const flattenProduct = flattenMetadata(product);
  const { pricingMethod, requestedUnit } = extractQuantityForOrderItem(item);
  const method = selectedPricingMethod || pricingMethod;

  const originMetadata = getMetadata(item.meta_data);

  let metadata = {};
  let updatedFields = {};

  metadata.shookit_base_price_override =
    originMetadata.shookit_base_price_override;

  // FIXED Items (like pineapple)
  if (method === conf.FIXED_PRICING_METHOD) {
    metadata = {
      shookit_pricing_method: conf.FIXED_PRICING_METHOD,
      shookit_base_price_override: originMetadata.shookit_base_price_override
    };
    if (requestedUnit === "KG") {
      metadata.shookit_shipped_weight = parseFloat(selectedQuantity);
    } else {
      metadata.shookit_shipped_quantity = parseFloat(selectedQuantity);
    }

    // New price
    const newPrice =
      parseFloat(originMetadata.shookit_base_price_override) *
      parseFloat(selectedQuantity);

    updatedFields = {
      total: "" + newPrice,
      subtotal: "" + newPrice,
      quantity: selectedQuantity
    };
  }

  // For Weight-Based Products
  if (method === conf.WEIGHT_PRICING_METHOD && requestedUnit === "KG") {
    metadata = {
      shookit_pricing_method: conf.WEIGHT_PRICING_METHOD,
      shookit_shipped_weight: selectedWeight,
      shookit_base_price_override: originMetadata.shookit_base_price_override
    };

    const newPrice =
      parseFloat(originMetadata.shookit_base_price_override) *
      parseFloat(selectedWeight);

    updatedFields = {
      total: "" + newPrice,
      subtotal: "" + newPrice,
      quantity: selectedQuantity
    };
  }

  if (method === conf.WEIGHT_PRICING_METHOD && requestedUnit === "Unit") {
    metadata = {
      shookit_pricing_method: conf.WEIGHT_PRICING_METHOD,
      shookit_shipped_quantity: selectedQuantity,
      shookit_estimated_weight:
        originMetadata.shookit_estimated_weight ||
        estimatedWeight(item, product),
      shookit_shipped_weight: selectedWeight,
      shookit_base_price_override: originMetadata.shookit_base_price_override
    };

    // Use WooPriceCalculator to calculate new price
    const newPrice =
      parseFloat(originMetadata.shookit_base_price_override) *
      parseFloat(selectedWeight);

    updatedFields = {
      total: "" + newPrice,
      subtotal: "" + newPrice,
      quantity: selectedQuantity
    };
  }

  if (flattenProduct.is_calculated) {
    metadata.Quantity = selectedQuantity;
  }

  const meta_data = Object.keys(metadata).map(key => ({
    key,
    value: metadata[key]
  }));

  const wcUpdateData = {
    ...updatedFields,
    meta_data
  };

  const result = await wcApi.updateItem(orderId, item.id, item.product_id, wcUpdateData);

  if (result.status === 200) {
    return result.data;
  }

  return null;
}

export async function updateLineItemBeforePacking(
  orderId,
  item,
  product,
  newQty,
  newUnit,
  newPrice,
  pricingMethod,
  newStatus = null,
  newProductPrice = null
) {
  let meta_data = [];

  // Clear P&P Metadata
  const keys = [
    "SHOOKIT_SEEN",
    "shookit_shipped_quantity",
    "shookit_estimated_weight",
    "shookit_shipped_weight"
  ];
  meta_data = keys.map(keyName => ({ key: keyName, value: null }));

  // Set MetaData
  if (pricingMethod === conf.FIXED_PRICING_METHOD) {
    console.log(">>>> .... fixed pricing method");
    meta_data.push({ key: "Quantity", value: "" + newQty });
  } else {
    console.log(`New unit :   ${newUnit} <<<<<<<<<`);
    meta_data.push({ key: "Quantity", value: "" + newQty });
    meta_data.push({ key: "Type", value: "" + newUnit });
  }
  meta_data.push({ key: "shookit_requested_quantity", value: "" + newQty });
  meta_data.push({ key: "shookit_requested_unit", value: "" + newUnit });
  meta_data.push({ key: "shookit_pricing_method", value: "" + pricingMethod });

  if (newProductPrice) {
    meta_data.push({
      key: "shookit_base_price_override",
      value: newProductPrice
    });
  }

  // Set New Price
  let payload = {
    product_id: "" + item.product_id,
    quantity: parseFloat(newQty),
    total: "" + newPrice,
    subtotal: "" + newPrice,
    meta_data
  };

  await wcApi.updateItem(orderId, item.id, item.product_id, payload, newStatus);

  await wcApi.reapplyCoupons({ orderId });
  return await wcApi.fetchOrder(orderId);
}

export async function updateLineItemAfterPacking({
  orderId,
  item,
  product,
  newPrice,
  newProductPrice = null
}) {
  const payload = {
    product_id: "" + item.product_id,
    total: "" + newPrice,
    subtotal: "" + newPrice,
    meta_data: []
  };
  if (newProductPrice) {
    payload.meta_data = [
      { key: "shookit_base_price_override", value: newProductPrice }
    ];
  }

  await wcApi.updateItem(orderId, item.id, item.product_id, payload);

  await wcApi.reapplyCoupons({ orderId });
  return await wcApi.fetchOrder(orderId);
}

/*
 * Sort orders by start time slot
 */
export function sortOrders(orders) {
  return orders.sort((a, b) => {
    const ts1 = parseInt(a[conf.WC_ORDER_TS_START].split(":")[0]);
    const ts2 = parseInt(b[conf.WC_ORDER_TS_START].split(":")[0]);
    if (ts1 > ts2) {
      return 1;
    }
    if (ts1 < ts2) {
      return -1;
    }
    return 0;
  });
}

export function shookitRound(num) {
  let floorNum = Math.floor(num);
  let numToReturn = floorNum
  let minDistance = Math.abs(floorNum - num)
  let compatableNums = [floorNum + 0.5,  Math.ceil(num)];

  compatableNums.forEach(targetToCheck => {
    if (Math.abs(targetToCheck - num) < minDistance) {
      minDistance = Math.abs(targetToCheck - num);
      numToReturn = targetToCheck
    }
  })
  return numToReturn;
}  