import React from "react";

import { useWooUser } from "../../../utils/authentication/WooUserContext";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import TopBar from "../PickAndPack/TopBar";
import { Typography } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  paper: { paddingTop: 130, paddingBottom: 80, textAlign: "center" }
}));

export default function Sitelist() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <TopBar title="Site List" />

      <Paper square className={classes.paper}>
        <Typography variant="h5">Welcome Shookiter</Typography>
        <p>Choose a site from the sidebar!</p>
      </Paper>
    </React.Fragment>
  );
}
