import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import _ from "lodash";
import {VAT} from '../../../../config'

import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";

import * as wcApi from "../../../../services/wcApi";
import * as calcApi from "../../../../services/priceCalculatorApi";
import * as shookitUtils from "../../../../utils/shookit";
import {
  AVAILABLE_UNITS,
  FIXED_PRICING_METHOD,
  WEIGHT_PRICING_METHOD
} from "../../../../config";

import AddItemForm from "../AddItemForm";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    height: "25vh",
    width: "50vw"
  },
  bigDialogContent: {
    height: "40vh",
    width: "50vw"
  },
  menuOption: {
    fontSize: 12
  },
  selectField: {
    width: "100%"
  }
}));

const AddItemFormDialog = ({ open, onClose, orderId, orderDetails }) => {
  const styles = useStyles();
  const initialItem = {
    id: _.uniqueId(),
    product: null,
    units: AVAILABLE_UNITS.KG,
    quantity: "",
    basePrice: "",
    isTypeFieldEnabled: false
  };
  const [addItemError, setAddItemError] = useState(null);
  const [itemsToAdd, setItemsToAdd] = useState([initialItem]);

  const cancelClicked = e => {
    if (onClose) {
      onClose(null);
    }
    clearForm();
  };

  const clearForm = () => {
    setItemsToAdd([initialItem]);
  };

  const getItemTotal = async (newItemData) => {
    if (newItemData.isTypeFieldEnabled) {
      return await calcApi.calculatePrice(
        newItemData.product.id,
        newItemData.quantity,
        newItemData.units === AVAILABLE_UNITS.KG
          ? FIXED_PRICING_METHOD
          : WEIGHT_PRICING_METHOD,
        newItemData.basePrice
      );
    } else {
      return parseFloat(newItemData.quantity) * parseFloat(newItemData.basePrice);
    }
  };

  const checkAllItems = () => {
    for (let i = 0; i < itemsToAdd.length; i++) {
      if (itemsToAdd[i].product === null ) {
        if (itemsToAdd[i].quantity > 0) {
          setAddItemError({message: "No item selected"});
          return false;
        }
      } else {
        if (itemsToAdd[i].quantity === 0 || itemsToAdd[i].quantity === "") {
          setAddItemError({message: "Quantity must be more than 0"});
          return false;
        }

        if (itemsToAdd[i].basePrice === "") {
          setAddItemError({message: "There is no price for the item"});
          return false;
        }
      }
    }
    return true;
  };

  const updateItem = (data) => {
    let clonedItems = _.cloneDeep(itemsToAdd);
    let itemExists = false;
    let shouldAdd = true;
    for (let i = 0; i < clonedItems.length; i++) {
      if (clonedItems[i].id === data.id) {
        clonedItems[i] = data;
        itemExists = true;
      }
      if (clonedItems[i].product === null) {
        shouldAdd = false;
      }
    }

    if (!itemExists) {
      clonedItems.push(data);
    }

    if (shouldAdd) {
      let newItem = initialItem;
      newItem.id = _.uniqueId();
      clonedItems.push(newItem);
    }

    setItemsToAdd(clonedItems);
  };

  const deleteItem = (itemID) => {
    let clonedItems = _.cloneDeep(itemsToAdd);

    for(let i = 0; i < clonedItems.length; i++) {
      if (clonedItems[i].id === itemID) {
        clonedItems.splice(i, 1);
        break;
      }
    }

    setItemsToAdd(clonedItems);
  };

  const addItemsClicked = async () => {

    if (!checkAllItems()) {
      return;
    }
    let newLineItems = [];
    for (let i = 0; i < itemsToAdd.length; i++) {
      let newItemData = itemsToAdd[i];
      if (newItemData.product === null) {
        continue;
      }

      let meta_data = [
        { key: "shookit_requested_quantity", value: newItemData.quantity },
        { key: "shookit_requested_unit", value: newItemData.units },
        { key: "shookit_base_price_override", value: newItemData.basePrice }
      ];

      let pricingMethod;
      if (newItemData.isTypeFieldEnabled) {
        meta_data.push(
          { key: "Quantity", value: newItemData.quantity },
          { key: "Type", value: newItemData.units }
        );
        if (newItemData.units === AVAILABLE_UNITS.KG) {
          pricingMethod = FIXED_PRICING_METHOD;
        } else {
          pricingMethod = WEIGHT_PRICING_METHOD;
        }
      } else {
        pricingMethod = FIXED_PRICING_METHOD;
      }

      meta_data.push({ key: "shookit_pricing_method", value: pricingMethod });
      let price = (await getItemTotal(newItemData)).toString();
      if (newItemData.taxStatus == 'taxable') {
        price = (price / VAT).toString();
        let basePriceOverride = price/newItemData.quantity;
        meta_data.forEach(data => {
          if (data.key == "shookit_base_price_override") {
            data.value = basePriceOverride;
          }
        })
      }
      newLineItems.push({
        product_id: newItemData.product.id,
        quantity: newItemData.quantity,
        total: price,
        subtotal: price,
        meta_data
      });
    }

    let payload = {
      line_items: newLineItems
    };

    // Update status if required
    if (shookitUtils.getOrderStatus(orderDetails) !== "PENDING") {
      payload["meta_data"] = [{ key: "SHOOKIT_STATUS", value: "FULFILLING" }];
    }

    try {
      let result = await wcApi.updateOrder(orderId, payload);
      if (onClose) {
        clearForm();
        await wcApi.reapplyCoupons({ orderId });
        onClose(result.data);
      }
    } catch (err) {
      setAddItemError(err.response.data);
    }
  };

  const handleModalClose = () => {
    setAddItemError(null);
  };

  useEffect(() => {
    if (itemsToAdd.length > 3) {
      console.log(styles)
    }
  },[itemsToAdd.length]);

  return (
    <Dialog
      open={open}
      onClose={cancelClicked}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      PaperProps={{ elevation: 24 }}
    >
      <Dialog open={addItemError != null} onClose={handleModalClose}>
        <DialogTitle>Failed to add item</DialogTitle>
        <DialogContent>
          Failed to add new item due to an error:{" "}
          <i>{addItemError != null ? addItemError.message : ""}</i>
        </DialogContent>
      </Dialog>
      <DialogTitle id="form-dialog-title">Add New Item</DialogTitle>
      <DialogContent dividers className={itemsToAdd.length < 4 ? styles.dialogContent : styles.bigDialogContent}>
        <Grid container direction="column" justify="space-evenly" spacing={1}>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              Product
            </Grid>
            <Grid item xs={1}>
              Type
            </Grid>
            <Grid item xs={2}>
              Quantity
            </Grid>
            <Grid item xs={2}>
              Base Price
            </Grid>
            <Grid item xs={1}>
            </Grid>
          </Grid>
          {
            itemsToAdd.map(item => {
              return (
                <AddItemForm
                  key={item.id}
                  itemData={item}
                  customerID={orderDetails.customer_id}
                  updateItem={updateItem}
                  deleteItem={deleteItem}
                />
              )
            })
          }

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={cancelClicked}>
          Cancel
        </Button>
        <Button color="primary" onClick={addItemsClicked}>
          {itemsToAdd.length <= 2 ? "Add Item" : `Add ${itemsToAdd.length - 1} Items`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddItemFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancelClicked: PropTypes.func
  // orderId: PropTypes.string.isRequired
};

export default AddItemFormDialog;
