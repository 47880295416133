import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { keyBy } from "lodash";
import { Link, useParams } from "react-router-dom";

import {
  makeStyles,
  Drawer,
  Divider,
  List,
  ListItem,
  ListSubheader,
  ListItemText
} from "@material-ui/core";

import { SiteList } from "./SiteList";
import * as config from "../../../config";
import { useAuth, useWooUser } from "../../../utils/authentication";
import { MobileSidebarContext } from "./SidebarContext";
import {getCurrentLanguage, setLanguage, LANGUAGES} from "../../../utils/locale";
import * as geoApi from "../../../services/geoApi";

const useStyles = makeStyles({
  noSelect: {
    userSelect: "none",
    WebkitUserSelect: " none"
  },
  list: {
    width: 250,
    userSelect: "none",
    WebkitUserSelect: " none"
  },
  fullList: {
    width: "auto"
  }
});

export default function MobileSidebar({ children }) {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const auth = useAuth();
  const wooUser = useWooUser();

  const [open, setOpen] = React.useState(false);
  const [geoSites, setGeoSites] = React.useState({});

  const currentSiteId = params.siteId;

  // Download Site mapping from ID to name
  async function fetchData() {
    const result = await geoApi.fetchAllSites();
    setGeoSites(keyBy(result, "site-id"));
  }

  // Load Sites List
  useEffect(() => {
    fetchData();
  }, []);

  function logout() {
    auth.setAuthenticated(false);
    auth.setAuthBody(null);
  }

  function getOtherLanguage() {
    const currentLanguage = getCurrentLanguage();

    return Object.keys(LANGUAGES).filter(language=>language!=currentLanguage)[0];

  }

  function changeLanguage() {
    setLanguage(getOtherLanguage())
  }

  function goToQ() {
    history.push("/customers");
  }

  const list = (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        <ListItem>Logged in as:</ListItem>
        <ListItem>
          <b>{`${wooUser.first_name} ${wooUser.last_name}`}</b> &nbsp;
          <small>({wooUser.role})</small>
        </ListItem>
        {wooUser.role !== "shookiter" ? (
          <ListItem button onClick={goToQ}>
            Go Back to Q
          </ListItem>
        ) : (
          <></>
        )}
        <ListItem button onClick={logout}>
          Logout
        </ListItem>
      </List>

      <Divider />
      <SiteList siteMapping={geoSites} />

      <Divider />
      <List>
        <ListSubheader>Reports</ListSubheader>
        <ListItem
          button
          component={Link}
          to={`/pickandpack/${currentSiteId}/reports/daily`}
        >
          <ListItemText primary="Daily Product Summary" />
        </ListItem>
        {/* <ListItem button>
          <ListItemText primary="Aggregated total orders" />
        </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={changeLanguage}>
          <ListItemText primary={ getOtherLanguage() === "ar" ? "عربى":"Change to Hebrew"} />
        </ListItem>
      </List>
    </div>
  );

  const context = {
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    }
  };

  return (
    <MobileSidebarContext.Provider value={context}>
      <Drawer open={open} onClose={() => setOpen(false)}>
        {list}
      </Drawer>
      <div className={classes.noSelect}>{children}</div>
    </MobileSidebarContext.Provider>
  );
}
