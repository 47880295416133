import React from "react";
import { useHistory } from "react-router-dom";
import * as wcApi from "../../../services/wcApi";
import * as woocommerce from "../../../utils/woocommerce";
import * as shookit from "../../../utils/shookit";
import * as twilioApi from "../../../services/twilioApi";
import OrderList from "./OrderList";
import { find } from "lodash";

const FILTER_READY = 1;

export function ReadyPage({ siteId, orders, onOrderUpdate }) {
  const history = useHistory();
  if (!history.location.state || history.location.state.filter !== FILTER_READY) {
    history.push({pathname: history.location.pathname, state: {filter: FILTER_READY}});
  }
  
  function onClick(orderId) {
    history.push(`/pickandpack/${siteId}/orders/${orderId}`);
  }

  function handleSelected(selectedOrders) {
    selectedOrders.forEach(async orderId => {
      // Update status
      await shookit.updateShookiterStatus(orderId, "ON_ITS_WAY");
      await wcApi.sendPickupSMS({ orderId });

      // Update view
      onOrderUpdate();
    });
  }

  return (
    <React.Fragment>
      <OrderList
        orders={orders}
        // editMode={editMode}
        onPress={onClick}
        enableSelection={true}
        onSelected={handleSelected}
        buttonTitle="On It's Way"
      />
    </React.Fragment>
  );
}
