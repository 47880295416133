import React from "react";
import OrderList from "./OrderList";
import * as wcApi from "../../../services/wcApi";
import * as woocommerce from "../../../utils/woocommerce";
import { useHistory } from "react-router-dom";
import * as shookit from "../../../utils/shookit";
import * as twilioApi from "../../../services/twilioApi";
import { find } from "lodash";

const FILTER_DONE = 2;

export function OnItsWayPage({ siteId, orders, onOrderUpdate }) {
  const history = useHistory();
  if (!history.location.state || history.location.state.filter !== FILTER_DONE) {
    history.push({pathname: history.location.pathname, state: {filter: FILTER_DONE}});
  }

  function onClick(orderId) {
    history.push(`/pickandpack/${siteId}/orders/${orderId}/user`);
  }
  
  function handleSelected(selectedOrders) {
    selectedOrders.forEach(async orderId => {
      await shookit.updateShookiterStatus(orderId, "ARRIVED");
      
      // Find order in orders
      const order = find(orders, { id: orderId });

      // Fetch Customer
      const customer = await wcApi.fetchCustomerDetail(order.customer_id);
      const metadata = woocommerce.getMetadata(customer.meta_data);

      // Send SMS (no 'await' to ignore errors)
      if (metadata['subscribe_arrived_sms'] !== "0") {
        twilioApi.sendOrderArrived(order.id, order.billing.phone, "il", metadata.zendesk_id || 0);
      }

      wcApi.sendInvoice(orderId);
      onOrderUpdate();
    });
  }

  return (
    <React.Fragment>
      <OrderList
        orders={orders}
        // editMode={editMode}
        onPress={onClick}
        enableSelection={false}
        limitToSingleSelection={true}
        onSelected={handleSelected}
        buttonTitle="Arrived"
      />
    </React.Fragment>
  );
}
