import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { keyBy } from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getDaysInMonth, getMonth, getYear } from "date-fns";
import { makeStyles } from "@material-ui/core";

import * as wcApi from "../../../../services/wcApi";
import * as geoApi from "../../../../services/geoApi";
import * as config from "../../../../config";
import { formatAsDay } from "../../../../utils/shookit"
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  buttonPadding: {
    padding: "5px 16px",
    "&:hover": { padding: "5px 16px" }
  }
}));

export default function EditDeliveryDialog({
  onClose,
  open,
  role,
  currentSite,
  currentDay,
  currentStart,
  currentEnd
}) {
  const classes = useStyles();
  const history = useHistory();

  const maxDatesToShow = 7;
  const [availableTimeslots, setavailableTimeslots] = useState([]);
  const [selectedWindow, setSelectedWindow] = useState(null);
  const [selectedTs, setSelectedTs] = useState([null, null]);

  const [selectedSite, setSelectedSite] = useState(currentSite || null);

  const [isLoading, setIsLoading] = useState(true);
  // const [geoLocation, setGeoLocation] = useState(null);
  const [geoError, setGeoError] = useState(null);
  const [allSites, setAllSites] = useState(null);

  const getShookitSite = async () => {
    try {      
      let timeSlots = await geoApi.fetchShookitUpcomingTimeslots(selectedSite, role);
      setavailableTimeslots(timeSlots.data.windows);
    } catch (err) {
      console.error(err);
      setGeoError({ message: "Failed to load site information" });
    }
  };

  useEffect(() => {
    getShookitSite()
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  }, [selectedSite]);

  // Download list of all sites
  async function fetchAllSites() {
    const result = await geoApi.fetchAllSites();
    setAllSites(result);
  }

  // Load Sites List
  useEffect(() => {
    fetchAllSites();
  }, []);

  const onCancelClicked = e => {
    if (onClose) {
      onClose(null);
    }
  };

  const selectWindowSlot = timeslotId => {
    setSelectedWindow(timeslotId);
  };

  function findSelectedTimeslots(timeslots) {
    if (currentDay === null) {
      return;
    }

    const day = new Date(currentDay);

    timeslots.forEach((window, index) => {
      if (
        window.date.day === day.getDate() &&
        window.date.month === day.getMonth() + 1 &&
        window.date.year === getYear(day)
      ) {
        setSelectedWindow(index);

        // Find timeslot
        window.timeslots.forEach(([start, end], index) => {
          if (start === currentStart) {
            setSelectedTs([start, end]);
          }
        });
      }
    });
  }

  const getShookitTimeslotDate = () => {
    let ts = availableTimeslots.find(ts => ts.id === selectedWindow);
    let date = new Date(ts.date.year, ts.date.month - 1, ts.date.day);
    date.setHours(0, 0, 0, 0);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
    return date.toISOString();
  };

  async function onSaveClicked() {
    let leaveAtDoor = (selectedTs.length > 2 && selectedTs[3].isNightWindow) ? 1 : 0 ;
    onClose({
      site: selectedSite,
      day: getShookitTimeslotDate(),
      start: selectedTs[0],
      end: selectedTs[1],
      leaveAtDoor
    });
  }

  const selectTs = ts => {
    console.log(ts);
    setSelectedTs(ts);
  };

  if (isLoading || null === allSites) {
    return (
      <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
        <DialogTitle>Update delivery</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (geoError) {
    return (
      <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
        <DialogTitle>Can not create new</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Due to an errror: <i>{geoError.message}</i>
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onCancelClicked} maxWidth="lg">
      <DialogTitle>Update delivery</DialogTitle>
      <DialogContent dividers>
        <Typography varian="h4">Site</Typography>
        <Grid container direction="row">
          {allSites.map(site => (
            <Grid item key={site["site-id"]}>
              <Button
                variant={selectedSite === site["site-id"] ? "outlined" : "text"}
                className={classes.buttonPadding}
                color={selectedSite === site["site-id"] ? "primary" : "default"}
                disabled={!site.isactive}
                onClick={() => setSelectedSite(site["site-id"])}>
                {site.name}
              </Button>
            </Grid>
          ))}
        </Grid>
        <br/>
        <Typography varian="h4">Select Day</Typography>
        <Grid container direction="row">
          {availableTimeslots.slice(0, maxDatesToShow).map(timeslotWindow => (
            <Grid item key={timeslotWindow.id}>
              <Button
                variant={selectedWindow === timeslotWindow.id ? "outlined" : "text"}
                className={classes.buttonPadding}
                color={selectedWindow === timeslotWindow.id ? "primary" : "default"}
                onClick={() => selectWindowSlot(timeslotWindow.id)}>
                {formatAsDay(new Date(timeslotWindow.date.year, timeslotWindow.date.month - 1, timeslotWindow.date.day))}
              </Button>
            </Grid>
          ))}
        </Grid>
        <br/>
        <Typography varian="h4">Select Time slot</Typography>
        <Grid container direction="column">
          {selectedWindow != null && availableTimeslots.find(ts => ts.id === selectedWindow) !== undefined ? (
            availableTimeslots
              .find(ts => ts.id === selectedWindow)
              .timeslots.map((ts, index) => (
                <Grid item key={index}>
                  <Button
                    className={classes.buttonPadding}
                    variant={selectedTs[0] === ts[0] && selectedTs[1] === ts[1] ? "outlined" : "text"}
                    color={selectedTs[0] === ts[0] && selectedTs[1] === ts[1] ? "primary" : "default"}
                    onClick={() => selectTs(ts)}>
                    {ts[0]} - {ts[1]}
                  </Button>
                </Grid>
              ))
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={selectedTs[0] == null || selectedTs[1] == null}
          onClick={onSaveClicked}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
