import React, {useState} from 'react'

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

import * as wcApi from "../../../../services/wcApi";

const useStyles = makeStyles(theme => ({
  emptyOrders: {
    textAlign: "center",
    padding: theme.spacing(5)
  },
  paper: {
    padding: theme.spacing(2)
  },
  btnGroup: {
    marginRight: theme.spacing(2)
  },
  danger: {
    backgroundColor: 'rgba(227, 118, 86)',
  }
}));

export default function MarkAsInvoicedDialog ({showModal, onClose, selectedOrders, fetchOrders}) {
  const classes = useStyles();
  const [isCreatingReceipt, setIsCreatingReceipt] = useState(false);
  const [markAsInvociedError, setmarkAsInvociedError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [documentId, setDocumentId] = useState(null);
  const [invalidDocumentId, setinvalidDocumentId] = useState(false)

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  const markAsInvoiced = async () => {
    setIsCreatingReceipt(true);
    setinvalidDocumentId(false);
    if (!/^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/.test(documentId)) {
      setinvalidDocumentId(true);
      setIsCreatingReceipt(false);
      return;
    }

    try {
      await wcApi.markAsInvoicedManually(selectedOrders, documentId, selectedDate.getTime());
      fetchOrders();
    }
    catch (err) {
      setmarkAsInvociedError(err.message || err);
    }
    finally {
      setIsCreatingReceipt(false);
      setSelectedDate(new Date());
      onClose()
    }
  };

  const handleDocumentIdChange = (e) => {
    setDocumentId(e.target.value);
  }

  const resetForm = () => {
    setSelectedDate(new Date());
    onClose();
  }

  return (
    <>
    <Dialog fullWidth open={markAsInvociedError != null} onClose={() => resetForm()}>
    <DialogTitle>Failed to create a Receipt due to an error</DialogTitle>
    <DialogContent>
      <i>{markAsInvociedError}</i>
    </DialogContent>
    </Dialog>
    <Dialog fullWidth open={showModal} onClose={() => onClose()}>
    <DialogTitle>Create a Receipt for {selectedOrders.length} Orders!</DialogTitle>
    <DialogContent>
      <Grid container direction="column">
        <Grid item>
          <TextField
            required
            error={invalidDocumentId}
            type="text"
            helperText="Document ID from GreenInvocie - NOT THE NUMBER!"
            value={documentId}
            onChange={handleDocumentIdChange}
          />
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Payment Due Date"
            helperText="When should the customer pay?"
            value={selectedDate}
            onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>        
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={() => resetForm()}>
        Cancel
      </Button>
      <Button
        disabled={isCreatingReceipt}
        className={classes.danger}
        color="primary"
        type="submit"                
        onClick={markAsInvoiced}>
        Mark as Invocied!
      </Button>
    </DialogActions>
  </Dialog>
  </>
  )
}