import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, isEqual } from "lodash";
import { Grid, Container, Paper, TextField } from "@material-ui/core";
import { searchShookiters, updateCustomer } from "../../../services/wcApi";
import { fetchAllSites } from "../../../services/geoApi";
import PaperTitle from "../../UI/PaperTitle";
import UsersTable from "./UsersTable";
import EditSiteDialog from "./EditSiteDialog";
import useDebounce from "./use-debounce";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  title: {
    flex: "1"
  },
  titleBar: {
    display: "flex",
    alignItems: "center"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const fetchUsers = async ({ searchValue, sites }) => {
  const siteIds = searchValue
    ? sites
        .filter(site =>
          site.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map(site => site["site-id"])
    : [];

  let users = await searchShookiters({ searchValue, sites: siteIds });
  return mapUsers({ users, sites });
};

const mapUsers = ({ users, sites }) => {
  const idSite = sites.reduce((obj, site) => {
    obj[site["site-id"]] = site;
    return obj;
  }, {});

  return users.map(user => {
    return {
      id: user.id,
      email: user.email,
      username: user.username,
      role: user.role,
      region:
        user.sites && user.sites.length && idSite[user.sites[0]] ? idSite[user.sites[0]].region : null,
      sites: user.sites
    };
  });
};

export default () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  const [query, setQuery] = useState();
  const [isSearching, setIsSearching] = useState(false);

  const [editedUser, setEditedUser] = useState({});
  const debouncedQuery = useDebounce(query, 250);

  const isDialogOpen = !isEmpty(editedUser);
  const usernameToUser = users.reduce((obj, user) => {
    obj[user.username] = user;
    return obj;
  }, {});

  useEffect(() => {
    const fetchData = async () => {
      const rawSites = await fetchAllSites();
      setSites(rawSites);
      const rawUsers = await fetchUsers({
        sites: rawSites
      });
      setUsers(rawUsers);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery !== undefined) {
        setIsSearching(true);
        const rawUsers = await fetchUsers({
          sites,
          searchValue: debouncedQuery
        });
        setUsers(rawUsers);
        setIsSearching(false);
      }
    };
    fetchData();
  }, [debouncedQuery]);

  const editUser = username => {
    setEditedUser(usernameToUser[username]);
  };

  const closeDialog = () => {
    setEditedUser({});
  };

  const saveUser = async (user, newSites) => {
    if (!isEqual(user.sites, newSites)) {
      await updateCustomer(user.id, {
        meta_data: [
          {
            key: "managed_sites",
            value: newSites.join(",")
          }
        ]
      });
      const rawUsers = await fetchUsers({
        sites
      });
      setUsers(rawUsers);
      closeDialog();
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <EditSiteDialog
              open={isDialogOpen}
              user={editedUser}
              closeDialog={closeDialog}
              sites={sites}
              onSave={newSites => {
                saveUser(editedUser, newSites);
              }}
            />
            <div className={classes.titleBar}>
              <div className={classes.title}>
                <PaperTitle>Shookiter & Driver Site Assignment</PaperTitle>
              </div>
              <TextField
                label="Search user or site"
                value={query}
                onChange={event => {
                  setQuery(event.target.value);
                }}
                className={classes.textField}
                margin="normal"
              />
            </div>
            <UsersTable users={users} editUser={editUser} sites={sites} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
