import React, { useState, useRef, useEffect } from "react";
import { useClickOutside } from "react-click-outside-hook";
import { FIXED_PRICING_METHOD } from "../../../config";
import Table from "@material-ui/core/Table";
import PhotoIcon from "@material-ui/icons/Photo";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InteractiveTableRow from "../../UI/InteractiveTableRow";
import PropTypes from "prop-types";
import { extractQuantityForOrderItem } from "../../../utils/woocommerce";
import { makeStyles } from "@material-ui/styles";
import Label, { LabelGroup } from "../../UI/Label";

import EditLineItemForm from "./EditLineItemForm";

import * as woocommerce from "../../../utils/woocommerce";
import { Box } from "@material-ui/core";

const AVAILABLE_UNITS = ["KG", "Unit"];

const useStyles = makeStyles(theme => ({
  missing: {
    color: "red"
  },
  table: {
    "& td": {
      textAlign: "center"
    },
    "& th": {
      textAlign: "center"
    }
  },
  oldPrice: {
    textDecoration: "line-through"
  },
  productImage: {
    maxHeight: "80px",
    width: "auto",
    height: "auto"
  }
}));

function OrderProductsTable({
  order,
  products,
  onItemEdited,
  onItemDeleted,
  updateInProgress,
  isEditAllowed,
  isPriceEditAllowed
}) {
  const classes = useStyles();
  // const outsideRef = useRef(null);
  const [ref, hasClickedOutside] = useClickOutside();

  const [clickedRowIndex, setClickedRowIndex] = useState(-1);
  const [editMode, setEditMode] = useState(false);
  const [editableFields, setEditableFields] = useState({
    quanitity: "0",
    unit: AVAILABLE_UNITS[0]
  });

  const currencySymbol = order.currency_symbol || "₪";

  const onRowClicked = rowKey => {
    if (!isEditAllowed && !isPriceEditAllowed) {
      return;
    }

    setClickedRowIndex(rowKey);

    if (rowKey !== clickedRowIndex && editMode) {
      setEditMode(false);
    }
  };

  const onRowEditClicked = rowKey => {
    if (!editMode) {
      const editingItem = order.line_items[rowKey];
      const { requestedQuantity, requestedUnit } = extractQuantityForOrderItem(
        editingItem
      );

      setEditableFields({ requestedQuantity, requestedUnit });
      setEditMode(true);
    }
  };

  const onRowEditDiscardClicked = rowKey => {
    if (editMode) {
      setEditMode(false);
    }
  };

  const onRowDeleteClicked = rowKey => {
    onItemDeleted(rowKey);

    if (editMode) {
      setEditMode(false);
    }
  };

  async function onRowSaveClicked(wcItem, newValues) {
    await onItemEdited(wcItem, newValues);

    if (editMode) {
      setEditMode(false);
    }
  }

  const onRowChange = (rowKey, newValue) => {
    // console.log(newValue);
    setEditableFields(newValue);
  };

  const getPackedQuantityCell = item => {
    const {
      pricingMethod,
      shippedWeight,
      shippedQuantity
    } = extractQuantityForOrderItem(item);
    if (shippedQuantity === undefined && shippedWeight === undefined) {
      return <TableCell>----</TableCell>;
    } else if (parseFloat(item.quanitity) === 0) {
      return <TableCell className={classes.missing}>Missing</TableCell>;
    } else if (pricingMethod === FIXED_PRICING_METHOD) {
      return (
        <TableCell>
          <Label
            name={woocommerce.formatUnitForItem(item)}
            value={item.quantity}
          />
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <LabelGroup>
            <Label
              name={woocommerce.formatUnitForItem(item)}
              value={shippedQuantity}
            />
            <Label name={"KG"} value={shippedWeight} />
          </LabelGroup>
        </TableCell>
      );
    }
  };

  useEffect(() => {
    setClickedRowIndex(-1);
    setEditMode(false);
    return () => {};
  }, [hasClickedOutside]);

  // Products purchased with by KG, we want to know the original price
  function estimateUnitPrice(item, product) {
    let price = parseFloat(item.subtotal + item.subtotal_tax) / item.quantity;
    return `${price.toFixed(2)}${currencySymbol}`;
  }

  return (
    <div>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Picture</TableCell>
            <TableCell>SKU#</TableCell>
            <TableCell>Item Name</TableCell>
            <TableCell>Required Qty</TableCell>
            <TableCell>Picking Qty</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>VAT</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Refund</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody ref={ref}>
          {order.line_items.map((wcItem, i) => {
            const item = woocommerce.flattenMetadata(wcItem);
            const discount = ((parseFloat(item.subtotal) + parseFloat(item.subtotal_tax)) - (parseFloat(item.total) + parseFloat(item.total_tax))).toFixed(2);
            const {
              pricingMethod,
              requestedQuantity,
              requestedUnit,
              shippedQuantity,
              shippedWeight
            } = woocommerce.extractQuantityForOrderItem(item);
            const product = products[item.product_id];

            const packedQuantity = shippedWeight
              ? shippedWeight
              : shippedQuantity;

            const refund = parseFloat(
              item.refunds.reduce(
                (totalRefund, refundLine) =>
                  parseFloat(refundLine.total) + totalRefund,
                0
              )
            );
            const vatRefund = parseFloat(
              item.refunds.reduce(
                (totalTaxRefund, refundLine) =>
                  parseFloat(refundLine.total_tax) + totalTaxRefund,
                0
              )
            );
            const quantityRefund = parseFloat(
              item.refunds.reduce(
                (totalQuantityRefund, refundLine) =>
                  parseFloat(refundLine.quantity) + totalQuantityRefund,
                0
              )
            );
            const currentVat = parseFloat(item.total_tax) + vatRefund;
            const productImgUrl =
              product && product.images.length > 0
                ? product.images[0].src
                : null;

            return (
              <React.Fragment key={i}>
                <InteractiveTableRow
                  rowIndex={i}
                  highlight={clickedRowIndex === i && !editMode}
                  onClickRow={onRowClicked}
                  onClickEdit={onRowEditClicked}
                  onClickDelete={onRowDeleteClicked}
                  onClickDiscardEdit={onRowEditDiscardClicked}
                  isLoading={updateInProgress}
                  isDeleteAllowed={isEditAllowed}
                >
                  <TableCell>
                    {productImgUrl ? (
                      <img
                        width="80px"
                        src={productImgUrl}
                        className={classes.productImage}
                      />
                    ) : (
                      <MissingPhoto />
                    )}
                  </TableCell>
                  <TableCell>{item.sku}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  {/* Request Quantity */}
                  <TableCell>
                    <Label
                      name={woocommerce.formatUnitForItem(item)}
                      value={requestedQuantity}
                    />
                  </TableCell>

                  {/* Packed Quantity */}
                  {getPackedQuantityCell(item)}

                  {/* Unit Price */}
                  <TableCell>
                    {pricingMethod === "FIXED" ? (
                      <Label
                        name={`/${requestedUnit}`}
                        value={estimateUnitPrice(item, product)}
                      />
                    ) : (
                      <LabelGroup>
                        <Label
                          name={`/${requestedUnit}`}
                          value={estimateUnitPrice(item, product)}
                        />
                        {item.shookit_base_price_override ? (
                          <Label
                            name={`/KG`}
                            value={`${item.shookit_base_price_override}${currencySymbol}`}
                          />
                        ) : (
                          <Label
                            name={`/KG`}
                            value={`${product &&
                              product.price}${currencySymbol}`}
                          />
                        )}
                      </LabelGroup>
                    )}
                  </TableCell>

                  {/*  Discount */}
                  <TableCell>
                    {discount}
                    {currencySymbol}
                  </TableCell>

                  {/* VAT */}
                  <TableCell>
                    {vatRefund ? (
                      <>
                        <Box className={classes.oldPrice}>
                          {item.total_tax}
                          {currencySymbol}
                        </Box>
                        <Box>
                          {currentVat.toFixed(2)}
                          {currencySymbol}
                        </Box>
                      </>
                    ) : (
                      <Box>
                        {item.total_tax}
                        {currencySymbol}
                      </Box>
                    )}
                  </TableCell>

                  {/* Total (With Vat and Discounts) */}
                  <TableCell>
                    {refund || discount > 0 ? (
                      <>
                        <div className={classes.oldPrice}>
                          {(
                            parseFloat(item.subtotal) +
                            parseFloat(item.subtotal_tax) 
                          ).toFixed(2)}
                          {currencySymbol}
                        </div>
                        <div>
                          {(
                            parseFloat(item.total) +
                            parseFloat(currentVat) +
                            parseFloat(refund)
                          ).toFixed(2)}
                          {currencySymbol}
                        </div>
                      </>
                    ) : (
                      `${(
                        parseFloat(item.total) + parseFloat(item.total_tax)
                      ).toFixed(2)} ${currencySymbol}`
                    )}
                  </TableCell>

                  {/*  Refund */}
                  <TableCell>
                    {quantityRefund === 0 ? (
                      <>
                        {(refund + vatRefund).toFixed(2)}
                        {currencySymbol}
                      </>
                    ) : (
                      <LabelGroup>
                        <Label
                          name={currencySymbol}
                          value={(refund + vatRefund).toFixed(2)}
                        />
                        <Label name={requestedUnit} value={quantityRefund} />
                      </LabelGroup>
                    )}
                  </TableCell>
                </InteractiveTableRow>

                {/* Edit Mode */}
                {clickedRowIndex === i && editMode && product && (
                  <EditLineItemForm
                    item={item}
                    initialQuantity={
                      isEditAllowed ? requestedQuantity : packedQuantity
                    }
                    initialUnit={requestedUnit}
                    initialPrice={item.total}
                    initialPricingMethod={pricingMethod}
                    currencySymbol={currencySymbol}
                    product={product}
                    onCancel={() => {
                      setClickedRowIndex(-1);
                      setEditMode(false);
                    }}
                    onSave={newValues => onRowSaveClicked(wcItem, newValues)}
                    canEditQuantity={isEditAllowed}
                  />
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

OrderProductsTable.propTypes = {
  order: PropTypes.object.isRequired,
  // currencySymbol: PropTypes.string.isRequired,
  onItemEdited: PropTypes.func,
  onItemDeleted: PropTypes.func,
  products: PropTypes.object.isRequired,
  updateInProgress: PropTypes.bool
};

export default OrderProductsTable;

// // Discard edit mode and unhighlight the active row when clicked outside of the table.
// const handleClickOutside = event => {
//   if (outsideRef.current && !outsideRef.current.contains(event.target)) {
//     setEditMode(false);
//     setClickedRowIndex(-1);
//   }
// };

// // useEffect(() => {
// //     // Bind the event listener
// //     document.addEventListener("mousedown", handleClickOutside);
// //     return () => {
// //         // Unbind the event listener on clean up
// //         document.removeEventListener("mousedown", handleClickOutside);
// //     };
// // });

function MissingPhoto({}) {
  return (
    <div
      style={{
        width: "80px",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <PhotoIcon fontSize="large" />
    </div>
  );
}
