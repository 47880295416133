import React from "react";
import { useHistory } from "react-router-dom";
import OrderList from "./OrderList";

const FILTER_READY = 1;

export function DonePage({ siteId, orders, onOrderUpdate }) {
  const history = useHistory();
  if (!history.location.state || history.location.state.filter !== FILTER_READY) {
    history.push({pathname: history.location.pathname, state: {filter: FILTER_READY}});
  }
  
  function onClick(orderId) {
    history.push(`/pickandpack/${siteId}/orders/${orderId}`);
  }

  function handleSelected(selectedOrders) {
  }

  return (
    <React.Fragment>
      <OrderList
        orders={orders}
        // editMode={editMode}
        onPress={onClick}
        enableSelection={false}
        onSelected={handleSelected}
        buttonTitle=""
      />
    </React.Fragment>
  );
}
