import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Grid
} from "@material-ui/core"

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
  topBar: {
    position: "relative"
  },
  appBar: {
    position: "fixed",
    bottom: 0,
    top: "auto"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {},
  message: {
    height: "100%",
    fontSize: "2em",
    backgroundColor: "#eceff1"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OverPickingDialog({ open, onCancel, onContinue, onFix }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.topBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Picking Error
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid className={classes.message} container direction="column" justify="center" alignItems="center" spacing={5}>
          <Grid item>
              <ErrorOutlineIcon style={{ color: "red", fontSize: 150}}/>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              The quantity entered is 200% above estimated
            </Typography>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" className={classes.button} size="large" onClick={onFix}>
              Back to fix the item
            </Button>
          </Grid>
          <Grid item />
          <Grid item>
            <Button color="secondary" style={{ color: "red" }} onClick={onContinue} className={classes.button}>
              Continue Anyway
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
