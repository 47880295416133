import axios from "axios";
import https from "https";
import { BASE_AUTO_SCALE_URL, AUTO_SCALE_SUPPORTED_CUSTOMERS } from "../config";

const SUPPORTED_CUSTOMERS = AUTO_SCALE_SUPPORTED_CUSTOMERS.split(",");

const axiosInst = axios.create({
    httpsAgent: new https.Agent({  
        rejectUnauthorized: false
    })
});

export async function getWeightFromScale() {
    let response;
    response = await axiosInst.get(BASE_AUTO_SCALE_URL).catch(e => console.error(e));

    if (!response) {
        return false;
    }

    const payload = response.data;
    const { err, weight, unit, stable } = payload;

    if (err !== 1) {
        // Error state
        console.error(`Auto scale returned an error (code: ${err}), payload: `, payload);
        return false;
    }

    if (unit !== 0) {
        console.error(`Auto scale returned invalid unit number: ${unit}, expected: 0 (KG), payload: `, payload);
        return false;
    }

    if (stable === false) {
        console.error(`Auto scale returned stable=false, payload: `, payload);
        return false;
    }

    let parsedWeight = 0;
    try {
        parsedWeight = parseFloat(weight);
    } catch {
        console.error("Parsing weight from Auto Scale failed, payload: ", payload);
        return false;
    }

    return parsedWeight;
}

// TODO: this is a temporary mapping to for piloting the auto scale feature.
export function isAutoScaleSupported(customer_id) {
    return SUPPORTED_CUSTOMERS.includes(customer_id.toString());
}