import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import * as geoApi from "../../../services/geoApi";
import * as wcApi from "../../../services/wcApi";

import FormSelect from "../../UI/FormSelect";
import { CircularProgress, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  }
}));

export default function ForceSiteField({ customer, onCustomerUpdated }) {
  const classes = useStyles();
  const [sites, setSites] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentSite, setCurrentSite] = useState(customer.site_of_service);

  useEffect(() => {
    geoApi.fetchAllSites().then(result => {
      setSites(result.filter(site => site.isactive));
    });
    return () => {};
  }, []);

  async function handleSiteChanged(newSiteName) {
    const newSite = sites.find(site => site.name === newSiteName);
    if (!newSite) return;

    // Update site in database
    setIsSaving(true);
    const payload = {
      meta_data: [
        {
          key: "site_of_service",
          value: newSite["site-id"]
        }
      ]
    };
    const result = await wcApi.updateCustomer(customer.id, payload);
    onCustomerUpdated(result.data);
    setCurrentSite(newSite["site-id"]);
    setEditMode(false);
    setIsSaving(false);
  }

  // Find Default
  let selectedSite = null;
  if (currentSite) {
    const index = sites.findIndex(site => site["site-id"] === customer.site_of_service);
    if (index >= 0) {
      selectedSite = sites[index].name;
    }
  }

  if (sites.length === 0) {
    return (
      <div>
        <b>Force Site: </b> <CircularProgress size="18px" />
      </div>
    );
  }

  if (editMode) {
    return (
      <div>
        <b>Force Site:</b>{" "}
        {sites.length > 0 && (
          <FormSelect
            small
            values={sites.map(site => site.name)}
            initialValue={selectedSite}
            onChange={handleSiteChanged}
            fullWidth={false}
          />
        )}
      </div>
    );
  } else {
    return (
      <div onClick={() => setEditMode(true)} className={classes.clickable}>
        <b>Force Site:</b> {selectedSite || "[not set]"}
        {isSaving && <LinearProgress />}
      </div>
    );
  }
}
