import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { isEmpty, isEqual, isNumber } from "lodash";
import Cookies from "js-cookie";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import PaymentTypes from "../../../enums/PaymentTypes";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleIcon from '@material-ui/icons/AddCircle';

import * as twilioApi from "../../../services/twilioApi";
import * as wcApi from "../../../services/wcApi";
import * as shookit from "../../../utils/shookit";
import * as woocommerce from "../../../utils/woocommerce";

import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import { OrderItemsList } from "./OrderItemsList";
import { Box, Typography } from "@material-ui/core";

import Customer from "../Customer";
import Dialog from "./Dialog";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingTop: 60,
    paddingBottom: 160
  },
  notes: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  noBag: {
    float: "right",
    position: "relative",
    "& svg": { position: "absolute", right: 0 }
  },
  noBagsText: {
    float: "right",
    position: "relative",
    paddingRight: "20px",
    fontSize: "18px"
  },
  useBags: {
    position: "absolute", 
    left: '-36px',
    color: '#2eb49f',
    top: '4px',
    height: '20px',
    width: '20px'
  },
  useBags2: {
    position: "absolute", 
    left: '-48px',
    color: '#2eb49f',
    top: '4px',
    height: '20px',
    width: '20px'
  }
}));

export default function Order({ match, history }) {
  const location = useLocation();

  const orderId = match.params.orderId;
  const siteId = match.params.siteId;
  const [notFound, setNotFound] = useState(false);
  const [products, setProducts] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(
    Boolean(Cookies.get(`arrived-${orderId}`, false))
  );

  let readOnly = false;

  function reloadOrder() {
    // console.log(">>>> Reloading order");
    wcApi
      .fetchOrderDetails(orderId)
      .then(result => {
        if (result) {
          result = woocommerce.flattenOrderMetadata(result);
          result = processLineitems(result);

          if (!customerDetails || customerDetails.id !== result.customer_id) {
            wcApi
              .fetchCustomerDetail(result.customer_id)
              .then(customer => {
                if (customer) {
                  let customerFlatData = woocommerce.flattenMetadata(customer);
                  setCustomerDetails(customerFlatData);
                } else {
                  setNotFound(true);
                }
              })
              .catch(err => setNotFound(true));
          }
          setOrderDetails(result);
        } else {
          setNotFound(true);
        }
      })
      .catch(err => {
        setNotFound(true);
      });
  }

  // Load order details
  useEffect(() => {
    reloadOrder();
  }, [orderId]);

  // Load Products details (e.g. photos)
  useEffect(() => {
    if (!orderDetails) {
      return;
    }
    const productIds = orderDetails["line_items"].map(item => item.product_id);

    // Optimize request, don't to it if already loaded
    if (products) {
      const equals = isEqual(
        Object.keys(products).sort(),
        productIds.map(x => "" + x).sort()
      );
      if (equals) {
        return () => {};
      }
    }

    wcApi
      .fetchProductDetails(productIds)
      .then(result => {
        // console.log(result);
        setProducts(result);
      })
      .catch(err => {});
  }, [orderDetails]);

  // Count Items
  let packedItems = "";
  let totalItems = "";
  if (!isEmpty(orderDetails)) {
    packedItems = orderDetails.line_items.filter(
      item => item.isFullyPacked !== null
    ).length;
    totalItems = orderDetails.line_items.length;
  }

  // Set Read Only according to status
  if (
    !isEmpty(orderDetails) &&
    ["ON_ITS_WAY", "ARRIVED"].includes(shookit.getShookiterStatus(orderDetails))
  ) {
    readOnly = true;
  }

  // If order is not marked as seen
  else if (!isEmpty(orderDetails) && !shookit.isSeen(orderDetails)) {
    shookit.updateOrderSeenStatus(orderId, true);
  }

  // If order is marked as PENDING and there is at least 1 packed item
  if (
    !isEmpty(orderDetails) &&
    shookit.getShookiterStatus(orderDetails) === "PENDING" &&
    packedItems > 0
  ) {
    console.log(">>> Updaing status to fulfilling");
    shookit.updateShookiterStatus(orderId, "FULFILLING");
  }

  const updateLineItemMetadata = (item, key, value) => {
    // console.log(`>>> Updating ${item.id}   ${key} => ${value}`);
    wcApi.updateItemMeta(orderId, item.id, item.product_id, key, value);
    reloadOrder();
  };

  function onItemSeen(item) {
    updateLineItemMetadata(item, shookit.seenKey, true);
  }

  async function onItemUpdated(
    item,
    product,
    selectedQuantity,
    selectedWeight
  ) {
    if (selectedQuantity === "" && selectedWeight === "") {
      return reloadOrder();
    }
    let result = await shookit.updateLineItemForPacking(
      orderId,
      item,
      product,
      selectedQuantity,
      selectedWeight
    );

    if (result) {
      result = woocommerce.flattenOrderMetadata(result);
      result = processLineitems(result);
      setOrderDetails(result);
      
      const status = shookit.getShookiterStatus(orderDetails)
      if ([shookit.statusValues.READY, shookit.statusValues.ON_ITS_WAY].includes(status)) {
        // the coupons will be reapplied on submit otherwise.
        await wcApi.reapplyCoupons({ orderId });
      }
    } else {
      reloadOrder();
    }
  }

  const isCompany = order => {
    return order.shipping.company && !isEmpty(order.shipping.company);
  };

  const paymentMethod = () => {
    if (
      customerDetails.payment_method &&
      customerDetails.payment_method in PaymentTypes
    ) {
      return PaymentTypes[customerDetails.payment_method];
    }

    return "Not Set";
  };
  const shouldBill = order => {
    if (!isCompany(order)) {
      return true;
    }
    let method = paymentMethod();
    if (method === PaymentTypes.preorder) {
      return true;
    }

    return false;
  };

  async function changeOrderStatus() {
    let nextStatus = "READY";

    const currentStatus = shookit.getShookiterStatus(orderDetails);
    if (currentStatus === "READY") {
      nextStatus = "ON_ITS_WAY";
      await wcApi.sendPickupSMS({ orderId });
      if (customerDetails['subscribe_on_the_way_sms'] !== "0") {
        twilioApi.sendOrderOnItsWay(
          orderId,
          orderDetails.billing.phone,
          "il",
          orderDetails.zendesk_id || 0
        );
      }
    }
    if (currentStatus === "ON_ITS_WAY") {
      nextStatus = "ARRIVED";

      Cookies.set(`arrived-${orderId}`, true, { expires: 7 });
      setDisabledSubmitButton(true);
      await wcApi.completeOrder(orderId);
    }

    await shookit.updateShookiterStatus(orderId, nextStatus);
    history.goBack();
  }

  async function finishOrderWithMissingItems() {
    // Send SMS
    if (customerDetails['subscribe_missing_sms'] !== "0") {
      wcApi.sendMissingItemsSms({ orderId });
    }
    await wcApi.reapplyCoupons({ orderId });      
    changeOrderStatus();
  }

  function getMissingItems() {
    return orderDetails.line_items
      .filter(
        item =>
          parseFloat(item.shookit_shipped_quantity) === 0 ||
          parseFloat(item.shookit_shipped_weight) === 0
      )
      .map(item => ({
        name: item.name,
        count: item.quantity,
        comment: item.shookit_requested_unit || "Unit"
      }));
  }

  function countMissingItems() {
    return orderDetails.line_items.filter(
      item =>
        parseFloat(item.shookit_shipped_quantity) === 0 ||
        parseFloat(item.shookit_shipped_weight) === 0
    ).length;
  }

  async function handleSubmit() {
    const currentStatus = shookit.getShookiterStatus(orderDetails);

    // We show the modal (+the sms) only when the order it being fulfilled
    if (["FULFILLING", "PENDING"].includes(currentStatus)) {
      if (countMissingItems() > 0) {
        setTimeout(() => setShowModal(true), 400);
        return;
      }
      await wcApi.reapplyCoupons({ orderId });
    }
    changeOrderStatus();
  }

  function getSubmitTitle(status) {
    switch (status) {
      case "PENDING":
      case "FULFILLING":
        return "Ready";
      case "READY":
        return "On It's Way";
      case "ON_ITS_WAY":
        return "Arrived";
      case "ARRIVED":
        return undefined;

      default:
        return "Save";
    }
  }

  async function handleChangeBoxQty(boxQty) {
    let result = await wcApi.updateOrderMeta(orderId, "boxes_quantity", boxQty);
    if (result.data) {
      result = woocommerce.flattenOrderMetadata(result.data);
      result = processLineitems(result);
      setOrderDetails(result);
    }
  }

  return (
    <>
      <CssBaseline />

      <Dialog
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        onApprove={() => {
          setShowModal(false);
          finishOrderWithMissingItems();
        }}
      />

      {orderDetails && products ? (
        <Switch>
          <Route exact path="/pickandpack/:siteId/orders/:orderId">
            <TopBar
              title={`Picking order ${orderId}`}
              onBackClicked={history.goBack}
              onProfileClicked={() =>
                history.replace(`/pickandpack/${siteId}/orders/${orderId}/user`)
              }
            />

            <OrderDetailsPage
              orderDetails={orderDetails}
              products={products}
              onItemUpdated={onItemUpdated}
              readOnly={readOnly}
            />

            {shookit.getShookiterStatus(orderDetails) !== "ARRIVED" && (
              <BottomBar
                packedItems={packedItems}
                totalItems={totalItems}
                onDone={handleSubmit}
                doneTitle={getSubmitTitle(
                  shookit.getShookiterStatus(orderDetails)
                )}
                disabled={disabledSubmitButton}
                boxesQuantity={orderDetails.boxes_quantity ? Number(orderDetails.boxes_quantity) : 0}
                onChangeBoxesQty={handleChangeBoxQty}
                // onSave={shookit.getShookiterStatus(orderDetails) === "READY" ? handleSubmit : undefined}
                // saveTitle={shookit.getShookiterStatus(orderDetails) === "READY" ? "On Its Way" : undefined}
              />
            )}
          </Route>

          <Route exact path="/pickandpack/:siteId/orders/:orderId/user">
            <TopBar
              title={`Picking order ${orderId}`}
              onBackClicked={history.goBack}
              onCartClicked={() =>
                history.replace(`/pickandpack/${siteId}/orders/${orderId}`)
              }
            />

            <Customer
              order={orderDetails}
              sendArrived={handleSubmit}
              disabledArrived={disabledSubmitButton}
            />
          </Route>
        </Switch>
      ) : (
        <React.Fragment>
          <TopBar
            title={`Picking order ${orderId}`}
            onBackClicked={history.goBack}
            onProfileClicked={() =>
              history.replace(`/pickandpack/${siteId}/orders/${orderId}/user`)
            }
          />
          <Box style={{ marginTop: "200px" }} textAlign="center">
            <CircularProgress size="100px" />
          </Box>
        </React.Fragment>
      )}
    </>
  );
}

/*
 * Return value:
 *   null - Not packed yet
 *   false - Items missing
 *   true - Packed OK
 */
function isFullyPacked(item) {
  const requested = parseFloat(item.shookit_requested_quantity);
  if (item.hasOwnProperty("shookit_shipped_quantity")) {
    return requested === parseFloat(item.shookit_shipped_quantity);
  } else if (item.hasOwnProperty("shookit_shipped_weight")) {
    const shipped = parseFloat(item.shookit_shipped_weight);
    return (
      requested - shookit.MAX_WEIGHT_DIFFERENCE <= shipped &&
      shipped <= requested + shookit.MAX_WEIGHT_DIFFERENCE
    );
  } else {
    return null;
  }
}

function processLineitems(order) {
  if (!("line_items" in order) || order === null) {
    return order;
  }

  const newLineItems = order.line_items.map(item => {
    item = woocommerce.flattenMetadata(item);
    item.isFullyPacked = isFullyPacked(item);
    return item;
  });

  order.line_items = newLineItems;

  return order;
}

function OrderDetailsPage({
  orderDetails,
  products,
  onItemUpdated,
  readOnly
}) {
  const classes = useStyles();
  console.log(">>>>>use bags:", orderDetails._shipping_usebags);
  return (
    <Grid
      container
      className={classes.grid}
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      <Grid item>
        <Paper className={classes.notes}>
          {orderDetails._shipping_usebags === "1" && (
            <>
              <div className={classes.noBag}>
                <LocalMallIcon />
                <ClearIcon color="error" />
              </div>
            </>
          )}
          {(!orderDetails._shipping_usebags || orderDetails._shipping_usebags === "0") && (
            <>
              <div className={classes.noBag}>
                <LocalMallIcon />
                <AddCircleIcon className={classes.useBags} />
                <AddCircleIcon className={classes.useBags2} />
              </div>
            </>
          )}
          <b>Order Notes:</b> {orderDetails.customer_note || "----"}
        </Paper>
      </Grid>
      <OrderItemsList
        items={orderDetails["line_items"]}
        products={products}
        onItemUpdated={onItemUpdated}
        readOnly={readOnly}
      />
    </Grid>
  );
}
