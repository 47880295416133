import React, { useState, createRef } from "react";
import clsx from "clsx";
import * as autoScale from "../../../services/autoScaleApi";

import {
  makeStyles,
  Grid,
  Box,
  Button,
  LinearProgress
} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";

import NumberInput from "../../UI/Mobile/NumberInput";

import { __, getCurrentLanguage } from "../../../utils/locale";

import * as woocommerce from "../../../utils/woocommerce";
import * as shookit from "../../../utils/shookit";
import * as conf from "../../../config";
import OverPickingDialog from "./OverPickingDialog";
import { useWooUser } from "../../../utils/authentication";


const useStyles = makeStyles(theme => ({
  item: {
    marginTop: theme.spacing(2),
    background: "white",
    padding: "5px",
    boxShadow:
      "0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15)",
    width: "100%"
    // marginBottom: theme.spacing(1)
  },
  itemPhotoContainer: {
    // backgroundColor: "#607d8b",
    width: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  itemImage: {
    maxHeight: "80px",
    maxWidth: "80px",
    width: "auto",
    height: "auto"
  },
  itemDetails: {
    padding: theme.spacing(0.8, 1.6)
  },
  itemStatus: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1)
  },
  itemForm: {
    flexWrap: "nowrap",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderTop: "1px #e0e0e0 solid"
  },
  highlight: {
    backgroundColor: "#bbdefb"
  },
  itemValid: {
    color: "#2196f3"
  },
  itemInValid: {
    color: "red"
  },
  invalidInput: {
    background: "#ffcdd2"
  }
}));

// TODO remove siteId this when all site are supported for auto scale
export function OrderItem({ item, product, onSave, readOnly }) {
  const wooUser = useWooUser();
  const classes = useStyles();
  const {
    pricingMethod,
    requestedUnit,
    requestedQuantity
  } = woocommerce.extractQuantityForOrderItem(item);
  const flatItem = woocommerce.flattenMetadata(item);
  const [invalidValues, setInvalidValues] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingWeight, setIsLoadingWeight] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [quantity, setQuantity] = useState(
    flatItem.shookit_shipped_quantity ||
      (pricingMethod === conf.WEIGHT_PRICING_METHOD
        ? requestedQuantity
        : requestedUnit === "KG"
        ? flatItem.shookit_shipped_weight
        : "")
  );
    
  const [weight, setWeight] = useState(flatItem.shookit_shipped_weight || "");
  const [showOverPickingModal, setShowOverPickingModal] = useState(false);

  const ref = createRef();

  function isValidValues() {
    const isQuantity = Boolean(!parseFloat(quantity));
    const isWeight = Boolean(!parseFloat(weight));

    if (pricingMethod === conf.FIXED_PRICING_METHOD) {
      return true;
    }
    return isQuantity === isWeight;
  }

  function checkPicking() {
    if (pricingMethod !== conf.FIXED_PRICING_METHOD) {
      if (parseFloat(weight) > estimatedWeight * shookit.MAX_ITEM_OVER_PACKING) {
        return false;
      }
    }

    if (parseFloat(quantity) > parseFloat(flatItem.shookit_requested_quantity) * shookit.MAX_ITEM_OVER_PACKING) {
      return false;
    }
    return true;
  }

  const checkChanges = async () => {
    // Make sure values are valid
    if (!isValidValues()) {
      setInvalidValues(true);
      return;
    }
    setEditMode(false);
    setInvalidValues(false);

    if (!checkPicking()) {
      setShowOverPickingModal(true);
    }
    else {
      await saveChanges()
    }
  };

  const saveChanges = async () => {
    setIsSaving(true);
    await onSave(quantity, weight);
    setIsSaving(false);
  };

  const handleContinueOverPicking = async () => {
    setShowOverPickingModal(false);
    await saveChanges()
  };

  const packedQuantity = woocommerce.formatPackedQuantity(flatItem);

  const estimatedWeight =
    flatItem.shookit_estimated_weight || shookit.estimatedWeight(item, product);

  const productName =
    woocommerce.flattenMetadata(product)[
      `translation_${getCurrentLanguage()}`
    ] || item.name;
  
  const toggleEditMode = () => {
    const newMode = !editMode;
    setEditMode(newMode);

    if (!newMode) {
      return;
    }

    if (pricingMethod === conf.FIXED_PRICING_METHOD) {
      if (requestedUnit !== "KG") {
        return;
      }
    }

    if (!item.shookit_shipped_weight && item.shookit_shipped_weight !== 0) {
      loadWeight();
    }
  };
  
  const loadWeight = async () => {
    if (!autoScale.isAutoScaleSupported(wooUser.id)) {
      return;
    }

    setIsLoadingWeight(true);
    setIsSaving(true);

    const weight = await autoScale.getWeightFromScale();

    if (weight !== false && weight >= 0) {
      if (pricingMethod === conf.FIXED_PRICING_METHOD && requestedUnit === "KG") {
        setQuantity(weight);
      } else {
        setWeight(weight);
      }
    }

    setIsLoadingWeight(false);
    setIsSaving(false);
  };

  const onInputLongPressed = () => {
    if (pricingMethod === conf.FIXED_PRICING_METHOD) {
      if (requestedUnit !== "KG") {
        return;
      }
    }

    if (item.shookit_shipped_weight || item.shookit_shipped_weight === 0) {
      loadWeight();
    }
  };

  return (
    <>
      <OverPickingDialog
        open={showOverPickingModal}
        onCancel={() => {
          setShowOverPickingModal(false);
        }}
        onContinue={handleContinueOverPicking}
        onFix={() => {
          setShowOverPickingModal(false);
          setEditMode(true);
        }}
      />

      <Grid
        ref={ref}
        item
        className={clsx(classes.item, {
          [classes.highlight]: item.isFullyPacked === null
        })}
        onClick={() => {
          !readOnly && editMode === false && setEditMode(true);
          // if (!editMode) {
          //   ref.current.scrollIntoView({
          //     behavior: "smooth",
          //     block: "center"
          //   });
          // }
        }}
      >
        <Grid container>
          <Grid item className={classes.itemPhotoContainer}>
            {product && product.images.length > 0 ? (
              <img
                alt=""
                className={classes.itemImage}
                src={product.images[0].src}
              />
            ) : (
              <PhotoIcon fontSize="large" />
            )}
          </Grid>
          <Grid item xs>
            <Grid
              container
              direction="column"
              spacing={0}
              className={classes.itemDetails}
              onClick={() => !readOnly && toggleEditMode()}
            >
              <Grid item>
                <Box fontSize={"1.2em"} fontWeight="bold" mb={1}>
                  ({product.sku}) {productName}
                </Box>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={5}>
                    Ordered
                  </Grid>
                  <Grid item xs={7}>
                    {woocommerce.formatQuantityForItem(item)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  Packed
                </Grid>
                <Grid item xs={7}>
                  <b>
                    {packedQuantity === "Missing" ? (
                      <div className={classes.itemInValid}>{packedQuantity}</div>
                    ) : (
                      packedQuantity
                    )}
                  </b>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.itemStatus,
              item.isFullyPacked === false
                ? classes.itemInValid
                : classes.itemValid
            )}
          >
            {item.isFullyPacked === null ? (
              <FiberManualRecordOutlinedIcon />
            ) : (
              <FiberManualRecordIcon />
            )}
          </Grid>
        </Grid>
        {isSaving && <LinearProgress />}
        {editMode && (
          <Grid
            container
            className={classes.itemForm}
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item>Packed:</Grid>
            <Grid
              item
              className={invalidValues ? classes.invalidInput : undefined}
            >
              {pricingMethod === conf.FIXED_PRICING_METHOD ? (
                <NumberInput
                  description={__(requestedUnit ? requestedUnit : "Unit")}
                  value={quantity}
                  onChange={setQuantity}
                  isDecimal={requestedUnit === "KG"}
                  disabled={isLoadingWeight}
                  onLongPress={onInputLongPressed}
                />
              ) : requestedUnit === "Unit" ? (
                <>
                  <NumberInput
                    description={__("Units")}
                    value={quantity}
                    onChange={setQuantity}
                  />
                  <NumberInput
                    description={__("KG")}
                    value={weight}
                    onChange={setWeight}
                    disabled={isLoadingWeight}
                    isDecimal
                    onLongPress={onInputLongPressed}
                  />
                  {!isNaN(estimatedWeight) && (
                    <div>
                      Original Estimated Weight:{" "}
                      {parseFloat(estimatedWeight).toFixed(2)}
                    </div>
                  )}
                </>
              ) : (
                <NumberInput
                  description={__("KG")}
                  isDecimal
                  value={quantity}
                  onChange={setQuantity}
                />
              )}
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={checkChanges}>
                OK
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
