import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { intersection } from "lodash";
import { makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(2)
  },
  smallFont: {
    fontSize: "0.85rem"
  }
}));

const SELECT_ALL_VALUE = "select_all";

const FormSelectItem = ({
  label = null,
  values,
  initialValue,
  onChange,
  multiple = false,
  small = false,
  disabled = false,
  selectAll = false,
  fullWidth = true
}) => {
  const classes = useStyles();

  const [selected, setSelected] = useState(initialValue || []);
  const [selectedAll, setSelectedAll] = useState(false);
  const [possibilities, setPossibilities] = useState(values);

  useEffect(() => {
    if (intersection(possibilities, values).length !== values.length) {
      setPossibilities(values);
      setSelected([]);
      setSelectedAll(false);
    }
  });

  useEffect(() => {
    return () => {
      setPossibilities([]);
      setSelectedAll(false);
      setSelected([]);
    };
  }, []);

  const handleSelectChange = event => {
    if (multiple && selectAll) {
      const selectAllChecked = Boolean(event.target.value.filter(x => x === SELECT_ALL_VALUE).length);
      const selectAllClicked = selectAllChecked !== selectedAll;
      if (selectAllClicked) {
        if (!selectedAll) {
          onChange && onChange(values.filter(x => x !== SELECT_ALL_VALUE));
          setSelected([SELECT_ALL_VALUE, ...values]);
          setSelectedAll(true);
        } else {
          onChange && onChange([]);
          setSelected([]);
          setSelectedAll(false);
        }
      } else {
        onChange && onChange(event.target.value.filter(x => x !== SELECT_ALL_VALUE));
        const allIsSelected = intersection(event.target.value, values).length === values.length;
        setSelectedAll(allIsSelected);
        setSelected([
          ...event.target.value.filter(x => x !== SELECT_ALL_VALUE),
          ...(allIsSelected ? [SELECT_ALL_VALUE] : [])
        ]);
      }
    } else {
      setSelected(event.target.value);
      onChange && onChange(event.target.value);
    }
  };

  return (
    <FormControl className={clsx(!small && classes.field)} fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        className={clsx(small && classes.smallFont)}
        value={selected}
        multiple={multiple}
        onChange={handleSelectChange}
        inputProps={{
          name: `FormSelectItem-${selected}`,
          id: `FormSelectItem-${selected}`
        }}
        disabled={disabled}
        renderValue={value => {
          if (multiple && selectAll) {
            return value.filter(x => x !== SELECT_ALL_VALUE).join(", ");
          } else {
            return value;
          }
        }}>
        {selectAll && (
          <MenuItem value={SELECT_ALL_VALUE}>
            {intersection(selected, values).length === values.length ? "Select None" : "Select All"}
          </MenuItem>
        )}
        {values.map((value, index) => {
          return (
            <MenuItem key={`${value}${index}`} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FormSelectItem;
