import React from 'react'
import MaskedInput from "react-text-mask"

const PhoneInput = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}                        
            mask={['0', /[1-9]/, /[1-9]?/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u200B"}
            placeholder="054-568-7878" />
    );
};

export default PhoneInput;