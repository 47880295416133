import React from "react";
import _ from "lodash";

import { OrderItem } from "./OrderItem";
import { flattenMetadata } from "../../../utils/woocommerce";

export function OrderItemsList({
  items,
  products,
  onItemUpdated,
  readOnly
}) {
  let pickingProductOrder = {};
  Object.keys(products).forEach(productID => {
    let flatProduct = flattenMetadata(products[productID]);
    pickingProductOrder[productID] = flatProduct.picking_item_order ? flatProduct.picking_item_order : 0;
  });
  items.sort((first, second) => {
    return (parseInt(pickingProductOrder[first.product_id]) - parseInt(pickingProductOrder[second.product_id])) * -1;
  });

  return items.map(item => {
    const product = _.get(products, item.product_id, null);
    return (
      <OrderItem
        key={item.id}
        item={item}
        product={product}
        readOnly={readOnly}
        onSave={(quantity, weight) =>
          onItemUpdated(item, product, quantity, weight)
        }
      />
    );
  });
}
