import * as config from "../config";
import axios from "axios";
import * as wcApi from "./wcApi.js";


export async function listAll() {
  return axios.get(`${config.BASE_RECURRING_URL}/recur`);
}

export async function addRecurring(payload) {
  const response = await axios.post(`${config.BASE_RECURRING_URL}/recur`, payload);

  if (response.status === 200) {
    const orderId = payload.order_id;
    await wcApi.updateOrderMeta(orderId, "is_recurring", "1");
  }
}

export async function updateRecurring(recurdId, payload) {
  await axios.put(`${config.BASE_RECURRING_URL}/recur/${recurdId}`, payload);
}

export async function deleteRecurring(recurdId) {
  await axios.delete(`${config.BASE_RECURRING_URL}/recur/${recurdId}`);
}
