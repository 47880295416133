import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import * as config from "../../../config";
import _ from "lodash";

import PaperTitle from "../../UI/PaperTitle";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import TripOriginIcon from "@material-ui/icons/TripOrigin";
import TimerIcon from "@material-ui/icons/Timer";

import * as dataUtils from "./dataUtils";
import TimeslotsTable from "./TimeslotsTable";
import { Grid, Box } from "@material-ui/core";
import * as geoApi from "../../../services/geoApi";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  title: {
    flex: "1"
  },

  titleBar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  titleBarHighlighted: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    // color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonProgress: {
    color: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },

  error: {
    textAlign: "center",
    // borderRadius: "5px",
    // margin: "0 1.7rem 1rem",
    padding: "0.2rem",
    backgroundColor: theme.palette.secondary.light
  }
}));

const TimeslotsTableContainer = ({ siteId, site, setSite, currentBrand }) => {
  const classes = useStyles();

  const [editMode, setEditMode] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [groupedTsBackup, setGroupedTsBackup] = useState({});
  const [groupedTs, setGroupedTs] = useState({});

  // Load Data once
  useEffect(() => {
    async function fetchData() {
      const result = await geoApi.fetchShookitSiteInformation(siteId);

      setSite(result);

      // Process Data to frontend
      const groupedTimeslots = dataUtils.splitTimeslotsToRoles([config.ROLE_ALL_SITE], result);

      setGroupedTs(groupedTimeslots);
      _setBackupTimeslots(groupedTimeslots)
    }

    fetchData();
  }, [siteId]);

  function _setBackupTimeslots(groupedTsBackup) {
    setGroupedTsBackup(_.cloneDeep(groupedTsBackup));

  }
  function dismissChanges() {
    setEditMode(false);
    setErrorMessage(null);
    setGroupedTs(_.cloneDeep(groupedTsBackup));
  }

  async function saveChanges() {
    // Group changes to save to server
    site.timeslots = _.concat(...Object.values(groupedTs));

    setIsSaving(true);
    try {
      await geoApi.updateSite(siteId, site);
      setEditMode(false);
      setErrorMessage(null);
      _setBackupTimeslots(groupedTs)
    } catch (e) {
      setErrorMessage(`Error: ${e.response.data.status}`);
    }finally {
      setIsSaving(false);
    }
  }

  function assertColon(s) {
    // Handle 0:00 format
    if (s[1] === ":") {
      s = "0" + s;
      return s;
    }

    // Handle 00:00 format
    if (s.length >= 3 && s[2] !== ":") {
      s = s.slice(0, 2) + ":" + s.slice(2);
      return s;
    }
    return s;
  }

  function onCellChanged(role, dayIndex, timeslotIndex, newCellData) {
    var newGroup = _.cloneDeep(groupedTs);
    let isNightSlot = false ;
      for (let i = 0; i < newCellData.length; i++) {
        if ( !newCellData[i] || typeof newCellData[i].isNightWindow === typeof undefined) {
            continue;
        } else {
             isNightSlot = newCellData[i].isNightWindow ? true : false;
            break;
         } 
      }
    
    // If Cell is marked to remove
    if (newCellData[0] == null) {
      isNightSlot === true ? newGroup[role][dayIndex].nightTimeslots.splice(timeslotIndex, 1) : newGroup[role][dayIndex].timeslots.pop(timeslotIndex);
    }
    // Otherwise update Cell
    else {
      // Automatically add ":" symbol if user forgot
      newCellData[0] = assertColon(newCellData[0]);
      newCellData[1] = assertColon(newCellData[1]);
      // newGroup[role][dayIndex].timeslots[timeslotIndex] = newCellData;
      if (isNightSlot === true) {
        newGroup[role][dayIndex].nightTimeslots[timeslotIndex] = newCellData
      } else {
        if (isNightSlot === false && isNightSlot != "active") {
          newCellData.splice(3, 1)
        }
        newGroup[role][dayIndex].timeslots[timeslotIndex] = newCellData;
      } 
    }

    setGroupedTs(newGroup);
  }

  function updateTimeSlots(role , day) {
    var newGroup = _.cloneDeep(groupedTs);
    newGroup[role][day.day - 1] = day;
    setGroupedTs(newGroup);
  }

  async function createBrandWindows(brandName) {
    // Duplicate default windows to the new brandName
    var newGroup = _.cloneDeep(groupedTs);

    // Duplicate Default windows
    var newWindows = _.cloneDeep(groupedTs[config.ROLE_ALL_SITE]);
    newWindows.forEach(element => {
      element.role = brandName;
      //remove max orders
      _.forEach(element.timeslots,timeslot=> timeslot.splice(3,1));
    });
    newGroup[brandName] = newWindows;

    setGroupedTs(newGroup);
    setEditMode("TIMESLOTS");
  }

  function deleteCustomWindows(brandName) {
    var newGroup = _.cloneDeep(groupedTs);

    delete newGroup[brandName];

    setGroupedTs(newGroup);
    setEditMode("TIMESLOTS");
  }

  function renderDuplicateWindow(brandName) {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid container>
          <Box pt={3} textAlign="center" width="100%">
            <b>{brandName}</b> is currently using the default windows
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={3} pb={4} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => {
                createBrandWindows(brandName);
              }}
            >
              <TimerIcon className={classes.extendedIcon} />
              Duplicate and Overwrite default windows
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper className={classes.paper}>
      {/* Title Toolbar */}
      {editMode ? (
        <EditHeader currentBrand={currentBrand} onCancel={dismissChanges} onSave={saveChanges} isSaving={isSaving} />
      ) : (
        <NormalHeader
          currentBrand={currentBrand}
          onTimeslotEdit={() => setEditMode("TIMESLOTS")}
          onBlockEdit={() => setEditMode("BLOCKING")}
          onDeleteCustomWindow={() => deleteCustomWindows(currentBrand)}
        />
      )}

      {/* Error Message */}
      {errorMessage && <div className={classes.error}>{errorMessage}</div>}

      {/* Timeslots Table */}
      {site ? (
        /* For new brand - allow duplicate */
        currentBrand in groupedTs ? (
          <TimeslotsTable
            editMode={editMode}
            data={groupedTs[currentBrand]}
            onCellChanged={(dayIndex, timeslotIndex, newCellData,) => {
              onCellChanged(currentBrand, dayIndex, timeslotIndex, newCellData);
            }}
            nightWindowChanged={(day) => {
              updateTimeSlots(currentBrand , day)
            }} 
          />
        ) : (
          renderDuplicateWindow(currentBrand)
        )
      ) : (
        <LinearProgress />
      )}
    </Paper>
  );
};

function NormalHeader({ onTimeslotEdit, onBlockEdit, currentBrand, onDeleteCustomWindow }) {
  const classes = useStyles();

  return (
    <div className={classes.titleBar}>
      <div className={classes.title}>
        <PaperTitle>Timeslots for {currentBrand}</PaperTitle>
      </div>

      {currentBrand !== config.ROLE_ALL_SITE && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={onDeleteCustomWindow}
        >
          <TripOriginIcon className={classes.extendedIcon} />
          Use Default
        </Button>
      )}
      <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onBlockEdit}>
        <BlockIcon className={classes.extendedIcon} />
        Block Timeslots
      </Button>
      <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onTimeslotEdit}>
        <EditIcon className={classes.extendedIcon} />
        Edit Timeslots
      </Button>
    </div>
  );
}

function EditHeader({ onSave, isSaving, onCancel, currentBrand }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleBarHighlighted}>
        <div className={classes.title}>
          <Typography component="h2" variant="h6" color="textPrimary" gutterBottom>
            Timeslots for {currentBrand}
          </Typography>
        </div>

        <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onCancel}>
          <ClearIcon className={classes.extendedIcon} />
          Dismiss Changes
        </Button>

        {/* <div className={classes.wrapper}> */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={onSave}
          disabled={isSaving}
        >
          <CheckIcon className={classes.extendedIcon} />
          Save Changes
        </Button>
      </div>
      {isSaving && <LinearProgress />}
    </>
  );
}

export default TimeslotsTableContainer;
