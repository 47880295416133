import React from "react";
import styled, { css } from "styled-components";

export const Stepper = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: row;
  position: relative;

  /* Horizontal Line */
  &::before {
    content: "";
    border-top: 2px solid white;
    position: absolute;
    width: calc(100% - 64px);
    /* top: 30px; */
    /* top: 24px; */
    top: 21px;
    margin: 0 32px;
  }
`;

export const Item = styled.li`
  list-style: none;
  /* margin-top: 56px; */
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8rem;

  & div {
    opacity: 0.6;
  }

  /* Circles */
  &::before {
    content: "";
    background: #ffffff;
    width: 16px;
    height: 16px;
    border: 2px solid #58be8c;
    border-radius: 50%;
    z-index: 2;
    /* margin-bottom: -44px;
    margin-top: 24px; */
    /* margin-bottom: -38px;
    margin-top: 18px; */
    margin-bottom: -36px;
    margin-top: 14px;
  }

  ${props =>
    props.checked &&
    css`
      &::after {
        content: "";
        background: #58be8c;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 0;
        /* margin-bottom: -44px;
        margin-top: 27px; */
        /* margin-bottom: -38px;
        margin-top: 21px; */
        margin-top: 17px;
        z-index: 2;
        position: absolute;
      }
    `}

  ${props =>
    props.current &&
    css`
      & div {
        opacity: 1;
      }
      &::before {
        border: 2px solid #004238;
      }

      &::after {
        content: "";
        background: #298658;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 0;
        /* margin-bottom: -44px;
        margin-top: 27px; */
        /* margin-bottom: -38px;
        margin-top: 21px; */
        margin-top: 17px;
        z-index: 2;
        position: absolute;
      }
    `}
`;

export const Header = styled.div`
  width: 100px;
  text-align: center;
`;
// //
// export default function StepperDemo({}) {
//   return (
//     <Stepper>
//       <Item>
//         <Header>Hello</Header>
//       </Item>
//       <Item>
//         <Header>World</Header>
//       </Item>
//       <Item>
//         <Header>How</Header>
//       </Item>
//       <Item>
//         <Header>Are</Header>
//       </Item>
//       <Item>
//         <Header>Are</Header>
//       </Item>
//     </Stepper>
//   );
// }
// export const Stepper = styled.ul`
//   padding-left: 0;
//   display: flex;
//   flex-direction: row;
//   position: relative;

//   /* Horizontal Line */
//   &::before {
//     content: "";
//     border-top: 2px solid red;
//     position: absolute;
//     width: calc(100% - 64px);
//     bottom: 26px;
//     margin: 0 32px;
//   }
// `;

// export const Item = styled.li`
//   list-style: none;
//   /* margin-top: 56px; */
//   /* margin-bottom: 10px; */
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-transform: uppercase;
//   font-size: 0.8rem;

//   /* Circles */
//   &::before {
//     content: "";
//     background: #ffffff;
//     width: 16px;
//     height: 16px;
//     border: 2px solid red;
//     border-radius: 50%;
//     /* margin-top: 32px; */
//     z-index: 2;
//   }
// `;

// export const Header = styled.div`
//   width: 100px;
//   text-align: center;
// `;
// // //
// // export default function StepperDemo({}) {
// //   return (
// //     <Stepper>
// //       <Item>
// //         <Header>Hello</Header>
// //       </Item>
// //       <Item>
// //         <Header>World</Header>
// //       </Item>
// //       <Item>
// //         <Header>How</Header>
// //       </Item>
// //       <Item>
// //         <Header>Are</Header>
// //       </Item>
// //       <Item>
// //         <Header>Are</Header>
// //       </Item>
// //     </Stepper>
// //   );
// // }
