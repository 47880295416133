import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/styles";
import MaterialTextField from "@material-ui/core/TextField";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export const PaneForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  padding: 0 4px 1px 0;
  font-weight: bold;
`;

export const Input = styled.div`
  flex: 1;
`;


export function TextField({ label, value, onChange, editMode, required }) {
  return (
    <PaneForm>
      <Field>
        <Label>{label}:</Label>
        <Input>
          {editMode ? (
            <MaterialTextField
              fullWidth
              required={required}
              placeholder={label}
              value={value}
              onChange={onChange}
            />
          ) : (
            value
          )}
        </Input>
      </Field>
    </PaneForm>
  );
}

export function TextArea({ label, value, onChange, editMode, required }) {
  return (
    <PaneForm>
      <Field style={editMode ? { flexDirection: "column", alignItems: "stretch" } : undefined}>
        <Label>{label}:</Label>
        <Input>
          {editMode ? (
            <TextareaAutosize
              style={{ width: "100%" }}
              required={required}
              placeholder={label}
              value={value}
              onChange={onChange}
            />
          ) : (
            value
          )}
        </Input>
      </Field>
    </PaneForm>
  );
}

export function CheckboxField({ label, value, onChange, editMode }) {
  return (
    <PaneForm>
      <Field>
        <Label>{label}:</Label>
        <Input>
          {editMode ? <MaterialCheckbox checked={value} onChange={onChange} /> : value ? "Yes" : "No"}
        </Input>
      </Field>
    </PaneForm>
  );
}
