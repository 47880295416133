import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import parse from "date-fns/parse";

import * as config from "../../../config";
import * as timestamp from "../../../utils/timestamps";
import * as woocommerce from "../../../utils/woocommerce";
import * as wcApi from "../../../services/wcApi";

import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import TopBar from "./TopBar";
import BottomNavigation from "./BottomNavigation";

import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DatePicker } from "./DatePicker";

import { DonePage } from "./DonePage";
import { OnItsWayPage } from "./OnItsWayPage";
import { ReadyPage } from "./ReadyPage";
import { TodoPage } from "./TodoPage";
import { SearchResultPage } from "./SearchResultPage";
import SearchBar from "./SearchBar";

export const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  paper: {
    paddingTop: 60,
    paddingBottom: 50,
    backgroundColor: "#eceff1"
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: "auto",
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  },
  flipIcon: {
    transform: "scaleX(-1)"
  }
}));

const FILTER_TODO = 0;
const FILTER_READY = 1;
const FILTER_ONITSWAY = 2;
const FILTER_DONE = 3;

function parseDate(date) {
  // return new Date();
  if (date) {
    return parse(date, "d-M-yyyy", new Date());
  }
  return date;
}

function getToday() {
  var today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
}

export default function PickAndPack({ match }) {
  const siteId = match.params.siteId;
  const date = parseDate(match.params.date) || getToday();

  return <PickAndPackPage siteId={siteId} date={date} />;
}

function PickAndPackPage({ siteId, date }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  let filter = location.state ? location.state.filter : 0;
  const [orders, setOrders] = useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState(filter);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  const getPageFromFilter = () => {
    switch (selectedFilter) {
      case FILTER_READY:
        return 'ready';
      case FILTER_TODO:
        return 'todo';
      case FILTER_ONITSWAY:
        return 'ontheway';
      case FILTER_DONE:
        return 'arrived';
      default:
        return 'todo';
    }
  }

  async function reloadOrders() {
    setIsLoading(true);

    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60);

    // Get all order for today (for all sites, we will filter sites manually)
    wcApi
      .fetchPickingOrders(siteId, newDate.toISOString(), getPageFromFilter(), setLoadingProgress)
      .then(result => {
        // Flat meta_data for easy search
        result = woocommerce.flattenOrdersMetadata(result);

        // Filter by site_id if required
        // result = filterSite(result, siteId);
        // Sort
        // result = shookit.sortOrders(result);
        setOrders(result);
        setIsLoading(false);
      })
      .catch(err => {
        // TODO
        setIsLoading(false);
      });
  }

  useEffect(() => {
    reloadOrders();
  }, [date, siteId]);

  useEffect(() => {
    const interval = setInterval(() => {
      reloadOrders();
    }, 1000 * 60 * 2);

    return () => clearInterval(interval);
  });

  function setSelectedDate(date) {
    const parsedDate = timestamp.WCFormatDay(date);
    history.push(`/pickandpack/${siteId}/${parsedDate}`);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <TopBar title="Picking and Packing" siteId={siteId} selectedFilter={selectedFilter} date={date}/>

      <Paper square className={classes.paper}>
        <DatePicker selectedDate={date} onDateChanged={setSelectedDate} />
        {!isLoading || loadingProgress === 1 ? (
          <PageContent selectedFilter={selectedFilter} siteId={siteId} orders={orders} onOrderUpdate={reloadOrders} />
        ) : (
          <LoadingProgress percentages={loadingProgress} />
        )}
      </Paper>

      <BottomNavigation current={selectedFilter} onChange={setSelectedFilter} />
    </React.Fragment>
  );
}

function LoadingProgress({ percentages }) {
  return (
    <Box pt={5} pb={5} minHeight="400px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center">
            <Box fontSize="1.2rem">Loading...</Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress variant="determinate" value={percentages * 100} />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center">
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function PageContent({ selectedFilter, siteId, orders, onOrderUpdate }) {
  const [localFilter, setLocalFilter] = useState("");
  const [localSearch, setLocalSearch] = useState(null);

  function handleSearch(searchByNumber, searchInput) {
    if (searchByNumber) {
      setLocalFilter("");

      wcApi
        .fetchOrderDetails(searchInput)
        .then(result => {
          let order = woocommerce.flattenOrderMetadata(result);
          if (order[config.WC_ORDER_SITE] === siteId) {
            setLocalSearch([order]);
          } else {
            setLocalSearch([]);
          }
        })
        .catch(err => {
          // TODO
          setLocalSearch([]);
        });
    } else {
      setLocalFilter(searchInput);
      setLocalSearch(null);
    }
  }

  function handleClear() {
    setLocalFilter("");
    setLocalSearch(null);
  }

  var page = <></>;
  let filteredOrders = orders;

  // If local filter is set - we just need to filer the orders
  if (localFilter !== "") {
    filteredOrders = orders.filter(order => {
      // Built keywords string
      const searchValues = `${order.id} ${order.shipping.first_name} ${order.shipping.last_name} ${order._shipping_autofill_checkout}`.toLowerCase();

      // Split Keywords
      const keywords = localFilter.toLowerCase().split(" ");

      // Return true only if all keywords found
      return keywords.map(keyword => searchValues.includes(keyword)).every(res => res === true);
    });
  }

  if (selectedFilter === FILTER_TODO) {
    page = <TodoPage siteId={siteId} orders={orders} />;
  }

  if (selectedFilter === FILTER_READY) {
    page = <ReadyPage siteId={siteId} orders={orders} onOrderUpdate={onOrderUpdate} />;
  }

  if (selectedFilter === FILTER_ONITSWAY) {
    page = <OnItsWayPage siteId={siteId} orders={orders} onOrderUpdate={onOrderUpdate} />;
  }

  if (selectedFilter === FILTER_DONE) {
    page = <DonePage siteId={siteId} orders={orders} onOrderUpdate={onOrderUpdate} />;
  }

  // If local search override was enabled
  if (localSearch !== null) {
    filteredOrders = localSearch;
  }
  if (localSearch !== null || localFilter !== "") {
    page = <SearchResultPage siteId={siteId} orders={filteredOrders} />;
  }

  return (
    <>
      <SearchBar onSearch={handleSearch} onClear={handleClear} />
      {page}
    </>
  );
}
