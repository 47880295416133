import Cookies from "js-cookie";

const DEFAULT_LANGUAGE = "he";

const TRANSLATIONS = {
  ar: {
    kg: "كلغ",
    units: "وحدات",
  }
};

export const getCurrentLanguage = () => {
  const language = Cookies.get("language");
  return language ? language : DEFAULT_LANGUAGE;
};

export const setLanguage = language => {
  Cookies.set("language", language);
};

export const __ = text => {
  const translations = TRANSLATIONS[getCurrentLanguage()];
  return translations && translations[text.toLowerCase()]
    ? translations[text.toLowerCase()]
    : text;
};

export const LANGUAGES = { he: "Hebrew", ar: "Arabic" };
