import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";

import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddressDetailsPane from "./DetailsPane/AddressDetailsPane";
import CustomerInfoPane from "./DetailsPane/CustomerInfo";
import OrderInfoPane from "./DetailsPane/OrderInfo";
import ShippingInfoPane from "./DetailsPane/ShippingInfo";
import * as woocommerce from "../../../utils/woocommerce";
import * as wcApi from "../../../services/wcApi";
import * as timestamp from "../../../utils/timestamps";
import OrderPaymentMethodField from "./OrderPaymentMethodField";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  bold: {
    fontWeight: "bold"
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function OrderDetailsPane({ order, onOrderUpdated }) {
  const styles = useStyles();
  const [customerDetails, setCustomerDetails] = useState({});

  useEffect(() => {
    wcApi.fetchCustomerDetail(order.customer_id)
      .then(result => {
        let customerFlatData = woocommerce.flattenMetadata(result);
        setCustomerDetails(customerFlatData)
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  let paymentMethod = "";
  if (order.payment_method_title) {
    paymentMethod = order.payment_method;
  } else if (customerDetails.payment_method) {
    paymentMethod = customerDetails.payment_method
  }

  const onCustomerUpdated = () => {

  };

  return (
    <>
      <Paper className={styles.paper}>
        <OrderInfoPane order={order} onOrderUpdated={onOrderUpdated} />
      </Paper>
      <Paper className={styles.paper}>
        <CustomerInfoPane customerEmail={customerDetails.email} order={order} onOrderUpdated={onOrderUpdated} />
      </Paper>
      <Paper className={styles.paper}>
        <AddressDetailsPane order={order} onOrderUpdated={onOrderUpdated} />
      </Paper>

      <Paper className={styles.paper}>
        <ShippingInfoPane order={order} onOrderUpdated={onOrderUpdated} />
      </Paper>

      <Paper className={styles.paper}>
        <Typography color="primary">Payment Info</Typography>
        <div>
          <OrderPaymentMethodField
              order={order}              
              onOrderUpdate={onOrderUpdated}
          />
        </div>
        {order._creditguard_authorization && (
          <div>
            <b>Last digits:</b> {order._ccnumber}
          </div>
        )}
        <div>
          <b>Paid</b>:{" "}
          {order.set_paid === true || order.date_paid
            ? `On ${timestamp.formatISODateString(order.date_paid)}`
            : "No"}
        </div>
      </Paper>
    </>
  );
}

export default OrderDetailsPane;
