import defaultAxios from "axios";
import { BASE_GEO_API_URL, BOND_API_KEY } from "../config";


const geoAxios = defaultAxios.create({
    headers: {
        common: {
            "Authorization": `Bearer ${BOND_API_KEY}`
        }
    }
});


export async function fetchShookitSiteInformation(siteId) {
    const siteInfo = await geoAxios.get(`${BASE_GEO_API_URL}/sites/${siteId}`);

    return siteInfo.data;
}

export async function fetchShookitSiteForAddress({
    role,
    longitude,
    latitude
}) {
    return geoAxios.get(
        `${BASE_GEO_API_URL}/siteOfAddress?role=${role}&latitude=${latitude}&longitude=${longitude}`
    );
}

export async function fetchShookitSiteForPlaceId({ role, placeId }) {
    return geoAxios.get(
        `${BASE_GEO_API_URL}/isPlaceIdSupported/?role=${role}&place_id=${placeId}`
    );
}

export async function fetchShookitUpcomingTimeslots(siteId, role) {
  try {
    let timeslots = await geoAxios.get(
      `${BASE_GEO_API_URL}/sites/${siteId}/upcomingTimeslots?role=${role}`
  );  
  timeslots.data.windows = await insertNightTimeslots(timeslots.data.windows, siteId)
  return timeslots;
  }
  catch (e) {
    console.log('CATCH ERROR');
    console.log(e);
  }
    
}

const DAY_VALUES = {
    "Sun": 1,
    "Mon": 2,
    "Tue": 3,
    "Wed": 4,
    "Thu": 5,
    "Fri": 6,
    "Sat": 7,
  }


async function insertNightTimeslots(windows, siteId) {
    let siteData = await fetchShookitSiteInformation(siteId)
    let windowsToFill = []
    let todayMidnight = new Date()
    todayMidnight.setHours(0,0,0,0);
    const tommorowDate = Date.parse(todayMidnight) + (1000 * 60 * 60 * 24);

    //  Make sure night timeslot dont already exist
    for (let i = 0; i<windows.length; i++) {
      for (let j = 0; j < windows[i].timeslots.length; j++) {
        if (windows[i].timeslots[j].length > 3 && (windows[i].timeslots[j][3].isNightWindow != typeof undefined && windows[i].timeslots[j][3].isNightWindow  == true )) {
          return windows;
        }
      }
    }
    // Run on all windows, attach night timeslots based on the day
    windows = windows.map(window => {
        let windowDay = new Date(window.date.year, window.date.month - 1, window.date.day).toString().split(' ')[0];
        windowDay = DAY_VALUES[windowDay]
        let date = new Date(`${window.date.year}-${window.date.month}-${window.date.day}`);
        if (!siteData.timeslots[windowDay - 1].nightTimeslots || (tommorowDate > Date.parse(date))) {
            return window
        }    

        let allDaySlotsCount = siteData.timeslots[windowDay - 1].nightTimeslots.length + siteData.timeslots[windowDay - 1].timeslots.length;

        if (window.timeslots.length < allDaySlotsCount) {
            siteData.timeslots[windowDay - 1].nightTimeslots =  siteData.timeslots[windowDay - 1].nightTimeslots.reverse(); 
            siteData.timeslots[windowDay - 1].nightTimeslots.forEach(nightTimeslot => {
            if (nightTimeslot[2] === "active") {
                window.timeslots.unshift(nightTimeslot);
            }
            });
        }
        return window;
    })
  
    // Run from tommorow in night data, should find a day with not upcoming site and active nightslot, add nightslot
      let lastDayIndex = windows[windows.length -1].date
      const lastDayDate = Date.parse(new Date(lastDayIndex.month + '-' + lastDayIndex.day + '-' + lastDayIndex.year)); 
      const diffTime = lastDayDate - tommorowDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      let currentDayDate = tommorowDate;

      for (let i = 0; i < diffDays; i++) { 
        var a = new Date(currentDayDate);
        let windowDay = a.toString().split(' ')[0];
        windowDay = DAY_VALUES[windowDay]
        
        let currentDayDay = a.getDate();
        let currentDayMonth = a.getMonth() + 1;
        let currentYear = a.getFullYear();
        if(!siteData.timeslots[windowDay-1].nightTimeslots) {
          continue;
        }

        if ((siteData.timeslots[windowDay-1].timeslots.length === 0  || areAllTimeslotsInactive(siteData.timeslots[windowDay-1].timeslots)) && siteData.timeslots[windowDay-1].nightTimeslots.length > 0) {
            let unFilteredNightslots =  siteData.timeslots[windowDay-1].nightTimeslots;
            let filteredNightslots = unFilteredNightslots.filter(nightTimeslot => {
              return nightTimeslot[2] === "active";
            })

            if (filteredNightslots.length > 0) {
              windowsToFill.push({
                date : {day : currentDayDay, month : currentDayMonth, year : currentYear},
                id : windowsToFill.length + 1 + windows.length,
                timeslots: filteredNightslots
              }) 
            }
        }
         currentDayDate += (1000 * 60 * 60 * 24); 
      }

      windowsToFill.forEach(window => {
        let windowToFillDate = Date.parse(new Date(window.date.month + '-' + window.date.day + '-' + window.date.year))
        for (let i = 0; i < windows.length -1; i++) {
          let currWindowDate1 = windows[i].date;
          let currWindowDate2 = windows[i+1].date;
          let timeSlotDay1 = Date.parse(new Date(currWindowDate1.month + '-' + currWindowDate1.day + '-' + currWindowDate1.year));
          let timeSlotDay2 = Date.parse(new Date(currWindowDate2.month + '-' + currWindowDate2.day + '-' + currWindowDate2.year));

          if (windowToFillDate > timeSlotDay1 && windowToFillDate < timeSlotDay2) {
            windows.splice(i+1, 0, window);
            break;
          }

          if (windowToFillDate < timeSlotDay1) {
            windows.splice(i, 0, window)
            break;
          }
        }
      })
      return windows
  }
  
  function areAllTimeslotsInactive(timeslotsDay) {
    return timeslotsDay.every(window => {
      return window[2] != 'active';
    })
  }



export async function fetchAllSites() {
    const result = await geoAxios(`${BASE_GEO_API_URL}/sites`);

    return result.data.sites;
}

export async function uploadMapsFile(file, role) {
    const data = new FormData();
    data.append("file", file);
    data.append("role", role);

    return geoAxios.post(`${BASE_GEO_API_URL}/maps`, data);
}


export async function deleteRoleMaps(role) {
    return geoAxios.delete(`${BASE_GEO_API_URL}/maps/${role}`);
}

export async function isRoleModified(role) {
    const results = await geoAxios.get(`${BASE_GEO_API_URL}/maps/isModified/${role}`);
    return results.data
}

export async function addNewSite(site) {
    return await geoAxios.post(`${BASE_GEO_API_URL}/sites`, site);
}

export async function updateSite(siteId, site) {
    const result = await geoAxios.put(
        `${BASE_GEO_API_URL}/sites/${siteId}`,
        site
    );

    return result.data;
}

export async function deleteSite(siteId) {
    const result = await geoAxios.delete(`${BASE_GEO_API_URL}/sites/${siteId}`);

    return result.data;
}

export async function getSpecialDates(siteId) {
    const result = await geoAxios(`${BASE_GEO_API_URL}/special_date`, {
        params: {
            "site-id": siteId
        }
    });

    return result.data;
}

export async function createSpecialDates(siteId, data) {
    const result = await geoAxios.post(`${BASE_GEO_API_URL}/special_date`, {
        "site-id": siteId,
        ...data
    });

    return result.data;
}

export async function updateSpecialDates(siteId, entryId, data) {
    const result = await geoAxios.put(`${BASE_GEO_API_URL}/special_date/${entryId}`, {
        "site-id": siteId,
        ...data
    });

    return result.data;
}

export async function deleteSpecialDates(entryId) {
    const result = await geoAxios.delete(`${BASE_GEO_API_URL}/special_date/${entryId}`);

    return result.data;
}
