import { BASE_GI_URL } from "../config"
import Axios from "axios"

export async function invoicePendingOrders () {
  const baseUri = `${BASE_GI_URL}invoice_pending_orders`;

  await Axios.post(baseUri, {});
}

export async function payInvoicedOrders (customer, invoiceDocumentId, billingSum, billingDate, orders) {
  const baseUri = `${BASE_GI_URL}pay_invoiced_orders`;
  
  await Axios.post(baseUri, {
    customer, invoiceDocumentId, billingSum, billingDate, orders
  });
}
