import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { isEmpty } from "lodash";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

import { theme } from "./theme";
import AppBar from "./components/AppBar";

/* Authentication */
import { AuthContextProvider, useAuth } from "./utils/authentication";

import WooUserContext, { useWooUser } from "./utils/authentication/WooUserContext";

/* Pages */
import Login from "./components/Pages/Login";
import Sites from "./components/Pages/Sites";
import ManageTimeslots from "./components/Pages/ManageTimeslots";
import CustomerOrderPage from "./components/Pages/CustomerOrder";
import Customers from "./components/Pages/Customers";
import RecurringOrders from "./components/Pages/RecurringOrders";
import Billing from "./components/Pages/Billing"

/* Mobile Pages */
import MobileSitelist from "./components/MobilePages/SiteList";
import MobilePickAndPack from "./components/MobilePages/PickAndPack";
import MobileOrder from "./components/MobilePages/Order";
import MobileSidebar from "./components/MobilePages/SideBar";
import MobileProductSummaryReport from "./components/MobilePages/Reports/ProductSummaryReportPage";
import UserSiteAssignment from "./components/Pages/UserSiteAssignment";

// Config
import {LOGROCKET_API_KEY} from "./config"

import LogRocket from 'logrocket';

const NoMatch = () => {
  return <div>Oops, seems like you navigate yourself to 404 island  </div>;
};

function AuthenticatedApp() {
  const wooUser = useWooUser();
  if (typeof process.env.REACT_APP_LOGROCKET_API_KEY !== 'undefined' && process.env.REACT_APP_LOGROCKET_API_KEY && process.env.REACT_APP_LOGROCKET_API_KEY.length && wooUser != null) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_API_KEY)
    LogRocket.identify(wooUser.id, {
      name: `${wooUser.first_name} ${wooUser.last_name}`,
      email: wooUser.email
    });
  }

  return (
    <Switch>
      <Route exact path="/">
        {!isEmpty(wooUser) &&
          (wooUser.role === "shookiter" ? <Redirect to="/pickandpack" /> : <Redirect to="/customers" />)}
      </Route>

      <Route exact path="/login">
        <Redirect to="/" />
      </Route>

      <Route path="/customers" component={Customers} />

      <Route path="/sites">
        <AppBar>
          <Switch>
            <Route exact path="/sites" component={Sites} />
            <Route path="/sites/:siteId" component={ManageTimeslots} />
          </Switch>
        </AppBar>
      </Route>

      <Route exact path="/pickandpack">
        <MobileSidebar>
          <MobileSitelist />
        </MobileSidebar>
      </Route>

      <Route path="/pickandpack/:siteId">
        <MobileSidebar>
          <Switch>
            <Route path="/pickandpack/:siteId/orders/:orderId" component={MobileOrder} />
            <Route exact path="/pickandpack/:siteId" component={MobilePickAndPack} />
            <Route exact path="/pickandpack/:siteId/:date" component={MobilePickAndPack} />
            <Route exact path="/pickandpack/:siteId/reports/daily" component={MobileProductSummaryReport} />
          </Switch>
        </MobileSidebar>
      </Route>

      <Route path="/order">
        <AppBar>
          <Route path="/order/:orderId(\d+)" component={CustomerOrderPage} />
        </AppBar>
      </Route>

      <Route path="/assignment">
        <AppBar>
          <Route path="/assignment" component={UserSiteAssignment} />
        </AppBar>
      </Route>
      
      <Route path="/recurring">
        <AppBar>
          <Route path="/recurring" component={RecurringOrders} />
        </AppBar>
      </Route>


      <Route path="/billing">
        <AppBar>
          <Route path="/billing" component={Billing} />
        </AppBar>
      </Route>

      <Route component={NoMatch} />
    </Switch>
  );
}

function NotAuthenticatedApp() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login" component={Login} />

      {/* No Match */}
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}

function App() {
  const auth = useAuth();

  return (
    <>
    <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet"></link>
    <div className="App">      
      <CssBaseline />
      <Router>{auth.authenticated === "true" ? <AuthenticatedApp /> : <NotAuthenticatedApp />}</Router>
    </div>
    </>
  );
}

function AppContainer() {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <WooUserContext>
          <App />
        </WooUserContext>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default AppContainer;
