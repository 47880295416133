import React, { useState, useEffect } from "react";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";

const DELETE_CONFIRMATION_TIMEOUT = 20000;

const useStyles = makeStyles(theme => ({
  row: {
    transition: "box-shadow 0.4s"
  },
  highlighted: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  },
  buttonsCellHidden: {
    display: "hidden"
  },
  button: {
    padding: theme.spacing(1),
  },
  TableCell: {
      width: "140px"
  }
}));

const InteractiveTableRow = props => {
  const [editClicked, setEditClicked] = useState(false);
  const [deletePrompted, setDeletePrompted] = useState(false);
  const styles = useStyles();

  const mainRowClick = e => {
    props.onClickRow(props.rowIndex);
  };

  const onEditClicked = e => {
    setEditClicked(true);
    props.onClickEdit(props.rowIndex);
  };

  const onDiscardClicked = e => {
    setEditClicked(false);
    props.onClickDiscardEdit(props.rowIndex);
  };

  const onSaveClicked = e => {
    setEditClicked(false);
    props.onClickSave(props.rowIndex);
  };

  const onDeleteClicked = e => {
    if (!deletePrompted) {
      setDeletePrompted(true);
      setTimeout(() => setDeletePrompted(false), DELETE_CONFIRMATION_TIMEOUT);
    }
  };

  let rowClassNames = styles.row;
  let buttonsClassNames = `${styles.buttonsCellHidden} ${styles.TableCell}`;

  if (props.highlight) {
    rowClassNames += ` ${styles.highlighted}`;
    buttonsClassNames = styles.TableCell;
  } else {
    if (editClicked) {
      setEditClicked(false);
    }

    if (deletePrompted) {
      setDeletePrompted(false);
    }
  }

  let actionCell = null;
  if (props.highlight) {
    if (props.isLoading) {
      actionCell = <CircularProgress />;
    } else {
      actionCell = (
        <>
          {editClicked ? (
            <div>
              <IconButton className={styles.button} onClick={onSaveClicked}>
                <SaveIcon />
              </IconButton>
              <IconButton className={styles.button} onClick={onDiscardClicked}>
                <ReplayIcon />
              </IconButton>
            </div>
          ) : (
            <IconButton className={styles.button} onClick={onEditClicked}>
              <EditIcon />
            </IconButton>
          )}
          {deletePrompted ? (
            <Tooltip
              open={true}
              title="Are you sure you wish to delete this item?"
            >
              <IconButton
                className={styles.button}
                onClick={e => props.onClickDelete(props.rowIndex)}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              className={styles.button}
              onClick={onDeleteClicked}
              disabled={!props.isDeleteAllowed}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      );
    }
  }

  return (
    <>
      <TableRow className={rowClassNames} onClick={mainRowClick}>
        {props.children}
        <TableCell className={buttonsClassNames}>{actionCell}</TableCell>
      </TableRow>
    </>
  );
};

InteractiveTableRow.propTypes = {
  rowIndex: PropTypes.number,
  onClickRow: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDiscardEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickSave: PropTypes.func,
  highlight: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default InteractiveTableRow;
