import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import * as timestamps from "../../../utils/timestamps";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: "white"
  },
  noPadding: {
    padding: 0
  },
  tableButton: {
    padding: theme.spacing(1)
  }
}));

export function RecurringTable({ orders, onEdit, onDelete }) {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell>Original Order#</TableCell>
          <TableCell>Company</TableCell>
          <TableCell>Days</TableCell>
          <TableCell>Expiration</TableCell>
          <TableCell>Status</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders && orders.length > 0 ? (
          orders.map(order => (
            <TableRow key={order.id}>
              <TableCell>{order.order_id}</TableCell>
              <TableCell>{order.company}</TableCell>
              <TableCell>{order.days.map(day => timestamps.dayShortname(day)).join(", ")}</TableCell>
              <TableCell>{new Date(order.expire_date).toLocaleDateString("en-GB")}</TableCell>
              <TableCell>{order.is_active ? "Active" : "Not Active"}</TableCell>
              <TableCell align="right" className={classes.noPadding}>
                <IconButton className={classes.tableButton} onClick={() => onEdit(order)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <div>No orders found</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
