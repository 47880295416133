import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  noPadding: {
    padding: 0
  },
  tableButton: {
    padding: theme.spacing(1)
  }
}));

export default function SitesTable(props) {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Code</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Customer Type</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Region</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.sites.map(row => (
          <TableRow key={row["site-id"]}>
            <TableCell>{row["site-id"]}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.roles.join()}</TableCell>
            <TableCell>{row.isactive ? "Active" : "Not Active"}</TableCell>
            <TableCell>{row.region}</TableCell>
            <TableCell align="right" className={classes.noPadding}>
              <IconButton className={classes.tableButton} component={Link} to={`/sites/${row["site-id"]}`}>
                <QueryBuilderIcon />
              </IconButton>
              <IconButton className={classes.tableButton} onClick={() => props.onEdit(row)}>
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
