import React from "react";
import { Route, withRouter } from "react-router-dom";

import AppBar from "../../AppBar";
import Index from "./SearchCustomer";
import ViewCustomer from "./ViewCustomer";
import CreateCustomer from "./CreateCustomer";

const Customers = () => {
  return (
    <AppBar>
      <Route exact path="/customers" component={Index} />
      <Route path="/customers/view/:customerId(\d+)" component={ViewCustomer} />
      <Route exact path="/customers/create" component={CreateCustomer} />
    </AppBar>
  );
};

export default withRouter(Customers);
