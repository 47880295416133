import React, { useState, useEffect, useMemo } from "react";
import * as wcApi from "../../../services/wcApi";

function CustomerOrdersCount({ customerId }) {
  const [count, setCount] = useState(null);

  useEffect(() => {
    wcApi
      .fetchCustomerOrders(customerId, 1)
      .then(result => {
        setCount(result.totalItems);
      })
      .catch(err => {});
  }, [customerId]);

  return <span>{count || "_"}</span>;
}

export default CustomerOrdersCount;
